import faunadb from 'faunadb'
import { tx } from '../modules/translate'

const { UnavailableError, Unauthorized, BadRequest } = faunadb.errors

export function isUnavailableErrorOrTerminatedRequest(error: any = {}) {
  return (
    error instanceof UnavailableError ||
    (error.message || '').includes('Request has been terminated')
  )
}

export const getErrorMessage = (error: Error | any) => {
  try {
    if (error?.response?.data?.reason) {
      return error.response.data.reason
    }
    if (error instanceof Unauthorized) {
      return tx('auth.errors.unauthorized')
    }

    if (error instanceof BadRequest) {
      const firstError = error.errors()[0]
      const { failures } = firstError

      if (failures) {
        return failures[0].description
      }

      return firstError.description
    }

    // Handle auth-service API errors
    if (error.request) {
      const body = JSON.parse(error.request.response)

      if (body.message) return body.message
    }

    return error.message
  } catch {
    return 'Something wrong happened!'
  }
}
