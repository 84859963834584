import produce from 'immer'
import { Action } from '../../utils/commonTypes'
import * as AlertTypes from './types'

const baseState: AlertTypes.Alert = {
  active: false,
  message: '',
  level: ''
}

export default (state: AlertTypes.Alert = baseState, action: Action): AlertTypes.Alert =>
  produce<AlertTypes.Alert>(state, draft => {
    switch (action.type) {
      case AlertTypes.SHOW_ALERT:
        draft.active = true
        draft.message = action.payload?.message
        draft.level = action.payload.level
        break

      case AlertTypes.HIDE_ALERT:
        draft.active = false
        break

      // no default
    }
  })
