import useSWR from 'swr'
import { getRestores } from './frontdoor/backup'
import { isRootDatabase } from '../databaseResource'
import { useEffect, useState } from 'react'
import usePermissions from '../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

const FAST_POLLING_INTERVAL = 10 // in seconds
const SLOW_POLLING_INTERVAL = 60 // in seconds

const useRestores = (baseDbPath: string, region?: string, onFinish?: () => void) => {
  const isRoot = isRootDatabase(baseDbPath)
  if (!isRoot && !region) throw 'Missing region parameter.'

  const [pollingInterval, setPollingInterval] = useState<number>(SLOW_POLLING_INTERVAL)
  const [activeRestoreCount, setActiveRestoreCount] = useState<number>(0)
  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.read
  )
  const hasReadPermissions = rolePermission && planPermission

  const cacheKey = isRoot
    ? ['restores', hasReadPermissions]
    : ['restores', region, baseDbPath, hasReadPermissions]

  const restores = useSWR(
    cacheKey,
    async () => {
      const restores = await getRestores({
        region,
        path: isRoot ? null : baseDbPath,
        states: ['Pending', 'InProgress', 'CancelRequested'],
        hasRequiredPermissions: hasReadPermissions
      })
      return restores.results || []
    },
    // Try to promptly detect completed restores as they finish.
    { refreshInterval: pollingInterval * 1000 }
  )

  const restoreData = restores.data
  const restoreRevalidate = restores.revalidate

  useEffect(() => {
    const count = (restoreData || []).length
    if (count < activeRestoreCount) {
      // We have fewer copies/restores than before, so some of them must have finished.
      onFinish()
    }
    setActiveRestoreCount(count)
  }, [restoreData, activeRestoreCount, onFinish])

  useEffect(() => {
    // If there is at least 1 active restore, poll every 10 seconds.
    if (activeRestoreCount > 0) setPollingInterval(FAST_POLLING_INTERVAL)
    if (activeRestoreCount === 0) setPollingInterval(SLOW_POLLING_INTERVAL)
    // Force a revalidation to ensure the new interval takes effect immediately.
    restoreRevalidate()
  }, [activeRestoreCount, restoreRevalidate])

  return restores
}

export default useRestores
