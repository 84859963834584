import { combineReducers } from 'redux'
import { reducer as databases } from './modules/databaseResource'
import { reducer as classes } from './modules/classResource'
import { reducer as indexes } from './modules/indexResource'
import { reducer as documents } from './modules/documentResource'
import { reducer as keys } from './modules/keyResource'
import { reducer as flash } from './modules/flash'
import alert from './modules/alert/reducer'
import modal from './modules/modal/reducer'
import loaders from './modules/loaders/reducer'
import { cacheReducer as cache } from './utils/fetch'

const reducers = combineReducers({
  databases,
  classes,
  indexes,
  documents,
  alert,
  modal,
  loaders,
  keys,
  flash,
  cache
})

export default reducers
