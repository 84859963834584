import difference from 'lodash/difference'
import { MemberClass } from '../../../modules/roles'
import MemberClassCard from './MemberClassCard'
import { tx } from '../../../modules/translate'
import ResourceSelect from './ResourceSelect'

type MembershipSectionProps = {
  classes: Record<string, any>[] | null | undefined
  membership: MemberClass[]
  onChange: (membership: MemberClass[]) => void
}

export default function MembershipSection({
  classes,
  membership,
  onChange
}: MembershipSectionProps) {
  const classOptions = getClassOptions()
  const isEditable = !!onChange

  function handleClassOptionChange(event) {
    addMembershipClass(event.currentTarget.value)
  }

  function addMembershipClass(selectedClass) {
    if (!classes || selectedClass === '') return
    const class_ = classes.find(class_ => class_.name === selectedClass)
    if (!class_) return

    onChange(
      membership.concat({
        resource: class_.ref,
        predicate: null
      })
    )
  }

  function handleMemberClassChange(updatedMemberClass) {
    if (!onChange) return

    onChange(
      membership.map(memberClass =>
        memberClass.resource.equals(updatedMemberClass.resource) ? updatedMemberClass : memberClass
      )
    )
  }

  function handleMemberClassRemove(removedMemberClass) {
    onChange(
      membership.filter(memberClass => !memberClass.resource.equals(removedMemberClass.resource))
    )
  }

  function getClassOptions() {
    if (!classes) return []

    const classNames = classes.map(class_ => class_.name)
    const membershipResourceNames = membership.map(m => m.resource.value.id)

    return difference(classNames, membershipResourceNames)
  }

  return (
    <section className="section">
      <div className="section__header">
        <div className="section__control">
          <ResourceSelect
            onChange={handleClassOptionChange}
            resourceOptions={classOptions}
            placeholderText={tx('role.addMemberCollection')}
          />
        </div>
      </div>

      {membership.length > 0 && (
        <div className="section__body">
          <div className="card-list">
            <div className="card-list-body">
              {membership.map(memberClass => (
                <MemberClassCard
                  key={memberClass.resource.value.id}
                  memberClass={memberClass}
                  onRemove={handleMemberClassRemove}
                  onChange={handleMemberClassChange}
                  isEditable={isEditable}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
