import { query } from 'faunadb'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import routes, { createRoute } from '../../../config/routes'
import { BackupConfiguration, Snapshot } from '../../../modules/api/frontdoor/backup'
import useBackupConfiguration from '../../../modules/api/useBackupConfiguration'
import useDatabases from '../../../modules/fauna/useDatabases'
import * as DatabaseResource from '../../../modules/databaseResource'
import * as ModalActions from '../../../modules/modal/actions'
import { tx } from '../../../modules/translate'
import { onDatabaseDeleteComplete } from '../../../utils/settings/databaseSettingsUtils'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import DatabaseForm from './DatabaseForm'
import { useContext } from 'react'
import { GlobalDispatchContext } from '../../../global-state'
import { DRAWER_TOGGLE } from '../../../global-state/reducer'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

export type EditProps = {
  match: any
  history: any
  updateDatabase: typeof DatabaseResource.actions.update
  fetchDatabase: typeof DatabaseResource.actions.fetch
  database?: typeof query.Database
  removeDatabase: typeof DatabaseResource.actions.remove
  showModal: typeof ModalActions.showModal
  hideModal: typeof ModalActions.hideModal
  onClose?: () => void
  onRestore?: (snapshot: Snapshot) => void
  onCopy?: (snapshot: Snapshot) => void
  onDelete?: () => void
  backupConfiguration?: BackupConfiguration
  showBackupSettingsOnly?: boolean
  shouldRedirectOnSubmit?: boolean
}

function DatabasesEdit({
  match,
  history,
  updateDatabase,
  fetchDatabase,
  database,
  removeDatabase,
  showModal,
  hideModal,
  onClose,
  onRestore,
  onCopy,
  onDelete,
  backupConfiguration,
  showBackupSettingsOnly,
  shouldRedirectOnSubmit = true
}: EditProps) {
  const region = match.params.region
  const databasePath = match.params.dbPath
  const parentPath = DatabaseResource.databaseParentPathFromPath(databasePath)

  const [isSaving, setIsSaving] = useState(false)

  const { revalidate: refreshDatabases } = useDatabases(parentPath, region)
  const { revalidate: refreshBackupConfiguration } = useBackupConfiguration(region, databasePath)

  const dispatch = useContext(GlobalDispatchContext)

  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.write
  )
  const hasBackupConfigurationPermissions = rolePermission && planPermission

  useEffect(() => {
    if (databasePath) {
      fetchDatabase({
        databasePath
      })
    }
  }, [databasePath, fetchDatabase])

  function handleDeleteClick() {
    if (!database) return

    const onOk = () => {
      removeDatabase({
        parentPath,
        name: database.name,
        onRemoveComplete: () => {
          hideModal()
          onDatabaseDeleteComplete(databasePath, database.name)
          const redirectPath = parentPath
            ? createRoute(routes.db.byId.path, parentPath, region)
            : routes.home.path
          refreshDatabases()
          history.push(redirectPath)
        }
      })
    }

    showModal({
      content: tx('database.messages.deleteConfirm', [database.name]),
      onOk,
      onCancel: hideModal,
      showOk: true,
      showCancel: true,
      okText: tx('actions.remove'),
      cancelText: tx('actions.cancel')
    })
  }

  function onSubmit(values) {
    if (!database) return

    setIsSaving(true)
    updateDatabase({
      values: {
        ...values,
        originalName: database.name,
        hasBackupConfigurationPermissions
      },
      parentPath,
      onComplete: () => {
        setIsSaving(false)
        refreshDatabases()
        refreshBackupConfiguration()

        // The only time we should redirect after editing is if we bring up the edit drawer
        //  on a specific database page (as opposed to the database table or backups page) because
        //  the current url will no longer be valid.
        if (shouldRedirectOnSubmit) {
          const redirectDatabasePath = parentPath ? `${parentPath}/${values.name}` : values.name
          const route = createRoute(routes.db.byId.path, redirectDatabasePath, region)
          history.push(route)
          // after redirect, hide the drawer which rendered this component
          dispatch({
            type: DRAWER_TOGGLE,
            payload: ''
          })
        } else {
          onClose()
        }
      }
    })
  }

  return (
    <div className="padding-y-2">
      {!database ? (
        <>{tx('loading.loading')}</>
      ) : (
        <DatabaseForm
          backupConfiguration={backupConfiguration}
          loading={isSaving}
          onCancel={typeof onClose === 'function' ? onClose : history.goBack}
          onRestore={onRestore}
          onCopy={onCopy}
          onSubmit={onSubmit}
          dbName={database?.name ?? ''}
          region={region}
          onDeleteClick={typeof onDelete === 'function' ? onDelete : handleDeleteClick}
          isEdit
          databasePath={databasePath}
          showBackupSettingsOnly={showBackupSettingsOnly}
        />
      )}
    </div>
  )
}

const mapState = (state, { match }) => {
  return {
    database: DatabaseResource.byName(state, match.params.dbPath)
  }
}

export const DatabasesEditWithoutBreadcrumbs = connect(mapState, {
  updateDatabase: DatabaseResource.actions.update,
  fetchDatabase: DatabaseResource.actions.fetch,
  removeDatabase: DatabaseResource.actions.remove,
  ...ModalActions
})(DatabasesEdit)

export default withBreadcrumbs(({ location }) => {
  return [
    {
      label: tx('actions.edit'),
      path: location.pathname
    }
  ]
})(DatabasesEditWithoutBreadcrumbs)
