import React, { FC } from 'react'
import { Region } from '../../../modules/auth/session/regions'
import { Select, SelectProps } from '../../shared/Select'

export type RegionSelectProps = SelectProps

export const GLOBAL_REGION = 'global'
export const CUSTOM_FOOTPRINT = '@@CUSTOM_FOOTPRINT'

export const makeOptions = (regions: Region[], canRequestOther: Boolean = true) => {
  const options = [
    {
      label: 'AVAILABLE',
      options: regions.map(r => {
        return {
          rate: r.rate,
          label: r.label,
          value: r.regionPrefix
        }
      })
    }
  ]
  if (canRequestOther)
    options.push({
      label: 'REQUEST',
      options: [
        {
          label: 'Custom footprint',
          value: CUSTOM_FOOTPRINT,
          rate: null
        }
      ]
    })
  return options
}

export const RegionSelect: FC<RegionSelectProps> = props => {
  return (
    <Select
      {...props}
      placeholder="-Select-"
      isSearchable={false}
      renderGroupRightLabel={({ index }) =>
        index === 0 && <span className="region-select-label">RATE</span>
      }
      renderOptionRightLabel={({ rate }) =>
        rate != null && <span className="region-select-label">{rate.toFixed(1)}X</span>
      }
    />
  )
}
