import * as React from 'react'
import Well from '../../shared/Well'
import Icon from '../../shared/Icon'
import { Link, useParams } from 'react-router-dom'
import { tx } from '../../../modules/translate'
import routes, { createRoute } from '../../../config/routes'

type Props = {
  classes: Record<string, any>[]
  displayAction?: boolean
  muted?: boolean
  databasePath: string
  description: boolean
  classEmptyMessage?: string
}

export default function EmptyIndex({
  classes,
  displayAction,
  muted,
  databasePath,
  description,
  classEmptyMessage
}: Props) {
  const { region } = useParams()
  if (classes && classes.length === 0) {
    return (
      <Well
        muted={muted}
        shadow
        title={classEmptyMessage || tx('index.empty.class_')}
        icon={<Icon name="arrow-circle-left" />}
        description={
          description && (
            <div className="container container--small">
              <p>{tx('index.messages.classEmpty')}</p>
            </div>
          )
        }
      />
    )
  }

  return (
    <Well
      shadow
      title={tx('index.empty.title')}
      icon={<Icon name="list" />}
      action={
        <Link
          className="btn btn-success"
          to={createRoute(routes.indexes.new.path, databasePath, region)}
        >
          {tx('index.actions.new')}
        </Link>
      }
      description={
        description && (
          <div className="container container--small">
            <p>{tx('index.messages.empty')}</p>
          </div>
        )
      }
    />
  )
}

EmptyIndex.defaultProps = {
  description: true
}
