import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'
import * as DatabaseResource from '../../../modules/databaseResource'
import { tx } from '../../../modules/translate'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Icon from '../../shared/Icon'

type MapStateToProps = {
  database: Record<string, any>
}

type Params = {
  dbPath: string
  region?: string
}

type MapDispatchToProps = {
  fetchDatabase: Function
}

type Props = RouteComponentProps<Params> & MapStateToProps & MapDispatchToProps

function DatabaseSettings({ database, fetchDatabase, match }: Props) {
  const databasePath = match.params.dbPath || ''
  const region = match.params.region || ''

  useEffect(() => {
    document.title = 'Database Settings - Fauna'
  }, [])

  useEffect(() => {
    fetchDatabase({ databasePath })
  }, [databasePath, fetchDatabase])

  return (
    <>
      <ContentHeader>
        {tx('database.settings')}
        <Link
          className="btn btn-subtle-link"
          to={createRoute(routes.db.edit.path, databasePath, region)}
        >
          <Icon name="cog" />
          {tx('actions.edit')}
        </Link>
      </ContentHeader>

      {database && (
        <div className="padding-y-4">
          <div className="form-group">
            <label htmlFor="">
              {tx('database.name')} {tx('attributes.name')} (cannot contain spaces)
            </label>
            <p data-hj-suppress>{database.name}</p>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state, { match }) => {
  return {
    database: DatabaseResource.byName(state, match.params.dbPath)
  }
}

const mapDispatchToProps = {
  fetchDatabase: DatabaseResource.actions.fetch
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withBreadcrumbs(({ location }) => {
    return [
      {
        label: 'Settings',
        path: location.pathname
      }
    ]
  })(DatabaseSettings)
)
