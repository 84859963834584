import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import FadeIn from 'react-fade-in'
import { Link } from 'react-router-dom'
import faunaLogo from '../../../assets/images/fauna-logotype.svg'
import routes from '../../../config/routes'
import { OtpErrorReason } from '../../../modules/api/auth-service/login'
import * as auth from '../../../modules/auth'
import emailAndPasswordStrategy from '../../../modules/auth/strategies/emailAndPassword'
import { useForm } from '../../../modules/form'
import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import GithubButton from './GithubButton'
import NetlifyButton from './NetlifyButton'
import { LegacyComponents } from 'frontend-components'
import OtpInput from './OtpInput'

const { Divider } = LegacyComponents

const SIGN_UP_URL = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/register`

type Props = {
  minimal?: boolean
  onSuccess: (response: auth.LoginResponse) => void
  onError: Function
}

type FormFields = {
  email: string
  password: string
  otp?: string
}

const FORGOT_PASSWORD_LINK = '/auth/forgot-password'

const initialValues =
  process.env.NODE_ENV === 'development'
    ? {
        email: 'test@test.com',
        password: '12345678'
      }
    : {
        email: '',
        password: ''
      }

const supportLink = (
  <a href="https://support.fauna.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">
    Support Team
  </a>
)

export default function SignInForm({ minimal, onSuccess, onError }: Props): React.ReactNode {
  const [isLoading, setIsLoading] = useState(false)
  const [isMFAFormVisible, setIsMFAFormVisible] = useState(false)
  const [isOtpCodeInvalid, setOtpCodeInvalid] = useState(false)
  const [isAccountLocked, setIsAccountLocked] = useState(false)
  const [isAnimationPaused, setAnimationPaused] = useState(true)
  const { formValues, handleChange } = useForm<FormFields>(initialValues)

  const handleSubmit = useCallback(
    (event?: React.SyntheticEvent<HTMLFormElement>): void => {
      if (event) {
        event.preventDefault()
      }

      setIsLoading(true)
      setOtpCodeInvalid(false)

      auth
        .login(emailAndPasswordStrategy, formValues)
        .then((result: auth.LoginResponse) => {
          setAnimationPaused(false)
          setTimeout(() => {
            onSuccess(result)
          }, 1700) //getting some time to show animation
        })
        .catch(error => {
          setIsLoading(false)
          if (error.reason === OtpErrorReason.OTP_INVALID_CODE) {
            setOtpCodeInvalid(true)
          } else if (error.reason === OtpErrorReason.OTP_REQUIRED) {
            setIsMFAFormVisible(true)
          } else if (error?.response?.data === 'Cooldown') {
            setIsAccountLocked(true)
          } else {
            onError(error)
          }
        })
    },
    [formValues, onSuccess, onError]
  )

  React.useEffect(() => {
    if (formValues.otp && formValues.otp.trim().length === 6) {
      handleSubmit()
    }
  }, [formValues.otp, handleSubmit])

  if (isAccountLocked) {
    return (
      <>
        <FadeIn>
          <h1> Account temporarily locked </h1>
          <div className="account-temporarily-locked">
            <span>
              {"We've "} detected an unusual number of attempts to login to your account. Your
              account has been locked for one hour. If you believe {" you've "} tripped this by
              mistake or have concerns please contact our {supportLink}.
            </span>
          </div>
        </FadeIn>
      </>
    )
  }

  return (
    <>
      {!isMFAFormVisible && (
        <>
          <div className={cn('verbiage', minimal && 'minimal')}>
            {!minimal ? (
              <>
                <h1>{tx('auth.messages.signIn')}</h1>
                <p>Get started for free. No credit card required.</p>
              </>
            ) : (
              <img src={faunaLogo} alt="Fauna logo" />
            )}
          </div>

          <GithubButton />
          {!minimal && <NetlifyButton />}

          <div className="sign-in-divider">
            <Divider>or</Divider>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">{tx('attributes.email')}</label>
              <input
                onChange={handleChange}
                id="email"
                name="email"
                type="email"
                value={formValues.email}
                required
                autoFocus
              />
            </div>

            <div className="form-group">
              <div className="password-label">
                <label htmlFor="password">{tx('attributes.password')}</label>
                <a
                  tabIndex={10}
                  href={FORGOT_PASSWORD_LINK}
                  className="forgot-password"
                  {...(minimal && {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  })}
                >
                  {tx('auth.actions.forgotPassword')}
                </a>
              </div>
              <input
                onChange={handleChange}
                id="password"
                name="password"
                type="password"
                value={formValues.password}
                required
              />
            </div>
            <div className="form-group">
              <Button loading={isLoading} className="btn-success" type="submit" block>
                {tx('auth.actions.enterNow')}
              </Button>
            </div>
          </form>
          <div className="no-have-account">
            {tx('auth.messages.noAccount')}
            <a className="margin-left-1" href={SIGN_UP_URL} target="_blank" rel="noreferrer">
              {tx('auth.actions.signUp')}.
            </a>
          </div>
        </>
      )}

      {isMFAFormVisible && (
        <FadeIn>
          <OtpInput
            onChange={otp => {
              handleChange({ currentTarget: { name: 'otp', value: otp } })
            }}
            isOtpCodeInvalid={isOtpCodeInvalid}
            isDisabled={isLoading}
            isAnimationPaused={isAnimationPaused}
          />

          <div className="no-have-account">
            <span>If you lost your OTP device, please contact our {supportLink}.</span>
          </div>
        </FadeIn>
      )}
    </>
  )
}
