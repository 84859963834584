import axios from 'axios'
import { rootSecret } from '..'
import { logAxiosError } from '../../../utils/log-helper'
import { useState } from 'react'
import { RequestStatus } from '..'
import { logout } from '../../auth/index'
import routes from '../../../config/routes'
import { useHistory } from 'react-router'
import * as cookies from '../../cookies/shortLivedTokenCookie'

export type UpdatePasswordData = {
  password: string
  new_password: string
  new_password_confirmation: string
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/auth/password`
export const CHECK_URL = `${process.env.REACT_APP_ACCOUNT_URL}/auth/check_password`

async function updatePassword(data: UpdatePasswordData) {
  try {
    const res = await axios.put(API_URL, data, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export async function checkPassword(input: string) {
  try {
    const response = await axios.post(
      CHECK_URL,
      { password: input },
      {
        headers: { secret: rootSecret() }
      }
    )
    if (response.data.token) {
      cookies.save({ token: response.data.token })
    }
    return response
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export default function usePassword() {
  const [updatePasswordStatus, setUpdatePasswordStatus] = useState<RequestStatus>(
    RequestStatus.idle
  )
  const history = useHistory()

  const update = {
    call: async (data: UpdatePasswordData) => {
      try {
        if (updatePasswordStatus !== RequestStatus.idle) return

        setUpdatePasswordStatus(RequestStatus.loading)
        await updatePassword(data)
        setUpdatePasswordStatus(RequestStatus.idle)
        logout()
        history.push(routes.auth.signIn.path)
      } catch (error) {
        setUpdatePasswordStatus(RequestStatus.idle)
        throw error
      }
    },
    status: updatePasswordStatus
  }

  return {
    update
  }
}
