import produce from 'immer'
import { Action } from '../../utils/commonTypes'
import * as ModalTypes from './types'

export type State = {
  show: boolean
  content: string | null | undefined
  onOk: Function | null | undefined
  onCancel: Function | null | undefined
  showOk: boolean
  showCancel: boolean
  okText: string | null | undefined
  cancelText: string | null | undefined
  level: string
}

const baseState: State = {
  show: false,
  content: null,
  onOk: null,
  onCancel: null,
  showOk: null,
  showCancel: null,
  okText: null,
  cancelText: null,
  level: ''
}

export default (state: State = baseState, action: Action): State =>
  produce<State>(state, draft => {
    switch (action.type) {
      case ModalTypes.SHOW_MODAL:
        const payload = action.payload
        draft.show = true
        draft.content = payload.content
        draft.onOk = payload.onOk
        draft.onCancel = payload.onCancel
        draft.showOk = payload.showOk
        draft.showCancel = payload.showCancel
        draft.okText = payload.okText
        draft.cancelText = payload.cancelText
        draft.level = payload.level
        break

      case ModalTypes.HIDE_MODAL:
        const reset = baseState
        draft.show = false
        draft.content = reset.content
        draft.onOk = reset.onOk
        draft.onCancel = reset.onCancel
        draft.showOk = reset.showOk
        draft.showCancel = reset.showCancel
        draft.okText = reset.okText
        draft.cancelText = reset.cancelText
        draft.level = reset.level
        break

      // no default
    }
  })
