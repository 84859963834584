import { useState, ReactElement, useMemo } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import Icon from '../shared/Icon'
import { Tab, Tabs, TabsProvider } from '../shared/Tabs'
import { tx } from '../../modules/translate'
import routes, { createRouteGenerator } from '../../config/routes'
import ContentHeader from '../layouts/ContentHeader'

function SecurityTabs(): ReactElement {
  const { dbPath, region } = useParams()
  const createRoute = createRouteGenerator(dbPath, region)
  const location = useLocation()
  const tabsByPath = useMemo(
    () => ({
      [createRoute(routes.keys.index.path)]: tx('keys.title'),
      [createRoute(routes.roles.index.path)]: tx('role.title'),
      [createRoute(routes.providers.index.path)]: tx('provider.title')
    }),
    [createRoute]
  )
  const [activeTab, setActiveTab] = useState(tabsByPath[location.pathname])

  const renderTabs = () => {
    return (
      <Tabs position="left">
        <Link to={createRoute(routes.keys.index.path)}>
          <Tab id={tx('keys.title')}>
            <Icon name="key" />
            <span className="margin-left-2">{tx('keys.title')}</span>
          </Tab>
        </Link>
        <Link to={createRoute(routes.roles.index.path)}>
          <Tab id={tx('role.title')}>
            <Icon name="users" />
            <span className="margin-left-2">{tx('role.title')}</span>
          </Tab>
        </Link>
        <Link to={createRoute(routes.providers.index.path)}>
          <Tab id={tx('provider.title')}>
            <Icon name="lock" />
            <span className="margin-left-2">{tx('provider.title')}</span>
          </Tab>
        </Link>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader tabs={renderTabs()} className="SecurityTabs margin-bottom-2" divider={false}>
        <span>{tx('security.title')}</span>
      </ContentHeader>
    </TabsProvider>
  )
}

export default SecurityTabs
