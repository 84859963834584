import { UsagePlanItem } from './types'
import Icon from '../../shared/Icon'
import { UsagePlan } from '../../../config/usagePlans'

enum PlanAction {
  current = 'current',
  selected = 'selected',
  downgrade = 'downgrade',
  upgrade = 'upgrade'
}

function calculateAction(plan: UsagePlan, current: UsagePlan, selected: UsagePlan) {
  const plansByPriceOrder = [
    UsagePlan.free,
    UsagePlan.individual,
    UsagePlan.team,
    UsagePlan.business
  ]

  if (plan === current) {
    return PlanAction.current
  } else if (plan === selected) {
    return PlanAction.selected
  } else if (plansByPriceOrder.indexOf(plan) < plansByPriceOrder.indexOf(current)) {
    return PlanAction.downgrade
  } else {
    return PlanAction.upgrade
  }
}

function getButtonText(action: PlanAction) {
  switch (action) {
    case PlanAction.current:
      return 'Current Plan'
    case PlanAction.selected:
      return 'Selected'
    case PlanAction.downgrade:
      return 'Contact support to downgrade'
    case PlanAction.upgrade:
      return 'Choose this plan'
    default:
      return 'Choose this plan'
  }
}

export default function PlanCard({
  planItem,
  selectedPlan,
  currentPlan,
  onSelect
}: {
  planItem: UsagePlanItem
  currentPlan: UsagePlan
  selectedPlan: UsagePlan
  onSelect: (plan: UsagePlan) => void
}) {
  const buttonAction = calculateAction(planItem.type, currentPlan, selectedPlan)
  const isSelected = buttonAction === PlanAction.selected
  const buttonClassName = `plan__button plan__button--${buttonAction}`

  return (
    <div
      className={`plan ${isSelected ? 'selected' : ''}`}
      data-testid={`selectPlan:${planItem.type}`}
      onClick={() => onSelect(planItem.type)}
    >
      <div className="plan__header">
        {isSelected && (
          <div className="plan__selected-mark">
            <Icon name="check" className="plan__selected-mark__icon" />
          </div>
        )}
        <span className="plan__name">{planItem.name}</span>
        <span className="plan__price">
          <span className="plan__price__symbol">$</span>
          <span className="plan__price__value">{planItem.price}</span>
          <span className="plan__price__period">/month</span>
        </span>
        <button type="button" className={buttonClassName}>
          {getButtonText(buttonAction)}
        </button>
      </div>

      <div className="plan__details">
        <ul className="plan__offers">
          {planItem.offers.map((offer, index) => (
            <li key={index} className="plan__offer">
              <Icon className="plan__offer__check-icon" name="check" />
              <p className="plan__offer__text">{offer}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
