import { useState } from 'react'
import { Match, RouterHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useSWR from 'swr'
import { errors as FaunaErrors } from 'faunadb'
import routes, { createRoute, createRouteGenerator } from '../../../config/routes'
import * as API from '../../../modules/api'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { showAlert, hideAlertDelay } from '../../../modules/alert/actions'
import RoleForm from './RoleForm'
import roleToRoleFormValues from './roleToRoleFormValues'
import roleFormValuesToFQLObject from './roleFormValuesToFQLObject'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'

type EditRoleProps = {
  match: Match
  history: RouterHistory
}

const INVALID_REF_CODE = 'invalid reference'

const findInvalidRefIndexes = (err: unknown): number[] => {
  if (!(err instanceof FaunaErrors.FaunaHTTPError)) {
    return []
  }

  return (
    err
      .errors()[0]
      ?.failures.filter(({ code }) => code === INVALID_REF_CODE)
      .map(({ field: [, index] }) => Number(index)) ?? []
  )
}

function EditRole({ match, history }: EditRoleProps) {
  const databasePath = match.params.dbPath || ''
  const region = match.params.region
  const roleId = match.params.roleId || ''
  const [isSaving, setIsSaving] = useState(false)
  const [invalidRefIndexes, setInvalidRefIndexes] = useState<number[]>([])
  const dispatch = useDispatch()
  const { data: role } = useSWR([databasePath, 'roles', roleId], () => {
    API.selectDatabase(databasePath)
    return API.role(roleId)
  })

  async function handleFormSubmit(values) {
    setIsSaving(true)

    try {
      const roleFQLValues = roleFormValuesToFQLObject(values)
      await API.updateRole(roleId, roleFQLValues)

      if (invalidRefIndexes.length) {
        setInvalidRefIndexes([])
      }

      if (values.name !== roleId) {
        history.push(
          createRoute(routes.roles.edit.path, databasePath, region).replace(':roleId', values.name)
        )
      }

      dispatch(showAlert('The role has been successfully updated.'))
      dispatch(hideAlertDelay())
    } catch (error) {
      const invalidRefIndexes = findInvalidRefIndexes(error)

      if (invalidRefIndexes.length) {
        return setInvalidRefIndexes(invalidRefIndexes)
      }

      dispatch(showAlert(getErrorMessage(error), 'error'))
    } finally {
      setIsSaving(false)
    }
  }

  function handleCancelClick() {
    history.goBack()
  }

  function handleOnDelete() {
    history.push(createRoute(routes.roles.index.path, databasePath, region))
  }

  if (!role) return <div>Loading...</div>

  const normalizedRole = roleToRoleFormValues(role)

  return (
    <RoleForm
      databasePath={databasePath}
      initialValues={normalizedRole}
      roleId={roleId}
      isLoading={isSaving}
      invalidRefIndexes={invalidRefIndexes}
      onSubmit={handleFormSubmit}
      onCancel={handleCancelClick}
      onDelete={handleOnDelete}
      onInvalidRefIndexesReset={() => setInvalidRefIndexes([])}
    />
  )
}

export default withBreadcrumbs(({ location, match }) => {
  const { dbPath, region } = match.params
  const createRoute = createRouteGenerator(dbPath, region)
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path)
    },
    {
      label: tx('role.title'),
      path: createRoute(routes.roles.index.path)
    },
    {
      label: tx('role.editTitle'),
      path: location.pathname
    }
  ]
})(EditRole)
