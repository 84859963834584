export function initializeForm(form: any) {
  if (!form) return form

  return Object.keys(form).reduce((newForm, key) => {
    let value

    if (form[key] === null || form[key] === undefined) {
      value = ''
    } else if (typeof form[key] === 'string') {
      value = form[key]
    } else if (typeof form[key] === 'object') {
      value = initializeForm(form[key])
    }

    return {
      ...newForm,
      [key]: value
    }
  }, {})
}
