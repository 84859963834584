import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './reducer'
import saga from './saga'

const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

export const setupStore = (initState?: any) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    reducer,
    initState,
    composeSetup(applyMiddleware(sagaMiddleware))
  ) as any

  sagaMiddleware.run(saga as any)

  return store
}
