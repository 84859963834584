import CardListItem from './CardListItem'
import { tx } from '../../../modules/translate'

export default function HelpfulResourcesCard(): JSX.Element {
  return (
    <div className="card card-sm">
      <div className="card-header">
        <b>{tx('helpful.helpfulResources')}</b>
      </div>

      <div className="card-body">
        <ul className="list list-block">
          <CardListItem
            href="https://docs.fauna.com/fauna/v4/learn/tutorials/fql/basics/"
            iconName="university"
            title={tx('helpful.learnFQLBasics.title')}
            subtitle={tx('helpful.learnFQLBasics.subtitle')}
          />

          <CardListItem
            href="https://docs.fauna.com/fauna/v4/learn/introduction/fql_for_sql_users"
            iconName="shapes"
            title={tx('helpful.fqlForSqlUsers.title')}
            subtitle={tx('helpful.fqlForSqlUsers.subtitle')}
          />

          <CardListItem
            href="https://docs.fauna.com/fauna/v4/api/fql/cheat_sheet"
            iconName="graduation-cap"
            title={tx('helpful.fqlCheatSheet.title')}
            subtitle={tx('helpful.fqlCheatSheet.subtitle')}
          />
        </ul>
      </div>
    </div>
  )
}
