import _ from 'lodash'
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import * as LoaderActions from '../loaders/actions'
import * as AlertActions from '../alert/actions'
import * as AlertTypes from '../alert/types'
import * as api from '../api'
import { Action, ResourceConfig } from './types'
import { getErrorMessage } from '../../utils/error'
import { logStatusCode, ResponseErrorTypes } from '../../utils/log-helper'

export default function createSaga(
  types: Record<string, any>,
  actions: Record<string, any>,
  functions: Record<string, any>,
  { name, plural, label }: ResourceConfig
) {
  function* fetch({ payload }: Action): Iterable<any> {
    try {
      api.selectDatabase(databaseParentPathFromPath(payload.databasePath))
      const result = yield call(functions.fetch, databaseIdFromPath(payload.databasePath))
      yield put(actions.fetchSuccess({ ...payload, [name]: result }))
    } catch (error) {
      yield call(handleError, error, payload, 'fetching')
    }
  }

  function* fetchList({ payload }: Action): Iterable<any> {
    try {
      api.selectDatabase(payload.databasePath)
      const result = yield call(functions.fetchList, payload)
      yield put(actions.fetchListSuccess({ ...payload, [plural]: result }))
      payload.onComplete && payload.onComplete(result)
    } catch (error) {
      payload.onComplete && payload.onComplete(null, error)
      yield call(handleError, error, payload, 'fetching a list from')
    }
  }

  function* fetchDocumentsByTerms({ payload }: Action): Iterable<any> {
    try {
      api.selectDatabase(payload.databasePath)

      const result = yield call(functions.fetchDocumentsByTerms, payload)
      yield put(actions.fetchDocumentsByTermsSuccess({ ...payload, [plural]: result }))
    } catch (error) {
      yield call(handleError, error, payload, 'fetching a list from')
    }

    payload.onComplete && payload.onComplete()
  }

  function* fetchDocumentByRef({ payload }: Action): Iterable<any> {
    try {
      api.selectDatabase(payload.databasePath)

      const result = yield call(functions.fetchDocumentByRef, payload)
      yield put(actions.fetchDocumentByRefSuccess({ ...payload, [plural]: result }))
    } catch (error) {
      yield call(handleError, error, payload, 'fetching a list from')
    }

    payload.onComplete && payload.onComplete()
  }

  function* create({ payload }: Action): Iterable<any> {
    try {
      yield put(LoaderActions.startLoad(types.resource.create.load))

      const result = yield call(functions.create, payload.values)
      // @ts-ignore
      const resourceName = result.name || result.ref.value.id
      const successPayload = { ...payload, [name]: result }
      yield put(actions.createSuccess(successPayload))

      // Sometimes you want to have actions where
      // the alert is not displayed after creation
      if (!payload.hideAlert) {
        const alertMessage = `${_.capitalize(label || name)} "${resourceName}" created.`
        yield put(AlertActions.showAlert(alertMessage))
        yield put(AlertActions.hideAlertDelay())
      }

      // copy resourceName into payload for reference
      payload._resourceName = resourceName
      payload.onComplete && payload.onComplete({ ...payload, result })
    } catch (error) {
      yield call(handleError, error, payload, 'creating')
      if (payload.onComplete) {
        yield fork(payload.onComplete, payload, error)
      }
    }

    yield put(LoaderActions.finishLoad(types.resource.create.load))
  }

  function* update({ payload }: Action): Iterable<any> {
    try {
      yield put(LoaderActions.startLoad(types.resource.update.load))

      const path = payload.parentPath || payload.databasePath
      api.selectDatabase(path)
      const result = yield call(functions.update, payload.values)
      // @ts-ignore
      const resourceName = result.name || result.ref.value.id
      const successPayload = { ...payload, [name]: result }
      yield put(actions.updateSuccess(successPayload))
      const alertMessage = `${_.capitalize(name)} "${resourceName}" updated.`
      yield put(AlertActions.showAlert(alertMessage))
      yield put(AlertActions.hideAlertDelay())
      payload.onComplete && payload.onComplete(payload)
    } catch (error) {
      payload.onComplete && payload.onComplete(payload, error)
      yield call(handleError, error, payload, 'updating')
    }
    yield put(LoaderActions.finishLoad(types.resource.update.load))
  }

  function* remove({ payload }: Action): Iterable<any> {
    try {
      yield put(LoaderActions.startLoad(types.resource.remove.load))

      api.selectDatabase(payload.parentPath)
      const result = yield call(functions.remove, payload)

      // @ts-ignore
      const resourceName = result.name || result.ref.value.id
      const successPayload = { ...payload, [name]: result }
      yield put(actions.removeSuccess(successPayload))
      const alertMessage = `${_.capitalize(name)} "${resourceName}" deleted.`
      yield put(AlertActions.showAlert(alertMessage))
      yield put(AlertActions.hideAlertDelay())

      payload.onRemoveComplete && payload.onRemoveComplete(payload)
    } catch (error) {
      logStatusCode(
        error.code ? error.code : error.response?.status,
        error,
        ResponseErrorTypes.HANDLED
      )
      payload.onRemoveComplete && payload.onRemoveComplete(payload, error)
      yield call(handleError, error, payload, 'deleting')
    }
    yield put(LoaderActions.finishLoad(types.resource.remove.load))
  }

  function* watchRequests(): Iterable<any> {
    yield takeLatest(types.collection.fetch.request, fetch)
    yield takeLatest(types.collection.fetchList.request, fetchList)
    yield takeLatest(types.collection.fetchDocumentsByTerms.request, fetchDocumentsByTerms)
    yield takeLatest(types.collection.fetchDocumentByRef.request, fetchDocumentByRef)
    yield takeLatest(types.resource.create.request, create)
    yield takeLatest(types.resource.update.request, update)
    yield takeLatest(types.resource.remove.request, remove)
  }

  function* handleError(error, payload) {
    yield put(actions.createError({ error }))

    if (!payload.noErrorAlert) {
      yield put(AlertActions.showAlert(getErrorMessage(error), AlertTypes.ERROR))
    }
  }

  return watchRequests
}

export const databaseIdFromPath = (path: string | null | undefined): string | null | undefined => {
  if (path) {
    const paths = path.split('/')
    return paths[paths.length - 1]
  }
}

export const databaseParentPathFromPath = (
  path: string | null | undefined
): string | null | undefined => {
  if (path) {
    const parts = path.split('/')
    path = parts.length === 1 ? undefined : _.dropRight(parts).join('/')
  }
  return path
}
