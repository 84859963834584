import * as React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import * as indexResource from '../../../modules/indexResource'
import { tx } from '../../../modules/translate'
import EmptyIndex from './EmptyIndex'

type Props = {
  fetchListIndexes: Function
  databasePath: string
  indexes: Record<string, any>[]
  selectedIndexes: Record<string, any>[]
  border: boolean
}

const { useEffect } = React

function IndexesTable({
  match,
  fetchListIndexes,
  databasePath,
  indexes,
  selectedIndexes,
  border
}: Props): React.ReactNode {
  const region = match.params.region

  useEffect(() => {
    fetchListIndexes({ databasePath })
  }, [databasePath, fetchListIndexes])

  function indexPath(index: Record<string, any>) {
    return databasePath
      ? `/indexes/${index.name}/@db/${region}/${String(databasePath)}`
      : `/indexes/${index.name}`
  }

  function isSelect(index: Record<string, any>) {
    return selectedIndexes.includes(index.name)
  }

  function renderLoading(): React.ReactNode {
    return <div>{tx('loading.loading')}</div>
  }

  return (
    <>
      {!indexes && renderLoading()}
      {indexes && indexes.length === 0 && <EmptyIndex databasePath={databasePath} />}
      {indexes && indexes.length > 0 && (
        <div className="scrollList">
          <table className={`table-card no-table-labels ${!border ? 'table--no-border' : ''}`}>
            <tbody>
              {indexes.map(index => (
                <tr key={index.name} className={`${isSelect(index) ? 'selected' : ''}`}>
                  <td>
                    <div>
                      <Link to={indexPath(index)} data-hj-suppress>
                        {index.name}
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

IndexesTable.defaultProps = {
  selectedIndexes: [],
  border: true
}

const mapStateToProps = (state, props) => {
  return {
    indexes: indexResource.all(state, props.databasePath)
  }
}

const mapDispatchToProps = {
  fetchListIndexes: indexResource.actions.fetchList
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndexesTable))
