import { call, put, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { Action } from '../../utils/commonTypes'
import * as AlertTypes from './types'
import * as AlertActions from './actions'

export function* hideAlertDelay({ payload }: Action): Iterable<any> {
  yield call(delay, payload)
  yield put(AlertActions.hideAlert())
}

export function* watchAlerts(): Iterable<any> {
  yield takeLatest(AlertTypes.HIDE_ALERT_DELAY, hideAlertDelay)
}
