// @ts-nocheck
import { values } from 'faunadb'

export type Privilege = {
  resource: Ref
  actions: {
    read?: boolean
    write?: boolean
    create?: boolean
    delete?: boolean
    history_read?: boolean
    history_write?: boolean
    unrestricted?: boolean
    call?: boolean
  }
}

export type MemberClass = {
  resource: Ref
  predicate?: string | null
}

export type Membership = MemberClass | MemberClass[]

export type RoleInput = {
  name: string
  membership: Membership
  privileges: Privilege | Privilege[]
}

export type RoleResource = RoleInput & {
  ref: Ref
}

export const builtInRoles = [
  { id: 'admin', name: 'Admin' },
  { id: 'server', name: 'Server' }
]

export const isBuiltInRole = (roleName: string) => builtInRoles.some(role => role.id === roleName)

export const createRoleValue = (roleName?: string) => {
  if (!roleName || roleName === '') return null

  if (isBuiltInRole(roleName)) return roleName

  return new values.Ref(roleName, values.Native.ROLES)
}

export const getRoleId = (role: values.Ref | string) => {
  if (role instanceof values.Ref) {
    return role.id
  }

  return role
}

export const byResourceType = (resourceType: string) => (privilege: Privilege): boolean => {
  const { resource } = privilege

  if (resource.collection === resourceType) return true
  if (resource.index === resourceType) return true
  if (resource.function === resourceType) return true

  return false
}
