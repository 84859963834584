import { connect } from 'react-redux'
import * as indexResource from '../../../modules/indexResource'
import { tx } from '../../../modules/translate'
import IconButton from '../../shared/IconButton'
import * as ModalActions from '../../../modules/modal/actions'

type Props = {
  indexName: string
  removeIndex: Function
  showModal: Function
  hideModal: Function
  databasePath: string
  index: Record<string, any>
  onRemove?: Function
}

function IndexForm({ index, removeIndex, showModal, hideModal, databasePath, onRemove }: Props) {
  function handleClick() {
    //const shouldRemove = window.confirm()
    const onOk = () => {
      removeIndex({
        parentPath: databasePath,
        databasePath,
        index
      })

      onRemove && onRemove(index)
      hideModal()
    }
    const onCancel = () => {
      hideModal()
    }
    showModal({
      content: tx('index.actions.confirmDelete', [index.name]),
      onOk,
      onCancel,
      showOk: true,
      showCancel: true,
      okText: tx('actions.remove'),
      cancelText: tx('actions.cancel')
    })
  }

  return (
    <IconButton color="muted" onClick={handleClick} label={tx('actions.remove')} icon="trash" />
  )
}

const mapStateToProps = (state, { indexName, databasePath }) => ({
  index: indexResource.byName(indexName, state, databasePath)
})

export default connect(mapStateToProps, {
  removeIndex: indexResource.actions.remove,
  ...ModalActions
})(IndexForm)
