export type RegionInfo = {
  displayName: string
  abbr: string
  frontdoorPrefix: string
  prefixAliases?: string[]
  rate: number
}

/*
  This list contains metadata about known region groups. This will be used to
  fill in any information that is missing from the LoginResponse. The
  prefixAliases field is used to rectify any discrepancies between how different
  services refer to the same region.
*/
export const DEFAULT_REGION_INFO: Record<string, RegionInfo> = {
  eu: {
    displayName: 'Europe',
    abbr: 'EU',
    frontdoorPrefix: 'eu-std',
    rate: 1.2
  },
  us: {
    displayName: 'United States',
    abbr: 'US',
    frontdoorPrefix: 'us-std',
    rate: 1
  },
  global: {
    displayName: 'Classic',
    abbr: 'C',
    frontdoorPrefix: 'classic',
    prefixAliases: ['legacy'],
    rate: 1.1
  }
}

/*
  Certain region groups will not be valid in every environment. This allows us
  to filter out invalid regions. For example the dev environment does not have a
  "classic" region group and so we should not attempt to fetch classic databases
  there.
*/
export const VALID_REGION_PREFIXES = process.env.REACT_APP_VALID_REGION_GROUPS
  ? process.env.REACT_APP_VALID_REGION_GROUPS.split(',')
  : Object.keys(DEFAULT_REGION_INFO)

/*
  The default region group is used as the secret to communicate with the
  auth-service.
*/
export const DEFAULT_REGION_PREFIX = process.env.REACT_APP_DEFAULT_REGION_GROUP ?? 'global'
