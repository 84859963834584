import { Action } from './types'

export default function createActions(types: Record<string, any>) {
  return {
    fetch(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.collection.fetch.request)
    },
    fetchSuccess(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.collection.fetch.success)
    },
    fetchError(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.collection.fetch.error)
    },

    fetchList(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.collection.fetchList.request)
    },
    fetchListSuccess(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.collection.fetchList.success)
    },
    fetchListError(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.collection.fetchList.error)
    },

    create(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.create.request)
    },
    createSuccess(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.create.success)
    },
    createError(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.create.error)
    },

    update(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.update.request)
    },
    updateSuccess(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.update.success)
    },
    updateError(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.update.error)
    },

    remove(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.remove.request)
    },
    removeSuccess(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.remove.success)
    },
    removeError(payload: Record<string, any> = {}): Action {
      return createAction(payload, types.resource.remove.error)
    },
    fetchDocumentsByTerms: (payload: Record<string, any> = {}): Action => {
      return { type: types.collection.fetchDocumentsByTerms.request, payload }
    },
    fetchDocumentsByTermsSuccess: (payload: Record<string, any> = {}): Action => {
      return { type: types.collection.fetchDocumentsByTerms.success, payload }
    },
    fetchDocumentsByTermsError: (payload: Record<string, any> = {}): Action => {
      return { type: types.collection.fetchDocumentsByTerms.error, payload }
    },
    fetchDocumentByRef: (payload: Record<string, any> = {}): Action => {
      return { type: types.collection.fetchDocumentByRef.request, payload }
    },
    fetchDocumentByRefSuccess: (payload: Record<string, any> = {}): Action => {
      return { type: types.collection.fetchDocumentByRef.success, payload }
    },
    fetchDocumentByRefError: (payload: Record<string, any> = {}): Action => {
      return { type: types.collection.fetchDocumentByRef.error, payload }
    }
  }
}

function createAction(payload: Record<string, any> = {}, type: string) {
  return { type, payload }
}
