export enum ScheduleCategory {
  DAILY,
  MONTHLY,
  WEEKLY
}

export type DatabaseBackupSchedule = {
  category: ScheduleCategory
  frequency: string
  pattern: RegExp
}

//  @databaseBackupScheduleOptions is used in database setting form and used across databases.
//  It's value should not be modified.
export const databaseBackupScheduleOptions: ReadonlyArray<Readonly<DatabaseBackupSchedule>> = [
  {
    category: ScheduleCategory.DAILY,
    frequency: '0 0 * * *',
    pattern: /^0\s+0\s+\*\s+\*\s+\*$/
  },
  {
    category: ScheduleCategory.WEEKLY,
    frequency: '0 0 * * 1',
    pattern: /^0\s+0\s+\*\s+\*\s+[1-7]$/
  },
  {
    category: ScheduleCategory.MONTHLY,
    frequency: '0 0 1 * *',
    pattern: /^0\s+0\s+([1][0-9]|[1-9]|[2][0-8]|L)\s+\*\s+\*$/
  }
]

export const getBackupScheduleCategory = (frequency: string) => {
  const databaseBackupScheduleOption = databaseBackupScheduleOptions.find(item =>
    item.pattern.test(frequency)
  )
  return databaseBackupScheduleOption?.category
}
