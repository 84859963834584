import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { values as FaunaValues } from 'faunadb'
import uniqWith from 'lodash/uniqWith'
import groupBy from 'lodash/groupBy'
import PrivilegesSection from './PrivilegesSection'
import MembershipSection from './MembershipSection'
import LazyCode from '../../shared/LazyCode'
import Button from '../../shared/Button'
import roleFormValuesToFQLString from './roleFormValuesToFQLString'
import { Tabs, Tab, TabsProvider, TabContent } from '../../shared/Tabs'
import { useForm } from '../../../modules/form'
import { byResourceType } from '../../../modules/roles'
import { useFetch } from '../../../utils/fetch'
import { Privilege, Membership } from '../../../modules/roles'
import * as API from '../../../modules/api'
import ContentHeader from '../../layouts/ContentHeader'
import { showModal, hideModal } from '../../../modules/modal/actions'
import { tx } from '../../../modules/translate'
import DeleteRoleButton from './DeleteRoleButton'
import { removeComments } from '../../../utils/dashboard'
import { ModalConfirm } from '../../shared/ModalConfirm'

const TO_SHOW_LIMIT = 3

const { COLLECTIONS, INDEXES, FUNCTIONS } = FaunaValues.Native

const schemaFilter = privilege => !privilege.resource.value.collection

type RoleFormValues = {
  name: string
  privileges: Privilege[]
  membership: Membership
}

type RoleFormProps = {
  initialValues: RoleFormValues
  isLoading: boolean
  databasePath: string
  onSubmit: (values: RoleFormValues) => Promise<void>
  onCancel: () => void
  onDelete?: () => void
  roleId?: null | string
  invalidRefIndexes?: number[]
  onInvalidRefIndexesReset?: () => void
}

const getInvalidResourcesMessage = (invalidRefIndexes: number[], privileges: any[]) => {
  const single = invalidRefIndexes.length === 1
  const invalidResources = invalidRefIndexes.map(idx => privileges[idx].resource)
  const groupedByType = groupBy(invalidResources, ({ collection }) => collection.id)
  const invalidResourcesString = Object.entries(groupedByType)
    .map(([resourceName, resources]) => {
      const name = resources.length > 1 ? resourceName : resourceName.replace(/(s|es)$/, '')
      const exceedsLimit = resources.length > TO_SHOW_LIMIT
      const resourcesString = `${resources
        .slice(0, TO_SHOW_LIMIT)
        .map(resource => `“${resource.id}”`)
        .join(', ')}${exceedsLimit ? ` and ${resources.length - TO_SHOW_LIMIT} more` : ''}`

      return `${name} ${resourcesString}`
    })
    .join('; ')

  return `This role cannot be saved because it refers to the ${invalidResourcesString} that ${
    single ? 'does' : 'do'
  } not appear to exist. Click “Continue” to automatically remove ${
    single ? 'this reference' : 'these references'
  } and save the role, or click “Cancel” to continue adjusting the role.`
}

export default function RoleForm({
  initialValues,
  isLoading,
  databasePath,
  onSubmit,
  onCancel,
  onDelete,
  roleId,
  invalidRefIndexes,
  onInvalidRefIndexesReset
}: RoleFormProps) {
  const dispatch = useDispatch()
  const { formValues, handleChange, setFormValues } = useForm(initialValues)
  const [roleActiveTab, setRoleActiveTab] = useState('privileges')
  const [viewModeActiveTab, setViewModeActiveTab] = useState('simple')
  const fqlValues = roleFormValuesToFQLString(formValues)
  const hasInvalidRefs = !!invalidRefIndexes?.length

  // TODO: use 'loading' and 'error' objects returned from useFetch. alert on error in this case
  // Classes
  const { data: classes } = useFetch(API.classes, null, {
    namespace: `${databasePath}_classes`
  })

  // Indexes
  const { data: indexes } = useFetch(API.indexes, null, {
    namespace: `${databasePath}_indexes`
  })

  // Functions
  const { data: functions } = useFetch(API.functions, null, {
    namespace: `${databasePath}_functions`
  })

  // Schemas
  const schemas = [
    { name: 'collections', ref: new FaunaValues.Ref('collections') },
    { name: 'indexes', ref: new FaunaValues.Ref('indexes') },
    { name: 'databases', ref: new FaunaValues.Ref('databases') },
    { name: 'roles', ref: new FaunaValues.Ref('roles') },
    { name: 'functions', ref: new FaunaValues.Ref('functions') },
    { name: 'keys', ref: new FaunaValues.Ref('keys') }
  ]

  const invalidResourcesMessage =
    hasInvalidRefs && getInvalidResourcesMessage(invalidRefIndexes, formValues.privileges)

  function handleFormSubmit(event?) {
    event?.preventDefault()

    // Strip comments from each membership's predicate string
    const membershipsWithCleanPredicates = formValues.membership.map(membership => {
      const predicateWithoutComments = removeComments(membership.predicate)
      return {
        ...membership,
        // We can't return an empty string, so default to null
        predicate: predicateWithoutComments ? predicateWithoutComments : null
      }
    })

    const filteredPrivileges = hasInvalidRefs
      ? formValues.privileges.filter((_, idx) => !invalidRefIndexes.includes(idx))
      : formValues.privileges

    onSubmit({
      ...formValues,
      membership: membershipsWithCleanPredicates,
      privileges: filteredPrivileges
    }).then(() => {
      if (hasInvalidRefs) {
        setFormValues({
          ...formValues,
          privileges: filteredPrivileges
        })
      }
    })
  }

  function handlePrivilegesChange(updatedPrivileges) {
    setFormValues({
      ...formValues,
      privileges: uniqWith(updatedPrivileges, (a, b) => a.resource.equals(b.resource))
    })
  }

  function handleMembershipChange(updatedMembership) {
    setFormValues({
      ...formValues,
      membership: updatedMembership
    })
  }

  function handleCancelClick() {
    const valueWasChanged = JSON.stringify(formValues) !== JSON.stringify(initialValues)

    if (!valueWasChanged) return onCancel()

    dispatch(
      showModal({
        content: tx('role.discardChangesConfirm'),
        onOk: () => {
          onCancel()
          dispatch(hideModal())
        },
        onCancel: () => dispatch(hideModal()),
        showOk: true,
        showCancel: true,
        okText: tx('actions.discardChanges'),
        cancelText: tx('actions.cancel')
      })
    )
  }

  return (
    <>
      <ModalConfirm
        modal={{
          show: hasInvalidRefs,
          content: invalidResourcesMessage,
          cancelText: 'Cancel',
          okText: 'Continue',
          onCancel: onInvalidRefIndexesReset,
          onOk: () => handleFormSubmit()
        }}
      />
      <TabsProvider activeTab={viewModeActiveTab} setActiveTab={setViewModeActiveTab}>
        <form onSubmit={handleFormSubmit}>
          <ContentHeader
            className="role__form__content-header"
            divider={false}
            tabs={
              <Tabs position="right">
                <Tab id="simple">{tx('tabs.simple')}</Tab>
                <Tab id="fql">{tx('tabs.fql')}</Tab>
              </Tabs>
            }
          >
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              required
            />
          </ContentHeader>
          <TabContent id="simple">
            <TabsProvider activeTab={roleActiveTab} setActiveTab={setRoleActiveTab}>
              <Tabs>
                <Tab id="privileges">{tx('role.privileges')}</Tab>
                <Tab id="membership">{tx('role.membership')}</Tab>
              </Tabs>
              <div className="padding-y-3">
                <TabContent id="privileges">
                  <PrivilegesSection
                    title={tx('class_.plural')}
                    titlePlaceholder={tx('class_.name')}
                    resources={classes}
                    privileges={formValues.privileges}
                    filter={byResourceType(COLLECTIONS)}
                    onChange={handlePrivilegesChange}
                    actions={{
                      read: tx('role.actions.read'),
                      write: tx('role.actions.write'),
                      create: tx('role.actions.create'),
                      delete: tx('role.actions.delete'),
                      history_read: tx('role.actions.historyRead'),
                      history_write: tx('role.actions.historyWrite'),
                      unrestricted_read: tx('role.actions.unrestricted')
                    }}
                  />
                  <PrivilegesSection
                    title={tx('index.plural')}
                    titlePlaceholder={tx('index.name')}
                    resources={indexes}
                    privileges={formValues.privileges}
                    filter={byResourceType(INDEXES)}
                    onChange={handlePrivilegesChange}
                    actions={{
                      unrestricted_read: tx('role.actions.unrestricted'),
                      read: tx('role.actions.read')
                    }}
                  />
                  <PrivilegesSection
                    title={tx('function_.title')}
                    titlePlaceholder={tx('function_.name')}
                    resources={functions}
                    privileges={formValues.privileges}
                    filter={byResourceType(FUNCTIONS)}
                    onChange={handlePrivilegesChange}
                    actions={{
                      call: tx('role.actions.call')
                    }}
                  />
                  <PrivilegesSection
                    title={tx('schema.title')}
                    titlePlaceholder={tx('schema.name')}
                    resources={schemas}
                    privileges={formValues.privileges}
                    filter={schemaFilter}
                    onChange={handlePrivilegesChange}
                    actions={{
                      read: tx('role.actions.read'),
                      write: tx('role.actions.write'),
                      create: tx('role.actions.create'),
                      delete: tx('role.actions.delete'),
                      history_read: tx('role.actions.historyRead'),
                      history_write: tx('role.actions.historyWrite')
                    }}
                  />
                </TabContent>
                <TabContent id="membership">
                  <MembershipSection
                    classes={classes}
                    membership={formValues.membership}
                    onChange={handleMembershipChange}
                  />
                </TabContent>
              </div>
            </TabsProvider>
          </TabContent>
          <TabContent id="fql">
            <div className="form-group padding-y-3">
              <label htmlFor="">{tx('attributes.faunaData')}</label>
              <LazyCode code={fqlValues} />
            </div>
          </TabContent>
          <div className="form-actions container container--xsmall">
            <Button type="button" color="secondary" onClick={handleCancelClick}>
              {tx('actions.discardChanges')}
            </Button>
            <Button type="submit" color="success" loading={isLoading}>
              {tx('actions.save')}
            </Button>
          </div>
          {roleId && (
            <div className="container container--xsmall">
              <hr className="margin-top-4 margin-bottom-3" />
              <DeleteRoleButton databasePath={databasePath} roleId={roleId} onDelete={onDelete} />
            </div>
          )}
        </form>
      </TabsProvider>
    </>
  )
}
