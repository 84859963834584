import { connect } from 'react-redux'
import * as DocumentResource from '../../../modules/documentResource'
import DocumentResultCard from './DocumentResultCard'
import DocumentCard from './DocumentCard'
import { tx } from '../../../modules/translate'

type Index = {
  name: string
}

type ComponentProps = {
  index: Index
  databasePath: string
}

type SearchDocumentsResultsProps = ComponentProps & {
  results: any[]
}

function SearchDocumentsResults({ results, index, databasePath }: SearchDocumentsResultsProps) {
  function renderResults() {
    return (
      <div className="card-list">
        <div className="card-list-body">
          {results.map((result, index) =>
            result.ref ? (
              <DocumentCard
                databasePath={databasePath}
                key={result.ref.id}
                document_={result}
                classId={result.ref.collection.id}
              />
            ) : (
              <DocumentResultCard document_={result} key={index} />
            )
          )}
        </div>
        {results && results.length === 100 && (
          <div style={{ padding: '10px' }}>{tx('document_.messages.capped')}</div>
        )}
      </div>
    )
  }

  if (!results) {
    return null
  }

  if (results.length === 0) {
    return (
      <div className="tip">
        <div className="tip__label">No results found</div>
        <p className="tip__text">No results found for the index &quot;{index.name}&quot;</p>
      </div>
    )
  }

  return renderResults()
}

export default connect<SearchDocumentsResultsProps, ComponentProps, _, _, _, _>(
  (state, { index }) => ({
    results: DocumentResource.all(state, index.name)
  })
)(SearchDocumentsResults)
