import moment from 'moment'

const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions()

export const TIME_FORMAT = 'HH:mm'
export const DATE_FORMAT = 'MM-DD-yyyy HH:mm:ss'

export const formatDate = (date: string | Date): string => {
  return moment(date).format(DATE_FORMAT)
}

export const addDays = (date: string | Date, days: number): string => {
  return moment(date)
    .add(days, 'days')
    .format()
}

export const nowUTC = (): string => {
  return moment()
    .utc()
    .format()
}

export const nowLocal = (): string => {
  return moment()
    .local()
    .format()
}

export const localToUTC = (date: string | Date): string => {
  return moment(date)
    .tz(localTZ)
    .tz('utc', true)
    .format()
}

export const utcToLocal = (date: string | Date): Date => {
  return moment(date)
    .tz('utc')
    .tz(localTZ, true)
    .toDate()
}
