import axios, { AxiosResponse } from 'axios'
import { logAxiosError } from '../../../utils/log-helper'

export const API_URL = `${process.env.REACT_APP_SHELL_URL}/github_login`

export default async function activateShell(
  state: string,
  secret: string
): Promise<AxiosResponse<unknown>> {
  try {
    return await axios.get(`${API_URL}?state=${state}&secret=${secret}`)
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}
