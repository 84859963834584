import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Match } from 'react-router-dom'
import * as AlertTypes from '../../../modules/alert/types'
import * as API from '../../../modules/api'
import { runFQLQuery } from '../../../modules/fql/eval'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import DocumentsTable from '../../pages/documents/DocumentsTable'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import LazyFQLEditor from '../../shared/LazyFQLEditor'

type Props = {
  match: Match
  alert: AlertTypes.Alert
}

type RememberQuery = {
  codeValue: string
  queryResult: {}
  inlineMessage: {
    message: string
    error: boolean
  }
}

function ConsoleIndex({ match }: Props) {
  const latestQuery = getLatestQuery()
  const databasePath = match.params.dbPath
  const defaultCodeValue = (latestQuery && latestQuery.codeValue) || 'Paginate(Indexes())'
  const [codeValue, setCodeValue] = useState(defaultCodeValue)
  const [queryResult, setQueryResult] = useState(latestQuery && latestQuery.queryResult)
  const [inlineMessage, setInlineMessage] = useState(latestQuery && latestQuery.inlineMessage)

  useEffect(() => {
    function rememberQuery() {
      const query = {
        codeValue,
        queryResult,
        inlineMessage
      }

      window.localStorage.setItem(match.params.dbPath, JSON.stringify(query))
    }

    rememberQuery()
    // we only have to update the remembered query when it was run.
    // because of this codeValue is ignored
  }, [queryResult, inlineMessage, codeValue, match.params.dbPath])

  function runQuery() {
    API.selectDatabase(databasePath)
    setInlineMessage(null)
    runFQLQuery(codeValue)
      .then(handleSuccess)
      .catch(handleError)
  }

  function getLatestQuery(): RememberQuery | null | undefined {
    try {
      return JSON.parse(window.localStorage.getItem(match.params.dbPath))
    } catch {
      return null
    }
  }

  function handleSuccess({ data }) {
    if (data) {
      if (Array.isArray(data)) {
        setQueryResult({ data })
      } else {
        setQueryResult({ data: [data] })
      }

      return
    }

    setInlineMessage({
      message: tx('query.messages.success'),
      error: false
    })
  }

  function handleError(error) {
    setQueryResult({ data: null })

    setInlineMessage({
      message: getErrorMessage(error),
      error: true
    })
  }

  return (
    <div style={{ paddingTop: 25 }}>
      <div
        data-hj-suppress
        style={{
          height: '300px'
        }}
      >
        <LazyFQLEditor height="100%" value={codeValue} onChange={value => setCodeValue(value)} />
      </div>

      <div
        style={{
          padding: 15,
          backgroundColor: '#282a36',
          display: 'flex',
          justifyContent: 'end'
        }}
      >
        <Button onClick={runQuery} color="primary">
          {tx('query.actions.run')}
        </Button>
      </div>

      {!inlineMessage && queryResult && (
        <>
          <div className="section no-padding-bottom">
            <div className="section-topbar no-margin">
              <h4>{tx('query.labels.results')}</h4>
              <span className="on-right">{queryResult.data && queryResult.data.length}</span>
            </div>
          </div>
          <DocumentsTable documents={queryResult.data} />
        </>
      )}

      {inlineMessage && (
        <>
          <div className="section no-padding-bottom">
            <div className="section-topbar no-margin">
              <h4>{tx('query.labels.results')}</h4>
            </div>
          </div>
          <div className="section">
            <div>{inlineMessage.message}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default connect(state => ({
  alert: state.alert
}))(
  withBreadcrumbs(({ location }) => {
    return [
      {
        label: tx('query.plural'),
        path: location.pathname
      }
    ]
  })(ConsoleIndex)
)
