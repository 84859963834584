import { RegionInfo } from '../../../config/regions'
import { Limits, ViewControls } from '../../api/auth-service/authz'
import SessionCookie from './cookie'

export type Limit = { soft?: number; hard?: number }
export type Limits = {
  db_count?: Limit
  /** history days limit */
  temporality?: Limit
  /** total team members including the owner */
  team_members?: Limit
}

export type SessionRegion = {
  regionPrefix?: string
  regionName?: string
  url?: string
  graphqlUrl?: string
  secret: string
}

export type RegionInfo = {
  displayName: string
  abbr: string
  rate: number
}

export type Session = {
  id: string
  secret: string
  user: {
    id: string
    name: string
    email: string
    role: string
    otp_enabled: boolean
    // storing as a string as its only used by pendo integration currently
    // if you need full date functionality update the login strategies to
    // convert this to a date and update the pendo integration to convert
    // the date to a ISO 8601 string
    // making optional as this depends on an auth service API change
    // we can make this required after the auth service is deployed
    created_at: string // ISO 8601 date string
    planSynced: boolean
    plan: string
    plan_display_name: string
    plan_monthly_price: number
    is_plan_deprecated: boolean
    limits: Limits
    eligible_for_trial: boolean
    previous_plan: string | null
  }
  account: {
    account_id: string
    company_name?: string
    legacy_account: boolean
  }
  view_controls: ViewControls
  regionGroups: Record<string, SessionRegion>
  userRegionGroups: Record<string, RegionInfo>
  feature_flags: { pricing_v2: boolean }
}

export class NoSessionError extends Error {}

export const checkSession = () => {
  if (!SessionCookie.exists()) throw new NoSessionError()
}

export const hasLocalSession = () => {
  return SessionCookie.exists()
}

export const authSecret = () => SessionCookie.get().data.secret
