import * as React from 'react'
import { Outlet } from 'react-outlet'
import Header from './Header'
import DatabaseSidebar from './DatabaseSidebar'
import Footer from './Footer'
import NetlifyAddon from '../NetlifyAddon'
import '../../assets/scss/themes/_current.scss'
import { RouteComponentProps } from 'react-router-dom'

type NavLayoutProps = RouteComponentProps<{ dbPath: string }> & {
  children: React.ReactNode
  sidebar?: React.ReactType | null
}

export default function NavLayout({
  children,
  sidebar = DatabaseSidebar
}: NavLayoutProps): React.ReactNode {
  const Sidebar = sidebar
  return (
    <>
      <NetlifyAddon />

      <div className="dashboard">
        <Header />

        <Outlet outletId="breadcrumb" />

        <div className="content">
          {sidebar && <Sidebar />}

          <div className="content__main">
            <div className="content__left-panel">
              <Outlet outletId="leftPanel" />
            </div>

            <div className="content__main-panel">
              {children}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
