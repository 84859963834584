import * as React from 'react'
import Button from './shared/Button'

type Props = {
  resourceDescription: string
  failureReasons: string[]
  userSuggestion: string
}

export default class DisplayStatusPage extends React.Component<Props> {
  static defaultProps = {
    resourceDescription: 'The resource you are trying to access was not found.',
    failureReasons: ['The resource was removed.', 'The URL is incorrect.'],
    userSuggestion: 'You can return to the dashboard to try and locate the resource.'
  }

  render() {
    const reasons = this.props.failureReasons.map((reason, index) => {
      return (
        <li key={'StatusReason-' + index}>
          <p className="text-lg">{reason}</p>
        </li>
      )
    })
    return (
      <div className="center padding-top-6 primary">
        <div>
          <div className="center">
            <div className="container container--large grid">
              <div className="row padding-y-8 padding-x-4 padding-x-small-0">
                <div className="col col--auto">
                  <div className="padding-bottom-4 ">
                    <ColoredLogo />
                  </div>

                  <header className="line-height-tight margin-bottom-2">
                    <h1 className="text-2xl no-margin padding-bottom-3">
                      {this.props.resourceDescription}
                    </h1>
                    <h2 className="text-28 no-margin">
                      {'This typically results from the following:'}
                    </h2>
                  </header>

                  <main className="padding-bottom-2">
                    <ul>{reasons}</ul>
                    <p className="text-16 padding-bottom-2">{this.props.userSuggestion}</p>

                    <Button
                      onClick={e => {
                        e.preventDefault()
                        window.location.href = '/'
                      }}
                      color="success"
                      className="margin-bottom-3"
                    >
                      BACK TO DASHBOARD
                    </Button>
                  </main>

                  <p>
                    If you believe you have you encountered this message in error, please{' '}
                    <a href="mailto:support@fauna.com">contact support</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function ColoredLogo() {
  return (
    <svg
      width="136"
      height="36"
      viewBox="0 0 136 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1208_38655)">
        <path
          d="M42.9352 14.5217V13.209C42.9352 8.89257 46.5682 5.69962 50.8375 5.69962C51.6936 5.69962 52.5498 5.82969 52.9315 5.95978L51.9482 9.815C51.6011 9.68491 51.2193 9.63761 50.8375 9.63761C48.6161 9.63761 47.1236 11.2932 47.1236 13.2208V14.5335H52.2489V17.762H47.1236V34.1763H42.9352V17.7501H40.4594V14.5217H42.9352Z"
          fill="#3A1AB6"
        />
        <path
          d="M53.0937 24.3486C53.0937 18.6722 57.1084 14.3085 62.4074 14.3085C65.1377 14.3085 67.5328 15.7985 68.9442 18.0218V14.5332H73.121V34.176H68.9327V30.6873C67.4749 32.9106 65.0914 34.4005 62.3957 34.4005C57.1084 34.3888 53.0937 29.9777 53.0937 24.3486ZM57.2819 24.3486C57.2819 28.1448 59.8851 31.113 63.09 31.113C65.8204 31.113 68.9442 28.7951 68.9442 24.3486C68.9442 20.5998 66.4221 17.5842 63.09 17.5842C59.8851 17.5842 57.2819 20.6353 57.2819 24.3486Z"
          fill="#3A1AB6"
        />
        <path
          d="M75.8166 25.9204V14.5323H79.9585V26.1924C79.9585 28.7232 81.8327 30.9109 84.4822 30.9109C87.8605 30.9109 89.6887 27.99 89.6887 25.9323V14.5441H93.8306V34.1867H89.6887V31.5261C88.3697 33.2289 86.2755 34.4115 83.8343 34.4115C78.8015 34.3878 75.8166 30.4971 75.8166 25.9204Z"
          fill="#3A1AB6"
        />
        <path
          d="M96.1447 34.1646V14.5218H100.287V17.1826C101.571 15.4797 103.665 14.2971 106.141 14.2971C111.139 14.2971 114.17 18.1879 114.17 22.7643V34.1527H110.028V22.4924C110.028 19.8316 108.027 17.7739 105.458 17.7739C102.045 17.7739 100.287 20.825 100.287 22.7527V34.1408H96.1447V34.1646Z"
          fill="#3A1AB6"
        />
        <path
          d="M116.045 24.3486C116.045 18.6722 120.059 14.3085 125.358 14.3085C128.089 14.3085 130.484 15.7985 131.895 18.0218V14.5332H136.084V34.176H131.895V30.6873C130.437 32.9106 128.054 34.4005 125.358 34.4005C120.059 34.3888 116.045 29.9777 116.045 24.3486ZM120.233 24.3486C120.233 28.1448 122.836 31.113 126.041 31.113C128.772 31.113 131.895 28.7951 131.895 24.3486C131.895 20.5998 129.373 17.5842 126.041 17.5842C122.836 17.5842 120.233 20.6353 120.233 24.3486Z"
          fill="#3A1AB6"
        />
        <path
          d="M24.7361 7.56853C22.5495 8.31357 21.4966 9.64989 20.7793 11.7312C20.5942 12.287 20.1314 12.902 19.6108 13.3159L21.4041 15.279L15.7117 11.2109L0 0C0 0 1.13384 7.52123 1.52721 10.2885C1.80488 12.2397 2.27925 13.1148 3.78331 14.0018L4.38495 14.3329L6.97658 15.7284L5.4378 14.9124L12.5416 18.874L12.4954 18.9805L4.84773 15.3618C5.25269 16.7809 6.03941 19.5126 6.37494 20.7189C6.73361 22.0197 7.13855 22.4927 8.37652 22.9541L10.6558 23.8055L12.0673 23.2378L10.274 24.4559L1.30738 36.0806C7.26582 30.4279 12.3102 28.4175 16.001 26.7737C20.7099 24.6924 23.5445 23.3561 25.3956 18.5547C26.7146 15.1844 27.7442 10.868 29.0518 9.2005L31.8401 5.55815C31.8401 5.55815 26.0666 7.11916 24.7361 7.56853Z"
          fill="#3A1AB6"
        />
      </g>
      <defs>
        <clipPath id="clip0_1208_38655">
          <rect width="136" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
