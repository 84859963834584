import { mutate } from 'swr'
import { removeKey } from '../../modules/api'
import * as DatabaseResource from '../../modules/databaseResource'

function mutateParentKeys(parentPath: string, dbName: string): void {
  // When deleting a child DB, we need to invalidate
  // the keys cache for its parent.
  mutate([parentPath, 'keys'], async keys => {
    return keys?.filter(key => {
      if (key.database === undefined || key.database.value.id !== dbName) {
        return key
      }

      removeKey(key.ref)
      return null
    })
  })
}

export function onDatabaseDeleteComplete(dbPath: string, dbName: string): void {
  const parentPath = DatabaseResource.databaseParentPathFromPath(dbPath)

  if (parentPath) {
    mutateParentKeys(parentPath, dbName)
  }
  mutate([dbPath, 'keys'], null, false)
}
