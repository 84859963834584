import * as cookies from './index'
import { LoginResponse } from '../auth'
import { Region } from '../auth/session/regions'
import { Session } from '../auth/session'

export type SessionCookieContents = LoginResponse & { provider?: string }

export const SESSION_COOKIE_NAME = 'session'

export const SESSION_COOKIE_CONFIG: cookies.CookieConfig = {
  secure: false,
  expires: 30, // days
  domain: cookies.COOKIE_DOMAIN
}

export const get = (): SessionCookieContents => cookies.get(SESSION_COOKIE_NAME)

export const remove = (): void => cookies.remove(SESSION_COOKIE_NAME)

export const save = (contents: SessionCookieContents): void => {
  // Make sure old region info is cleared before saving a new session
  Region.clear()
  // Save new session cookie
  cookies.save(SESSION_COOKIE_NAME, contents, SESSION_COOKIE_CONFIG)
}

export const mergeSessionData = (data: Partial<Session>): void => {
  const session = get()
  if (session) {
    save({ ...session, data: { ...session.data, ...data } })
  }
}

export const exists = (): boolean => cookies.exists(SESSION_COOKIE_NAME)
