import axios, { AxiosError } from 'axios'
import { errors } from 'faunadb'

// As a short-term solution, define constant values that we can filter on to make our console error
//  monitors more useful.
export enum ResponseErrorTypes {
  AXIOS_CALL_FAILED = 'AXIOS_CALL_FAILED', // http status code error in response in a request made via axios
  AXIOS_NO_RESPONSE = 'AXIOS_NO_RESPONSE', // no response received in a request made via axios
  AXIOS_INTERNAL_ERROR = 'AXIOS_INTERNAL_ERROR', // error forming request in axios. no request ever sent
  FAUNA = 'FAUNA', // error in response to a fauna query
  HTTP = 'HTTP', // network error response that is not axios based
  HANDLED = 'HANDLED' // used to represent errors we've handled explicitly in the code that come from any other source. Enables us to identify errors our code already expects and accounts for.
}

export function logMessage(
  level: 'error' | 'warn' | 'info',
  statusCode: any,
  message: any,
  errorType: ResponseErrorTypes,
  error?: any
) {
  const msg = `${errorType}|${statusCode}: ${message}`
  const log = console[level]

  error ? log(msg, error) : log(msg)
}

export function logStatusCode(
  statusCode: any,
  message: any,
  errorType?: ResponseErrorTypes,
  error?: any
) {
  logMessage(
    [400, 401, 403, 404, 200].includes(statusCode) ? 'info' : 'error',
    statusCode,
    message,
    errorType,
    error
  )
}

/**
 * Following https://axios-http.com/docs/handling_errors
 * Knowing an error came in a response from an Axios call, ensures the
 * structure of the response object. Using a common format for the log,
 * including the HTTP status code, helps us filter out the noise of console errors
 * in datadog.
 * @param error Axios error response object, or generic HTTP error object
 * HTTP Error: https://developer.mozilla.org/en-US/docs/Web/API/Response
 */
export function logAxiosError(error: any | AxiosError, message?: any) {
  // If the error is from the axios framework, separate internal axios errors
  //  and errors from the underlying service into separate buckets.
  // reference: https://axios-http.com/docs/handling_errors
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      logStatusCode(
        error.response.status,
        (message || 'Axios call failed: ') + error,
        ResponseErrorTypes.AXIOS_CALL_FAILED,
        error
      )
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      logMessage(
        'error',
        null,
        (message || 'Axios call failed to retrieve a response for request: ') + `${error}.`,
        ResponseErrorTypes.AXIOS_NO_RESPONSE,
        error
      )
    } else {
      // Something happened in setting up the request that triggered an Error
      logMessage(
        'error',
        null,
        (message || 'Unable to build request object for Axios: ') + error,
        ResponseErrorTypes.AXIOS_INTERNAL_ERROR,
        error
      )
    }
  } else {
    // handle generic HTTP error
    logStatusCode(
      error.status,
      (message || 'Failed HTTP request: ') + `${error}`,
      ResponseErrorTypes.HTTP,
      error
    )
  }
}

/**
 * Use the fauna error type to ensure the structure of the response object.
 * This allows us to log the status code in a predictable format and filter
 * these errors out from the console error noise in datadog.
 * @param error Fauna response object: https://github.com/fauna/faunadb-js/pull/405
 * https://fauna.github.io/faunadb-js/module-errors-FaunaHTTPError.html
 */
export function logFaunaError(error: errors.FaunaHTTPError, message?: any) {
  if (!error || !error.requestResult) {
    logMessage('error', null, 'Undefined error object in Fauna response.', ResponseErrorTypes.FAUNA)
    return
  }
  const { statusCode, method, path } = error?.requestResult
  logStatusCode(
    statusCode,
    (message || '') + ` Fauna ${method} query to path:${path || '""'} failed: ${error}`,
    ResponseErrorTypes.FAUNA
  )
}
