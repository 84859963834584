import { useCallback } from 'react'
import Button from '../../shared/Button'
import netlifyLogo from '../../../assets/images/netlify-logo.svg'
import routes from '../../../config/routes'

const AUTH_URL = String(process.env.REACT_APP_AUTH_URL)

export default function NetlifyButton() {
  const handleClick = useCallback(() => {
    const siteUrl = window.location.origin
    const oauthUrl =
      `${AUTH_URL}/oauth/start` +
      '?provider_name=netlify' +
      `&redirect_url=${siteUrl}${routes.auth.oauth.callback.path}`

    window.location.href = oauthUrl
  }, [])

  return (
    <Button
      block
      type="button"
      color="netlify"
      id="cloud-signup-cta-netlify"
      className="margin-bottom-2"
      onClick={handleClick}
    >
      <img src={netlifyLogo} alt="Netlify logo" />
      Netlify
    </Button>
  )
}
