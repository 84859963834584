import * as React from 'react'
import { useState, useEffect } from 'react'
import Alert from '../../shared/Alert'
import queryString from 'query-string'
import { tx } from '../../../modules/translate'
import AuthError from './AuthError'
import faunaLogo from '../../../assets/images/fauna-logotype.svg'
import cn from 'classnames'

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL
const WEBSITE_TERMS_OF_USE_URL = 'https://fauna.com/terms'
const WEBSITE_PRIVACY_POLICY = 'https://fauna.com/privacy'

type Props = {
  title: string
  form: Function
  content: React.ReactNode
  location: Record<string, any>
  className?: any
  layout?: string
  minimal?: boolean
}

export default function Layout({
  title,
  form,
  content,
  location,
  className,
  layout,
  minimal
}: Props) {
  const [alert, setAlert] = useState({
    active: false,
    message: null,
    level: 'error'
  })
  const contentBackgroundCN = cn({
    'auth-content-background': true,
    'auth-content-background--sign-in': layout === 'sign-in',
    'auth-content-background--sign-up': layout === 'sign-up'
  })
  const contentWrapperCN = cn({
    'auth-content-wrapper': true,
    'auth-content-wrapper--sign-in': layout === 'sign-in',
    'auth-content-wrapper--sign-up': layout === 'sign-up',
    'auth-content-wrapper--invite': layout === 'invite'
  })

  useEffect(() => {
    const { error, success, error_code: errorCode } = queryString.parse(location.search)

    if (error) {
      if (error === 'expired_session') {
        showAlert(tx('auth.errors.expiredSession'))
      } else if (errorCode !== 'user_is_using_another_strategy_or_provider') {
        showAlert(error)
      }
    }

    if (success) {
      showAlert(success, 'success')
    }

    document.title = title
  }, [location.search, title])

  function showAlert(message, level = 'error') {
    setAlert({
      active: true,
      message,
      level
    })
  }

  function toggleAlert() {
    setAlert({
      ...alert,
      active: !alert.active
    })
  }

  return (
    <>
      <Alert
        message={alert.message}
        active={alert.active}
        level={alert.level}
        onClose={toggleAlert}
      />

      <AuthError />

      <div className={cn('auth-content', className)}>
        {!minimal && (
          <a href={String(WEBSITE_URL)} className="auth-logo-wrapper">
            <img src={faunaLogo} alt="Fauna logo" />
          </a>
        )}
        <div className={cn(contentBackgroundCN, minimal && 'minimal')}>
          <div className={cn(contentWrapperCN, minimal && 'minimal')}>
            {!minimal && content}
            <div className="auth-form" data-testid="auth-form">
              {form({ showAlert })}
            </div>
          </div>
        </div>

        <footer className="auth-footer">
          <small>
            {tx('footer.copyright', [new Date().getFullYear().toString()])}
            &nbsp;
            {tx('auth.terms.first')}{' '}
            <a target="_blank" rel="noopener noreferrer" href={WEBSITE_TERMS_OF_USE_URL}>
              {tx('footer.terms')}
            </a>{' '}
            {tx('auth.terms.second')}{' '}
            <a target="_blank" rel="noopener noreferrer" href={WEBSITE_PRIVACY_POLICY}>
              {tx('footer.privacy')}
            </a>
            .
          </small>
        </footer>
      </div>
    </>
  )
}
