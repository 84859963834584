import useSWR from 'swr'
import * as API from '../../../modules/api'
import TableListPlaceholder from '../../shared/TableListPlaceholder'

import { useParams, Link } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'

const FunctionsTable = ({ selectedId }: { selectedId?: string }) => {
  const { dbPath, region } = useParams()

  const { data: functions } = useSWR([dbPath, 'functions'], () => {
    API.selectDatabase(dbPath)
    return API.functions()
  })

  return (
    <div className="scrollList">
      <table className={'table-card no-table-labels table--no-border '}>
        <tbody>
          {functions ? (
            functions.map(function_ => (
              <tr
                key={function_.name}
                className={`${selectedId === function_.name ? 'selected' : ''}`}
              >
                <td>
                  <Link
                    to={createRoute(routes.functions.byId.path, dbPath, region).replace(
                      ':functionId',
                      function_.name
                    )}
                    data-hj-suppress
                  >
                    {function_.name}
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <TableListPlaceholder></TableListPlaceholder>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default FunctionsTable
