import { useEffect, useRef } from 'react'

type UseFileBrowserOptions = {
  accept?: string
}

export function useFileBrowser({ accept }: UseFileBrowserOptions = {}) {
  const fileInput = useRef(null)

  useEffect(() => {
    if (!fileInput) return
    fileInput.current = document.createElement('input') as any
    fileInput.current.type = 'file'
    fileInput.current.accept = accept
    fileInput.current.hidden = true
    fileInput.current.setAttribute('data-testid', `file-upload-${accept.split(' ').join('-')}`)
    document.body.appendChild(fileInput.current)

    return () => {
      document.body.removeChild(fileInput.current)
    }
  }, [accept])

  const getFile = () => {
    return new Promise<File>((resolve, _reject) => {
      const currentFileInput: any = fileInput.current

      if (!currentFileInput) return

      currentFileInput.click()
      currentFileInput.onchange = () => {
        const file = currentFileInput.files[0]

        if (file) {
          currentFileInput.value = ''
          resolve(file)
        }
      }
    })
  }

  return {
    getFile
  }
}
