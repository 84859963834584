import * as React from 'react'
import routes from '../../../config/routes'
import useInvoices, { Invoice } from '../../../modules/api/auth-service/invoices'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Icon from '../../shared/Icon'
import Spinner from '../../shared/Spinner'
import Well from '../../shared/Well'

const Invoices: React.FC = () => {
  const { data: invoices } = useInvoices()

  if (!invoices) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }
  return (
    <>
      <ContentHeader divider className="margin-bottom-4">
        Invoices
      </ContentHeader>

      {invoices.length === 0 && (
        <div className="card margin-top-4">
          <Well
            shadow
            title="No invoices yet"
            icon={<Icon name="file-invoice-dollar" />}
            description={
              <div className="container container--small">
                <p>You don't have generated invoices.</p>
              </div>
            }
          />
        </div>
      )}

      {invoices.length > 0 && (
        <>
          <section className="container">
            <div className="card-list">
              <div className="card-list-header grid">
                <div className="row">
                  <div className="label col width-15 display-none display-small-block">Date</div>
                  <div className="label col width-small-15">Amount</div>
                  <div className="label col width-30 display-none display-small-block">ID</div>
                  <div className="label col width-25 display-none display-small-block">
                    Payment Method
                  </div>
                  <div className="label col width-15 display-none display-small-block">Status</div>
                </div>
              </div>

              <div className="card-list-body">
                {invoices.map(invoice => (
                  <InvoiceRow invoice={invoice} key={invoice.id || 'none'} />
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

const InvoiceRow = ({ invoice }: { invoice: Invoice }) => {
  return (
    <a
      href={invoice.url}
      target="_blank"
      rel="noopener noreferrer"
      className="card grid current-color"
    >
      <div className="card-body">
        <div className="row">
          <div className="col display-none display-small-block width-15">
            {new Date(invoice.date).toLocaleDateString()}
          </div>
          <div className="col width-small-15 text-ellipsis">${invoice.amount.toFixed(2)}</div>
          <div className="col width-30  display-none display-small-block text-ellipsis">
            {invoice.id || 'None'}
          </div>
          <div className="col width-25 display-none display-small-block text-ellipsis">
            {invoice.payment_method || 'None'}
          </div>
          <div className="col width-15 display-none display-small-block">{invoice.status}</div>
        </div>
      </div>
    </a>
  )
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Invoices',
    path: routes.settings.invoices.path
  }
])(Invoices)
