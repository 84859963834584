import { checkSession } from '../modules/auth/session'
import SessionCookie from '../modules/auth/session/cookie'
import { Settings, DateTime } from 'luxon'
import sha256 from 'crypto-js/sha256'

Settings.defaultZone = 'utc'

const ACCOUNT_ID_HASH_TO_EXIPRATION_TIMES = new Map<string, DateTime>([
  [
    'cb5c5e6a5c2d080d70d69704926a3963c99963896a30957174dc796fd81f3777',
    DateTime.fromISO('2024-04-01T00:00:00Z')
  ],
  [
    '5aed86454e3c2e74a5531a03ead6468d47f05dc44ad26e0550ee0b06e9a871f7',
    DateTime.fromISO('2024-04-01T00:00:00Z')
  ],
  [
    '445ee940db0c17787d3f6a4a2cc019ad7839eaeeab8b72f2f4f3063aac8e45e1',
    DateTime.fromISO('2024-04-01T00:00:00Z')
  ],
  [
    '203d08cda73b385d910562e428507800af6fc48fc55bc0ea85f786bb0f550056',
    DateTime.fromISO('2024-03-16T00:00:00Z')
  ],
  // Cleve's test account
  [
    'e0840b28373b922fd65b8027a88ab4049c5efda2524801fd4f7f89e81f12865f',
    DateTime.fromISO('2024-02-16T00:00:00Z')
  ]
])

const DEFAULT_EXP_TIME = DateTime.fromISO('2024-03-01T00:00:00Z')

/**
 * Any component or function can use this hook to check if a user has certain access rights to
 * GraphQL.
 */
export const useGraphQLAccess = () => {
  checkSession()
  const session = SessionCookie.get()
  const accountId = session?.data?.account?.account_id
  const expirationTime =
    ACCOUNT_ID_HASH_TO_EXIPRATION_TIMES.get(sha256(accountId).toString()) || DEFAULT_EXP_TIME
  const hasAccess = () => {
    return isAccessAllowed(accountId, DateTime.now(), DEFAULT_EXP_TIME)
  }
  return { hasAccess, expirationTime: expirationTime }
}

// visible for testing
export function isAccessAllowed(
  accountId: string,
  currentTime: DateTime,
  defaultExpTime: DateTime
): boolean {
  const expirationTime =
    ACCOUNT_ID_HASH_TO_EXIPRATION_TIMES.get(sha256(accountId).toString()) || defaultExpTime
  return currentTime < expirationTime
}
