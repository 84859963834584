import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import InfoBox, { InfoBoxType } from '../../../shared/InfoBox'
import Table, { AlignmentWrapper } from '../../../shared/Table'
import Icon from '../../../shared/Icon'
import Spinner from '../../../shared/Spinner'
import DashboardTooltip from '../../functions/DashboardTooltip'
import {
  createLogUrl,
  getQueryLog,
  ListQuerylogResultMember,
  LOG_STATE_NAMES
} from '../../../../modules/api/frontdoor/queryLogs'
import { showAlert } from '../../../../modules/alert/actions'
import { getErrorMessage } from '../../../../utils/error'
import { useDispatch } from 'react-redux'
import Button from '../../../shared/Button'
import { formatDate } from '../../../../utils/date'
import usePolling from '../../../../modules/api/usePolling'
import { tx } from '../../../../modules/translate'

export const NEW_REQUEST_POLLING_SPEED = 10000
const NEW_LINK_POLLING_SPEED = 1000

const QuerylogsTable: React.FC<{
  data: any
  inFlightRequests: Map<string, number>
  setInFlightRequests: Dispatch<React.SetStateAction<Map<string, number>>>
}> = ({ data: paginatedData, inFlightRequests, setInFlightRequests }) => {
  const dispatch = useDispatch()
  const { list, error, isLoadingMore, isInitialLoading } = paginatedData

  type RowData = {
    source: { region: string; database: string }
    start: string
    end: string
    status: string
    link: {
      url: string
      expiration: string
      request_id: string
    }
    updated_at: string
  }
  // Helper to format the data that will go to each cell in the react table, keys must
  //  much the accessors in columns array
  const formatRowData = useCallback((row: ListQuerylogResultMember): RowData => {
    return {
      source: { region: row.region_group, database: row.database },
      start: formatDate(row.time_start),
      end: formatDate(row.time_end),
      status: row.state,
      link: {
        url: row.presigned_url,
        expiration: row.presigned_url_expiration_time,
        request_id: row.request_id
      },
      updated_at: formatDate(row.updated_at)
    }
  }, [])

  // Build table data based on the data coming in from usePagination
  const tableData: RowData[] = useMemo(() => {
    if (isInitialLoading) {
      return []
    }
    if (!error) {
      return list.map((row: ListQuerylogResultMember) => {
        return formatRowData(row)
      })
    } else {
      dispatch(showAlert(getErrorMessage(error), 'error'))
      return []
    }
  }, [list, isInitialLoading, error, formatRowData, dispatch])

  // check if the request is in a terminal state
  const isTerminalState = useCallback((bundle: RowData) => {
    return ['DoesNotExist', 'Complete', 'Failed', 'TimedOut'].includes(bundle?.status)
  }, [])

  // Use a new state variable to keep track of asynchronous cell values instead of updating
  //  tableData and re-rendering the entire table on every poll change. This is useful when
  //  polling in one cell affects the a different cell showing the same bundle data
  const [updatedBundles, setUpdatedBundles] = useState(new Map())

  // To ensure links only download on terminal status after the download button is clicked,
  //  keep track of a set of request_ids that gets added to when download is clicked. Prevents
  //  links from opening after a new request is finished
  const [requestedDownloads, setRequestedDownloads] = useState(new Set())

  // Helperr method to set the map value for an updated bundle
  const updateABundle = (rowData: RowData) => {
    setUpdatedBundles(bundles => {
      bundles.set(rowData.link.request_id, rowData)
      return bundles
    })
  }

  // Helper method to set map value
  const removeInFlightRequest = request_id => {
    setInFlightRequests(ids => {
      ids.delete(request_id)
      return new Map(ids)
    })
  }

  // Helper method to set map value
  const addInFlightRequest = useCallback(
    (request_id, pollingSpeed) => {
      setInFlightRequests(ids => {
        ids.set(request_id, pollingSpeed)
        return new Map(ids)
      })
    },
    [setInFlightRequests]
  )

  // Initialize the bundle data using tableData, also set inFlightRequests if a user
  //  navigates back to the page when a request is in progress to resume polling
  // As tableData is updated, keep track and keep the inFlightRequests and
  //  updatedBundles up to date
  useEffect(() => {
    if (!isLoadingMore && !isInitialLoading) {
      const bundlesIncludingNewRequests = tableData.reduce((prev: Map<string, RowData>, curr) => {
        prev.set(curr.link.request_id, curr)
        return prev
      }, new Map())
      setUpdatedBundles(bundlesIncludingNewRequests)
    }
    return () => {
      setInFlightRequests(new Map())
      setUpdatedBundles(new Map())
      setRequestedDownloads(new Set())
    }
  }, [
    tableData,
    isLoadingMore,
    isInitialLoading,
    isTerminalState,
    addInFlightRequest,
    setInFlightRequests
  ])

  useEffect(() => {
    updatedBundles.forEach(row => {
      if (!isTerminalState(row)) {
        addInFlightRequest(row.link.request_id, NEW_REQUEST_POLLING_SPEED)
      }
    })
  }, [updatedBundles, isTerminalState, addInFlightRequest])

  const useBundlePolling = row => {
    const request_id = row?.values?.link?.request_id
    const { data, isValidating, mutate, revalidate, error } = usePolling<RowData>({
      key: request_id,
      delay: inFlightRequests.get(request_id),
      shouldFetch: inFlightRequests.has(request_id),
      fetcher: async () => {
        return getQueryLog(request_id).then(ql => {
          const rowData = formatRowData(ql.data)
          updateABundle(rowData)
          if (isTerminalState(rowData) && isTerminalState(updatedBundles.get(request_id))) {
            if (rowData.status === 'Complete' && requestedDownloads.has(request_id)) {
              setRequestedDownloads(ids => {
                ids.delete(request_id)
                return new Set(ids)
              })
              window.open(rowData.link.url, '_blank')
            }
            removeInFlightRequest(request_id)
          }
          return rowData
        })
      }
    })
    if (error) {
      removeInFlightRequest(request_id)
      dispatch(showAlert(getErrorMessage(error), 'error'))
    }
    const bundle = updatedBundles.get(request_id) || row?.values
    return {
      data,
      isValidating,
      mutate,
      revalidate,
      error,
      bundle
    }
  }

  // Button for downloading a log bundle, triggers a new inflight request when generating a new url
  // TODO: See if we can share the fetcher code here with the fetcher code in StatusCell
  const DownloadCell: React.FC<{ row: { values: RowData } }> = ({ row }) => {
    const { data, isValidating, mutate, revalidate, error, bundle } = useBundlePolling(row)
    const completed = bundle?.status === 'Complete'
    const refreshing = bundle?.status === 'CreatingNewUrl'
    return completed || refreshing ? (
      <>
        <AlignmentWrapper horizontalAlign="end">
          <Button
            color="secondary"
            loading={inFlightRequests.has(bundle.link.request_id)}
            onClick={async e => {
              e.preventDefault()
              setRequestedDownloads(ids => new Set(ids).add(row.values.link.request_id))
              // request a new signed URL for this bundle
              await createLogUrl(row.values.link.request_id)
              // start polling for when the worker finishes generating a new URL
              addInFlightRequest(row.values.link.request_id, NEW_LINK_POLLING_SPEED)
            }}
          >
            <Icon name="file-download" />
            {tx('logs.actions.download')}
          </Button>
        </AlignmentWrapper>
      </>
    ) : isValidating ? (
      <AlignmentWrapper horizontalAlign="end">
        <Spinner />
      </AlignmentWrapper>
    ) : (
      <AlignmentWrapper horizontalAlign="end">N/A</AlignmentWrapper>
    )
  }

  // Render status and kick off polling if it's in a non-terminal state
  // TODO: See if we can share the fetcher code here with the fetcher code in DownloadCell,
  //  would decrease GET calls if we use only request_id for key in both cells,
  //  but currently that causes a race condition for the resulting value of updatedBundles
  // TODO: dynamic, quicker polling time when the inFlightRequest is triggered via a download.
  //  Forces a wait of 10s for the download link when it generally takes less time than a new
  //  request to finish
  const StatusCell: React.FC<{ row: { values: RowData } }> = ({ row }) => {
    const { data, isValidating, mutate, revalidate, error, bundle } = useBundlePolling(row)
    return isValidating ? <Spinner /> : <>{LOG_STATE_NAMES[bundle?.status] || bundle?.status}</>
  }

  const columns = [
    {
      Header: () => {
        return (
          <DashboardTooltip
            header={'LOG SOURCE'}
            id="logSource"
            placement="top"
            contentText={tx('tooltips.logSource')}
          />
        )
      },
      accessor: 'source',
      minWidth: 150,
      Cell: ({ row }) => {
        const { region, database } = row.values.source
        const labelText = database ? 'DB' : 'RG'
        return (
          <AlignmentWrapper>
            {database ?? region}
            <span className="log-source-label">{labelText}</span>
          </AlignmentWrapper>
        )
      }
    },
    {
      Header: 'LAST UPDATED (UTC)',
      accessor: 'updated_at',
      minWidth: 100,
      Cell: ({ row }) => {
        // TODO: re-sort table data after date update
        const updatedAt = updatedBundles.has(row.values.link.request_id)
          ? updatedBundles.get(row.values.link.request_id).updated_at
          : row.values.updated_at

        return <>{updatedAt}</>
      }
    },
    {
      Header: 'START (UTC)',
      accessor: 'start',
      minWidth: 100
    },
    {
      Header: 'END (UTC)',
      accessor: 'end',
      minWidth: 100
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({ row }) => {
        return <StatusCell row={row} />
      }
    },
    {
      Header: () => {
        return <AlignmentWrapper horizontalAlign="end">LINK</AlignmentWrapper>
      },
      accessor: 'link',
      Cell: ({ row }) => {
        return <DownloadCell row={row} />
      }
    }
  ]

  if (isLoadingMore)
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  if (error)
    return (
      <InfoBox
        body={<div>Unable to fetch Query Logs.</div>}
        iconName="info-circle"
        type={InfoBoxType.danger}
      />
    )
  return (
    <>
      <Table data={tableData} columns={columns} paginatedData={paginatedData} />
    </>
  )
}

export default QuerylogsTable
