import axios from 'axios'
import useSWR from 'swr'
import { rootSecret } from '..'
import { logAxiosError } from '../../../utils/log-helper'
import SessionCookie from '../../auth/session/cookie'
import * as sltcookie from '../../cookies/shortLivedTokenCookie'

const BASE_OTP_URL = `${process.env.REACT_APP_ACCOUNT_URL}/auth/otp`
const BACKUP_CODES_URL = `${BASE_OTP_URL}/backup_codes`
const VERIFY_URL = `${BASE_OTP_URL}/verify`
const UPDATE_URL = `${BASE_OTP_URL}/otp_enabled`
const PROVISIONING_URL = `${BASE_OTP_URL}/provisioning_uri`

type ProvisioningResponse = {
  provisioning_url: string
}

export async function fetchBackupCodes() {
  try {
    const shortLivedToken = sltcookie.get()
    const res = await axios.post(
      BACKUP_CODES_URL,
      { token: shortLivedToken?.token || '' },
      { headers: { secret: rootSecret() } }
    )
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export async function verifyOTP(otp: string) {
  try {
    const shortLivedToken = sltcookie.get()
    const res = await axios.post(
      VERIFY_URL,
      {
        otp: otp,
        token: shortLivedToken?.token || ''
      },
      { headers: { secret: rootSecret() } }
    )
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export async function updateOTPEnabled(value: boolean) {
  try {
    const shortLivedToken = sltcookie.get()
    const res = await axios.put(
      UPDATE_URL,
      {
        otp_enabled: value,
        token: shortLivedToken?.token || ''
      },
      { headers: { secret: rootSecret() } }
    )
    const session = SessionCookie.get()
    SessionCookie.save({
      ...session,
      data: { ...session.data, user: { ...session.data.user, otp_enabled: value } }
    })
    sltcookie.remove()
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function getProvisioningURL() {
  try {
    const shortLivedToken = sltcookie.get()
    const res = await axios.post<ProvisioningResponse>(
      PROVISIONING_URL,
      { token: shortLivedToken?.token || '' },
      { headers: { secret: rootSecret() } }
    )
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export function useProvisioningURL() {
  return useSWR('/otp/provisioning_url', getProvisioningURL)
}
