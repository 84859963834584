import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Match, RouterHistory, Location } from 'react-router-dom'
import * as ClassResource from '../../../modules/classResource'
import { sortByName } from '../../../utils/array'
import EmptyClass from './EmptyClass'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'
import ClassShow from './Show'

type ClassesIndexProps = {
  fetchClasses: typeof ClassResource.actions.fetchList
  match: Match
  history: RouterHistory
  location: Location
}

function ClassesIndex({ fetchClasses, match, history, location }: ClassesIndexProps) {
  const databasePath = match.params.dbPath
  const region = match.params.region
  const [firstClass, setFirstClass] = useState(null)
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(
    () => {
      fetchClasses({
        databasePath,
        onComplete: classes => {
          if (classes.length > 0) {
            setFirstClass(sortByName(classes)[0])
            return
          }

          setIsEmpty(true)
        }
      })
    }, // we just want to run this effect when the database route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [databasePath]
  )

  if (isEmpty) {
    return (
      <div className="card margin-top-4">
        <EmptyClass databasePath={databasePath || 'root'} region={region} displayDescription />
      </div>
    )
  }

  if (firstClass) {
    const props = {
      history,
      location,
      match: {
        ...match,
        params: {
          ...match.params,
          classId: firstClass.name
        }
      }
    }

    return <ClassShow {...props} />
  }

  return <></>
}

export default connect(
  (state, { match }) => ({
    classes: ClassResource.all(state, match.params.dbPath)
  }),
  {
    fetchClasses: ClassResource.actions.fetchList
  }
)(
  withBreadcrumbs(({ location, classes }) => {
    const hasClasses = classes && classes.length > 0

    return !hasClasses
      ? [
          {
            label: tx('class_.plural'),
            path: location.pathname
          }
        ]
      : []
  })(ClassesIndex)
)
