import { HTMLProps } from 'react'
import Button from './Button'
import Icon from './Icon'
import React from 'react'

type IconButtonProps = HTMLProps<HTMLButtonElement> & {
  loading?: boolean
  icon: string
  label?: string
  color?: string
  onClick?: any
  size?: string
}

export default function IconButton({ icon, label, ...buttonProps }: IconButtonProps) {
  return (
    <Button {...buttonProps} className={`btn-icon ${buttonProps.className}`}>
      <Icon name={icon} className={label ? '' : 'no-margin'} />
      {label ? label : null}
    </Button>
  )
}

IconButton.defaultProps = {
  color: 'subtle-link',
  loading: false
}
