import Button from '../../shared/Button'
import Well from '../../shared/Well'
import graphQLIcon from '../../../assets/images/graphql-icon.svg'
import { tx } from '../../../modules/translate'

type GraphQLEmptyCardProps = {
  databaseSecret: string
  onImport: () => void
  isLoading: boolean
}

export default function GraphQLEmptyCard({
  databaseSecret,
  onImport,
  isLoading
}: GraphQLEmptyCardProps) {
  return (
    <div className="card">
      <Well
        shadow
        title={tx('graphql.importTitle')}
        icon={
          <span>
            <img src={graphQLIcon} alt="GraphQL Icon" />
          </span>
        }
        action={
          <Button onClick={onImport} loading={isLoading} color="success">
            {tx('graphql.importSchema')}
          </Button>
        }
        description={
          <div className="container container--small">
            <p className="text-center">{tx('graphql.importDescription')}</p>
          </div>
        }
      />
    </div>
  )
}
