import { tx } from '../../../modules/translate'

type ResourceSelectProps = {
  onChange: (privileges: Privilege[]) => void
  resourceOptions: [string]
  placeholderText: string
  emptyOptionsText: string
  dataTestId?: string
}

const ResourceSelect = ({
  onChange,
  resourceOptions = [],
  placeholderText,
  dataTestId,
  emptyOptionsText = tx('role.noResourcesAvailable')
}: ResourceSelectProps) => {
  const hasResourcesToShow = resourceOptions.length > 0
  const defaultOptionText = hasResourcesToShow ? placeholderText : emptyOptionsText

  return (
    <div data-testid={dataTestId} className="select select--with-button">
      <select onChange={onChange} disabled={!hasResourcesToShow}>
        <option value="" key="" disable="true">
          {defaultOptionText}
        </option>
        {resourceOptions.map(resourceName => (
          <option key={resourceName} value={resourceName}>
            {resourceName}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ResourceSelect
