import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import routes from '../../../config/routes'
import { showAlert } from '../../../modules/alert/actions'
import * as AlertTypes from '../../../modules/alert/types'
import { RequestStatus } from '../../../modules/api'
import useBillingInfo from '../../../modules/api/auth-service/billingInfo'
import useInvites, { Invite, useInvite } from '../../../modules/api/auth-service/invites'
import useMembers, {
  Member,
  MemberRole,
  useMember
} from '../../../modules/api/auth-service/members'
import SessionCookie from '../../../modules/auth/session/cookie'
import { useForm } from '../../../modules/form'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import IconButton from '../../shared/IconButton'
import Modal from '../../shared/Modal'
import ModalBody from '../../shared/ModalBody'
import ModalClose from '../../shared/ModalClose'
import { ModalConfirm } from '../../shared/ModalConfirm'
import SimpleDropdown from '../../shared/SimpleDropdown'
import Spinner from '../../shared/Spinner'
import DashboardTooltip from '../functions/DashboardTooltip'
import BetaAnnouncement from '../../shared/BetaAnnouncement'

const ROLES_CAN_INVITE = [MemberRole.admin, MemberRole.owner]
const initialInviteValues = { name: '', email: '', role: MemberRole.developer }
const initialConfirmModalState = { email: '', id: null, show: false }

const Members: React.FC = () => {
  const dispatch = useDispatch()
  const { handleChange, formValues, setFormValues } = useForm<{
    name: string
    email: string
    role: string
  }>(initialInviteValues)

  const { send, invites } = useInvites()
  const { members } = useMembers()
  const { billingInfo, hasTeamAccess } = useBillingInfo()
  const history = useHistory()
  const session = SessionCookie.get()
  const canInvite = ROLES_CAN_INVITE.includes(session.data.user.role)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      await send.call(formValues)
      dispatch(showAlert('User invited successfully!', AlertTypes.SUCCESS))
      setFormValues(initialInviteValues)
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), AlertTypes.ERROR))
    }
  }

  if (!invites.data || !members.data || !billingInfo.data) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  const v2PricingReleased = session.data?.feature_flags?.pricing_v2

  if (v2PricingReleased) {
    return (
      <>
        <ContentHeader divider>Members</ContentHeader>
        <div className="container padding-y-3">
          <BetaAnnouncement message="Team" />
        </div>
      </>
    )
  }

  return (
    <>
      <ContentHeader divider>Members</ContentHeader>

      <div className="container padding-y-3">
        {canInvite && (
          <section className="section padding-top-0">
            <h2 className="uppercase text-normal margin-bottom-3">Invite new members</h2>

            <form onSubmit={handleSubmit}>
              <div className="grid">
                <div className="row items-end">
                  <div className="col width-small-40">
                    <div className="form-group no-margin">
                      <label htmlFor="email" className="required">
                        Email
                      </label>
                      <input
                        required
                        autoFocus={hasTeamAccess}
                        value={formValues.email}
                        type="email"
                        onChange={handleChange}
                        name="email"
                        id="email"
                      />
                    </div>
                  </div>

                  <div className="col width-small-30">
                    <div className="form-group no-margin">
                      <label htmlFor="name" className="required">
                        Name
                      </label>
                      <input
                        required
                        value={formValues.name}
                        onChange={handleChange}
                        name="name"
                        id="name"
                      />
                    </div>
                  </div>

                  <div className="col width-small-30">
                    <div className="form-group no-margin">
                      <div className="form-label--tooltip">
                        <label htmlFor="role" className="required">
                          Role
                        </label>
                        <DashboardTooltip
                          id="roleid"
                          placement="left"
                          contentText={
                            <div>
                              <div className="margin-bottom-2">
                                <strong>Owner:</strong> Owners can add any kind of member to the
                                team, change billing information, and delete the team.
                              </div>
                              <div className="margin-bottom-2">
                                <strong>Admin:</strong> Admins can manage data within Fauna, as well
                                as change team metadata and add new admins and developers.
                              </div>
                              <div className="margin-bottom-2">
                                <strong>Developer:</strong> A user within a team that will be
                                managing data within Fauna. They cannot edit team metadata.
                              </div>
                              <div className="margin-bottom-2">
                                <strong>Billing:</strong> Billing users can change team metadata and
                                billing info.
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <MemberRolesSelect
                        role={session.data.user.role}
                        onChange={handleChange}
                        value={formValues.role}
                      />
                    </div>
                  </div>

                  <div className="col col--auto">
                    <div className="form-group no-margin">
                      <Button
                        type="submit"
                        className="btn-large"
                        color="success"
                        loading={send.status === RequestStatus.loading}
                      >
                        <Icon name="paper-plane" /> Send invite
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        )}

        {/** Adjust top alignment when the invite form is not displayed */}
        <section
          className={`section ${!canInvite ? 'padding-top-0' : ''}`}
          data-testid="list-members"
        >
          {canInvite && <h2 className="uppercase text-normal margin-bottom-3">Members</h2>}

          <div className="card-list">
            <div className="card-list-header grid">
              <div className="row">
                <div className="label col width-small-45">Email</div>
                <div className="label col width-35 display-none display-small-block">Role</div>
                <div className="label col width-15 display-none display-small-block">Status</div>
                <div className="label col width-5 display-none display-small-block" />
              </div>
            </div>

            <div className="card-list-body">
              {invites.data.map(invite => (
                <div className="card grid" key={invite.id}>
                  <div className="card-body">
                    <div className="row">
                      <div className="text-ellipsis col width-small-45">{invite.email}</div>

                      <div className="col width-small-35 display-none display-small-flex capitalize">
                        {invite.role}
                      </div>
                      <div className="col width-small-15 text-ellipsis display-none display-small-flex capitalize">
                        Invited
                      </div>

                      <div className="col width-5">
                        {canInvite && <InviteOptions invite={invite} />}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {members.data.map(member => (
                <div className="card grid" key={member.id}>
                  <div className="card-body">
                    <div className="row">
                      <div className="text-ellipsis col width-small-45">{member.email}</div>

                      <div className="col width-small-35 display-none display-small-flex capitalize">
                        {member.role || 'Owner'}
                      </div>
                      <div className="col width-small-15 text-ellipsis display-none display-small-flex capitalize">
                        Active
                      </div>

                      <div className="col width-5">
                        {canInvite && <MemberOptions member={member} />}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={!hasTeamAccess}
        // onClose={() => {}}
        className="upgrade-modal"
        data-testid="upgradeModal"
      >
        <ModalClose onClick={history.goBack} />

        <ModalBody>
          <h2 className="upgrade-modal__title">{tx('modals.teamsPaywall')}</h2>

          <div className="upgrade-modal__content">
            <ul className="upgrade-modal__offers">
              <li>
                <Icon name="rocket" className="upgrade-modal__offer-icon" />
                <span>Team management</span>
              </li>
              <li>
                <Icon name="rocket" className="upgrade-modal__offer-icon" />
                <span>3rd party auth integration</span>
              </li>
              <li>
                <Icon name="rocket" className="upgrade-modal__offer-icon" />
                <span>$150 worth of usage</span>
              </li>
              <li>
                <Icon name="rocket" className="upgrade-modal__offer-icon" />
                <span>Helpdesk with 48hrs SLA</span>
              </li>
            </ul>

            <div className="upgrade-modal__pricing">
              <div className="upgrade-modal__price">
                <span className="upgrade-modal__currency">$</span>
                <span className="upgrade-modal__value">150</span>
                <span className="upgrade-modal__recurrency">per month</span>
              </div>

              <Link
                to={routes.settings.billing.path}
                className="btn btn-success btn-large"
                data-testid="navigateToPlans"
              >
                Upgrade
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

const MemberRolesSelect: React.FC<{
  onChange: any
  value: string
  role: any
}> = ({ onChange, value, role }) => {
  const hasAdminPrivileges = role === MemberRole.owner || role === MemberRole.admin

  return (
    <select name="role" id="role" onChange={onChange} value={value} required>
      <option value="developer">Developer</option>
      {hasAdminPrivileges && <option value="billing">Billing</option>}
      {hasAdminPrivileges && <option value="admin">Admin</option>}
    </select>
  )
}

const InviteOptions = ({ invite }: { invite: Invite }) => {
  const { resend, remove } = useInvite(invite.id)
  const dispatch = useDispatch()
  const [confirmModal, setConfirmModal] = useState(initialConfirmModalState)

  const handleResendInviteClick = async (toggleDropdown: any) => {
    try {
      await resend.call()
      dispatch(showAlert('Invite was resent!', 'success'))
    } catch (error) {
      dispatch(showAlert('Error on resent the invite.', 'error'))
    } finally {
      toggleDropdown()
    }
  }

  const handleInviteRemovalClick = async (toggleDropdown: any) => {
    setConfirmModal({
      show: true,
      email: invite.email,
      id: invite.id
    })
    toggleDropdown()
  }

  const handleInviteRemoval = async () => {
    try {
      await remove.call()
      dispatch(showAlert('Invite was removed successfuly!', 'success'))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
    } finally {
      closeConfirmModal()
    }
  }

  const closeConfirmModal = () => {
    setConfirmModal(initialConfirmModalState)
  }

  return (
    <>
      <SimpleDropdown
        inline
        theme="light"
        position="right"
        renderItems={(toggleDropdown: any) => (
          <>
            <IconButton
              loading={resend.status === RequestStatus.loading}
              title="Resend invite"
              icon="paper-plane"
              className="dropdown-item"
              onClick={() => handleResendInviteClick(toggleDropdown)}
            />

            <IconButton
              loading={remove.status === RequestStatus.loading}
              title="Remove invite"
              icon="user-times"
              className="dropdown-item"
              onClick={() => handleInviteRemovalClick(toggleDropdown)}
            />
          </>
        )}
      >
        <IconButton icon="ellipsis-v" />
      </SimpleDropdown>

      <ModalConfirm
        modal={{
          show: confirmModal.show,
          content: ` Are you sure you want to remove ${confirmModal.email} invite?`,
          cancelText: 'Cancel',
          okText: 'Delete',
          onCancel: closeConfirmModal,
          onOk: handleInviteRemoval
        }}
      />
    </>
  )
}

const MemberOptions = ({ member }: { member: Member }) => {
  const { remove } = useMember(member.id)
  const dispatch = useDispatch()
  const [confirmModal, setConfirmModal] = useState(initialConfirmModalState)
  const session = SessionCookie.get()
  const isNotOwner = member.role !== MemberRole.owner
  const userAndMemberAreDiff = member.id !== session.data.user.id
  const canRemoveMember = isNotOwner && userAndMemberAreDiff

  const handleMemberRemovalClick = async (toggleDropdown: any) => {
    setConfirmModal({
      show: true,
      email: member.email,
      id: member.id
    })
    toggleDropdown()
  }

  const handleMemberRemoval = async () => {
    try {
      await remove.call()
      dispatch(showAlert('Member was removed successfuly!', 'success'))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
    } finally {
      closeConfirmModal()
    }
  }

  const closeConfirmModal = () => {
    setConfirmModal(initialConfirmModalState)
  }

  return (
    <>
      {canRemoveMember && (
        <SimpleDropdown
          inline
          theme="light"
          position="right"
          renderItems={(toggleDropdown: any) => (
            <>
              <IconButton
                loading={remove.status === RequestStatus.loading}
                title="Remove member"
                icon="user-times"
                className="dropdown-item"
                onClick={() => handleMemberRemovalClick(toggleDropdown)}
              />
            </>
          )}
        >
          <IconButton icon="ellipsis-v" />
        </SimpleDropdown>
      )}

      <ModalConfirm
        modal={{
          show: confirmModal.show,
          content: ` Are you sure you want to remove ${confirmModal.email} member?`,
          cancelText: 'Cancel',
          okText: 'Delete',
          onCancel: closeConfirmModal,
          onOk: handleMemberRemoval
        }}
      />
    </>
  )
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Team Management',
    path: routes.settings.members.path
  }
])(Members)
