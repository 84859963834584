import { NavLink, withRouter } from 'react-router-dom'
import routes from '../../../config/routes'
import { MemberRole } from '../../../modules/api/auth-service/members'
import SessionCookie from '../../../modules/auth/session/cookie'
import Icon from '../../shared/Icon'

const SettingsSidebar = () => {
  const session = SessionCookie.get()
  const role = session?.data?.user?.role
  const hasAdminPrivileges = [MemberRole.owner, MemberRole.admin].includes(role)
  const hasBillingPrivileges = [MemberRole.owner, MemberRole.billing].includes(role)

  return (
    <nav className="sidebar">
      {hasAdminPrivileges && (
        <NavLink exact to={routes.settings.account.path}>
          <Icon name="users-cog" />
          <span className="display-none display-xsmall-block">Account settings</span>
        </NavLink>
      )}

      <NavLink exact to={routes.settings.members.path}>
        <Icon name="users" />
        <span className="display-none display-xsmall-block">Team management</span>
      </NavLink>

      {hasBillingPrivileges && (
        <NavLink exact to={routes.settings.billing.path}>
          <Icon name="credit-card" />
          <span className="display-none display-xsmall-block">Plans & Billing</span>
        </NavLink>
      )}

      {hasBillingPrivileges && (
        <NavLink exact to={routes.settings.invoices.path}>
          <Icon name="file-invoice-dollar" />
          <span className="display-none display-xsmall-block">Invoices</span>
        </NavLink>
      )}

      <NavLink exact to={routes.settings.profile.path}>
        <Icon name="user-cog" />
        <span className="display-none display-xsmall-block">Personal settings</span>
      </NavLink>

      <NavLink exact to={routes.settings.security.path}>
        <Icon name="user-lock" />
        <span className="display-none display-xsmall-block">Security</span>
      </NavLink>

      <NavLink exact to={routes.settings.keys.path}>
        <Icon name="key" />
        <span className="display-none display-xsmall-block">Account keys</span>
      </NavLink>

      <NavLink exact to={routes.settings.backups.path}>
        <Icon name="archive" />
        <span className="display-none display-xsmall-block">Backups</span>
      </NavLink>

      <NavLink exact to={routes.settings.logs.query_logs.path}>
        <Icon name="chart-bar" />
        <span className="display-none display-xsmall-block">Logs</span>
      </NavLink>
    </nav>
  )
}

export default withRouter(SettingsSidebar)
