import { useState } from 'react'
import useSWR, { trigger, mutate } from 'swr'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes, { createRoute } from '../../../config/routes'
import ContentHeader from '../../layouts/ContentHeader'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import IconButton from '../../shared/IconButton'
import Spinner from '../../shared/Spinner'
import { ModalConfirm } from '../../shared/ModalConfirm'
import FunctionForm from './FunctionForm'
import { getFunction, updateFunction, selectDatabase, deleteFunction } from '../../../modules/api'
import { evalFQLCode } from '../../../modules/fql/eval'
import { formatFQLCode } from '../../../modules/fql/formatFQLCode'
import { tx } from '../../../modules/translate'
import { useHistory, useParams, Link } from 'react-router-dom'
import { showAlert, hideAlert } from '../../../modules/alert/actions'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import FunctionsTable from './FunctionsTable'
import { getRoleId, createRoleValue } from '../../../modules/roles'
import { getErrorMessage } from '../../../utils/error'
import { Plug } from 'react-outlet'

type FunctionsShowProps = {
  id?: string
}

const FunctionsShow = ({ id }: FunctionsShowProps) => {
  const [activeTab, setActiveTab] = useState('simple')
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { dbPath, functionId = id, region } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const { data: function_ } = useSWR(
    [functionId, dbPath, region, 'function'],
    (functionId, dbPath) => {
      selectDatabase(dbPath)
      return getFunction(functionId)
    },
    {
      revalidateOnFocus: false
    }
  )

  const initialValues = useMemo(() => {
    if (!function_) return undefined

    return {
      name: function_.name,
      body: formatFQLCode(function_.body),
      roleId: function_.role ? getRoleId(function_.role) : undefined
    }
  }, [function_])

  const handleSubmit = async formValues => {
    dispatch(hideAlert())
    setIsLoading(true)
    selectDatabase(dbPath)

    try {
      const updatedFunction = await updateFunction(functionId, {
        name: formValues.name,
        body: evalFQLCode(formValues.body),
        role: createRoleValue(formValues.roleId)
      })

      trigger([dbPath, 'functions'])
      mutate([functionId, dbPath, 'function'], updatedFunction, false)
      dispatch(showAlert('Function updated successfully!', 'success'))
      setIsLoading(false)

      if (updatedFunction.name !== function_.name) {
        history.push(
          createRoute(routes.functions.byId.path, dbPath, region).replace(
            ':functionId',
            formValues.name
          )
        )
      }
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    selectDatabase(dbPath)
    await deleteFunction(functionId)
    await mutate([dbPath, 'functions'], undefined, true)
    setIsConfirmOpen(false)
    history.push(createRoute(routes.functions.index.path, dbPath, region))
  }

  return (
    <>
      <Plug outletId="leftPanel">
        <div className="panel">
          <div className="panel__header">
            <h4>Functions</h4>

            <div className="panel__header__actions">
              <Link to={createRoute(routes.functions.new.path, dbPath, region)} className="btn">
                New function
              </Link>
            </div>
          </div>

          <div className="panel__content">
            <FunctionsTable selectedId={functionId} />
          </div>
        </div>
      </Plug>

      <div className="panel-content">
        {function_ ? (
          <>
            <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
              <ContentHeader
                tabs={
                  <Tabs position="right">
                    <Tab id="simple">Simple</Tab>
                    <Tab id="fql">FQL</Tab>
                  </Tabs>
                }
              >
                {function_.name}
              </ContentHeader>

              <div className="padding-y-3">
                <FunctionForm
                  key={functionId}
                  dbPath={dbPath}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  isLoading={isLoading}
                />

                <div className="container container--xsmall">
                  <hr className="margin-top-4 margin-bottom-3" />

                  <IconButton
                    color="muted"
                    onClick={() => setIsConfirmOpen(true)}
                    label={tx('actions.remove')}
                    icon="trash"
                  />

                  <ModalConfirm
                    modal={{
                      show: isConfirmOpen,
                      content: `Are you sure you want to delete function ${functionId}?`,
                      cancelText: 'Cancel',
                      okText: 'Delete',
                      onCancel: () => setIsConfirmOpen(false),
                      onOk: handleDelete
                    }}
                  />
                </div>
              </div>
            </TabsProvider>
          </>
        ) : (
          <div className="loader">
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}

export default withBreadcrumbs(({ location, match, id }) => {
  const { functionId = id, dbPath, region } = match.params

  return [
    {
      label: 'Functions',
      path: createRoute(routes.functions.index.path, dbPath, region)
    },
    {
      label: functionId,
      path: location.pathname
    }
  ]
})(FunctionsShow)
