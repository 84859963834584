import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import Clipboard from 'react-clipboard.js'
import Lottie from 'react-lottie'
import ReactOtpInput from 'react-otp-input'
import tickLottie from '../../../assets/lotties/tick.json'
import {
  fetchBackupCodes,
  useProvisioningURL,
  updateOTPEnabled,
  verifyOTP
} from '../../../modules/api/auth-service/otp'
import Button from '../../shared/Button'
import Modal from '../../shared/Modal'
import ModalBody from '../../shared/ModalBody'
import ModalHeader from '../../shared/ModalHeader'
import Spinner from '../../shared/Spinner'

type MFAStepProps = {
  currentStep: number
  updateState?: (props: MFASetupState) => void
  backupCodes?: string[]
  closeModal?: () => void
}

type MFASetupState = {
  currentStep: number
  backupCodes?: string[]
}

type MFASetupStep = {
  title: string
  body: React.FC<MFAStepProps>
}

const RegisterStep: React.FC<MFAStepProps> = ({ currentStep, updateState }) => {
  const { data: provisioningResponse } = useProvisioningURL()

  const [isOtpCodeInvalid, setOtpCodeInvalid] = useState(false)
  const [isOtpInputDisabled, setOtpInputDisabled] = useState(false)
  const [otpCode, setOtpCode] = React.useState('')

  function setInputInvalid() {
    setOtpCodeInvalid(true)
    setOtpInputDisabled(false)
  }

  function blockInput() {
    setOtpCodeInvalid(false)
    setOtpInputDisabled(true)
  }

  useEffect(() => {
    if (otpCode.trim().length === 6) {
      blockInput()
      verifyOTP(otpCode)
        .then(fetchBackupCodes)
        .then(data => {
          updateState({
            backupCodes: data.backup_codes.map(b => b.code),
            currentStep: currentStep + 1
          })
        })
        .catch(() => {
          setInputInvalid()
        })
    }
  }, [otpCode, currentStep, updateState])

  useEffect(() => {
    setOtpCode('')
  }, [isOtpCodeInvalid])

  return (
    <div>
      <div className="padding-x-1">
        <ol>
          <li>
            {' '}
            <p>
              Get the free{' '}
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                Google Authenticator
              </a>{' '}
              app (or any other OTP app) from the app store.
            </p>{' '}
          </li>
          <li>
            {' '}
            <p>Scan the QR code:</p>{' '}
          </li>
        </ol>
        {provisioningResponse ? (
          <div className="center margin-y-3">
            <div>
              <div className="center">
                <QRCode value={provisioningResponse.provisioning_url} />
              </div>
              <div className="center smaller">
                <span
                  data-testid="mfa-secret"
                  title="Copy to clipboard"
                  className="clipboard display-small-inline-block"
                >
                  {
                    /* matches a string starting with either '?' or '&', then 'secret=' then
                        declares a capture group matching only uppercase Letters and Numbers */
                    provisioningResponse.provisioning_url.match(/[?&]secret=([A-Z0-9]+)/)[1]
                  }
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
        <ol start={3}>
          <li>
            <p>Enter the 6-digit verification code: </p>{' '}
          </li>
        </ol>
      </div>

      <div>
        <ReactOtpInput
          onChange={otp => {
            setOtpCode(otp)
          }}
          numInputs={6}
          value={otpCode}
          containerStyle={`otp-container ${isOtpCodeInvalid ? 'otp-container--error' : ''}`}
          inputStyle="otp-input opacity-100"
          errorStyle="otp-input--error"
          hasErrored={isOtpCodeInvalid}
          shouldAutoFocus={true}
          isDisabled={isOtpInputDisabled}
        />

        {isOtpCodeInvalid && (
          <div className="bottom-note bottom-note--error center">
            Code Invalid. Please try again.
          </div>
        )}
      </div>
    </div>
  )
}

const BackupCodesStep: React.FC<MFAStepProps> = ({ backupCodes, currentStep, updateState }) => {
  const codes = backupCodes
  const txtFile = encodeURIComponent('Fauna Backup Codes \r\n' + codes.join('\r\n'))
  const [codesDownloaded, setCodesDownloaded] = useState(false)
  const [codesCopied, setCodesCopied] = useState(false)

  function enableMFA() {
    updateOTPEnabled(true).then(() => {
      updateState({ currentStep: currentStep + 1 })
    })
  }

  return (
    <div className="padding-x-4 display-flex flex-direction-column height-100 justify-content-space-evenly">
      <div className="backup-logo margin-y-3"></div>

      <div className="">
        <p className="font-normal">
          These recovery codes are good for one-time authentication when your multi-factor
          authentication device is unavailable. Please write these codes down and keep them in a
          safe place.{' '}
        </p>
      </div>

      <div className="display-flex code flex-wrap padding-y-2 justify-content-space-between">
        {codes.map(c => (
          <div key={c} className="h3 padding-right-4 backup-codes">
            {' '}
            {c}
          </div>
        ))}
      </div>

      <div className="center margin-x-1 margin-top-3 margin-bottom-1 uppercase smaller">
        <a
          href={`data:text/plain;charset=utf-8,${txtFile}`}
          className="secundary"
          download="fauna_backup_codes.txt"
          onClick={() => setCodesDownloaded(true)}
        >
          Download as Text File
        </a>
      </div>

      <div className="center">
        <Clipboard
          onClick={() => {
            setCodesCopied(true)
          }}
          data-clipboard-text={codes.join(' ')}
        >
          <div data-tip="Copy Ref to clipboard" className="card-col">
            <span title="Copy to clipboard" className="clipboard display-small-inline-block">
              {codesCopied ? 'Copied!' : '(Or copy your backup codes to clipboard)'}
            </span>
          </div>
        </Clipboard>
      </div>

      <hr className="margin-y-2" />

      <p className="center font-size-14 margin-top-1">
        <label>
          <input
            type="checkbox"
            data-testid="mfa-backup-codes-checkbox"
            checked={codesDownloaded}
            onChange={() => setCodesDownloaded(!codesDownloaded)}
          />{' '}
          Yes, I wrote these down.
        </label>
      </p>

      <div className="display-flex center margin-bottom-3 margin-top-2">
        <Button disabled={!codesDownloaded} onClick={enableMFA} color="success">
          Enable MFA
        </Button>
      </div>
    </div>
  )
}

const SuccessStep: React.FC<MFAStepProps> = props => {
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: tickLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <div className="padding-x-4 display-flex flex-direction-column height-100 justify-content-space-evenly">
      <Lottie options={animationOptions} height={89} width={89} isPaused={false} />

      <p className="font-normal center">MFA has now been enabled for your account.</p>

      <hr className="margin-y-2" />

      <div className="display-flex center margin-bottom-3 margin-top-2">
        <Button onClick={() => props.closeModal()} color="primary">
          Close
        </Button>
      </div>
    </div>
  )
}

const steps: Record<number, MFASetupStep> = {
  1: {
    title: 'Enable multi-factor authentication',
    body: RegisterStep
  },
  2: {
    title: 'One more thing!',
    body: BackupCodesStep
  },
  3: {
    title: 'Congratulations!',
    body: SuccessStep
  }
}

export const MFASetup = ({ isOpen, closeModal }) => {
  const [setupState, setSetupState] = useState<MFASetupState>({ currentStep: 1, backupCodes: [] })

  const component = steps[setupState.currentStep].body

  return (
    <Modal isOpen={isOpen} className="multi-fa-modal">
      <ModalHeader onClose={closeModal}>{steps[setupState.currentStep].title}</ModalHeader>
      <ModalBody className="padding-x-0 padding-bottom-0 padding-top-1">
        {React.createElement(component, {
          ...setupState,
          updateState: state => {
            setSetupState({ ...setupState, ...state })
          },
          closeModal
        })}
      </ModalBody>
    </Modal>
  )
}
