import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as API from '../../../modules/api'
import useBillingInfo from '../../../modules/api/auth-service/billingInfo'
import useDatabaseDetails from '../../../modules/fauna/useDatabaseDetails'
import getRegion from '../../../modules/auth/session/regions'
import { getFullDatabasePath } from '../../../modules/databaseResource'
import { tx } from '../../../modules/translate'
import { MetricName, CompoundMetricName } from '../../../modules/usage'
import { DatabaseRef } from '../../../types/faunadbTypes'
import * as colors from '../../../utils/colors'
import { getMetricDisplayName } from '../../../utils/usage'
import Dropdown from '../../shared/Dropdown'
import Icon from '../../shared/Icon'
import Well from '../../shared/Well'
import RangeDropdown from '../dashboard/RangeDropdown'
import DashboardTooltip from '../functions/DashboardTooltip'
import DatabaseSettingsDrawer, { Stage } from '../settings/DatabaseSettingsDrawer'
import { QuerylogDrawer } from './QuerylogDrawer'
import { NewDatabaseDrawer } from './NewDatabaseDrawer'
import DatabaseTableRow from './DatabaseTableRow'
import UsageChart from './UsageChart'
import { useDrawer } from './useDrawer'
import routes from '../../../config/routes'

type Props = {
  title: string
  region?: string
  databasePath: string
}

const DEFAULT_RANGE = 7
const DEFAULT_HISTOGRAM: MetricName = MetricName.Read

export default function DatabasesTableWithRegionGroup({ databasePath, title, region }: Props) {
  const history = useHistory()

  const path = databasePath ? databasePath : ''
  const isRoot = path === ''
  const { isFreePlan } = useBillingInfo()

  const createDbDrawer = useDrawer('createDb')
  const querylogDrawer = useDrawer('querylog')
  const settingsDrawer = useDrawer(isRoot ? 'rootDbSettings' : 'dbSettings')

  const [range, setRange] = useState(DEFAULT_RANGE)
  const [activeHistogramType, setActiveHistogramType] = useState(DEFAULT_HISTOGRAM)
  const [selectedDatabase, setSelectedDatabase] = useState<DbSettingsParams>({
    dbPath: '',
    region: ''
  })

  const { databases, restores, rows } = useDatabaseDetails(path, region)

  const hasRestores = restores && restores.length > 0 ? true : false
  const hasDatabases = databases && databases.length > 0

  function renderEmpty(): React.ReactNode {
    const classList = ['btn']
    classList.push(databasePath ? 'btn-primary' : 'btn-success')
    if (!databasePath) {
      return (
        <div className="databases-panel__body center">
          <Well
            centered
            shadow
            title={tx('database.empty.create')}
            icon={<Icon name="database" />}
            action={
              <span className={classList.join(' ')} onClick={createDbDrawer.onSwitch}>
                <span className="fas fa-database" />
                {tx('database.actions.new')}
              </span>
            }
          />
        </div>
      )
    } else {
      return (
        <div className="well well--muted">
          <div>
            Each database can have unlimited child databases to accommodate an unlimited number of
            tenants, each with their own security models. Learn more about multitenancy{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.fauna.com/fauna/v4/learn/tutorials/fql/multitenant"
            >
              in our docs
            </a>
            .
          </div>
        </div>
      )
    }
  }

  function renderDatabases(): React.ReactNode {
    // +2 because we are also rendering the account_level & backup restore usage
    const chartColors = colors.generate(databases.length + 2)
    const currentHistogramType = getCurrentHistogramType()

    const handleSettingsClick = (
      event: React.MouseEvent,
      database: DatabaseRef,
      regionPrefix: string
    ): void => {
      event.stopPropagation()
      if (isRoot) API.setCurrentRegion(getRegion(regionPrefix))
      setSelectedDatabase({
        dbPath: getFullDatabasePath(databasePath, database),
        region: regionPrefix
      })
      settingsDrawer.onSwitch()
    }

    const handleQuerylogClick = (
      event: React.MouseEvent,
      database: DatabaseRef,
      regionPrefix: string
    ): void => {
      event.stopPropagation()
      if (isRoot) API.setCurrentRegion(getRegion(regionPrefix))
      setSelectedDatabase({
        dbPath: getFullDatabasePath(databasePath, database),
        region: regionPrefix
      })
      querylogDrawer.onSwitch()
    }

    const renderMetricTooltip = (metric: string): React.ReactNode => {
      return (
        <div>
          <span>{tx(`tooltips.${metric}Tooltip`)} </span>
          <span>{isFreePlan ? tx(`tooltips.${metric}TooltipFree`) : null} </span>
          <span>{tx('tooltips.additionalDetails')} </span>
          <a href="https://fauna.com/pricing" target="_blank" rel="noopener noreferrer">
            {tx('tooltips.pricingAndPlansLink')}
          </a>
        </div>
      )
    }

    return (
      <div className="databases-panel__body">
        <div className="databases-panel__chart">
          <Dropdown
            items={histogramTypes()}
            value={activeHistogramType}
            onChange={setActiveHistogramType}
          />
          <UsageChart
            histogramType={activeHistogramType}
            colors={chartColors}
            dbDetails={rows}
            label={currentHistogramType.label}
            rootUsage={null}
            usageData={undefined}
            usageLoading={false}
            accountUsage={undefined}
          />
        </div>

        <div className="databases-panel__table">
          <div className="table-container">
            <div className="header-row">
              <div className="flex-table">
                <div className="dbNameColumn selected width-25 flex-table-card">
                  {tx('attributes.name')}
                </div>
                <div className={getUsageColumnClassName('read')}>
                  <DashboardTooltip
                    header={getMetricDisplayName(MetricName.Read)}
                    id="readops"
                    placement="left"
                    contentText={renderMetricTooltip('readOps')}
                  />
                </div>
                <div className={getUsageColumnClassName('write')}>
                  <DashboardTooltip
                    id="WriteOps"
                    header={getMetricDisplayName(MetricName.Write)}
                    placement="left"
                    contentText={renderMetricTooltip('writeOps')}
                  />
                </div>
                <div className={getUsageColumnClassName('storage')}>
                  <DashboardTooltip
                    id={getMetricDisplayName('storage')}
                    header={getMetricDisplayName(CompoundMetricName.Storage)}
                    placement="left"
                    contentText={renderMetricTooltip('storage')}
                  />
                </div>
                <div className={getUsageColumnClassName('compute')}>
                  <DashboardTooltip
                    id="ComputeOps"
                    header={getMetricDisplayName(MetricName.DatabaseCompute)}
                    placement="left"
                    contentText={renderMetricTooltip('computeOps')}
                  />
                </div>
                {isRoot && (
                  <div className={getUsageColumnClassName('RegionGroup')}>
                    <DashboardTooltip
                      id="RegionGroup"
                      header="Region Group"
                      placement="left"
                      contentText={
                        <div>
                          <p>Region Group determines where your data resides.</p>
                        </div>
                      }
                    />
                  </div>
                )}

                <>
                  <div className={getUsageColumnClassName('backups')}>Backups</div>
                  <div className={getUsageColumnClassName('actions')}>
                    <span style={{ paddingRight: '8px' }}>
                      {' '}
                      {tx('databaseTable.columns.actions')}
                    </span>
                  </div>
                </>
              </div>
            </div>
            <div className="scrollable-content">
              {isRoot && (
                <div className="flex-table content root">
                  <div
                    className="dbNameColumn width-25 flex-table-card tr-link-flex flex-table-card"
                    style={{
                      borderLeftColor: 'lightgrey'
                    }}
                  >
                    <span className="firstColumn" data-hj-suppress>
                      Total
                    </span>
                  </div>
                  <div className={getUsageRowClassName('read')}>
                    <span className="mobile-only">{getMetricDisplayName(MetricName.Read)}</span>
                    <span>--</span>
                  </div>
                  <div className={getUsageRowClassName('write')}>
                    <span className="mobile-only">{getMetricDisplayName(MetricName.Write)}</span>
                    <span>--</span>
                  </div>
                  <div className={getUsageRowClassName('storage')}>
                    <span className="mobile-only">
                      {getMetricDisplayName(CompoundMetricName.Storage)}
                    </span>
                    <span>--</span>
                  </div>
                  <div className={getUsageRowClassName('compute')}>
                    <span className="mobile-only">
                      {getMetricDisplayName(MetricName.DatabaseCompute)}
                    </span>
                    <span>--</span>
                  </div>
                  {isRoot && (
                    <div className={getUsageRowClassName('regionGroup')}>
                      <span className="mobile-only">Region Group</span>
                      <span>--</span>
                    </div>
                  )}

                  <>
                    <div className={getUsageRowClassName('backups')}>
                      <span className="mobile-only">Backups</span>
                      <span>--</span>
                    </div>
                    <div className={getUsageRowClassName('actions')}>
                      <span className="mobile-only">{tx('databaseTable.columns.actions')}</span>
                    </div>
                  </>
                </div>
              )}
              {isRoot && (
                <div className="flex-table content root">
                  <div
                    className="dbNameColumn width-25 flex-table-card tr-link-flex flex-table-card"
                    style={{
                      borderLeftColor: 'lightgrey'
                    }}
                  >
                    <span className="withTooltip">
                      <span className="firstColumn" data-hj-suppress>
                        Dashboard/Shell/Playground
                      </span>
                      <DashboardTooltip
                        id="DashboardShellPlayground"
                        placement="left"
                        contentText={
                          <div>
                            <p>{tx('billing.account')}</p>
                          </div>
                        }
                      />
                    </span>
                  </div>
                  <div className={getUsageRowClassName('read')}>
                    <span className="mobile-only">{getMetricDisplayName(MetricName.Read)}</span>
                    <span>--</span>
                  </div>
                  <div className={getUsageRowClassName('write')}>
                    <span className="mobile-only">{getMetricDisplayName(MetricName.Write)}</span>
                    <span>--</span>
                  </div>
                  <div className={getUsageRowClassName('storage')}>
                    <span className="mobile-only">
                      {getMetricDisplayName(MetricName.DatabaseStorage)}
                    </span>
                    <span>--</span>
                  </div>
                  <div className={getUsageRowClassName('compute')}>
                    <span className="mobile-only">
                      {getMetricDisplayName(MetricName.DatabaseCompute)}
                    </span>
                    <span>--</span>
                  </div>
                  {isRoot && (
                    <div className={getUsageRowClassName('regionGroup')}>
                      <span className="mobile-only">Region Group</span>
                      <span>--</span>
                    </div>
                  )}

                  <>
                    <div className={getUsageRowClassName('backups')}>
                      <span className="mobile-only">Backups</span>
                      <span>--</span>
                    </div>
                    <div className={getUsageRowClassName('regionGroup')}>
                      <span className="mobile-only">{tx('databaseTable.columns.actions')}</span>
                    </div>
                  </>
                </div>
              )}
              {rows.map((dbRow, index) => {
                return (
                  <DatabaseTableRow
                    key={index}
                    dbDetails={dbRow}
                    color={chartColors[index]}
                    isRoot={isRoot}
                    handleRowClick={handleRowClick}
                    getUsageRowClassName={getUsageRowClassName}
                    getMetricDisplayName={getMetricDisplayName}
                    handleQuerylogClick={handleQuerylogClick}
                    handleSettingsClick={handleSettingsClick}
                    usageId={dbRow.databasePath}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function handleRowClick(regionPrefix: string, database: DatabaseRef) {
    history.push(databaseLink(regionPrefix, database))
  }

  function getUsageColumnClassName(histogramType: string) {
    const defaultClassName = 'dbUsageColumn text-right flex-item flex-table-card '

    return `
      ${defaultClassName}
      ${histogramType === activeHistogramType ? 'selected' : ''}
    `
  }

  function getUsageRowClassName(histogramType: string) {
    const defaultClassName = 'dbUsageColumn text-right flex-item flex-table-card' // row

    return `
      ${defaultClassName}
      ${histogramType === activeHistogramType ? 'selected' : ''}
    `
  }

  function databaseLink(regionPrefix: string, database: DatabaseRef) {
    return `/db/${regionPrefix}/${getFullDatabasePath(databasePath, database)}`
  }

  function histogramTypes(): { value: MetricName | CompoundMetricName; label: string }[] {
    const types: { value: MetricName | CompoundMetricName; label: string }[] = [
      {
        value: MetricName.Read,
        label: getMetricDisplayName(MetricName.Read)
      },
      {
        value: MetricName.Write,
        label: getMetricDisplayName(MetricName.Write)
      },
      {
        value: MetricName.DatabaseCompute,
        label: getMetricDisplayName(MetricName.DatabaseCompute)
      },
      {
        value: CompoundMetricName.Storage,
        label: getMetricDisplayName(CompoundMetricName.Storage)
      }
    ]
    return types
  }

  function getCurrentHistogramType() {
    return histogramTypes().find(type => type.value === activeHistogramType)
  }

  function renderDateDropdown() {
    if (hasDatabases) {
      return <RangeDropdown value={range} onChange={setRange} />
    }
  }

  function renderContent() {
    if (!hasDatabases && !hasRestores) return renderEmpty()
    return renderDatabases()
  }

  return (
    <section className="databases-panel" data-testid="databases-panel">
      <QuerylogDrawer
        show={querylogDrawer.show}
        onClose={querylogDrawer.onSwitch}
        dbPath={selectedDatabase.dbPath}
        regionPrefix={selectedDatabase.region}
        onSuccess={() => {
          history.push(routes.settings.logs.query_logs.path)
        }}
      />
      <NewDatabaseDrawer show={createDbDrawer.show} onClose={createDbDrawer.onSwitch} />
      <DatabaseSettingsDrawer
        open={settingsDrawer.show}
        onClose={settingsDrawer.onSwitch}
        initialStage={Stage.SETTINGS}
        dbSettingsParams={selectedDatabase}
        shouldRedirectOnSubmit={false}
      />
      <div className="databases-panel__header">
        <h4>{title}</h4>
        <span className="btn btn-subtle-link" onClick={createDbDrawer.onSwitch}>
          <span className="fas fa-database" />
          {tx('database.actions.new')}
        </span>
        {renderDateDropdown()}
      </div>
      {renderContent()}
    </section>
  )
}

type DbSettingsParams = {
  dbPath: string
  region: string
}
