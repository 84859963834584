import { Link, useParams } from 'react-router-dom'
import Well from '../../shared/Well'
import Icon from '../../shared/Icon'
import routes, { createRoute } from '../../../config/routes'
import { tx } from '../../../modules/translate'

type Props = {
  databasePath: string
}

export default function EmptyKey({ databasePath }: Props) {
  const { region } = useParams()
  return (
    <Well
      shadow
      title="Create your first database key"
      icon={<Icon name="key" />}
      action={
        <Link
          className="btn btn-success"
          to={createRoute(routes.keys.new.path, databasePath || '', region)}
        >
          {tx('keys.actions.new')}
        </Link>
      }
      description={
        <div className="container container--small">
          <p>
            Requests are authenticated based on database keys that map to a role defining actions a
            request can perform and the resources that can be acted on.
          </p>
        </div>
      }
    />
  )
}
