import axios from 'axios'
import { rootSecret } from '..'
import { logAxiosError } from '../../../utils/log-helper'

export const API_URL = `${process.env.REACT_APP_AUTH_URL}/logout`

export default async function logoutSession(): Promise<void> {
  try {
    await axios.post(
      API_URL,
      {},
      {
        headers: { secret: rootSecret() }
      }
    )
  } catch (error) {
    logAxiosError(error)
  }
}
