import axios from 'axios'
import { Region } from '../../auth/session/regions'

const BASE_FDS_URL = `${process.env.REACT_APP_FRONTDOOR_URL}/api/v1`

const bearerToken = (secret: string) => 'Bearer ' + secret

export const whoami = async (region: Region): Promise<{}> => {
  const authorizationToken = bearerToken(region.secret)
  const frontdoorRegionPrefix = region.frontdoorPrefix
  const url = `${BASE_FDS_URL}/whoami`
  const { data } = await axios.get(url, {
    headers: {
      Authorization: authorizationToken,
      region_group: frontdoorRegionPrefix
    }
  })
  return data
}
