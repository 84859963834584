import {
  useBillingSettingsRouteValidation,
  useDBRouteValidation,
  useResetRouteValidation
} from './routeValidators'

export function createRouteGenerator(databasePath: string, region?: string): (string) => string {
  return (route: string) => createRoute(route, databasePath, region)
}

export function createRoute(route: string, databasePath: string, region?: string): string {
  if (region && region !== '') {
    return route.replace(':dbPath*', databasePath).replace(':region', region)
  } else {
    return route.replace(':dbPath*', databasePath).replace(':region/', '')
  }
}

// a validator attribute can be optionally included in any route object. The value must
//  be a function from the routeValidators.ts file.

export default {
  addons: {
    authorize: { exact: true, path: '/addons/authorize' }
  },
  auth: {
    signIn: { exact: true, path: '/accounts/login' },
    callback: { exact: true, path: '/accounts/login/:strategyName/callback' },
    oauth: {
      callback: { exact: true, path: '/auth/oauth/callback' }
    },
    forgotPassword: { exact: true, path: '/auth/forgot-password' },
    resetPassword: { exact: true, path: '/auth/reset-password', validator: useResetRouteValidation }
  },
  home: {
    exact: true,
    path: '/'
  },
  db: {
    index: { exact: true, path: '/db' },
    new: { exact: true, path: '/db-new/:region/:dbPath*' },
    rootNew: { exact: true, path: '/db-new/:dbPath*' },
    edit: { exact: true, path: '/db-edit/:region/:dbPath*', validator: useDBRouteValidation },
    show: { exact: true, path: '/db-show/:region/:dbPath*', validator: useDBRouteValidation },
    byId: { exact: false, path: '/db/:region/:dbPath*', validator: useDBRouteValidation }
  },
  classes: {
    index: { exact: true, path: '/collections/@db/:region/:dbPath*' },
    new: { exact: true, path: '/collections-new/@db/:region/:dbPath*' },
    byId: { exact: true, path: '/collections/:classId/@db/:region/:dbPath*' },
    settings: { exact: true, path: '/collections-edit/:classId/@db/:region/:dbPath*' },
    byIdWithoutDb: { exact: true, path: '/collections/:classId' }
  },
  instances: {
    new: {
      exact: true,
      path: '/collections/documents-new/:classId/@db/:region/:dbPath*'
    },
    edit: {
      exact: true,
      path: '/collections/documents-edit/:classId/:referenceId/@db/:region/:dbPath*'
    },
    show: {
      exact: true,
      path: '/collections/documents/:classId/:referenceId/@db/:region/:dbPath*'
    }
  },
  indexes: {
    indexWithoutDb: { exact: true, path: '/indexes' },
    index: { exact: true, path: '/indexes/@db/:region/:dbPath*' },
    settings: { exact: true, path: '/indexes-show/:indexId/@db/:region/:dbPath*' },
    edit: { exact: true, path: '/indexes-edit/:indexId/@db/:region/:dbPath*' },
    byId: { exact: true, path: '/indexes/:indexId/@db/:region/:dbPath*' },
    byIdWithoutDb: { exact: true, path: '/indexes/:indexId' },
    new: { exact: true, path: '/indexes-new/@db/:region/:dbPath*' }
  },
  functions: {
    index: { exact: true, path: '/functions/@db/:region/:dbPath*' },
    new: { exact: true, path: '/functions-new/@db/:region/:dbPath*' },
    byId: { exact: true, path: '/functions/:functionId/@db/:region/:dbPath*' }
  },
  backup: {
    index: { exact: true, path: '/backups/@db/:region/:dbPath*' }
  },
  keys: {
    index: { exact: true, path: '/keys/@db/:region/:dbPath*' },
    indexWithoutDb: { exact: true, path: '/keys/@db' },
    new: { exact: true, path: '/keys-new/@db/:region/:dbPath*' },
    newWithoutDb: { exact: true, path: '/keys-new/@db' }
  },
  webshell: {
    index: { exact: true, path: '/webshell/@db/:region/:dbPath*' },
    legacy: { exact: true, path: '/console/@db/:region/:dbPath*' }
  },
  roles: {
    index: { exact: true, path: '/roles/@db/:region/:dbPath*' },
    new: { exact: true, path: '/roles-new/@db/:region/:dbPath*' },
    show: { exact: true, path: '/roles-show/:roleId/@db/:region/:dbPath*' },
    edit: { exact: true, path: '/roles-edit/:roleId/@db/:region/:dbPath*' }
  },
  graphql: {
    index: { exact: true, path: '/graphql/@db/:region/:dbPath*' }
  },
  teams: {
    settings: { exact: true, path: '/teams/settings' },
    new: { exact: true, path: '/teams/new' },
    members: { exact: true, path: '/teams/members' },
    billing: { exact: true, path: '/teams/billing' },
    invoices: { exact: true, path: '/teams/invoices' }
  },
  profile: {
    billing: { exact: true, path: '/profile/billing' },
    invoices: { exact: true, path: '/profile/invoices' },
    security: { exact: true, path: '/profile/security' }
  },
  settings: {
    profile: { exact: true, path: '/settings/profile' },
    account: { exact: true, path: '/settings/account' },
    billing: {
      exact: true,
      path: '/settings/billing',
      validator: useBillingSettingsRouteValidation
    },
    invoices: { exact: true, path: '/settings/invoices' },
    members: { exact: true, path: '/settings/members' },
    security: { exact: true, path: '/settings/security' },
    backups: { exact: true, path: '/settings/backups' },
    keys: { exact: true, path: '/settings/keys' },
    logs: {
      query_logs: { exact: true, path: '/settings/logs/query-logs' }
    }
  },
  downloads: {
    index: { exact: true, path: '/downloads' }
  },
  providers: {
    edit: { exact: true, path: '/providers-edit/:providerId/@db/:region/:dbPath*' },
    index: { exact: true, path: '/providers/@db/:region/:dbPath*' },
    new: { exact: true, path: '/providers-new/@db/:region/:dbPath*' }
  },
  invites: {
    accept: { exact: true, path: '/invites/:token' }
  }
}
