import Drawer from 'rc-drawer'
import React from 'react'
import { Toaster } from 'react-hot-toast'
// @ts-ignore
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'
import ContentHeader from '../layouts/ContentHeader'

export interface ConsoleDrawerProps {
  open: boolean
  onClose: () => void
  afterVisibleChange?: (isVisible: boolean) => void
  onChange?: (isOpen: boolean) => void
  header?: string
}

export const ConsoleDrawer: React.FC<ConsoleDrawerProps> = ({
  header,
  open,
  onClose,
  afterVisibleChange,
  onChange,
  children
}) => {
  return (
    <>
      <Toaster />
      <Drawer
        open={open}
        placement="right"
        level={null}
        handler={false}
        onClose={onClose}
        afterVisibleChange={isVisible => {
          if (afterVisibleChange) afterVisibleChange(isVisible)
        }}
        onChange={isOpen => {
          if (onChange) onChange(isOpen)
        }}
      >
        <div className="console-drawer">
          <div className="console-drawer-header">
            <ContentHeader>{header}</ContentHeader>
            <span className="console-drawer-close" onClick={() => onClose()}>
              <CloseIcon />
            </span>
          </div>
          <div className="console-drawer-body">{children}</div>
        </div>
      </Drawer>
    </>
  )
}
