import { Client } from 'faunadb'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import Split from 'react-split'
import * as API from '../../../modules/api'
import events from '../../../modules/events'
import { runFQLQueryNoReject } from '../../../modules/fql/eval'
import { formatFQLCode } from '../../../modules/fql/formatFQLCode'
import { useLocalStorageReducer } from '../../../modules/localStorage'
import { tx } from '../../../modules/translate'
import { removeComments } from '../../../utils/dashboard'
import { useFileBrowser } from '../../../utils/filebrowser'
import { isMacOS } from '../../../utils/platforms'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import IconButton from '../../shared/IconButton'
import LazyFQLEditor from '../../shared/LazyFQLEditor'
import QueryElement from './QueryElement'
import { Select } from '../../shared/Select'
import { RunAs, useRunAs } from './runAs'
import { useFeatureFlag } from '../../../utils/flags'
import { FQL_X_FF } from '../../../modules/features'
import { Client as fqlV10Client, Expr as fqlV10Expr } from 'fql2-js'
import { Region } from '../../../modules/auth/session/regions'

// User queries can return anything.  Alias `any` just to follow the value
// NOTE: `runFQLQueryNoReject` returns `Promise<object>` but results are not
//       restricted to `object`.  `promiseSerial` drops the `Promise<object>`
//       type, so the results are left as `any`, which is what we need.
type FaunaResult = any

type ShellItem =
  | {
      error: string
      query: string
    }
  | {
      metrics: MetricInfo | {}
      result: FaunaResult
      query: string
    }

type QueryResponse =
  | {
      error: {
        message: string
      }
    }
  | {
      results: FaunaResult[]
    }

type MetricInfo = {
  queryBytesIn: number
  queryBytesOut: number
  queryTime: number
  storageBytesRead: number
  storageBytesWrite: number
  transactionRetries: number
  byteReadOps: number
  byteWriteOps: number
  computeOps: number
}

const generateInitialState = initialQuery => ({
  editor: initialQuery,
  shell: [],
  history: [],
  pointer: 0
})

const enum FQLVersion {
  V4 = 'fql_v4',
  V10 = 'fql_v10'
}

const LOCAL_STORAGE_ENTRY_LIMIT = 10

export function createMetricInfo(metricsArr: Array<Record<string, string>>) {
  const tooltipObject = {
    queryBytesIn: 0,
    queryBytesOut: 0,
    queryTime: 0,
    storageBytesRead: 0,
    storageBytesWrite: 0,
    transactionRetries: 0,
    byteReadOps: 0,
    byteWriteOps: 0,
    computeOps: 0
  }

  metricsArr.forEach(queryMetric => {
    for (const header in queryMetric) {
      if (queryMetric.hasOwnProperty(header)) {
        const metric = queryMetric[header]

        switch (header) {
          case 'x-query-bytes-in':
            tooltipObject.queryBytesIn += parseInt(metric)
            break
          case 'x-query-bytes-out':
            tooltipObject.queryBytesOut += parseInt(metric)
            break
          case 'x-query-time':
            tooltipObject.queryTime += parseInt(metric)
            break
          case 'x-storage-bytes-read':
            tooltipObject.storageBytesRead += parseInt(metric)
            break
          case 'x-storage-bytes-write':
            tooltipObject.storageBytesWrite += parseInt(metric)
            break
          case 'x-txn-retries':
            tooltipObject.transactionRetries += parseInt(metric)
            break
          case 'x-byte-read-ops':
            tooltipObject.byteReadOps += parseInt(metric)
            break
          case 'x-byte-write-ops':
            tooltipObject.byteWriteOps += parseInt(metric)
            break
          case 'x-compute-ops':
            tooltipObject.computeOps += parseInt(metric)
            break
          default:
            break
        }
      }
    }
  })

  return tooltipObject
}

export const webshellReducer = (state, action) => {
  let newPointer = 0

  switch (action.type) {
    case 'setEditor':
      return { ...state, editor: action.value }
    case 'setShell':
      return { ...state, shell: action.value }
    case 'addToShell':
      // If we haven't reached our localStorage limit, add the new entry
      if (state.shell.length < LOCAL_STORAGE_ENTRY_LIMIT) {
        return { ...state, shell: [...state.shell, action.value] }
      }

      // If the number of items in localStorage is over the limit defined,
      // remove the oldest item and add the new one to the list.
      const newShellState = [...state.shell.slice(1), action.value]
      return {
        ...state,
        shell: newShellState
      }

    case 'clearShell':
      return { ...state, shell: [] }
    case 'addHistoryEntry':
      const latestHistoryItem = state.history[state.history.length - 1]
      const updatedHistory =
        action.value === latestHistoryItem ? [...state.history] : [...state.history, action.value]

      // If we haven't reached out entry limit, add to history in localStorage
      if (updatedHistory.length <= LOCAL_STORAGE_ENTRY_LIMIT) {
        return {
          ...state,
          history: updatedHistory,
          pointer: state.history.length
        }
      }

      // Otherwise, remove the oldest entry before setting localStorage state
      return {
        ...state,
        history: updatedHistory.slice(1),
        pointer: updatedHistory.slice(1).length
      }

    case 'moveBackHistory':
      newPointer = state.pointer === 0 ? 0 : state.pointer - 1
      return {
        ...state,
        editor: state.history[newPointer],
        pointer: newPointer
      }
    case 'moveForwardHistory':
      newPointer = state.pointer >= state.history.length - 1 ? state.pointer : state.pointer + 1
      return {
        ...state,
        editor: state.history[newPointer],
        pointer: newPointer
      }
    default:
      throw new Error(`No action "${action.type}" found for webshell reducer.`)
  }
}

export const WEBSHELL_QUERY_SUCCESS = 'WEBSHELL_QUERY_SUCCESS'
export const WEBSHELL_SET_QUERY = 'WEBSHELL_SET_QUERY'

export function WebshellHelperText() {
  return isMacOS() ? (
    <small>
      <strong>Cmd + return</strong> to run &nbsp; &nbsp; <strong>Cmd + up/down</strong> to navigate
      query history
    </small>
  ) : (
    <small>
      <strong>Ctrl + enter</strong> to run &nbsp; &nbsp; <strong>Ctrl + up/down</strong> to navigate
      query history
    </small>
  )
}

export function WebShell({ match }: RouteComponentProps<{ dbPath: string; region: string }>) {
  let inputRef = null
  let outputRef = null
  const dbPath = match.params.dbPath
  const regionName = match.params.region

  const initialQuery = tx('webshell.initQuery')
  const { getFile } = useFileBrowser({ accept: '.txt, .fql' })

  const fqlXEnabled = useFeatureFlag(FQL_X_FF)
  const fqlVersionOptions = [
    { value: FQLVersion.V4, label: 'FQL v4' },
    { value: FQLVersion.V10, label: 'FQL v10' }
  ]

  const editorRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFQLVersion, setSelectedFQLVersion] = useState<FQLVersion>(FQLVersion.V4)
  const selectedFQLVersionRef = useRef(selectedFQLVersion)

  useEffect(() => {
    selectedFQLVersionRef.current = selectedFQLVersion
  }, [selectedFQLVersion])

  const [webshellState, dispatch] = useLocalStorageReducer(
    `webshellState_${dbPath}`,
    webshellReducer,
    initialQuery,
    generateInitialState
  ) as any
  const runAs = useRunAs({ dbPath })

  useEffect(() => {
    if (webshellState.shell.list && Array.isArray(webshellState.shell.list))
      dispatch({ type: 'setShell', value: webshellState.shell.list })
  }, [dispatch, webshellState.shell.list])

  useEffect(() => {
    document.title = `${tx('query.plural')} ${dbPath} - Fauna`
  }, [dbPath])

  useEffect(() => {
    const handleSetQuery = content => {
      dispatch({ type: 'setEditor', value: content })
    }

    events.on(WEBSHELL_SET_QUERY, handleSetQuery)
    return () => events.off(WEBSHELL_SET_QUERY, handleSetQuery)
  }, [dispatch, webshellState.editor])

  function setOutputRef(ref) {
    outputRef = ref
  }

  function setInputRef(ref) {
    inputRef = ref
  }

  const resizeEditors = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.editor.resize()
    }

    if (outputRef && outputRef.current) {
      outputRef.current.editor.resize()
    }
  }, [inputRef, outputRef])

  /** Resize editors after Split calculation */
  useEffect(() => {
    resizeEditors()
  }, [resizeEditors])

  function clearResults() {
    dispatch({ type: 'clearShell' })
  }

  async function runFql() {
    switch (selectedFQLVersionRef.current) {
      case FQLVersion.V4:
        await executeFqlV4()
        break
      case FQLVersion.V10:
        await executeFqlV10()
        break
      default:
        handleError(
          { message: 'Cannot execute query: an unknown version of FQL was specified.' },
          ''
        )
    }
  }

  async function executeFqlV10() {
    const region = Region.get(regionName)
    const url = region.url + '/query/1'
    const secret = API.getDatabaseSecret(dbPath, region)
    const client = new fqlV10Client({ endpoint: new URL(url), secret })

    const queryToExecute = inputRef.current.editor.getValue()
    const expr = new fqlV10Expr([queryToExecute], [])
    const req = client.createRequest(expr)

    setIsLoading(true)
    dispatch({ type: 'addHistoryEntry', value: queryToExecute })

    try {
      const res = client.parseResponse(await client.post(req))
      const output = JSON.stringify(res.body?.data, null, 2)

      const metricsTooltipInfo = createMetricInfo([res.headers])
      events.emit(WEBSHELL_QUERY_SUCCESS, { output, queryToExecute })

      const queryResponse: QueryResponse = { results: [output] }

      handleResults(queryResponse, queryToExecute, metricsTooltipInfo)
    } catch (error) {
      const message = error.summary ?? error.message
      handleError({ message }, queryToExecute)
    }
  }

  async function executeFqlV4() {
    const hasNoHighlightedText = inputRef.current.editor.getSelection().isEmpty()

    const queryToExecute = hasNoHighlightedText
      ? inputRef.current.editor.getValue()
      : inputRef.current.editor.getSelectedText()

    const queryWithoutComments = removeComments(queryToExecute).trim()

    if (queryWithoutComments) {
      const allMetrics = []

      setIsLoading(true)
      dispatch({ type: 'addHistoryEntry', value: queryToExecute })

      const queryClient = API.connectTo(dbPath, regionName, {
        ...runAs.runAsOption,
        observer: res => {
          allMetrics.push(res.responseHeaders)
        },
        headers: {
          'X-Fauna-Source': 'WebShell'
        }
      })

      let queries: string[] = []

      try {
        queries = splitQueries(queryWithoutComments)
        const wrapped = wrapQueries(queries, queryClient)
        const results: FaunaResult[] = await promiseSerial(wrapped)

        const metricsTooltipInfo = createMetricInfo(allMetrics)
        events.emit(WEBSHELL_QUERY_SUCCESS, { results, queries })

        const queryResponse: QueryResponse = { results }

        handleResults(queryResponse, queryWithoutComments, metricsTooltipInfo)
      } catch (error) {
        handleError(error, queryWithoutComments)
      }
    }
  }

  function wrapQueries(queries: string[], client: Client) {
    return queries.map(fql => () => runFQLQueryNoReject(fql, client))
  }

  async function promiseSerial(wrappedFns: (() => Promise<object>)[]) {
    const funcPromiseArr = wrappedFns.map(wrappedFn => wrappedFn())

    return Promise.all(funcPromiseArr).then(values => {
      return values
    })
  }

  function generateNewShellItem(
    response: QueryResponse,
    rawQueries: string,
    metricInfo: MetricInfo | {}
  ): ShellItem {
    if ('error' in response) {
      return {
        query: rawQueries + '\n',
        error: response.error.message
      }
    }

    // If the user only submitted a single request, then just display the first result.
    // Otherwise, display all of the results as an array
    const result = response.results.length === 1 ? response.results[0] : response.results

    return {
      query: rawQueries + '\n',
      result: formatFQLCode(result),
      metrics: metricInfo
    }
  }

  function handleError(error: any, rawQueries: string) {
    let message = error.message

    if ('errors' in error) {
      message = JSON.stringify(error.errors(), null, 2)
    }

    const queryResponse: QueryResponse = {
      error: { message }
    }

    handleResults(queryResponse, rawQueries, {})
  }

  function handleResults(results: QueryResponse, rawQueries: string, metricInfo: MetricInfo | {}) {
    const newShellContent = generateNewShellItem(results, rawQueries, metricInfo)
    dispatch({ type: 'addToShell', value: newShellContent })
    setIsLoading(false)

    const numOfLines = editorRef.current.props.value.split('\n').length
    editorRef.current.editor.scrollToLine(numOfLines)
  }

  function readFile() {
    getFile().then(schemaFile => {
      const reader = new FileReader()
      reader.onload = function(e) {
        const contents = e.target.result
        dispatch({ type: 'setEditor', value: contents })
      }
      reader.readAsText(schemaFile)
    })
  }

  function downloadWebshell() {
    const cleanDbPath = dbPath.replace(/[^a-z0-9//_-]/gi, '').replace('/', '-')

    // clean up \n and \"
    const text = JSON.stringify(webshellState.shell)
      .replace(/\\n/g, '\r\n')
      .replace(/\\"/g, '"')

    const element = document.createElement('a')
    const file = new Blob([text], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `${cleanDbPath}.fql`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
    document.body.removeChild(element)
  }

  function renderHeaderActions() {
    return (
      <>
        <IconButton onClick={clearResults} label={tx('webshell.clear')} icon="undo" />
        <IconButton onClick={downloadWebshell} label={tx('webshell.download')} icon="download" />
        <IconButton onClick={readFile} label={tx('webshell.openfile')} icon="upload" />
      </>
    )
  }

  function onEditorChange(value) {
    dispatch({ type: 'setEditor', value })
  }

  return (
    <>
      <ContentHeader actions={renderHeaderActions()} divider={false}>
        {tx('webshell.pageTitle')}
      </ContentHeader>

      <Split
        sizes={[75, 25]}
        minSize={50}
        gutterSize={10}
        gutterAlign="center"
        dragInterval={1}
        direction="vertical"
        cursor="ns-resize"
        className="query--wrapper"
        onDrag={resizeEditors}
      >
        <div className="query--history" data-hj-suppress>
          <QueryElement editorRef={editorRef} data={webshellState.shell} onMount={setOutputRef} />
        </div>

        <div className="query--editor" data-hj-suppress>
          <LazyFQLEditor
            height="100%"
            value={webshellState.editor}
            onChange={onEditorChange}
            onMount={setInputRef}
            autoFocus={true}
            commands={[
              {
                name: 'executeQuery',
                bindKey: { win: 'Ctrl-Enter', mac: 'Cmd-Enter' },
                exec: () => {
                  runFql()
                }
              },
              {
                name: 'navigatePreviousCommand',
                bindKey: { win: 'Ctrl-Up', mac: 'Cmd-Up' },
                exec: () => {
                  dispatch({ type: 'moveBackHistory' })
                }
              },
              {
                name: 'navigateNextCommand',
                bindKey: { win: 'Ctrl-Down', mac: 'Cmd-Down' },
                exec: () => {
                  dispatch({ type: 'moveForwardHistory' })
                }
              }
            ]}
          />
        </div>
      </Split>
      <div className="webshell-layout">
        <div className="webshell-query">
          <div className="webshell-toolbar">
            <div className="webshell-toolbar-buttons">
              <Button
                loading={isLoading}
                onClick={runFql}
                color="success"
                disabled={!webshellState.editor || runAs.executionDisabled}
              >
                {runAs.enabled ? tx('query.actions.runQueryAs') : tx('query.actions.run')}
              </Button>
              {fqlXEnabled && (
                <Select
                  isSearchable={false}
                  id="fql-language-selector"
                  data-testid="fql-language-selector"
                  menuPlacement={'top'}
                  options={fqlVersionOptions}
                  value={fqlVersionOptions.find(v => v.value === selectedFQLVersion)}
                  onChange={({ value }) => setSelectedFQLVersion(value as FQLVersion)}
                />
              )}
              <RunAs {...runAs} dbPath={dbPath} />
            </div>
            <WebshellHelperText />
          </div>
        </div>
      </div>
    </>
  )
}

export function splitQueries(code: string): string[] {
  const brackets: Record<string, string> = {
    '{': '}',
    '(': ')',
    '[': ']',
    '"': '"',
    "'": "'"
  }
  const openBrackets = new Set(Object.keys(brackets))
  const closeBrackets = new Set(Object.values(brackets))
  const queries = []
  const stack: string[] = []
  let start = 0
  let isOpening
  code = code
    .trim()
    .split('\n')
    .join('')
    .split('\r')
    .join('')

  for (let i = 0; i < code.length; i++) {
    if (openBrackets.has(code[i])) {
      stack.push(code[i])
      isOpening = true
    }

    if (closeBrackets.has(code[i]) && brackets[stack.pop()] !== code[i]) {
      throw new Error(`Unexpected closing bracket ${code[i]} at position: ${i + 1}`)
    }

    if (stack.length === 0 && isOpening) {
      const line = code.slice(start, i + 1)
      queries.push(line)
      start = i + 1
      isOpening = false
    }
  }

  if (isOpening) {
    throw new Error('Expect all opened brackets to be closed')
  }

  return queries
}

export default connect((state: any) => ({
  alert: state.alert
}))(
  withBreadcrumbs(({ location }: any) => {
    return [
      {
        label: tx('query.plural'),
        path: location.pathname
      }
    ]
  })(WebShell)
)
