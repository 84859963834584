import { HTMLAttributes } from 'react'
import React from 'react'

type SpinnerProps = HTMLAttributes<SVGElement> & {
  size?: 'xs' | 'sm' | 'md'
  color?: string
}

const sizes = {
  xs: {
    width: 16,
    height: 16
  },
  sm: {
    width: 24,
    height: 24
  },
  md: {
    width: 32,
    height: 32
  }
}

const Spinner = ({ size = 'md', color = '#492FB1', ...svgProps }: SpinnerProps) => {
  return (
    <svg
      {...sizes[size]}
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      data-testid="spinner"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        ng-attr-stroke="{{config.color}}"
        ng-attr-stroke-width="{{config.width}}"
        ng-attr-r="{{config.radius}}"
        ng-attr-stroke-dasharray="{{config.dasharray}}"
        stroke={color}
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="0.75s"
          begin="0s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </svg>
  )
}

export default Spinner
