import React, { FC, ReactNode, useMemo } from 'react'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'

export interface SelectProps extends ReactSelectProps {
  renderGroupRightLabel?: (option: unknown) => ReactNode
  renderOptionRightLabel?: (option: unknown) => ReactNode
}

export const Select: FC<SelectProps> = ({
  options,
  theme,
  styles,
  renderGroupRightLabel,
  renderOptionRightLabel,
  customFormatOptionLabel,
  customComponents,
  ...props
}) => {
  const optionsWithIndex = useMemo(
    () =>
      options?.map((val, index) => ({
        ...val,
        index
      })),
    [options]
  )

  return (
    <ReactSelect
      {...props}
      isDisabled={props.disabled || props.isDisabled}
      classNamePrefix="react-select"
      options={optionsWithIndex}
      theme={initial => {
        const styled = {
          ...initial,
          spacing: {
            ...initial.spacing,
            menuGutter: 1,
            controlHeight: 30
          },
          colors: {
            ...initial.colors,
            primary: '#3f00a5',
            primary25: '#f2f2f2',
            primary50: '#e6e6e6',
            neutral20: '#d7d7e8'
          }
        }

        if (!theme) {
          return styled
        }

        return typeof theme === 'function'
          ? theme(styled)
          : {
              ...styled,
              ...theme
            }
      }}
      styles={{
        menu: styles => ({
          ...styles,
          zIndex: 999,
          boxShadow: '1px 1px 4px 2px rgba(34, 48, 48, 0.1)'
        }),
        control: ({ '&:hover': _, boxShadow: __, ...styles }) => ({
          ...styles,
          cursor: 'pointer'
        }),
        valueContainer: ({ minHeight: _, ...styles }) => ({
          ...styles,
          height: '43px'
        }),
        option: (styles, { isDisabled, isSelected, theme: { colors } }) => ({
          ...styles,
          color: isDisabled ? colors.neutral20 : isSelected ? colors.neutral0 : '#33393d',
          cursor: 'pointer'
        }),
        group: ({ paddingTop: _, paddingBottom: __, ...styles }) => styles,
        groupHeading: ({ paddingLeft: _, paddingRight: __, marginBottom: ___, ...styles }) =>
          styles,
        ...styles
      }}
      formatGroupLabel={option => (
        <div className="select-group">
          <span className="select-group-label">{option.label}</span>
          {renderGroupRightLabel?.(option)}
        </div>
      )}
      formatOptionLabel={
        customFormatOptionLabel
          ? customFormatOptionLabel
          : option => (
              <div className="select-option" data-testid={option.value}>
                <span className="select-option-label">{option.label}</span>
                {renderOptionRightLabel?.(option)}
              </div>
            )
      }
      components={customComponents ? customComponents : {}}
    />
  )
}
