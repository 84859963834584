import { useEffect } from 'react'
import { connect } from 'react-redux'
import { ContextRouter } from 'react-router-dom'
import * as indexResource from '../../../modules/indexResource'
import * as DocumentResource from '../../../modules/documentResource'
import { tx } from '../../../modules/translate'
import { Link } from 'react-router-dom'
import Icon from '../../shared/Icon'
import IndexesTable from './IndexesTable'
import SearchDocumentsResults from './../documents/SearchDocumentsResults'
import DocumentsTable from '../documents/DocumentsTable'
import spinner from '../../../assets/images/spinner.svg'
import ContentHeader from '../../layouts/ContentHeader'
import SearchDocumentForm from './../documents/SearchDocumentForm'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes, { createRoute } from '../../../config/routes'
import { Plug } from 'react-outlet'

type MapStateToProps = {
  databases: Record<string, any>[]
  browseIndex: Record<string, any>
  isCoveringIndex: boolean
  documents: Record<string, any>[]
  indexes: Record<string, any>[]
  fetchDocuments: typeof DocumentResource.actions.fetchList
  fetchDocumentsByTerms: typeof DocumentResource.actions.fetchDocumentsByTerms
}

type MapDispatchToProps = {
  fetchListIndexes: Function
}

type Props = ContextRouter & MapStateToProps & MapDispatchToProps

function IndexesShow({
  indexes,
  documents,
  browseIndex,
  isCoveringIndex,
  fetchListIndexes,
  createIndex,
  match,
  location,
  fetchDocuments,
  fetchDocumentsByTerms
}: Props) {
  const hasTerms = indexResource.hasTerms(browseIndex)
  const databasePath = String(match.params.dbPath)
  const region = String(match.params.region)
  const indexId = String(match.params.indexId)
  const browseIndexIsAvailable = browseIndex && browseIndex.active

  useEffect(() => {
    document.title = `${indexId} - Fauna`
  }, [indexId])

  useEffect(() => {
    fetchListIndexes({ databasePath })
  }, [indexId, databasePath, fetchListIndexes])

  useEffect(() => {
    if (!browseIndexIsAvailable) return

    if (isCoveringIndex) {
      fetchDocuments({
        databasePath,
        indexName: browseIndex.name
      })
      return
    }

    if (!hasTerms) {
      fetchDocumentsByTerms({
        databasePath,
        indexName: browseIndex.name,
        terms: []
      })
      return
    }
  }, [
    browseIndex,
    browseIndexIsAvailable,
    databasePath,
    fetchDocuments,
    fetchDocumentsByTerms,
    hasTerms,
    isCoveringIndex
  ])

  useEffect(() => {
    function nextTimeout() {
      if (browseIndexIsAvailable === false) {
        // HACK : I have to use window to store this value
        // It MUST be available during effect cleanup which can't see state
        window.timeoutId = setTimeout(() => {
          if (browseIndex.active === false) {
            fetchListIndexes({ databasePath })
            nextTimeout()
          }
        }, getPollRate(browseIndex.ts))
      }
    }

    function getPollRate(ts) {
      const minutes = duration => duration * 60 * 1000
      const seconds = duration => duration * 1000
      const msDiff = new Date().getTime() - Math.round(ts / 1000)

      if (msDiff <= minutes(1)) {
        return seconds(10)
      } else if (msDiff <= minutes(10)) {
        return seconds(30)
      }

      return minutes(10)
    }

    nextTimeout()

    return () => {
      if (window.timeoutId) {
        clearTimeout(window.timeoutId)
        delete window.timeoutId
      }
    }
  }, [browseIndex, browseIndexIsAvailable, databasePath, fetchListIndexes, location.pathname])

  return (
    <>
      <Plug outletId="leftPanel">
        <div className="panel">
          <div className="panel__header">
            <h4>{tx('index.plural')}</h4>

            <div className="panel__header__actions">
              <Link
                to={createRoute(routes.indexes.new.path, databasePath, region)}
                className="btn btn-subtle-link"
              >
                {tx('index.actions.new')}
              </Link>
            </div>
          </div>

          <div className="panel__content">
            <IndexesTable selectedIndexes={[indexId]} databasePath={databasePath} border={false} />
          </div>
        </div>
      </Plug>

      <ContentHeader>
        {indexId}
        <div className="content-header__links">
          <Link
            className="btn btn-subtle-link"
            to={createRoute(routes.indexes.edit.path, databasePath, region).replace(
              ':indexId',
              indexId
            )}
          >
            <Icon name="cog" />
            Settings
          </Link>
        </div>
      </ContentHeader>

      {!hasTerms && (
        <div className="page-action-bar margin-bottom-2">
          <h4>{tx('document_.plural')}</h4>
        </div>
      )}

      {browseIndex && !browseIndex.active && (
        <div className="tip">
          <div className="tip__label">
            <img src={spinner} alt="" className="spinner" />
            Index building...
          </div>

          <p className="tip__text">
            The index “{indexId}” is still building. Depending on the size of your dataset, this may
            take upwards of 10 minutes.
          </p>
        </div>
      )}

      {browseIndex && browseIndex.active && (
        <>
          {indexes === undefined && <div className="section">{tx('loading.loading')}</div>}

          {!isCoveringIndex && hasTerms && (
            <>
              <div className="page-action-bar">
                <h4>Search {tx('document_.plural')}</h4>
              </div>

              <p className="width-small-60 margin-bottom-4">
                Note: select the data type from the dropdown before entering the term to search. For
                booleans and anything that is not a string or number, use the FQL option.
              </p>

              <SearchDocumentForm index={browseIndex} databasePath={databasePath} />

              <SearchDocumentsResults index={browseIndex} databasePath={databasePath} />
            </>
          )}

          {(isCoveringIndex || !hasTerms) && (
            <div className="padding-bottom-4">
              <DocumentsTable
                browseIndex={browseIndex}
                databasePath={databasePath}
                documents={documents}
                classId={indexResource.getClassIdFromIndex(browseIndex)}
                edit={false}
                remove={false}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, props) => {
  const { dbPath, indexId } = props.match.params
  const indexes = indexResource.all(state, dbPath)
  const browseIndex = indexes && indexResource.byName(indexId, state, dbPath)
  const isCoveringIndex = browseIndex && indexResource.isBrowseIndex(browseIndex)
  const documents = browseIndex && DocumentResource.all(state, browseIndex.name)

  return {
    indexes,
    browseIndex,
    isCoveringIndex,
    documents
  }
}

const mapDispatchToProps = {
  fetchListIndexes: indexResource.actions.fetchList,
  fetchDocuments: DocumentResource.actions.fetchList,
  fetchDocumentsByTerms: DocumentResource.actions.fetchDocumentsByTerms
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withBreadcrumbs(({ location, match }, routes) => {
    return [
      {
        label: match.params.indexId,
        path: location.pathname
      }
    ]
  })(IndexesShow)
)
