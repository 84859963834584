import React, { useMemo } from 'react'
import { Column, useTable, useResizeColumns, useFlexLayout, useExpanded } from 'react-table'

import Icon from './Icon'
import Button from './Button'

// Delete me later :)

type TableProps = {
  data: Array<any>
  columns: Array<Column>
  paginatedData?: any
  tableTitle?: string
  defaultColumnWidthOptions?: {
    width: number
    minWidth: number
    maxWidth: number
  }
}

type AsyncCellProps = {
  promiseFunction: any
  onUpdate: (response: any) => string | React.ReactNode
  memoKeys: string[]
}

type RowExpanderProps = {
  row: any
  hasSubRows: boolean
  onExpand: () => void
  insetWidth?: number
}

type AlignmentWrapperProps = {
  verticalAlign?: 'center' | 'start' | 'end'
  horizontalAlign?: 'center' | 'start' | 'end'
  dimensions?: {
    width?: string
    height?: string
  }
  direction?: 'row' | 'column'
}

/*
  A resizable table component built using https://react-table.tanstack.com/docs/overview.
*/
export const Table: React.FC<TableProps> = ({
  data,
  columns,
  paginatedData,
  tableTitle,
  defaultColumnWidthOptions = { width: 125, minWidth: 50, maxWidth: 300 }
}) => {
  return (
    <BaseTable
      data={data}
      columns={columns}
      paginatedData={paginatedData}
      tableTitle={tableTitle || ''}
      defaultColumnWidthOptions={defaultColumnWidthOptions}
    />
  )
}

/**
 *  A helper component which conditionally renders cell content based on the running
    result of an incoming promise function. To use, pass the AsyncCell 
    component to a columns 'Cell' field.
    For example: { Header: 'Foo', accessor: 'foo', Cell: props => <AsyncCell /> }
    Promise errors and resolves might need to be rendered differently depending on where the 
      instance of Table lives in the app. This cell accepts a callback onUpdate from the parent
      where the promise result is sent and rendering is handled.
 * @param obj: {
 *   promiseFunction: a promise with any return shape
 *   memoKeys: attribute names that are expected to be present in the return shape of the promise
 *   onUpdate: callback from the page which renders the table. Let the parent component decide
 *    how to handle the promise result.
 * }
 * @returns
 */
export const AsyncCell: React.FC<AsyncCellProps> = ({ promiseFunction, memoKeys, onUpdate }) => {
  const memoValues = []
  // we create an array of values to 'listen' to in order to trigger memoization again.
  //  the array values are populated via Reference to values on the promiseFunction. Thus
  //  when the promise function's properties update, memoValues does as well, thus triggering
  //  useMemo to run again and update the `result` variable.
  memoKeys.forEach(key => {
    memoValues.push(promiseFunction?.[key])
  })
  // useMemo ensures the cell value constantly updates depending on the state of
  //  the promise execution
  const result = useMemo(() => {
    return promiseFunction
  }, memoValues)
  return <>{onUpdate(result)}</>
}

/* 
  A helper component which can be used to expand a row. It should
  be placed in it's own "expansion" column at the begining of the table
  or be included within the first column. You must provide the row to be expanded,
  whether or not the row has sub rows and a handler to fire when the row is 
  expanded (i.e. insert new rows into the table on expansion). The inset width 
  controls the amount a sub row is indented.
*/
export const RowExpander: React.FC<RowExpanderProps> = ({
  row,
  hasSubRows,
  onExpand,
  insetWidth = 15
}) => {
  const { depth, isExpanded, toggleRowExpanded, getToggleRowExpandedProps } = row

  const iconWrapperClassName = `padding-right-1 muted`

  const Inset = () => {
    if (depth > 0)
      return (
        <AlignmentWrapper dimensions={{ width: 'auto', height: '100%' }}>
          <div style={{ width: `${(depth - 1) * insetWidth}px` }} />
          <AlignmentWrapper
            direction="column"
            dimensions={{ width: `${insetWidth}px`, height: '100%' }}
          >
            <div className="inset-branch" />
            <div />
          </AlignmentWrapper>
          <div className={`${!hasSubRows && 'margin-right-1 inset-branch-end'}`} />
        </AlignmentWrapper>
      )
    return <></>
  }

  const leafRow = <Inset />
  const expandableRow = (
    <AlignmentWrapper dimensions={{ width: 'auto', height: '100%' }}>
      <Inset />
      <div
        {...getToggleRowExpandedProps()}
        onClick={() => {
          if (!isExpanded) onExpand()
          toggleRowExpanded()
        }}
      >
        {isExpanded ? (
          <Icon name="minus-square" mode="far" wrapperClassName={iconWrapperClassName} />
        ) : (
          <Icon name="plus-square" mode="far" wrapperClassName={iconWrapperClassName} />
        )}
      </div>
    </AlignmentWrapper>
  )

  return hasSubRows ? expandableRow : leafRow
}

/* 
  A helper component which can be used to align content within cells or headers.
  The verticalAlign prop can be: 'center' | 'start' | 'end'.
  The horizontalAlign prop can be: 'center' | 'start' | 'end'.
  The dimensions prop controls the size of the wrapper. 
  The direction prop controls how items are stacked (left to right or top to bottom).
  By default all cells will be vertically aligned to the center and
  horizontally aligned to the left.
*/
export const AlignmentWrapper: React.FC<AlignmentWrapperProps> = ({
  verticalAlign = 'center',
  horizontalAlign = 'left',
  dimensions = { width: '100%', height: '100%' },
  direction = 'row',
  ...props
}) => {
  return (
    <div
      style={{
        width: dimensions.width,
        height: dimensions.height,
        display: 'flex',
        flexDirection: direction,
        alignItems: verticalAlign,
        justifyContent: horizontalAlign
      }}
    >
      {props.children}
    </div>
  )
}

/**
 *  A recursive function, run every time a row dropdown button is clicked.
 *    it accepts the current list of rows along with a new set of subRows that
 *    were fetched on the click event. It recurses through the existing set of
 *    rows and subrows, adding new subRows where necessary.
 *
 * @param obj: {
 *  existingRows: array of row objects
 *  subRowsToInsert: array of row objects representing the children of
 *    the specific `rowId` that was expanded
 *  rowId: the row which was clicked and expanded. Example: '1.0.1'
 *    A rowId of '1.0.1' means the 2nd row (1), its first child (0),
 *    and that child's second child (1) were all clicked/expanded.
 * }
 * @returns array of new row objects
 */
export const buildTableRows = ({ existingRows, subRowsToInsert, rowId }) => {
  // path is an array of number strings. e.g. '1.0.1' -> ['1','0','1']
  const path = rowId.split('.')
  // id is the root of the current sub-tree of rows.
  const id = path[0]

  return existingRows.map((row, index) => {
    // remove the parent row id (first element of path), create path representing the next child
    const nextChildRowPath = path.slice(1).join('.')
    const currentRowWasClicked = index === Number(id)
    if (!currentRowWasClicked) return row
    // if there are still more children to process, recurse with the child path to find
    //  where the subRowsToInsert should go. subRowsToInsert will always
    //  be inserted into a leaf row, so path length of 1 is the terminating condition.
    const subRows =
      path.length === 1
        ? subRowsToInsert
        : buildTableRows({
            existingRows: row.subRows,
            subRowsToInsert,
            rowId: nextChildRowPath
          })
    return { ...row, subRows }
  })
}

const PaginationActions = props => {
  if (!props.paginatedData || !props.paginatedData.list) return <></>
  const {
    isLoadingMore,
    isInitialLoading,
    pageIndex,
    hasPrev,
    hasNext,
    list,
    onPrev,
    onNext,
    goToFirst,
    afterRemove,
    error,
    pageSize
  } = props.paginatedData
  return (
    <div className="pagination-footer">
      <Button color="secondary" disabled={!hasPrev || isLoadingMore} onClick={onPrev}>
        Previous Page
      </Button>
      <div className="pagination-progress">
        <span className="padding-x-3">
          {pageSize * pageIndex + 1}-{pageSize * pageIndex + list.length} of{' '}
          {isLoadingMore || hasNext ? 'many' : pageSize * pageIndex + list.length}
        </span>
        <Button color="secondary" disabled={!hasNext || isLoadingMore} onClick={onNext}>
          Next Page
        </Button>
      </div>
    </div>
  )
}

function BaseTable({ columns, data, paginatedData, tableTitle, defaultColumnWidthOptions }) {
  const defaultColumn = React.useMemo(() => defaultColumnWidthOptions, [defaultColumnWidthOptions])

  const { rows, headerGroups, prepareRow, getTableProps, getTableBodyProps } = useTable(
    {
      data,
      columns,
      defaultColumn,
      // @ts-ignore
      autoResetExpanded: false
    },
    useExpanded,
    useResizeColumns,
    useFlexLayout
  )

  return (
    <div className="table-responsive">
      <div className="table" {...getTableProps()}>
        <h3>{tableTitle}</h3>
        <div className="thead">
          {headerGroups.map(headerGroup => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()
            return (
              <div className="tr" {...headerGroupProps} key={headerGroupProps.key}>
                {headerGroup.headers.map(column => {
                  const headerProps = column.getHeaderProps()
                  return (
                    <div className="th" {...headerProps} key={headerProps.key}>
                      {column.render('Header')}
                      {/* @ts-ignore */}
                      {column.canResize && <div {...column.getResizerProps()} />}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="tbody" {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            const rowProps = row.getRowProps()
            return (
              <div className="tr" {...rowProps} key={rowProps.key}>
                {row.cells.map(cell => {
                  const cellProps = cell.getCellProps()
                  return (
                    <div className="td" {...cellProps} key={cellProps.key}>
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
      <PaginationActions paginatedData={paginatedData} />
    </div>
  )
}

export default Table
