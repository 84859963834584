import cn from 'classnames'

export const MIN_PASSWORD_LENGTH = 8

export function validateEmail(email: string): Boolean {
  const emailRegex = new RegExp(/[\S]+@[\S]+[.][\S]+/g)
  return emailRegex.test(email)
}

export function emailInputClassName(emailError: string, emailTouched: boolean): string {
  return cn({
    'form-input': true,
    'valid--border': !emailError && emailTouched,
    'invalid--border': emailError && emailTouched
  })
}

export function passwordInputClassName(passwordError: string, passwordTouched: boolean): string {
  return cn({
    'form-input': true,
    'valid--border': !passwordError && passwordTouched,
    'invalid--border': passwordError && passwordTouched
  })
}

export function confirmPasswordInputClassName(
  confirmPasswordError: string,
  confirmPasswordTouched: boolean,
  password: string
): string {
  return cn({
    'form-input': true,
    'valid--border': !confirmPasswordError && confirmPasswordTouched && password,
    'invalid--border': confirmPasswordError && confirmPasswordTouched && password
  })
}

export function validatePassword(password: string): Boolean {
  return password.length >= MIN_PASSWORD_LENGTH
}
