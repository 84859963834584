import { useState } from 'react'
import useSWR from 'swr'
import axios from 'axios'
import { rootSecret, RequestStatus } from '../index'
import { logAxiosError } from '../../../utils/log-helper'

export type ContactInfo = {
  contact_name: string
  contact_email: string
  company_name: string
  website: string
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/account`
export const CACHE = '/settings/account'

async function getContactInfo() {
  return axios
    .get<ContactInfo>(API_URL, {
      headers: { secret: rootSecret() }
    })
    .then(res => res.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

async function updateContactInfo(data: Partial<ContactInfo>) {
  return axios
    .put<ContactInfo>(API_URL, data, {
      headers: { secret: rootSecret() }
    })
    .then(res => res.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export default function useContactInfo() {
  const [updateStatus, setUpdateStatus] = useState<RequestStatus>(RequestStatus.idle)

  const contactInfo = useSWR(CACHE, getContactInfo)

  const update = {
    call: async (data: ContactInfo) => {
      if (updateStatus !== RequestStatus.idle) return

      setUpdateStatus(RequestStatus.loading)
      await contactInfo.mutate(() => updateContactInfo(data))
      setUpdateStatus(RequestStatus.idle)
    },
    status: updateStatus
  }

  return {
    contactInfo: contactInfo,
    update
  }
}
