import * as prettier from 'prettier/standalone'
import * as babel from 'prettier/parser-babylon'

import { stringify } from './stringify'

export const formatFQLCode = (code: object | string) => {
  if (typeof code === 'object') {
    code = stringify(code)
  }

  try {
    return prettier
      .format(`${code}`, {
        parser: 'babel',
        plugins: [babel],
        semi: true
      })
      .trim()
      .replace(/;$/gm, '')
      .replace(/\{\n\}/gm, '{}')
  } catch (error) {
    try {
      const formatted = prettier
        .format(`(${code})`, {
          parser: 'babel',
          plugins: [babel],
          semi: true
        })
        .trim()
        .replace(/;$/gm, '')
        .replace(/\{\n\}/gm, '{}')
      if (formatted.startsWith('({') && formatted.endsWith('})')) {
        return formatted.substring(1, formatted.length - 1)
      } else {
        return formatted
      }
    } catch (error) {
      return code
    }
  }
}
