import Button from '../shared/Button'
import Icon from '../shared/Icon'

type ModalCloseProps = {
  onClick: () => void
}

export function ModalClose({ onClick }: ModalCloseProps) {
  return (
    <div className="modal__close">
      <Button type="button" className="btn-icon" color="muted" title="Close" onClick={onClick}>
        <Icon name="times" />
      </Button>
    </div>
  )
}

export default ModalClose
