export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const HIDE_ALERT_DELAY = 'HIDE_ALERT_DELAY'
export const HIDE_ALERT_DELAY_AMOUNT = 2500

export const SUCCESS = 'success'
export const ERROR = 'error'

export type Alert = {
  active: boolean
  message: string
  level: string
}
