import * as React from 'react'
import ModalClose from './ModalClose'

type ModalHeaderProps = {
  children: React.ReactNode
  onClose: () => void
}

export function ModalHeader({ onClose, children }: ModalHeaderProps) {
  return (
    <div className="modal__header">
      <div className="modal__title">{children}</div>
      {onClose && <ModalClose onClick={onClose} />}
    </div>
  )
}

export default ModalHeader
