import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Button from '../../shared/Button'
import { tx } from '../../../modules/translate'
import * as DatabaseResource from '../../../modules/databaseResource'
import routes, { createRoute } from '../../../config/routes'
import { onDatabaseDeleteComplete } from '../../../utils/settings/databaseSettingsUtils'
import useDatabases from '../../../modules/fauna/useDatabases'

interface DeleteDatabaseProps {
  onCancel: () => void
  onRemoveComplete: () => void
  dbPath: string
  region: string
}

export const DeleteDatabase: React.FC<DeleteDatabaseProps> = ({
  onCancel,
  onRemoveComplete,
  dbPath,
  region
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const parentPath = DatabaseResource.databaseParentPathFromPath(dbPath)
  const database = useSelector(state => DatabaseResource.byName(state, dbPath))
  const { data: databases, mutate: mutateDatabases } = useDatabases(parentPath, region)

  const removeDatabaseFromList = (dbName: string): void => {
    mutateDatabases((databases || []).filter(dbWithRg => dbWithRg.db.id != dbName))
  }

  return (
    <>
      <p>{tx('database.messages.deleteConfirm', [dbPath])}</p>
      <p>
        <strong>{tx('database.messages.actionWarning')}</strong>
      </p>
      <div className="form-actions">
        <Button onClick={onCancel} color="secondary">
          {tx('actions.cancel')}
        </Button>
        <Button
          type="submit"
          color="success"
          onClick={() => {
            dispatch(
              DatabaseResource.actions.remove({
                parentPath,
                name: database.name,
                onRemoveComplete: () => {
                  onRemoveComplete()

                  onDatabaseDeleteComplete(dbPath, database.name)
                  removeDatabaseFromList(database.name)

                  const redirectPath = parentPath
                    ? createRoute(routes.db.byId.path, parentPath, region)
                    : routes.home.path

                  history.push(redirectPath)
                }
              })
            )
          }}
        >
          {tx('database.actions.delete')}
        </Button>
      </div>
    </>
  )
}
