import { useEffect, useRef, useState } from 'react'
import useSWR, { cache } from 'swr'

export type Page<List extends unknown[]> = {
  data?: List
  results?: List
  after?: unknown
  nextToken?: unknown
}

export function usePagination<List extends unknown[], Keys>(
  key: Keys[],
  pageSize: number,
  fetcher: (...args: any[]) => Promise<Page<List>>
) {
  const lastPageRef = useRef<null | Page<List>>(null)
  const [afters, setAfters] = useState<unknown[]>([])
  const after = afters[afters.length - 1] ?? null
  const keyWithAfter = [...key, after]
  const { error, data: page, mutate } = useSWR(keyWithAfter, fetcher, {
    dedupingInterval: 0
  })
  const isLoadingMore = !page && !error
  const isInitialLoading = isLoadingMore && !lastPageRef.current

  useEffect(() => {
    if (page) {
      lastPageRef.current = page
    }
  })

  useEffect(
    () => () => {
      lastPageRef.current = null
      setAfters([])
    },
    [key]
  )

  const pushAfter = () => {
    if (page?.after) {
      setAfters([...afters, page.after])
    } else if (page?.nextToken) {
      setAfters([...afters, page.nextToken])
    }
  }

  const popAfter = () => {
    setAfters(afters.slice(0, -1))
  }

  const goToFirst = () => {
    setAfters([])
  }

  const afterRemove = () => {
    mutate(async prev => {
      const page = await fetcher(...keyWithAfter)

      if (page.data.length) {
        return page
      }

      cache.delete(keyWithAfter)

      if (afters.length) {
        popAfter()

        return prev
      }

      return {
        ...prev,
        data: [] as List
      }
    }, false)
  }

  return {
    isLoadingMore,
    isInitialLoading,
    pageIndex: afters.length,
    hasPrev: afters.length > 0,
    hasNext: page?.after != null || page?.nextToken != null,
    list: page?.data ?? page?.results ?? lastPageRef.current?.data ?? ([] as List),
    onPrev: popAfter,
    onNext: pushAfter,
    goToFirst,
    afterRemove,
    error,
    pageSize,
    mutate
  }
}
