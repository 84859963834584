import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import * as DocumentResource from '../../../modules/documentResource'
import * as IndexResource from '../../../modules/indexResource'
import { evalFQLCode } from '../../../modules/fql/eval'
import { showAlert } from '../../../modules/alert/actions'
import * as AlertTypes from '../../../modules/alert/types'
import LazyFQLEditor from '../../shared/LazyFQLEditor'

type Term = {
  field: string[]
}

type Index = {
  terms: Term[]
  name: string
}

type Props = {
  index: Index
  databasePath: string
  fetchDocumentsByTerms: Function
  showAlert: Function
}

const castingTypes = {
  text: 'text',
  number: 'number',
  code: 'code'
}

const inputTypes = [
  { value: castingTypes.text, label: 'String' },
  { value: castingTypes.number, label: 'Number' },
  { value: castingTypes.code, label: 'FQL' }
]

function SearchDocumentForm({ index, databasePath, fetchDocumentsByTerms, showAlert }: Props) {
  const [isSearching, setIsSearching] = useState(false)
  const [termFields, setTermFields] = useState(null)

  useEffect(() => {
    function mountTermFields() {
      const { terms } = index

      if (!Array.isArray(terms)) return mountField(terms)

      return terms.reduce((termFields, term) => {
        return {
          ...termFields,
          ...mountField(term)
        }
      }, {})
    }

    function mountField(term) {
      const defaultType = castingTypes.text
      const termName = getTermName(term)

      return {
        [termName]: {
          name: termName,
          value: '',
          type: defaultType
        }
      }
    }

    if (index.terms) {
      setTermFields(mountTermFields())
    }
  }, [index])

  function getTermName(term) {
    return IndexResource.getTermOrValueName(term)
  }

  function handleSubmit(event) {
    event.preventDefault()
    setIsSearching(true)

    fetchDocumentsByTerms({
      terms: getTermFiledsValue(),
      indexName: index.name,
      databasePath,
      onComplete: () => setIsSearching(false)
    })
  }

  function getTermFiledsValue() {
    return _.map(termFields, termField => {
      return applyCasting(termField.type, termField.value)
    })
  }

  function applyCasting(type, value) {
    switch (type) {
      case castingTypes.text:
        return String(value)
      case castingTypes.number:
        return Number(value)
      case castingTypes.code:
        try {
          return evalFQLCode(value)
        } catch (error) {
          showAlert(error.message, AlertTypes.ERROR)
          return
        }

      default:
        return value
    }
  }

  function handleInputChange(event) {
    setTermFields({
      ...termFields,
      [event.target.name]: {
        ...termFields[event.target.name],
        value: event.target.value
      }
    })
  }

  function setTermFieldType(fieldName, type) {
    setTermFields({
      ...termFields,
      [fieldName]: {
        ...termFields[fieldName],
        type
      }
    })
  }

  function renderTermField(termField) {
    const { name, type } = termField

    return (
      <div className="input-group input-group--vertical">
        <select value={type} onChange={event => setTermFieldType(name, event.currentTarget.value)}>
          {inputTypes.map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
        {renderTermFieldInput(termField)}
      </div>
    )
  }

  function renderTermFieldInput(termField) {
    const { name, value, type } = termField

    if (type !== castingTypes.code) {
      return (
        <input
          type={type}
          onChange={handleInputChange}
          name={name}
          value={value}
          id={name}
          required
        />
      )
    }

    return (
      <div className="input--code" data-hj-suppress>
        <LazyFQLEditor
          showGutter={false}
          value={value}
          onChange={value => handleInputChange({ target: { name, value } })}
          height="14px"
          maxLines={1}
        />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="form--inline form--filter margin-bottom-3">
      {_.map(termFields, termField => {
        const { name } = termField

        return (
          <div className="form-group" key={name}>
            <label htmlFor={name}>{name}</label>
            {renderTermField(termField)}
          </div>
        )
      })}

      <div className="form-group">
        <Button block loading={isSearching} type="submit" color="primary">
          <Icon name="search" />
        </Button>
      </div>
    </form>
  )
}

export default connect(null, {
  fetchDocumentsByTerms: DocumentResource.actions.fetchDocumentsByTerms,
  showAlert
})(SearchDocumentForm)
