import Dropdown from '../../shared/Dropdown'
import { tx } from '../../../modules/translate'
import React from 'react'

type Props = {
  value: any
  onChange: Function
}

export default function RangeDropdown({ value, onChange }: Props) {
  return <Dropdown size="sm" position="right" items={ranges()} value={value} onChange={onChange} />
}

function ranges() {
  return [
    { value: 7, label: tx('period.last7Days') },
    { value: 30, label: tx('period.last30Days') }
  ]
}
