import * as cookies from './index'

export type ShortLivedTokenCookieContents = { token: string }

export const SHORT_LIVED_TOKEN_COOKIE_NAME = 'fauna-short-lived-token'

export const SHORT_LIVED_TOKEN_COOKIE_CONFIG: cookies.CookieConfig = {
  secure: false,
  expires: 1, // days
  domain: cookies.COOKIE_DOMAIN,
  sameSite: 'strict'
}

export const get = (): ShortLivedTokenCookieContents => cookies.get(SHORT_LIVED_TOKEN_COOKIE_NAME)

export const remove = (): void => cookies.remove(SHORT_LIVED_TOKEN_COOKIE_NAME)

export const save = (contents: ShortLivedTokenCookieContents): void =>
  cookies.save(SHORT_LIVED_TOKEN_COOKIE_NAME, contents, SHORT_LIVED_TOKEN_COOKIE_CONFIG)

export const exists = (): boolean => cookies.exists(SHORT_LIVED_TOKEN_COOKIE_NAME)
