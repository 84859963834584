import { call, put, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { Action } from '../../utils/commonTypes'
import * as ModalTypes from './types'
import * as ModalActions from './actions'

export function* hideModalDelay({ payload }: Action): Iterable<any> {
  yield call(delay, payload)
  yield put(ModalActions.hideModal())
}

export function* watchModals(): Iterable<any> {
  yield takeLatest(ModalTypes.HIDE_MODAL_DELAY, hideModalDelay)
}
