import axios from 'axios'
import { logAxiosError } from '../../../utils/log-helper'

export type RegisterParams = {
  email: string
  password: string
  captcha_token?: string
}

export const API_URL = `${process.env.REACT_APP_AUTH_URL}/register`

export const register = (credentials: RegisterParams): Promise<Record<string, any>> => {
  return axios
    .post(
      API_URL,
      {
        ...credentials,
        strategy: 'email_and_password'
      },
      { withCredentials: true }
    )
    .then(result => result.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}
