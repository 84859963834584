import axios from 'axios'
import { logAxiosError } from '../utils/log-helper'
import SessionCookie from './auth/session/cookie'

export const fetchSiteInfo = (token: string) => {
  const authServiceUrl = process.env.REACT_APP_AUTH_URL
  const session = SessionCookie.get()

  return axios
    .get(`${authServiceUrl}/addons/netlify`, {
      params: { token },
      headers: { secret: session.data.secret }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export const importSiteDatabase = (name: string, token: string) => {
  const authServiceUrl = process.env.REACT_APP_AUTH_URL
  const session = SessionCookie.get()

  return axios
    .post(
      `${authServiceUrl}/addons/netlify`,
      { token, name },
      {
        headers: { secret: session.data.secret }
      }
    )
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}
