import Icon from '../shared/Icon'
import IconButton from '../shared/IconButton'
import { tx } from '../../modules/translate'

type Props = {
  message?: any
  level: 'success' | 'error'
  active: boolean
  onClose: Function
  details?: any
}

export default function Alert({ message, level, active, onClose, details }: Props) {
  function handleRefresh() {
    window.location.reload(true)
  }

  return (
    <div className={`alert alert-${level} ${active ? 'active' : ''}`}>
      <div className="alert__header">
        {message}

        <span className="alert-close" onClick={onClose}>
          <Icon name="times" />
        </span>

        {message === tx('status.errors.unavailable') && (
          <div className="alert-actions">
            <a href={process.env.REACT_APP_STATUS_URL} rel="noopener noreferrer" target="_blank">
              <span className="btn">
                <Icon name="external-link-alt" />
                {tx('status.name')}
              </span>
            </a>
            <IconButton icon="redo" label="Retry" onClick={handleRefresh} />
          </div>
        )}
      </div>

      {details && <pre className="alert__details">{details}</pre>}
    </div>
  )
}

Alert.defaultProps = {
  level: 'success',
  active: false
}
