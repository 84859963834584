import Well from '../../shared/Well'
import Icon from '../../shared/Icon'
import { Link, useParams } from 'react-router-dom'
import { tx } from '../../../modules/translate'
import routes, { createRoute } from '../../../config/routes'

type Props = {
  databasePath: string
}

export default function EmptyIndex({ databasePath }: Props) {
  const { region } = useParams()
  return (
    <div>
      <Well
        shadow
        title={tx('index.empty.title')}
        icon={<Icon name="table" />}
        action={
          <Link
            to={createRoute(routes.indexes.new.path, databasePath, region)}
            className="btn btn-success"
          >
            new index
          </Link>
        }
      />
    </div>
  )
}
