import routes from '../config/routes'

export const ALLOWED_BILLING_PATHS = [
  routes.settings.billing.path,
  routes.settings.invoices.path,
  routes.settings.members.path,
  routes.settings.profile.path,
  routes.settings.security.path,
  routes.settings.backups.path,
  routes.settings.keys.path,
  routes.settings.logs.query_logs.path
]
