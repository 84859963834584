import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as classResource from '../../../modules/classResource'
import { tx } from '../../../modules/translate'
import EmptyClass from './EmptyClass'
import routes, { createRoute } from '../../../config/routes'
import { Match } from 'react-router-dom'
import { withRouter } from 'react-router'

type Props = {
  match: Match
  fetchListClasses: Function
  databasePath: string
  classes: Record<string, any>[]
  selectedClasses: Record<string, any>[]
  shouldFetch: boolean
  border: boolean
}

const { useEffect } = React

function ClassesTable({
  match,
  fetchListClasses,
  databasePath,
  classes,
  selectedClasses,
  shouldFetch,
  border
}: Props) {
  const region = match.params.region

  useEffect(() => {
    if (shouldFetch) {
      fetchListClasses({ databasePath })
    }
  }, [databasePath, fetchListClasses, shouldFetch])

  function classPath(class_: Record<string, any>) {
    return createRoute(routes.classes.byId.path, databasePath, region)
      .replace(':classId', class_.name)
      .replace(':dbPath*', databasePath)
  }

  function isSelect(class_: Record<string, any>) {
    return selectedClasses.includes(class_.name)
  }

  function renderLoading(): React.ReactNode {
    return <div className="card-body center">{tx('loading.loading')}</div>
  }

  return (
    <>
      {!classes && renderLoading()}
      {classes && classes.length === 0 && (
        <EmptyClass databasePath={databasePath} region={region} />
      )}
      {classes && classes.length > 0 && (
        <div className="scrollList">
          <table className={`table-card no-table-labels ${!border ? 'table--no-border' : ''}`}>
            <tbody>
              {classes.map(class_ => (
                <tr key={class_.name} className={`${isSelect(class_) ? 'selected' : ''}`}>
                  <td>
                    <div>
                      <Link to={classPath(class_)} data-hj-suppress>
                        {class_.name}
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

ClassesTable.defaultProps = {
  selectedClasses: [],
  shouldFetch: true,
  border: true
}

const mapStateToProps = (state, props) => {
  return {
    classes: classResource.all(state, props.databasePath)
  }
}

const mapDispatchToProps = {
  fetchListClasses: classResource.actions.fetchList
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClassesTable))
