import {
  get,
  remove,
  save,
  exists,
  mergeSessionData,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_CONFIG
} from '../../cookies/sessionCookie'

export default {
  get,
  remove,
  save,
  exists,
  mergeSessionData,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_CONFIG
}
