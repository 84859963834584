import * as React from 'react'
import cn from 'classnames'

type Props = {
  emailError: string
  emailTouched: boolean
  className: string
  email: string
  emailLabel: string
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  setFieldTouched: Function
  validationLabel: React.ReactElement
}

export default function EmailInput(props: Props): React.ReactElement {
  return (
    <div className="form-group">
      <label className="required" htmlFor="email">
        {props.emailLabel}
      </label>
      <input
        value={props.email}
        onChange={props.handleChange}
        onBlur={e => {
          e.preventDefault()
          props.setFieldTouched('email', true)
        }}
        className={cn({
          'form-input': true,
          'valid--border': !props.emailError && props.emailTouched,
          'invalid--border': props.emailError && props.emailTouched
        })}
        id="email"
        name="email"
        type="email"
        required
      />
      {props.validationLabel}
    </div>
  )
}
