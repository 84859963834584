import { useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import { GlobalDispatchContext } from '../global-state'

/**
 * This is a wrapper around useFormik. This allows us to reset the form
 *  from anywhere within the Global Context. E.g. clicking out of a settings
 *  drawer. We pull out the function we need from the formik instance and put
 *  it in the global context.
 *  dispatch must be within useEffect to not trigger a react render error
 * @param options useFormik options passed from the child using the form
 * @returns formik instance for the child to use with all properties available
 */
export const useGlobalFormik = options => {
  const dispatch = useContext(GlobalDispatchContext)
  const formikInstance = useFormik({
    ...options
  })
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_RESET_FUNCTION',
      payload: formikInstance.resetForm
    })
  }, [])
  return formikInstance
}
