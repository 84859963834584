import { useEffect } from 'react'
import * as React from 'react'
import useSWR from 'swr'
import { useFormik } from 'formik'
import classNames from 'classnames'
import Clipboard from 'react-clipboard.js'
import { useDispatch } from 'react-redux'

import * as API from './../../../modules/api'
import { RoleRef, RoleWithPredicate } from '../../../types/faunadbTypes'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import LazyCode from '../../shared/LazyCode'
import { ProviderFormValues } from '../providers/New'
import { TabContent } from '../../shared/Tabs'
import { tx } from '../../../modules/translate'
import { databaseIdFromPath, databaseParentPathFromPath } from '../../../modules/databaseResource'
import { showAlert, hideAlertDelay } from '../../../modules/alert/actions'
import ProviderRoleCard from './ProviderRoleCard'

type ProviderFormProps = {
  region?: string
  dbPath: string
  initialValues: ProviderFormValues
  onCancel: Function
  onSubmit: Function
  provider?: ProviderFormValues
}

type ProviderRoleSelectProps = {
  roles: RoleRef[]
  selectedRoles: RoleWithPredicate[]
  setSelectedRoles: Function
  isLoading: boolean
}

type ProviderSelectedRolesProps = {
  dbPath: string
  selectedRoles: RoleWithPredicate[]
  setSelectedRoles: Function
}

type ProviderFormErrorState = {
  name?: string
  issuer?: string
  jwksUri?: string
  roles?: string
}

type FormValidationLabelProps = {
  isInvalid: boolean
  labelText: string
}

export function isValidJwksUri(uri: string): boolean {
  return uri.startsWith('https://')
}

function handleClipboardClick(event, dispatch) {
  event.stopPropagation()
  dispatch(showAlert('Copied to Clipboard'))
  dispatch(hideAlertDelay())
}

function ProviderRoleSelect(props: ProviderRoleSelectProps): React.ReactElement {
  const { roles, selectedRoles, setSelectedRoles, isLoading } = props

  return (
    <select
      name="roles"
      id="roles"
      className="margin-bottom-2"
      onChange={event => {
        const selectedItem = event.currentTarget.value

        const roleObj = roles.find((role: RoleRef) => {
          const roleId = role.value.id

          return roleId === selectedItem
            ? {
                role: roleId,
                predicate: role.predicate ? role.predicate : null
              }
            : false
        })

        setSelectedRoles('roles', selectedRoles.concat([{ ...roleObj }]))
      }}
      defaultValue={0}
      disabled={roles?.length === selectedRoles?.length}
      data-testid="provider-form-role-select"
    >
      {/* Display placeholder when no custom roles have been created */}
      {!roles || (roles.length === 0 && <option>No roles have been created...</option>)}

      {/* Display a loading state while Role info loads */}
      {isLoading && <option>Loading...</option>}

      {/* Display all custom roles in drop down */}
      {roles && roles.length > 0 && <option>Select a role</option>}

      {roles &&
        roles.length > 0 &&
        roles
          .filter((role: RoleRef) => {
            if (!selectedRoles.length) return role

            const roleIsSelected = selectedRoles.find((selectedRole: RoleWithPredicate) => {
              const roleId = selectedRole.role ? selectedRole.role : selectedRole.value.id
              return roleId === role.id
            })

            return !roleIsSelected
          })
          .map((role: RoleRef) => {
            const roleId = role.role ? role.role : role.value.id
            return (
              <option key={roleId} id={roleId} value={roleId}>
                {roleId}
              </option>
            )
          })}
    </select>
  )
}

function ProviderSelectedRoles(props: ProviderSelectedRolesProps): React.ReactElement | null {
  const { selectedRoles, setSelectedRoles } = props

  return (
    <>
      {selectedRoles.length > 0
        ? selectedRoles.map(role => {
            const roleId = role.role ? role.role : role.value.id
            const rolePredicate = role.role ? role.predicate : null

            return (
              <ProviderRoleCard
                key={roleId}
                roleId={roleId}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
                predicate={rolePredicate}
              />
            )
          })
        : null}
    </>
  )
}

export function FormValidationLabel(props: FormValidationLabelProps): React.ReactElement {
  const { isInvalid, labelText } = props
  return (
    <div className="form-validation-label">
      <p className={`${isInvalid ? 'invalid' : 'valid'}`}>{labelText}</p>
    </div>
  )
}

function ProviderForm(props: ProviderFormProps) {
  const { region, dbPath, initialValues, onCancel, onSubmit, provider } = props
  const dbName = databaseIdFromPath(dbPath)
  const parentPath = databaseParentPathFromPath(dbPath)
  const dispatch = useDispatch()

  const { data: roles } = useSWR<API.RolesDataResponse>([dbPath, 'roles'], () => {
    API.selectDatabase(dbPath)
    return API.roles()
  })

  const { data: databaseInfo } = useSWR([dbPath, 'database'], () => {
    // To get info about the current DB, we must query with a client configured to the parent
    API.selectDatabase(parentPath)
    return API.database(dbName)
  })

  const formik = useFormik({
    initialValues: initialValues,
    initialErrors: {},
    initialTouched: {
      name: false,
      issuer: false,
      jwksUri: false,
      roles: []
    },
    onSubmit: values => {
      onSubmit(values)
    },
    validate: values => {
      const errors: ProviderFormErrorState = {}

      if (!values.name) {
        errors.name = 'Please enter a name for this access provider'
      }

      if (!values.issuer) {
        errors.issuer = 'Please enter a unique issuer URL'
      }

      if (!values.jwksUri) {
        errors.jwksUri = 'Please enter a valid URI'
      } else if (values.jwksUri && !isValidJwksUri(values.jwksUri)) {
        errors.jwksUri = 'Please enter a valid Auth0 or Okta URL'
      }

      return errors
    }
  })

  useEffect(() => {
    if (provider) {
      formik.setValues(provider)
    }
  }, [provider]) // eslint-disable-line

  useEffect(() => {
    if (databaseInfo) {
      formik.setFieldValue('audience', `https://db.fauna.com/db/${databaseInfo.global_id}`)
    }
  }, [databaseInfo]) // eslint-disable-line

  return (
    <>
      <TabContent id="simple">
        <form data-testid="provider-form" onSubmit={formik.handleSubmit}>
          <div className="container container--medium">
            <div className="form-group margin-bottom-2">
              <label htmlFor="name" className="form-input-label required">
                {tx('provider.form.nameLabel')}
              </label>
              <input
                required
                id="name"
                name="name"
                type="text"
                value={formik.values.name}
                className={classNames({
                  'form-input': true,
                  'valid--border': !formik.errors.name && formik.touched.name,
                  'invalid--border': formik.errors.name && formik.touched.name
                })}
                {...formik.getFieldProps('name')}
                onFocus={e => {
                  e.preventDefault()
                  formik.setFieldTouched('name', false)
                }}
              />
              <FormValidationLabel
                isInvalid={Boolean(formik.errors.name)}
                labelText={`${formik.errors.name && formik.touched.name ? formik.errors.name : ''}`}
              />
              <label className="form-input-label">Audience</label>
              <div className="grid">
                {databaseInfo && (
                  <>
                    <span data-testid="audience">{formik.values.audience}</span>

                    <Clipboard
                      onClick={event => handleClipboardClick(event, dispatch)}
                      data-clipboard-text={formik.values.audience}
                      button-title="Copy URL to clipboard"
                    >
                      <Icon className="margin-left-4 muted" name="copy" />
                    </Clipboard>
                  </>
                )}
              </div>
            </div>
            <hr className="margin-bottom-3" />
            <div className="form-group margin-bottom-2">
              <p>{tx('provider.form.issuerJwksInstructionsText')}</p>
              <label htmlFor="issuer" className="form-input-label required">
                {tx('provider.form.issuerLabel')}
              </label>
              <input
                required
                id="issuer"
                name="issuer"
                type="text"
                value={formik.values.issuer}
                className={classNames({
                  'form-input': true,
                  'valid--border': !formik.errors.issuer && formik.touched.issuer,
                  'invalid--border': formik.errors.issuer && formik.touched.issuer
                })}
                {...formik.getFieldProps('issuer')}
                onFocus={e => {
                  e.preventDefault()
                  formik.setFieldTouched('issuer', false)
                }}
              />
              <FormValidationLabel
                isInvalid={Boolean(formik.errors.issuer)}
                labelText={`${
                  formik.errors.issuer && formik.touched.issuer ? formik.errors.issuer : ''
                }`}
              />
            </div>
            <div className="form-group margin-bottom-4">
              <label htmlFor="jwks" className="form-input-label required">
                {tx('provider.form.jwksUriLabel')}
              </label>
              <input
                required
                id="jwks"
                name="jwksUri"
                type="text"
                value={formik.values.jwksUri}
                className={classNames({
                  'form-input': true,
                  'valid--border': !formik.errors.jwksUri && formik.touched.jwksUri,
                  'invalid--border': formik.errors.jwksUri && formik.touched.jwksUri
                })}
                {...formik.getFieldProps('jwksUri')}
                onFocus={e => {
                  e.preventDefault()
                  formik.setFieldTouched('jwksUri', false)
                }}
              />
              <FormValidationLabel
                isInvalid={Boolean(formik.errors.jwksUri)}
                labelText={`${
                  formik.errors.jwksUri && formik.touched.jwksUri ? formik.errors.jwksUri : ''
                }`}
              />
            </div>
            <hr className="margin-bottom-3" />
            <div className="form-group">
              <p>{tx('provider.form.roleInstructionText')}</p>

              <ProviderRoleSelect
                roles={roles}
                selectedRoles={formik.values.roles}
                setSelectedRoles={formik.setFieldValue}
                isLoading={!roles}
              />
              {formik.values.roles && (
                <ProviderSelectedRoles
                  dbPath={dbPath}
                  selectedRoles={formik.values.roles}
                  setSelectedRoles={formik.setFieldValue}
                />
              )}
            </div>
            <hr className="margin-bottom-3" />
            <div className="form-actions container container--xsmall">
              <Button color="secondary" onClick={() => onCancel()}>
                {tx('actions.cancel')}
              </Button>
              <Button
                type="submit"
                color="success"
                disabled={!formik.values.name || !formik.values.issuer || !formik.values.jwksUri}
              >
                {provider ? tx('actions.update') : tx('actions.save')}
              </Button>
            </div>
          </div>
        </form>
      </TabContent>
      <TabContent id="fql">
        <div className="form-group">
          <label htmlFor="">{tx('attributes.faunaData')}</label>
          <LazyCode code={formik.values} />
        </div>
      </TabContent>
    </>
  )
}

export default ProviderForm
