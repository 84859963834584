import React from 'react'
import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { Router } from 'react-router-dom'
import HTML5Backend from 'react-dnd-html5-backend'
import { SWRConfig } from 'swr'
import Routes from './Routes'
import ErrorBoundary from './ErrorBoundary'
import { setupStore } from '../bootstrap'
import { createHistory } from '../history'
import GlobalStateProvider from '../global-state'
import { COOKIE_DOMAIN } from '../modules/cookies'
import { OPT_IN_COOKIE_NAME } from '../modules/cookies/optInCookie'
import { COOKIE_BANNER_FF, feature } from '../modules/features'
import { LegacyComponents } from 'frontend-components'

const { CookieBanner } = LegacyComponents

const store = setupStore()
const history = createHistory(store)

const swrConfig = {
  errorRetryCount: 1,
  revalidateOnFocus: false
}

const App = () => {
  // this app currently uses Redux API and the native context API for global state.
  //  while they can coexist without issue, we should phase off of redux usage.
  //  Multiple custom react contexts may be created to expose multiple "global" states at different
  //  levels of the app, allowing for better scoping of shared data across components.

  const cookieBannerEnabled = feature(COOKIE_BANNER_FF).isEnabled()

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store as any}>
        <ErrorBoundary>
          <GlobalStateProvider>
            <SWRConfig value={swrConfig}>
              <Router history={history}>
                <Routes />
                {cookieBannerEnabled && (
                  <CookieBanner cookieDomain={COOKIE_DOMAIN} cookieName={OPT_IN_COOKIE_NAME} />
                )}
              </Router>
            </SWRConfig>
          </GlobalStateProvider>
        </ErrorBoundary>
      </Provider>
    </DndProvider>
  )
}

export default App
