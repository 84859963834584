import { Action } from '../../utils/commonTypes'
import * as ModalTypes from './types'

export function showModal(payload): Action {
  return {
    type: ModalTypes.SHOW_MODAL,
    payload
  }
}

export function hideModal(): Action {
  return {
    type: ModalTypes.HIDE_MODAL
  }
}

export function hideModalDelay(delay: number = ModalTypes.HIDE_MODAL_DELAY_AMOUNT): Action {
  return {
    type: ModalTypes.HIDE_MODAL_DELAY,
    payload: delay
  }
}
