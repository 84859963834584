import useSWR from 'swr'
import * as API from '../api'
import { getDatabaseKey } from '../../utils/swrUtils'
import getRegion, { Region } from '../auth/session/regions'
import { isRootDatabase } from '../databaseResource'

const useDatabases = (baseDbPath: string, region?: string) => {
  const isRoot = isRootDatabase(baseDbPath)
  if (!isRoot && !region) throw 'Missing region parameter.'
  return useSWR(
    isRoot ? getDatabaseKey(baseDbPath) : getDatabaseKey(baseDbPath, region),
    async (): Promise<API.DatabasesDataResponseWithRegionGroup[]> => {
      if (isRoot) {
        // Fetch all databases for the home page.
        return await API.AllDatabases(Region.all)
      } else {
        // Fetch only child databases for the current database.
        if (region) API.setCurrentRegion(getRegion(region))
        API.selectDatabase(baseDbPath)
        const dbs = await API.databases()
        return dbs.map(db => {
          return {
            db: db,
            regionName: getRegion(region).regionName,
            regionPrefix: getRegion(region).regionPrefix
          }
        })
      }
    }
  )
}

export default useDatabases
