export const getBoolEnv = (variableName: string, fallbackValue?: boolean) => {
  const value = getEnv(variableName)

  if (value === undefined) {
    return fallbackValue
  }

  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  throw new Error(
    `"${String(value)}" is not a valid boolean string. It should be "false" or "true".`
  )
}

export const getEnv = (variableName: string) => {
  return process.env[variableName]
}
