import { connect } from 'react-redux'

type Props = {
  modal: Record<string, any>
  isLoading?: boolean
  loadingText?: string
  cancelButtonProps?: Record<string, any>
  confirmButtonProps?: Record<string, any>
}

export function ModalConfirm({
  modal,
  isLoading,
  loadingText,
  cancelButtonProps,
  confirmButtonProps
}: Props) {
  const showHideClassName = modal.show ? 'modal-show' : 'modal-hide'

  return (
    <div className={`modalOverlay ${showHideClassName}`}>
      <div className="modal">
        <section className="modalMain">
          <span data-hj-suppress>{modal.content}</span>
          <div className="modalActions">
            <button {...cancelButtonProps} type="button" className="btn" onClick={modal.onCancel}>
              {modal.cancelText}
            </button>
            <button
              {...confirmButtonProps}
              type="button"
              disabled={isLoading}
              className="btn"
              onClick={modal.onOk}
            >
              {isLoading ? loadingText : modal.okText}
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return { modal: state.modal }
}

export default connect(mapStateToProps)(ModalConfirm)
