import { connect } from 'react-redux'
import BaseAlert from '../shared/Alert'
import * as AlertActions from '../../modules/alert/actions'

type Alert = {
  message: string
  level: 'success' | 'error'
  active: boolean
}

type Props = {
  alert: Alert
  hideAlert: Function
}

function AlertContainer({ alert, hideAlert }: Props) {
  return <BaseAlert {...alert} onClose={hideAlert} />
}

const mapStateToProps = state => ({
  alert: state.alert
})

export default connect(mapStateToProps, { ...AlertActions })(AlertContainer)
