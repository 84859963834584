import { useMemo, useState, useContext, useEffect } from 'react'
import useDatabases from '../modules/fauna/useDatabases'
import { RouteValidationResult } from './types'
import * as auth from '../modules/auth'
import { ValidateResetTokenParams } from '../modules/api/auth-service/forgotPassword'
import { useLocation, useHistory } from 'react-router-dom'
import { GlobalDispatchContext, GlobalStateContext } from '../global-state'
import { SET_PWD_RESET } from '../global-state/reducer'
import usePermissions from './usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

/*
  ROUTE VALIDATION FUNCTIONS

  This file serves as a place to store different route validators. Because the app
  uses client-side rendering, we cannot validate a dynamic URL points to a valid resource
  without making an API call. Different pages in the app request different resources, so the
  API call used to validate if a URL points to a valid resource may differ.
  1. Choose which API call or async function makes most sense to validate a given
    URL route (see the routes in routes.ts).
  2. Create a new hook (e.g. useDBRouteValidation) that accepts the url variables
  3. Use the async function's return value to determine the proper status and messages to
    send back to the Routes.tsx component
  4. Once a new validation hook is created, apply it to any route in routes.ts.
    Because these validators exist to validate the URL path is correct, they will likely only be
    used on GET routes (e.g. won't need to validate a route for creating a new db)

    TODO:
    * deduce from a route validator if the session is still valid, if not, logout/prompt reauth
      (could get stale session while on any page)
    *
*/

type ValidationResponse = {
  validationStatus: RouteValidationResult
  validationMessage: string
  validationFailureReasons: string[]
  validationFailureUserSuggestion: string
}

const defaultValidationResponse = {
  validationStatus: RouteValidationResult.SUCCESS,
  validationMessage: 'Failed to access resource',
  validationFailureReasons: ['URL may be incorrect'],
  validationFailureUserSuggestion: 'You may attempt a page refresh or return to the dashboard.'
}

export function useResetRouteValidation(): ValidationResponse {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [isLoading, setIsLoading] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(false)
  const dispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)

  if (queryParams.has('token') && queryParams.has('email')) {
    const token = queryParams.get('token')
    const email = queryParams.get('email')
    queryParams.delete('token')
    queryParams.delete('email')
    history.replace({
      search: queryParams.toString()
    })
    dispatch({
      type: SET_PWD_RESET,
      payload: {
        email,
        token
      }
    })
  }

  useEffect(() => {
    if (globalState.passwordResetEmail && globalState.passwordResetToken) {
      setIsLoading(true)
      const validationParams: ValidateResetTokenParams = {
        email: globalState.passwordResetEmail,
        reset_token: globalState.passwordResetToken
      }
      auth
        .validateResetToken(validationParams)
        .then(response => setIsTokenValid(response.isTokenValid))
        .catch(() => setIsTokenValid(false))
        .finally(() => setIsLoading(false))
    }
  }, [globalState.passwordResetEmail, globalState.passwordResetToken])

  const validationStatus = isLoading
    ? RouteValidationResult.PENDING
    : RouteValidationResult.NOT_FOUND
  const validationMessage = isLoading
    ? 'Validating password reset token...'
    : 'Invalid password reset token.'

  return isTokenValid
    ? defaultValidationResponse
    : {
        validationStatus,
        validationMessage,
        validationFailureReasons: ['Your password reset token is invalid or expired.'],
        validationFailureUserSuggestion:
          'Please verify the reset link from your email or submit a new forgot password request'
      }
}

export function useBillingSettingsRouteValidation(): ValidationResponse {
  const { rolePermission } = usePermissions(PermissionDomain.BILLING, PermissionLevel.read)
  return rolePermission
    ? defaultValidationResponse
    : {
        validationStatus: RouteValidationResult.PERMISSION_DENIED,
        validationMessage: `Unauthorized. You are not allowed to view this page.`,
        validationFailureReasons: ['You do not have sufficient role permissions.'],
        validationFailureUserSuggestion: 'Please contact your account admin'
      }
}

/**
 * Validate a db exists based on url params. useDatabaseDetails calls useDatabases and useRestores.
 *  useDatabases returns an error but no status code. Thus the !status check. useRestores does
 *  return a status code. useDatabaseDetails' error object may the error response from one or the
 *  other so both cases must be handled.
 * @param urlMatchParams the url path including dpPath and region
 * @returns RouteValidationResult
 */
export function useDBRouteValidation(urlMatchParams): ValidationResponse {
  const { dbPath, region } = urlMatchParams
  const { error, data, isValidating } = useDatabases(dbPath, region)
  const validationResponse = useMemo(() => {
    let {
      validationStatus,
      validationMessage,
      validationFailureReasons,
      validationFailureUserSuggestion
    } = { ...defaultValidationResponse }
    // TODO: check if a copy/restore is in progress and display the unvailable 404 wording
    //  in the figma design: https://www.figma.com/file/GRERj2zC6g7KcdDq82oDi6/Design?node-id=1243%3A38595
    if (!isValidating && error) {
      const { status } = JSON.parse(JSON.stringify(error))
      if (!status || status == 404) {
        validationStatus = RouteValidationResult.NOT_FOUND
        validationMessage = `The database ${dbPath} in region ${region} does not exist`
        validationFailureReasons = [
          'It has been deleted',
          'The URL is incorrect',
          'A copy or restore is in progress'
        ]
        validationFailureUserSuggestion =
          'You can return to the dashboard to try and locate the database'
      }
      if (status === 500) validationStatus = RouteValidationResult.INTERNAL_ERROR
    } else if ((!error && !data) || isValidating) {
      validationMessage = `Verifying DB path is valid...`
      validationStatus = RouteValidationResult.PENDING
    } else {
      validationStatus = RouteValidationResult.SUCCESS
    }
    return {
      validationStatus,
      validationMessage,
      validationFailureReasons,
      validationFailureUserSuggestion
    }
  }, [error, data, isValidating, dbPath, region])
  return validationResponse
}
