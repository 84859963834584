import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router'
import routes from '../../../config/routes'
import * as api from '../../../modules/api'
import { Invite, useInviteByToken } from '../../../modules/api/auth-service/invites'
import useMembers from '../../../modules/api/auth-service/members'
import { login } from '../../../modules/auth'
import emailAndPassword from '../../../modules/auth/strategies/emailAndPassword'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import Button from '../../shared/Button'
import Spinner from '../../shared/Spinner'
import AuthLayout from '../auth/Layout'

const DB_URL = process.env.REACT_APP_DB_URL as string

const Loading = () => (
  <div className="loader">
    <Spinner />
  </div>
)

const Error = () => (
  <div className="verbiage verbiage--left">
    <h1>This invite is no longer valid</h1>
    <p>
      Invites expire after 24 hours, or once they have been used. Please ask your team admin to send
      you another invite.
    </p>
  </div>
)

const NewMember = ({
  token,
  invite,
  showAlert
}: {
  token: string
  invite: Invite
  showAlert: Function
}) => {
  const history = useHistory()
  const { create } = useMembers({ initialData: [] })
  const { getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      name: invite.name,
      email: invite.email,
      password: '',
      password_confirmation: ''
    },
    onSubmit: async values => {
      try {
        await create.call({ ...values, token })

        const credentials = {
          email: values.email,
          password: values.password
        }
        const response = await login(emailAndPassword, credentials)
        api.connect({
          urlString: DB_URL,
          secret: response.data.secret
        })

        history.push(routes.home.path)
      } catch (error) {
        showAlert(getErrorMessage(error), 'error')
      }
    }
  })

  return (
    <>
      <div className="verbiage">
        <h1>{tx('auth.messages.signIn')}</h1>
        <p>
          Join <strong>{invite.send_by}</strong>'s team!
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input required type="text" id="name" {...getFieldProps('name')} />
        </div>

        <div className="form-group">
          <label htmlFor="email">E-mail</label>
          <input required type="email" id="email" {...getFieldProps('email')} />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input required type="password" id="password" {...getFieldProps('password')} />
        </div>

        <div className="form-group">
          <label htmlFor="password_confirmation">Confirm password</label>
          <input
            required
            type="password"
            id="password_confirmation"
            {...getFieldProps('password_confirmation')}
          />
        </div>

        <div className="form-group">
          <Button
            loading={create.status === api.RequestStatus.loading}
            type="submit"
            className="btn-success"
            block
          >
            Join the team
          </Button>
        </div>
      </form>
    </>
  )
}

const AcceptInvite = () => {
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const { invite } = useInviteByToken(token)

  return (
    <AuthLayout
      title={tx('auth.messages.signIn')}
      content={null}
      location={history.location}
      className="invalid-invite"
      layout="invite"
      form={({ showAlert }) => {
        if (invite.isValidating) {
          return <Loading />
        }

        if (invite.error) {
          return <Error />
        }

        if (invite.data) {
          return <NewMember token={token} invite={invite.data} showAlert={showAlert} />
        }
      }}
    />
  )
}

export default AcceptInvite
