import { useSelector, useDispatch, shallowEqual } from 'react-redux'

export type CacheOptions = {
  namespace: string
  create?: boolean
  remove?: boolean
  update?: boolean
  single?: boolean
  id?: string
}

type ObjectWithId = {
  ref: {
    value: {
      id: string
    }
  }
}

type CacheData = any

type CacheAction = {
  type: string
  payload?: {
    data: CacheData
    options: CacheOptions
  }
}

type CacheItems = {
  [key: string]: CacheData
}

type CacheNamespace = {
  items: CacheItems
}

type CacheState = {
  [key: string]: CacheNamespace
}

type State = {
  cache: CacheState
}

const getID = (object: ObjectWithId) => {
  return object.ref.value.id
}

const getObjectsById = (data: CacheData): CacheItems => {
  return data.reduce((objectsById, object) => {
    return {
      ...objectsById,
      [getID(object)]: object
    }
  }, {})
}

const getItems = (state?: CacheNamespace): CacheItems | null | undefined => {
  if (state) {
    return state.items
  }
}

const selectCacheItems = (cacheOptions?: CacheOptions) => (state: State) => {
  if (!cacheOptions) return undefined
  if (cacheOptions.single && !cacheOptions.id) {
    throw new Error('To use cacheOptions.single you should provide cacheOptions.id both.')
  }

  const { namespace } = cacheOptions

  if (state.cache[namespace]) {
    if (cacheOptions.single && cacheOptions.id) {
      return state.cache[namespace].items[cacheOptions.id]
    }

    return Object.values(state.cache[namespace].items)
  }
}

export const useCache = (cacheOptions?: CacheOptions) => {
  const dispatch = useDispatch()
  const cacheData = useSelector(selectCacheItems(cacheOptions), shallowEqual)

  return {
    cacheData,
    setCache: (data: CacheData) => {
      if (!cacheOptions) return

      const { create, update, remove, single } = cacheOptions

      if (create || update || single) return dispatch(setCacheItems([data], cacheOptions))
      if (remove) return dispatch(removeCacheItem(data, cacheOptions))
      return dispatch(setCacheItems(data, cacheOptions))
    }
  }
}

export const setCacheItems = (data: CacheData, options: CacheOptions): CacheAction => {
  return {
    type: 'SET_CACHE_ITEMS',
    payload: {
      options,
      data
    }
  }
}

export const removeCacheItem = (data: CacheData, options: CacheOptions): CacheAction => {
  return {
    type: 'REMOVE_CACHE_ITEM',
    payload: {
      options,
      data
    }
  }
}

export const reducer = (state: CacheState = {}, { type, payload }: CacheAction) => {
  if (type === 'SET_CACHE_ITEMS' && payload) {
    const { options, data } = payload
    const { namespace } = options

    return {
      ...state,
      [namespace]: {
        ...state[namespace],
        items: {
          ...getItems(state[namespace]),
          ...getObjectsById(data)
        }
      }
    }
  }

  if (type === 'REMOVE_CACHE_ITEM' && payload) {
    const { options, data } = payload
    const { namespace } = options
    const namespaceItems = getItems(state[namespace])

    namespaceItems && delete namespaceItems[getID(data)]

    return {
      ...state,
      [namespace]: {
        ...state[namespace],
        items: namespaceItems
      }
    }
  }

  return state
}
