type Options = {
  decimalCases?: number
}

const defaultOptions = {
  decimalCases: 2
}

export function format(number: number, options: Options = defaultOptions): string {
  if (!number) return '--'

  return number.toLocaleString(undefined, {
    minimumFractionDigits: options.decimalCases,
    maximumFractionDigits: options.decimalCases
  })
}

export function displayEmpty(): string {
  return '--'
}
