import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'
import { sendRegionRequest } from '../../../modules/api/3rd-party/regionRequest'
import useDatabases from '../../../modules/fauna/useDatabases'
import SessionCookie from '../../../modules/auth/session/cookie'
import getRegion from '../../../modules/auth/session/regions'
import * as DatabaseResource from '../../../modules/databaseResource'
import { tx } from '../../../modules/translate'
import { ConsoleDrawer } from '../../shared/ConsoleDrawer'
import { makeToast } from '../../shared/toast'
import createDemoData from './createDemoData'
import DatabaseForm from './DatabaseForm'
import { createBackupConfiguration } from '../../../modules/api/frontdoor/backup'
import { logAxiosError, logFaunaError } from '../../../utils/log-helper'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import { CUSTOM_FOOTPRINT } from './RegionSelect'

export interface NewDatabaseDrawerProps {
  show: boolean
  onClose: () => void
}

export const NewDatabaseDrawer: FC<NewDatabaseDrawerProps> = ({ show, onClose }) => {
  const {
    rolePermission: backupRestoreRolePermission,
    planPermission: backupRestorePlanPermission
  } = usePermissions(PermissionDomain.BACKUP_RESTORE, PermissionLevel.write)

  const [isLoading, setIsLoading] = useState(false)
  const { dbPath, region } = useParams<{ dbPath?: string; region?: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = SessionCookie.get().data

  const { revalidate: refreshDatabases } = useDatabases(dbPath, region)

  const onSubmit = (values, demoDataIsEnabled, regionGroup) => {
    setIsLoading(true)

    if (values.regionGroup === CUSTOM_FOOTPRINT) {
      return sendRegionRequest({
        reason: values.customFootprintReason,
        email: user.email
      })
        .then(() => {
          makeToast({
            title: 'Request submitted!',
            message: 'A member of our team will reach out shortly to follow up on your request.'
          })
        })
        .catch(err => {
          makeToast({
            title: 'Error',
            message: 'Something went wrong during sending the request.'
          })

          return Promise.reject(err)
        })
        .finally(() => {
          setIsLoading(false)
          onClose()
        })
    }

    const payload = {
      values: {
        ...values,
        region: getRegion(regionGroup),
        parent: dbPath
      },
      hideAlert: true,
      databasePath: dbPath,
      parentPath: dbPath,
      onComplete: async (_, error) => {
        if (error) return setIsLoading(false)

        const newDatabasePath = dbPath === '' ? values.name : `${dbPath}/${values.name}`

        if (values.backupConfigurationControls.enabled) {
          await createBackupConfiguration(
            newDatabasePath,
            getRegion(regionGroup).regionPrefix,
            values.backupConfigurationControls,
            backupRestoreRolePermission && backupRestorePlanPermission
          ).catch(error => {
            logAxiosError(error)
            makeToast({
              title: 'Error',
              message: 'We were unable to configure backups for your database. Please try again.'
            })
          })
        }

        if (demoDataIsEnabled) {
          await createDemoData(newDatabasePath, getRegion(regionGroup)).catch(error => {
            logFaunaError(error)
          })
        }

        refreshDatabases()
        setIsLoading(false)
        onClose()
        history.push({
          pathname: createRoute(routes.db.byId.path, newDatabasePath, regionGroup)
        })
      }
    }

    dispatch(DatabaseResource.actions.create(payload))
  }

  return (
    <ConsoleDrawer open={show} onClose={onClose} header={tx('database.actions.new')}>
      <div className="padding-y-2">
        <DatabaseForm
          region={region}
          key={String(show)}
          loading={isLoading}
          isCreate={true}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </div>
    </ConsoleDrawer>
  )
}
