import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Match, RouterHistory } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'
import * as API from '../../../modules/api'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { showAlert } from '../../../modules/alert/actions'
import RoleForm from './RoleForm'
import roleFormValuesToFQLObject from './roleFormValuesToFQLObject'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'

const initialValues = {
  name: 'MyCustomRole',
  privileges: [],
  membership: []
}

type NewRoleProps = {
  match: Match
  history: RouterHistory
}

function NewRole({ match, history }: NewRoleProps) {
  const databasePath = match.params.dbPath || ''
  const region = match.params.region || ''
  API.selectDatabase(databasePath)

  const [isCreating, setIsCreating] = useState(false)
  const dispatch = useDispatch()

  async function handleFormSubmit(values) {
    setIsCreating(true)

    try {
      const roleFQLValues = roleFormValuesToFQLObject(values)
      await API.createRole(roleFQLValues)

      history.push(createRoute(routes.roles.index.path, databasePath, region))
    } catch (error) {
      setIsCreating(false)
      dispatch(showAlert(getErrorMessage(error), 'error'))
    }
  }

  function handleCancelClick() {
    history.goBack()
  }

  return (
    <RoleForm
      databasePath={databasePath}
      initialValues={initialValues}
      isLoading={isCreating}
      onSubmit={handleFormSubmit}
      onCancel={handleCancelClick}
    />
  )
}

export default withBreadcrumbs(({ location, match }) => {
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path, match.params.dbPath, match.params.region)
    },
    {
      label: tx('role.title'),
      path: createRoute(routes.roles.index.path, match.params.dbPath, match.params.region)
    },
    {
      label: tx('role.newRole'),
      path: location.pathname
    }
  ]
})(NewRole)
