import { matchPath, NavLink, useParams, withRouter } from 'react-router-dom'
//@ts-ignore
import graphQLIcon from '../../assets/images/graphql-icon.svg'
import routes, { createRoute } from '../../config/routes'
import { useGraphQLAccess } from '../../config/useGraphQLAccess'
import { tx } from '../../modules/translate'
import Icon from '../shared/Icon'

function DatabaseSidebar() {
  const { dbPath: databasePath = '', region = '' } = useParams()
  const { hasAccess: hasGraphQLAccess } = useGraphQLAccess()

  return (
    <nav className="sidebar">
      <NavLink exact to={createRoute(routes.db.byId.path, databasePath, region)}>
        <Icon name="database" />
        <span className="display-none display-xsmall-block">{tx('database.overview')}</span>
      </NavLink>

      <NavLink
        to={createRoute(routes.classes.index.path, databasePath, region)}
        isActive={(match, location) =>
          !!matchPath(location.pathname, routes.classes.byId) ||
          !!matchPath(location.pathname, routes.classes.index)
        }
      >
        <Icon name="table" />
        <span className="display-none display-xsmall-block">{tx('class_.plural')}</span>
      </NavLink>

      <NavLink
        to={createRoute(routes.indexes.index.path, databasePath, region)}
        isActive={(match, location) =>
          !!matchPath(location.pathname, routes.indexes.byId) ||
          !!matchPath(location.pathname, routes.indexes.index)
        }
      >
        <Icon name="list" />
        <span className="display-none display-xsmall-block">{tx('index.plural')}</span>
      </NavLink>
      <NavLink
        to={createRoute(routes.functions.index.path, databasePath, region)}
        isActive={(match, location) =>
          !!matchPath(location.pathname, routes.functions.byId) ||
          !!matchPath(location.pathname, routes.functions.index)
        }
      >
        <Icon name="code" />
        <span className="display-none display-xsmall-block">Functions</span>
      </NavLink>
      <NavLink exact to={createRoute(routes.webshell.index.path, databasePath, region)}>
        <Icon name="terminal" />
        <span className="display-none display-xsmall-block">{tx('query.plural')}</span>
      </NavLink>

      {hasGraphQLAccess() && (
        <NavLink exact to={createRoute(routes.graphql.index.path, databasePath, region)}>
          <i>
            <img src={graphQLIcon} alt="GraphQL Icon" />
          </i>
          <span className="display-none display-xsmall-block">GraphQL</span>
        </NavLink>
      )}

      <NavLink exact to={createRoute(routes.keys.index.path, databasePath, region)}>
        <Icon name="user-lock" />
        <span className="display-none display-xsmall-block">{tx('security.title')}</span>
      </NavLink>

      <NavLink exact to={createRoute(routes.backup.index.path, databasePath, region)}>
        <Icon name="history" />
        <span className="display-none display-xsmall-block">{tx('backup.titlePlural')}</span>
      </NavLink>
    </nav>
  )
}

export default withRouter(DatabaseSidebar)
