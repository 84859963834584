import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import useSnapshots from '../../modules/api/useSnapshots'
import useDatabaseDetails from '../../modules/fauna/useDatabaseDetails'
import useBackupConfiguration from '../../modules/api/useBackupConfiguration'
import { ScheduleCategory } from '../pages/databases/common'
import { INDEFINITE_TTL_DAYS } from '../../modules/api/frontdoor/backup'
import getRegion from '../../modules/auth/session/regions'
import Icon from './Icon'
import Spinner from './Spinner'
import SnapshotDate from './SnapshotDate'
import { Table, AsyncCell, RowExpander, AlignmentWrapper, buildTableRows } from './Table'
import { tx } from '../../modules/translate'

type Props = {
  onOpenSettings: (event: React.MouseEvent, path: string, regionPrefix: string) => void
}

const formatData = data => {
  return data.map(row => {
    const dbName = row.db.id
    const dbPath = row.databasePath
    const regionPrefix = row.regionPrefix
    const regionName = getRegion(regionPrefix)?.displayName || '--'
    const restore = row.restore
    const name = restore ? `${dbName} (${restore.type}: ${_.startCase(restore.state)})` : dbName
    return {
      name,
      region: regionName,
      // This is a workaround to give AsyncCells the props they need to fetch data.
      // It is filtered out before the row is rendered.
      props: {
        dbPath,
        regionPrefix,
        restore
      }
    }
  })
}

export const DatabasesTable: React.FC<Props> = ({ onOpenSettings }) => {
  const [tableRows, setTableRows] = useState([])
  // A map of table row ids to the number of child databases currently being restored.
  //  This is used to determine if we need to update sub rows to reflect changing restore state.
  const [activeRestores, setActiveRestores] = useState({})

  const { rows: rootDatabases, loading: loadingRootDatabases } = useDatabaseDetails('')

  // The Table component expects a callback function to render asynchronous data in the context
  //  of the page the table lives in. This function is a generic render handler that we pass
  //  to the AsyncCell in Table. Each column can define how this function should display data
  //  or errors, by passing in `renderData` and `renderOnError` callbacks.
  // This method of accepting the `renderData` callback ensures that we can do error checking
  //  first, and not run into unsafe object access for promise that hasn't completed yet.
  const asyncCellOnUpdate = ({
    data,
    error,
    isLoading = !data && !error,
    renderData,
    renderOnError = () => '--'
  }: any) => {
    if (isLoading) return <>--</>
    if (error) return <>{renderOnError(error)}</>
    if (!data) return <>--</>
    return <>{renderData(data)}</>
  }

  // Update or insert the given sub rows.
  const upsertSubRows = (parentRowId: string, subRows) => {
    const updatedRows = buildTableRows({
      rowId: parentRowId,
      existingRows: tableRows,
      subRowsToInsert: formatData(subRows)
    })
    setTableRows(updatedRows)
  }

  /**
   * Instead of resetting the table view to the original list of rootDatabases,
   *  use the previous selection of expanded rows to maintain expansion state against
   *  the new set of rootDatabases. Get expansion state from the existin tableRows,
   *  match on Name against the new rootDatabases and add the subRows attribute
   * @param newRootDatabases the newly fetched list of rootDatabases after polling
   * @returns table rows
   */
  const mergeExpandedRows = newRootDatabases => {
    const oldRows = tableRows
    const newRows = formatData(newRootDatabases)
    const oldSubRows = oldRows.reduce((obj, item) => {
      obj[`${item.name}-${item.region}`] = item.subRows
      return obj
    }, {})
    newRows.map(item => {
      if (oldSubRows[`${item.name}-${item.region}`]) {
        item.subRows = oldSubRows[`${item.name}-${item.region}`]
      }
      return item
    })
    return newRows
  }

  const columns = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        minWidth: 200,
        Cell: ({ row }) => {
          const { name } = row.values
          const { id, isExpanded } = row
          const { restore, dbPath, regionPrefix } = row.original.props

          // load any child databases for the current row
          const { rows: subRows } = useDatabaseDetails(dbPath, regionPrefix)
          const hasSubRows = subRows && subRows.length > 0

          const onExpand = () => {
            if (hasSubRows) upsertSubRows(id, subRows)
          }

          useEffect(() => {
            // This effect hook should keep sub rows in the table up to date as restores
            //  are kicked off. We should only update the table if we know a restore has
            //  started or finished.
            if (hasSubRows && isExpanded) {
              const prevActiveRestores = activeRestores[id]
              const curActiveRestores = subRows.reduce((acc, row) => acc + (row.restore ? 1 : 0), 0)
              if (curActiveRestores !== prevActiveRestores) {
                setActiveRestores(prev => {
                  const temp = prev
                  temp[id] = curActiveRestores
                  return temp
                })
                upsertSubRows(id, subRows)
              }
            }
          }, [id, subRows, hasSubRows, isExpanded])

          return (
            <AlignmentWrapper>
              <RowExpander row={row} hasSubRows={hasSubRows} onExpand={onExpand} />
              <div className={restore ? 'secondary-neutral italic' : ''}>{name}</div>
            </AlignmentWrapper>
          )
        }
      },
      { Header: 'REGION', accessor: 'region' },
      {
        Header: 'BACKUPS',
        accessor: 'backups',
        maxWidth: 75,
        Cell: ({ row }) => {
          const { dbPath, regionPrefix } = row.original.props
          return (
            <AsyncCell
              promiseFunction={useBackupConfiguration(regionPrefix, dbPath)}
              memoKeys={['data', 'error']}
              onUpdate={response => {
                const { data, error } = response
                const renderData = dataToRender =>
                  dataToRender['enabled'] ? (
                    <Icon name="check" className="muted" />
                  ) : (
                    <Icon name="ban" className="muted" />
                  )
                const renderOnError = error => {
                  // We will get a 404 if a backup config has not yet
                  // been created (i.e. backups are not enabled).
                  return error.response?.status === 404 ? (
                    <Icon name="ban" className="muted" />
                  ) : (
                    '--'
                  )
                }
                return asyncCellOnUpdate({ data, error, renderData, renderOnError })
              }}
            ></AsyncCell>
          )
        }
      },
      {
        Header: 'LATEST SNAPSHOT',
        accessor: 'snapshot',
        minWidth: 200,
        Cell: ({ row }) => {
          const { dbPath: path, regionPrefix: region } = row.original.props
          return (
            <AsyncCell
              promiseFunction={useSnapshots({
                path,
                region,
                pageSize: 1,
                latestOnly: true
              })}
              memoKeys={['list']}
              onUpdate={response => {
                // since the promiseFunction passed into the cell returned the
                //  result of `usePagination`, the return shape is different than
                //  those promiseFunctions which return `useSWR` ({data, error})
                const { isLoadingMore, error, list: snapshotList } = response
                const renderData = dataToRender => {
                  return (
                    <SnapshotDate
                      state={dataToRender.data.state}
                      timestamp={dataToRender.data.snapshot_ts['@ts']}
                    />
                  )
                }
                return asyncCellOnUpdate({
                  data: snapshotList?.[0],
                  error,
                  isLoading: isLoadingMore,
                  renderData
                })
              }}
            />
          )
        }
      },
      {
        Header: 'SCHEDULE',
        accessor: 'schedule',
        maxWidth: 75,
        Cell: ({ row }) => {
          const { dbPath, regionPrefix } = row.original.props
          // Frequency has been punted for GA so hardcoding to Daily for the time being.
          return (
            <AsyncCell
              promiseFunction={useBackupConfiguration(regionPrefix, dbPath)}
              memoKeys={['data', 'error']}
              onUpdate={response => {
                const { data, error } = response
                const renderData = dataToRender => {
                  if (dataToRender['category'] === ScheduleCategory.DAILY) {
                    return tx('backup.schedule.daily.label')
                  } else if (dataToRender['category'] === ScheduleCategory.MONTHLY) {
                    return tx('backup.schedule.monthly.label')
                  } else if (dataToRender['category'] === ScheduleCategory.WEEKLY) {
                    return tx('backup.schedule.weekly.label')
                  } else {
                    return tx('backup.schedule.daily.label')
                  }
                }
                return asyncCellOnUpdate({ data, error, renderData })
              }}
            />
          )
        }
      },
      {
        Header: 'RETENTION',
        accessor: 'retention',
        maxWidth: 75,
        Cell: ({ row }) => {
          const { dbPath, regionPrefix } = row.original.props
          return (
            <AsyncCell
              promiseFunction={useBackupConfiguration(regionPrefix, dbPath)}
              memoKeys={['data', 'error']}
              onUpdate={response => {
                const { data, error } = response
                const renderData = dataToRender => (
                  <div>
                    {dataToRender['ttl_days'] === INDEFINITE_TTL_DAYS
                      ? 'Indefinite'
                      : `${dataToRender['ttl_days']} days`}
                  </div>
                )
                return asyncCellOnUpdate({ data, error, renderData })
              }}
            />
          )
        }
      },
      {
        Header: <AlignmentWrapper horizontalAlign="end">ACTIONS</AlignmentWrapper>,
        accessor: 'actions',
        maxWidth: 50,
        Cell: ({ row }) => {
          const { dbPath, regionPrefix, restore: activeRestore } = row.original.props
          return (
            <AlignmentWrapper horizontalAlign="end">
              {!activeRestore && (
                <Icon
                  name="cog"
                  className="muted"
                  wrapperClassName={activeRestore ? '' : 'icon--hoverable'}
                  onClick={event => {
                    if (activeRestore) return
                    onOpenSettings(event, dbPath, regionPrefix)
                  }}
                />
              )}
            </AlignmentWrapper>
          )
        }
      }
    ],
    [tableRows]
  )

  useEffect(() => {
    if (rootDatabases && !loadingRootDatabases) {
      setTableRows(mergeExpandedRows(rootDatabases))
    }
  }, [rootDatabases, loadingRootDatabases])

  if (loadingRootDatabases)
    return (
      <div className="loader">
        <Spinner />
      </div>
    )

  return <Table data={tableRows || []} columns={columns} />
}

export default DatabasesTable
