const userAgent = window.navigator.userAgent
const platform = window.navigator.platform
const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
const iosPlatforms = ['iPhone', 'iPad', 'iPod']

export const isMacOS = () => macosPlatforms.includes(platform)
export const isIOS = () => iosPlatforms.includes(platform)
export const isWindows = () => windowsPlatforms.includes(platform)
export const isAndroid = () => /Android/.test(userAgent)
export const isLinux = () => /Linux/.test(platform)
