import Icon from '../../shared/Icon'

type CardListItemProps = {
  href: string
  iconName?: string
  iconRender?: () => React.ReactNode
  title: string
  subtitle: string
}

const CardListItem: React.FC<CardListItemProps> = ({
  href,
  iconName,
  iconRender,
  title,
  subtitle
}) => {
  return (
    <li>
      <a target="_blank" rel="noopener noreferrer" href={href}>
        <div className="list-icon">{iconRender ? iconRender() : <Icon name={iconName} />}</div>
        <div className="list-content">
          <div className="list-title">{title}</div>
          <div className="list-subtitle">{subtitle}</div>
        </div>
      </a>
    </li>
  )
}

export default CardListItem
