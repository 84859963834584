import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { tx } from '../../../modules/translate'
import { Snapshot, DEFAULT_BACKUP_CONFIGURATION } from '../../../modules/api/frontdoor/backup'
import useBackupConfiguration from '../../../modules/api/useBackupConfiguration'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import SnapshotsTable from '../../shared/SnapshotsTable'
import { useDrawer } from '../databases/useDrawer'
import DatabaseSettingsDrawer, { Stage } from '../settings/DatabaseSettingsDrawer'
import { ScheduleCategory } from '../databases/common'
import { INDEFINITE_TTL_DAYS } from '../../../modules/api/frontdoor/backup'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import capitalize from 'lodash/capitalize'
import { SessionCookieContents } from '../../../modules/cookies/sessionCookie'
import SessionCookie from '../../../modules/auth/session/cookie'

function BackupsIndex() {
  const { planPermission: showBackupRestore } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.read
  )
  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptablePlans: string[]
  if (v2Released) {
    acceptablePlans = ['Startup', 'Trial', 'Pro', 'Enterprise']
  } else {
    acceptablePlans = ['team', 'business']
  }

  const { dbPath, region } = useParams<{ dbPath: string; region: string }>()
  const { data: backupConfiguration, error: backupConfigurationError } = useBackupConfiguration(
    region,
    dbPath
  )
  const backupConfigurationLoading = !backupConfiguration && !backupConfigurationError
  const { frequency, ttl_days, backupsEnabled, category } = useMemo(
    () => ({
      // TODO: have default values as constants in a config file
      backupsEnabled: backupConfiguration?.enabled || false,
      frequency: backupConfiguration?.frequency || DEFAULT_BACKUP_CONFIGURATION.frequency,
      ttl_days: backupConfiguration?.ttl_days || DEFAULT_BACKUP_CONFIGURATION.ttl_days,
      category: backupConfiguration?.category || DEFAULT_BACKUP_CONFIGURATION.category
    }),
    [backupConfiguration]
  )

  const dbSettingsDrawer = useDrawer('dbSettings')
  const [stage, setStage] = useState(Stage.SETTINGS)
  const [snapshot, setSnapshot] = useState<Snapshot>()

  const onRestore = (snapshot: Snapshot) => {
    setStage(Stage.RESTORE)
    dbSettingsDrawer.onSwitch()
    setSnapshot(snapshot)
  }

  const onCopy = (snapshot: Snapshot) => {
    setStage(Stage.COPY)
    setSnapshot(snapshot)
    dbSettingsDrawer.onSwitch()
  }

  /**
   * Create the retention string to display.
   * @param number the ttl configured on the backup config
   * @returns string with formatted retention value
   */
  const buildRetentionString = ttl_days => {
    return `Retention: ${ttl_days === INDEFINITE_TTL_DAYS ? 'Indefinite' : `${ttl_days} days`}`
  }

  const buildScheduleString = category => {
    if (category === ScheduleCategory.DAILY) {
      return tx('backup.schedule.daily.label')
    } else if (category === ScheduleCategory.MONTHLY) {
      return tx('backup.schedule.monthly.label')
    } else if (category === ScheduleCategory.WEEKLY) {
      return tx('backup.schedule.weekly.label')
    } else {
      return tx('backup.schedule.daily.label')
    }
  }

  const SubHeader = ({ frequency, ttl_days, backupsEnabled, backupConfigurationLoading }) => {
    if (backupConfigurationLoading) return <></>
    // If no backup configuration is present
    if (!backupConfiguration)
      return (
        <div>
          <div className={'secondary-neutral '}>Schedule: Disabled</div>
        </div>
      )
    // If the backup configuration has previously been configured
    //  but has since been turned off.
    if (backupConfiguration && !backupsEnabled)
      return (
        <div>
          <div className={'secondary-neutral '}>
            Schedule: Disabled {'\u00B7'} {buildRetentionString(ttl_days)}
          </div>
        </div>
      )
    // If backups are enabled
    if (backupsEnabled)
      return (
        <div>
          <div className={'secondary-neutral '}>
            Schedule: {buildScheduleString(category)} {'\u00B7'} {buildRetentionString(ttl_days)}
          </div>
        </div>
      )
  }

  return (
    <div className="grid margin-top-4">
      <div className={'margin-bottom-4'}>
        <h2>{tx('backup.titlePlural')}</h2>
        <SubHeader
          ttl_days={ttl_days}
          frequency={frequency}
          backupsEnabled={backupsEnabled}
          backupConfigurationLoading={backupConfigurationLoading}
        />
      </div>
      {!showBackupRestore && (
        <InfoBox
          body={
            <div>
              This feature is only available to users on the following plans:{' '}
              {acceptablePlans.map(plan => capitalize(plan)).join(', ') + '. '}
              <a href="/settings/billing">
                <u>Upgrade plan</u>
              </a>
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )}
      {showBackupRestore && (
        <div>
          <SnapshotsTable
            onCopy={onCopy}
            onRestore={onRestore}
            dbPath={dbPath}
            region={region}
            ttl_days={ttl_days}
            columns={['date', 'size', 'expires', 'actions']}
            // snapshot table should only reset to the first page when
            //  it's displayed within a drawer
            resetToFirstPage={false}
            showTableTitle={true}
          />
          <DatabaseSettingsDrawer
            initialStage={stage}
            dbSettingsParams={{ dbPath, region, snapshot }}
            open={dbSettingsDrawer.show}
            onClose={dbSettingsDrawer.onSwitch}
            shouldRedirectOnSubmit={true}
          />
        </div>
      )}
    </div>
  )
}

export default withBreadcrumbs(({ location }) => {
  return [
    {
      label: tx('backup.titlePlural'),
      path: location.pathname
    }
  ]
})(BackupsIndex)
