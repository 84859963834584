const queryRegex = /Query\((.*)\)/

export function extractQueryFunction(code: string): string {
  const matches = code.match(queryRegex)
  return matches ? matches[1] : code
}

export function injectQuery(code: string): string {
  const matches = code.match(queryRegex)
  return !matches ? `Query(${code})` : code
}
