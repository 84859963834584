import moment from 'moment-timezone'
import { useEffect } from 'react'

import useSnapshots from '../../modules/api/useSnapshots'
import getRegion from '../../modules/auth/session/regions'
import { Snapshot } from '../../modules/api/frontdoor/backup'

import Icon from '../shared/Icon'
import Spinner from '../shared/Spinner'
import SnapshotDate from './SnapshotDate'
import Table, { AlignmentWrapper } from './Table'
import DashboardTooltip from '../pages/functions/DashboardTooltip'
import usePermissions from '../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

moment.updateLocale('en', {
  relativeTime: {
    s: '< 1h',
    ss: '< 1h',
    m: '< 1h',
    mm: '< 1h',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy'
  }
})

type SnapshotsTableProps = {
  columns: Array<string>
  region?: string
  dbPath?: string
  ttl_days?: number
  onCopy?: (snapshot: Snapshot, dbPath?: string, regionPrefix?: string) => void
  onRestore?: (snapshot: Snapshot) => void
  resetToFirstPage?: boolean
  smallTableView?: boolean
  pageSize?: number
  showTableTitle?: boolean
  withBackupConfiguration?: boolean
}

const SnapshotsTable: React.FC<SnapshotsTableProps> = ({
  columns,
  region,
  dbPath,
  ttl_days,
  onCopy,
  onRestore,
  pageSize = 5,
  showTableTitle = false,
  resetToFirstPage = false,
  withBackupConfiguration = false
}) => {
  /**
   * If given a database path and region prefix this will fetch all snapshots for that
   * database. If not provided this will fetch all snapshots for the tenants account. If the
   * 'withBackupConfiguration' flag is set this will return an array of objects with both a
   * 'snapshot' field and 'backup_configuration' field.
   */
  const paginatedData = useSnapshots({ path: dbPath, region, pageSize, withBackupConfiguration })

  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.write
  )
  const hasBackupRestorePermission = rolePermission && planPermission

  /**
   * Given a snapshot timestamp, use the backup config to determine when the
   *  snapshot will expire. Granularity of days and hours.
   * @param timestamp string returned from Snapshot fauna collection
   * @returns string
   */
  const buildExpireString = (timestamp: string, ttl = ttl_days) => {
    if (!ttl) return '--'
    const expireDate = moment.utc(timestamp).add(Number(ttl), 'days')
    const timeUntilExpire = moment.duration(expireDate.diff(moment.utc()))
    if (timeUntilExpire.hours() < 0 || timeUntilExpire.days() < 0) return '--'
    const expires = timeUntilExpire.days()
      ? `${timeUntilExpire.days()} days`
      : `${timeUntilExpire.hours()} hours`
    return expires
  }

  /**
   * Add a nice label to the bytes number.
   *  Calculate how many times bytes divides into 1024, use that to
   *  determine which label to show. return a decimal format.
   * @param bytes number of bytes
   * @returns string with label on bytes
   */
  function bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    let i = Math.floor(Math.log(bytes) / Math.log(1024))
    if (i === 0) return `${bytes} ${sizes[i]})`
    // don't show TB, stop at GB.
    if (i >= sizes.length - 1) {
      i = sizes.length - 1
    }
    return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`
  }

  /**
   * Dive the byte number for snapshot size by GB byte size. Set to 1 decimal point.
   * @param bytes number of bytes that is the size of a snapshot
   * @returns string number with one decimal point
   */
  const buildSnapshotSize = (bytes: number) => {
    let displayString = '--'
    let tooltipString = 'No size data'
    const GB = 1024 ** 3
    if (bytes) {
      displayString = `${(bytes / GB).toFixed(1)}`
      tooltipString = bytesToSize(bytes)
    }
    return {
      displayString,
      tooltipString
    }
  }

  /**
   * Get the region display name.
   * @param regionPrefix string representing the region
   * @returns string display name corresponding to the region prefix
   */
  const buildRegion = (regionPrefix?: string) => {
    if (!regionPrefix) return '--'
    return getRegion(regionPrefix).displayName
  }

  /**
   * Unpack a row from the useSnapshots hook depending on given params.
   * @param row Snapshot or Snapshot/BackupConfiguration object
   * @returns a tuple of the form [Snapshot, BackupConfiguration?]
   */
  const unpackRow = row => {
    if (!withBackupConfiguration) return [row, undefined]
    return [row.snapshot, row.backup_configuration]
  }

  // snapshots are displayed in the Database Settings drawer as well as the backups page.
  //  when snapshots are viewed within the drawer, we want to reset the snapshot table view
  //  when the drawer is made visible. Otherwise, if snapshots are viewed outside of a drawer we
  //  don't want to reset the view. This prop value is controlled by the components which render
  //  snapshots. i.e. when rendered in the drawer (DatabaseForm.tsx), this prop value can leverage
  //  a global variable which represents the current visibility of any drawer.
  // Note we don't pass any dependencies to useEffect, this ensures it runs only once on the first
  //  render. Otherwise any dependency that triggers a re-render would reset the page of data
  useEffect(() => {
    if (resetToFirstPage && paginatedData.pageIndex) {
      paginatedData.goToFirst()
    }
  }, [resetToFirstPage])

  const tableData = paginatedData.list.map(row => {
    const [snapshot, backupConfiguration] = unpackRow(row)
    const state = snapshot?.data?.state
    const disabled = state !== 'Complete' || !hasBackupRestorePermission
    const timestamp = snapshot.data.snapshot_ts['@ts']
    const expires = buildExpireString(timestamp, backupConfiguration?.data?.ttl_days)
    const size = buildSnapshotSize(snapshot.data?.snapshot_size)
    const dbPath = snapshot.data?.database_path?.join('/')
    const date = <SnapshotDate state={state} timestamp={timestamp} />
    const regionPrefix = backupConfiguration?.data?.metadata?.region_group
    const region = buildRegion(regionPrefix)
    const iconClassName = disabled ? 'ghost' : 'muted'
    const iconWrapperClassName = `icon--hoverable ${disabled && 'disabled'}`
    const actions = (
      <AlignmentWrapper direction="row" horizontalAlign="end">
        {onCopy && (
          <Icon
            name="copy"
            className={iconClassName}
            wrapperClassName={iconWrapperClassName}
            onClick={() =>
              disabled ? null : onCopy(snapshot, dbPath, getRegion(regionPrefix)?.regionPrefix)
            }
          />
        )}
        {onRestore && (
          <Icon
            name="history"
            className={iconClassName}
            wrapperClassName={iconWrapperClassName}
            onClick={() => (disabled ? null : onRestore(snapshot))}
          />
        )}
      </AlignmentWrapper>
    )
    return {
      date,
      size,
      name: dbPath,
      region,
      expires,
      actions
    }
  })

  let snapshotColumns = [
    {
      Header: 'NAME',
      accessor: 'name',
      minWidth: 100
    },
    {
      Header: 'REGION',
      accessor: 'region',
      maxWidth: 100
    },
    {
      Header: 'DATE',
      accessor: 'date',
      minWidth: 150
    },
    { Header: 'EXPIRES', accessor: 'expires', maxWidth: 75 },
    {
      Header: 'SIZE (GB)',
      accessor: 'size',
      maxWidth: 50,
      Cell: ({ row }) => {
        const { id } = row
        const { displayString, tooltipString } = row.values.size
        return (
          <AlignmentWrapper horizontalAlign="start">
            <div data-tip={`${id}-size`} data-for={`${id}-size`}>
              {displayString}
            </div>
            <DashboardTooltip
              id={`${id}-size`}
              className="actions-popover"
              placement="top"
              displayIcon={false}
              contentText={<div>{tooltipString}</div>}
            />
          </AlignmentWrapper>
        )
      }
    },
    {
      Header: <AlignmentWrapper horizontalAlign="end">ACTIONS</AlignmentWrapper>,
      accessor: 'actions',
      maxWidth: 75
    }
  ]

  snapshotColumns = snapshotColumns.filter(col => columns.includes(col.accessor))

  if (paginatedData.isInitialLoading || paginatedData.isLoadingMore)
    return (
      <div className="loader">
        <Spinner />
      </div>
    )

  const showNote = !paginatedData.list.length && !paginatedData.pageIndex
  if (!showNote && !paginatedData.list.length) return null

  return (
    <div className="snapshots-table">
      {showNote && (
        <div>
          <p>
            <b>Note:</b> Creating a backup of your database can take a few hours. Snapshots are
            generated on a fixed, once-daily schedule. It make take up to 24 hours for your first
            backup to be reflected in your admin panel.
          </p>
          <p>
            To learn more about backup cost, see our{' '}
            <a href="https://fauna.com/pricing" target="_blank" rel="noreferrer">
              pricing page
            </a>
          </p>
        </div>
      )}
      <Table
        data={tableData}
        columns={snapshotColumns}
        paginatedData={paginatedData}
        tableTitle={showTableTitle ? 'Snapshots' : ''}
      />
    </div>
  )
}

export default SnapshotsTable
