import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import Icon from '../../shared/Icon'

type DashboardTooltipProps = {
  id: string
  header?: string
  placement: string
  contentText: any
  iconClassName?: string
  afterShow?: any
  afterHide?: any
  displayIcon?: boolean
  iconName?: string
  className?: string
  rapOn?: boolean
  iconHoverable?: boolean
}

const DashboardTooltip = ({
  id,
  header,
  placement,
  contentText,
  iconClassName,
  afterShow,
  afterHide,
  displayIcon,
  iconName = 'question-circle',
  className,
  rapOn = true,
  iconHoverable = false
}: DashboardTooltipProps) => {
  return (
    <div>
      {header}
      {displayIcon !== false && (
        <div className={rapOn ? 'rap-manager' : ''}>
          <div className={rapOn ? 'rap-target-container' : ''}>
            <Icon
              data-for={id}
              data-tip={id}
              name={iconName}
              className={iconClassName || 'secondary'}
              wrapperClassName={iconHoverable ? 'icon--hoverable' : ''}
            />
          </div>
        </div>
      )}
      <ReactTooltip
        id={id}
        type={'dark'}
        effect="solid"
        getContent={() => contentText}
        className={cn('popover-content', className)}
        afterShow={afterShow}
        afterHide={afterHide}
        delayHide={200}
        delayShow={200}
        delayUpdate={200}
        // @ts-ignore
        place={placement}
      />
    </div>
  )
}

export default DashboardTooltip
