import { BillingInfo } from '../../../modules/api/auth-service/billingInfo'
import useBillingSources from '../../../modules/api/auth-service/billingSources'
import PaymentLoading from './PaymentLoading'
import { PaymentMethodInfo } from './PaymentMethods'
import { UsagePlanItem } from './types'
import { formatPrice } from './utils'

type Props = {
  plan: UsagePlanItem
  sourceId: BillingInfo['payment_method_id']
}

export default function CurrentPlan({ plan, sourceId }: Props) {
  const { sources } = useBillingSources()

  if (!sources.data) {
    return <PaymentLoading />
  }

  const currentSource = sources.data.find(source => source.id === sourceId)

  return (
    <div className="current-plan" data-testid="currentPlanBar">
      <div className="current-plan__name">
        {plan.name}: {formatPrice(plan.price)}/month
      </div>

      <div className="current-plan__billing-source">
        {currentSource && <PaymentMethodInfo paymentMethod={currentSource} />}
      </div>
    </div>
  )
}
