import difference from 'lodash/difference'
import { Privilege } from '../../../modules/roles'
import { tx } from '../../../modules/translate'
import PrivilegeCard from './PrivilegeCard'
import ResourceSelect from './ResourceSelect'

const longLabels = ['History Read', 'History Write', 'Unrestricted']

type PrivilegesSectionProps = {
  privileges: Privilege[]
  resources: Record<string, any>[] | null | undefined
  title: string
  titlePlaceholder: string
  actions: {
    [key: string]: string
  }
  onChange: (privileges: Privilege[]) => void
  filter: (privilege: Privilege) => boolean
}

export default function PrivilegesSection({
  privileges,
  resources,
  title,
  titlePlaceholder,
  onChange,
  actions,
  filter
}: PrivilegesSectionProps) {
  const filteredPrivileges = privileges.filter(filter)
  const resourceOptions = getResourceOptions(filteredPrivileges)
  const actionLabels = Object.values(actions)
  const isEditable = !!onChange

  function handleOptionChange(event) {
    addPrivilegeToResource(event.currentTarget.value)
  }

  function getResourceOptions(selectedPrivileges) {
    if (!resources) return

    const resourcesNames = resources.map(r => r.name)
    const privilegeNames = selectedPrivileges.map(p => p.resource.value.id)

    return difference(resourcesNames, privilegeNames)
  }

  function addPrivilegeToResource(selectedOption) {
    if (!resources) return

    const selectedResource = resources.find(resource => resource.name === selectedOption)

    if (!selectedResource) return

    onChange(
      privileges.concat({
        resource: selectedResource.ref,
        actions: createDefaultActions()
      })
    )
  }

  function handlePrivilegeRemove(removedPrivilege) {
    onChange(
      privileges.filter(privilege => {
        return !privilege.resource.equals(removedPrivilege.resource)
      })
    )
  }

  function handlePrivilegeChange(updatedPrivilege) {
    if (!onChange) return

    onChange(
      privileges.map(privilege =>
        privilege.resource.equals(updatedPrivilege.resource) ? updatedPrivilege : privilege
      )
    )
  }

  function createDefaultActions() {
    return Object.keys(actions).reduce((actionValues, actionKey: string) => {
      return {
        ...actionValues,
        [actionKey]: false
      }
    }, {})
  }

  function renderLabel(actionLabel) {
    actionLabel = String(actionLabel)
    let labelWidth = 'width-15'

    if (longLabels.includes(actionLabel)) {
      labelWidth = 'width-20 width-small-15'
    }

    return (
      <div key={actionLabel} className={`label ${labelWidth}`}>
        {actionLabel}
      </div>
    )
  }

  return (
    <section className="section">
      <div className="section__header">
        <div className="section__control">
          <h3 className="section__title">{title}</h3>
          <ResourceSelect
            dataTestId={title}
            onChange={handleOptionChange}
            resourceOptions={resourceOptions}
            placeholderText={tx('role.addResource', [titlePlaceholder])}
          />
        </div>

        {filteredPrivileges.length > 0 && (
          <div className="section__labels section__labels--right">
            {actionLabels.map(renderLabel)}
            {isEditable && (
              <div className="label width-small-10 display-none display-small-block" />
            )}
          </div>
        )}
      </div>

      {filteredPrivileges.length > 0 && (
        <div className="section__body">
          <div className="card-list">
            <div className="card-list-body">
              {filteredPrivileges.map((privilege, index) => (
                <PrivilegeCard
                  key={index}
                  privilege={privilege}
                  actions={actions}
                  onRemove={handlePrivilegeRemove}
                  onChange={handlePrivilegeChange}
                  isEditable={isEditable}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
