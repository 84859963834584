import cookies from 'js-cookie'
import { useEffect } from 'react'
import queryString from 'query-string'
import { ContextRouter } from 'react-router-dom'
import * as api from '../../../modules/api'
import SessionCookie from '../../../modules/auth/session/cookie'
import routes from '../../../config/routes'
import { appendQueryString } from '../../../utils/url'

const DB_URL = process.env.REACT_APP_DB_URL || ''

export default function OAuthCallback({ location, history }: ContextRouter) {
  const {
    credentials: urlCredentials, //todo: remove this in a future release
    provider_name: provider,
    error_code: errorCode,
    first_session: firstSession
  } = queryString.parse(location.search)

  let encodedCredentials = null

  if (cookies.get('credentials')) {
    encodedCredentials = cookies.get('credentials')
    cookies.remove('credentials', { path: '/' })
  } else {
    encodedCredentials = urlCredentials
  }

  useEffect(() => {
    if (errorCode) {
      history.push(`${routes.auth.signIn.path}${location.search}`)
      return
    }

    const credentials = encodedCredentials ? JSON.parse(window.atob(encodedCredentials)) : {}

    SessionCookie.save({
      data: credentials,
      strategy: 'oauth',
      provider
    })

    api.connect({
      urlString: DB_URL,
      secret: credentials.secret
    })

    if (firstSession) {
      history.push(appendQueryString(routes.home.path, '?first_session=true'))
    } else {
      history.push(routes.home.path)
    }
  }, [encodedCredentials, errorCode, firstSession, history, location.search, provider])

  return null
}
