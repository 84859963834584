import { lazy, Suspense } from 'react'
const JSONDisplay = lazy(() => import('./JSONDisplay'))

export default function LazyJSONDisplay(props: Record<string, any>) {
  return (
    <Suspense fallback={<></>}>
      <JSONDisplay {...props} />
    </Suspense>
  )
}
