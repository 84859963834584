import oauth from './oauth'
import emailAndPassword from './emailAndPassword'
import { LoginResponse } from '..'

export type Strategy<Params> = (params: Params) => Promise<LoginResponse>

export default {
  oauth,
  emailAndPassword
}
