import * as React from 'react'
import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import IconButton from '../../shared/IconButton'
import { useForm, getValue } from '../../../modules/form'
import DashboardTooltip from '../functions/DashboardTooltip'
import { TabContent } from '../../shared/Tabs'
import LazyCode from '../../shared/LazyCode'

export type FormValues = {
  name: string
  history_days: number
  ttl_days: number | string
}

type Props = {
  loading: boolean
  onCancel: Function
  onSubmit: (arg0: Record<string, any>, arg1: boolean) => any
  onFailure?: Function
  submitLabel?: string
  initialValues?: FormValues
  onDeleteClick: Function
}

export const FormTabIDs = {
  simple: 'simple',
  fql: 'fql'
}

export const DefaultFormValues = {
  name: '',
  history_days: 0,
  ttl_days: ''
}

export default function ClassForm({
  loading,
  onCancel,
  submitLabel,
  onSubmit,
  onFailure,
  initialValues,
  onDeleteClick
}: Props): React.ReactNode {
  const form = React.createRef()
  const { formValues, handleChange } = useForm(initialValues)

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault()
    onSubmit(getClassValues())
  }

  function getClassValues() {
    return {
      name: formValues.name,
      history_days: getValue(formValues.history_days, Number),
      ttl_days: getValue(formValues.ttl_days, Number)
    }
  }

  function handleSubmitClick() {
    if (form.current && !form.current.checkValidity()) {
      onFailure && onFailure()
    }
  }

  return (
    <form onSubmit={handleSubmit} ref={form}>
      <TabContent id={FormTabIDs.simple}>
        <div className="container container--xsmall">
          <div className="form-group">
            <div className="form-label--tooltip">
              <label className="required" htmlFor="name">
                {tx('class_.name')} {tx('attributes.name')}
              </label>
              <DashboardTooltip
                id="tooltip1"
                placement="left"
                contentText={<div>{tx('tooltips.className')}</div>}
              />
            </div>
            <div className="formField--tooltip">
              <input
                onChange={handleChange}
                value={formValues.name}
                id="name"
                name="name"
                type="text"
                required
                autoFocus
              />
            </div>
          </div>

          <div className="form-group">
            <div className="grid">
              <div className="row">
                <div className="col">
                  <div className="form-label--tooltip">
                    <label className="optional" htmlFor="history_days">
                      {tx('attributes.historyDays')}
                    </label>
                    <DashboardTooltip
                      id="historydays1"
                      placement="left"
                      contentText={<div>{tx('tooltips.trackDays')}</div>}
                    />
                  </div>
                  <div className="formField--tooltip">
                    <input
                      id="history_days"
                      name="history_days"
                      type="number"
                      min="0"
                      onChange={handleChange}
                      value={formValues.history_days}
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="form-label--tooltip">
                    <label className="optional" htmlFor="ttl_days">
                      {tx('attributes.ttl')}
                    </label>
                    <DashboardTooltip
                      id="ttldays1"
                      placement="left"
                      contentText={<div>{tx('tooltips.ttl')}</div>}
                    />
                  </div>
                  <div className="formField--tooltip">
                    <input
                      id="ttl_days"
                      name="ttl_days"
                      type="number"
                      min="1"
                      onChange={handleChange}
                      value={formValues.ttl_days}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabContent>

      <TabContent id={FormTabIDs.fql}>
        <div className="form-group">
          <label htmlFor="">{tx('attributes.faunaData')}</label>
          <LazyCode code={getClassValues()} />
        </div>
      </TabContent>

      <div className="form-actions container container--xsmall">
        <Button onClick={onCancel} color="secondary">
          {tx('actions.cancel')}
        </Button>

        <Button type="submit" color="success" loading={loading} onClick={handleSubmitClick}>
          {submitLabel || tx('actions.save')}
        </Button>
      </div>

      {onDeleteClick && (
        <div className="container container--xsmall">
          <hr className="margin-top-4 margin-bottom-3" />

          <IconButton
            color="muted"
            onClick={onDeleteClick}
            label={tx('actions.remove')}
            icon="trash"
          />
        </div>
      )}
    </form>
  )
}

ClassForm.defaultProps = {
  loading: false,
  initialValues: DefaultFormValues
}
