import axios from 'axios'
import { logAxiosError } from '../../../utils/log-helper'
import { LoginResponse } from '../../auth'

export enum OtpErrorReason {
  'OTP_INVALID_CODE',
  'OTP_ALREADY_USED',
  'OTP_REQUIRED'
}

export type Params = {
  email: string
  password: string
  otp?: string
}

export const API_URL = `${process.env.REACT_APP_AUTH_URL}/login`

export class OtpError extends Error {
  reason: OtpErrorReason

  constructor(message: string, reason: OtpErrorReason) {
    super(message)
    this.reason = reason
  }
}

export default async function login(params: Params): Promise<LoginResponse> {
  try {
    const { data } = await axios.post(API_URL, params)
    return {
      data,
      strategy: 'email_and_password'
    }
  } catch (error) {
    if (error.response.data?.code === 'otp_required') {
      // don't log -- this is an expected part of the OTP login flow
      throw new OtpError('OTP code Required', OtpErrorReason.OTP_REQUIRED)
    } else if (
      error.response.data?.code === 'otp_invalid' ||
      error.response.data?.code === 'backup_code_already_used'
    ) {
      logAxiosError(error)
      throw new OtpError('invalid otp code', OtpErrorReason.OTP_INVALID_CODE)
    }

    logAxiosError(error)
    throw error
  }
}
