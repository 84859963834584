import { ReactNode } from 'react'
import Icon from './Icon'

export enum InfoBoxType {
  primary = 'primary',
  warning = 'warning',
  danger = 'danger'
}

type InfoBoxProps = {
  header?: string
  body: string | ReactNode
  iconName: string
  type: InfoBoxType
}

const InfoBox = ({ header, body, iconName, type }: InfoBoxProps) => {
  return (
    <div className="info-box-wrapper">
      <div className={`info-box ${type}`}>
        <Icon name={iconName} className={type} />
        <div>
          {header && <b>{header}</b>}
          <div>{body}</div>
        </div>
      </div>
    </div>
  )
}

export default InfoBox
