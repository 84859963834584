import { useState, useEffect } from 'react'
import { Match, RouterHistory, Location } from 'react-router-dom'
import { connect } from 'react-redux'
import { query } from 'faunadb'
import queryString from 'query-string'
import ContentHeader from '../../layouts/ContentHeader'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'
import routes, { createRoute } from '../../../config/routes'
import IndexForm from './IndexForm'
import * as ClassResource from '../../../modules/classResource'
import * as IndexResource from '../../../modules/indexResource'

type Props = {
  history: RouterHistory
  match: Match
  location: Location
  classes: typeof query.Class[]
  fetchClasses: typeof ClassResource.actions.fetchList
  createIndex: typeof IndexResource.actions.create
}

function IndexesNew({ history, match, fetchClasses, classes, createIndex, location }: Props) {
  const region = match.params.region
  const databasePath = match.params.dbPath
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('simple')

  const initialValues = {
    name: '',
    unique: false,
    serialized: true,
    source: getDefaultSource(),
    terms: [],
    values: []
  }

  useEffect(() => {
    document.title = `${tx('index.actions.new')} - Fauna`
  }, [])

  useEffect(() => {
    fetchClasses({
      databasePath
    })
  }, [databasePath, fetchClasses])

  function getDefaultSource() {
    return queryString.parse(location.search).classId || ''
  }

  function onCancel() {
    history.goBack()
  }

  function onSubmit(values) {
    setIsLoading(true)

    createIndex({
      values,
      databasePath,
      onComplete: (payload, error) => {
        if (error) {
          setIsLoading(false)
          return
        }

        history.push(
          createRoute(routes.indexes.byId.path, databasePath, region).replace(
            ':indexId',
            values.name
          )
        )
      }
    })
  }

  function renderTabs() {
    return (
      <Tabs position="right">
        <Tab id="simple">{tx('tabs.simple')}</Tab>
        <Tab id="fql">{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader tabs={renderTabs()}>{tx('index.actions.new')}</ContentHeader>

      <div className="padding-y-3">
        <IndexForm
          initialValues={initialValues}
          databasePath={databasePath}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={isLoading}
          classes={classes}
        />
      </div>
    </TabsProvider>
  )
}

export default connect(
  (state, { match }) => ({
    classes: ClassResource.all(state, match.params.dbPath)
  }),
  {
    fetchClasses: ClassResource.actions.fetchList,
    createIndex: IndexResource.actions.create
  }
)(
  withBreadcrumbs(({ location, match }) => {
    return [
      {
        label: tx('index.plural'),
        path: createRoute(routes.indexes.index.path, match.params.dbPath, match.params.region)
      },
      {
        label: tx('index.actions.new'),
        path: location.pathname
      }
    ]
  })(IndexesNew)
)
