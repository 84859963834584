import useSWR from 'swr'
import usePermissions from '../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import { DEFAULT_BACKUP_CONFIGURATION, getBackupConfiguration } from './frontdoor/backup'
import { getBackupScheduleCategory } from '../../components/pages/databases/common'

/**
 * Function that can be used as a custom hook. Accepts region and db path and fetches backup
 *  configuration. If backupRestore isn't enabled, null swr key ensures the data call isn't made.
 *  This function ensures we do not retry on 404, i.e. no backup config exists at the path.
 * @param regionPrefix string
 * @param dbPath  string
 * @param shouldNotFetch boolean
 * @returns {any} output of useSWR
 */
const useBackupConfiguration = (regionPrefix: string, dbPath: string, shouldNotFetch?: boolean) => {
  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.read
  )

  const hasReadPermissions = rolePermission && planPermission
  const cacheKey = ['backupConfiguration', regionPrefix, dbPath, hasReadPermissions]

  return useSWR(
    cacheKey,
    async () => {
      if (shouldNotFetch) return null
      return await getBackupConfiguration(dbPath, regionPrefix, hasReadPermissions)
    },
    {
      onErrorRetry: error => {
        // do not attempt to refetch on 404 when getting backup configuration
        if (error.status === 404) return
      },
      onSuccess: data => {
        let category = getBackupScheduleCategory(data.frequency)
        // category is enum type and it may have a valid 0 value.
        if (category === undefined) {
          // Fauna backend service will validate the cron expression using same regexp as used by
          // frontend. Unsupported cron expression will not be saved by the backend.
          // So this code block where backend returns an unsupported value
          // for any existing database should never be triggered.
          console.error(
            'Unsupported backup category value %s for the backup frequency. Falling back to daily frequency',
            data.frequency
          )
          category = DEFAULT_BACKUP_CONFIGURATION.category
        }
        data.category = category
      }
    }
  )
}

export default useBackupConfiguration
