import axios from 'axios'
import { logAxiosError } from '../../../utils/log-helper'
import { LoginResponse } from '../../auth'

export type Params = {
  code: string
}

export const API_URL = `${process.env.REACT_APP_AUTH_URL}/login/github`

export default async function login(params: Params): Promise<LoginResponse> {
  try {
    const { data } = await axios.post(API_URL, params)
    return {
      data,
      strategy: 'github'
    }
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}
