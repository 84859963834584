import { useCallback } from 'react'
import Icon from '../../shared/Icon'
import Button from '../../shared/Button'
import routes from '../../../config/routes'

const AUTH_URL = String(process.env.REACT_APP_AUTH_URL)

export default function GithubButton() {
  const handleClick = useCallback(() => {
    const siteUrl = window.location.origin
    const oauthUrl =
      `${AUTH_URL}/oauth/start` +
      '?provider_name=github' +
      `&redirect_url=${siteUrl}${routes.auth.oauth.callback.path}`

    window.location.href = oauthUrl
  }, [])

  return (
    <Button component="a" id="cloud-signup-cta-github" color="github" onClick={handleClick}>
      <Icon mode="fab" name="github" /> GitHub
    </Button>
  )
}
