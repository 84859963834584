import React, { useEffect } from 'react'
import queryString from 'query-string'
import { Toaster } from 'react-hot-toast'
import { ContextRouter, useLocation } from 'react-router-dom'
import { makeToast } from '../../shared/toast'
import AuthLayout from './Layout'
import routes from '../../../config/routes'
import SignInForm from './SignInForm'
import { tx } from '../../../modules/translate'

const UNAUTHORIZED_CODE = 401
const UNAVAILABLE_CODE = 500

export default function SignIn(props: ContextRouter): React.ReactNode {
  const { state: { minimal } = {}, search } = useLocation<undefined | { minimal?: boolean }>()

  useEffect(() => {
    const searchParams = queryString.parse(search)
    if (searchParams['invalid-session'] === 'true') {
      makeToast({
        title: tx('auth.errors.invalidSession'),
        message: tx('auth.errors.invalidSessionMessage')
      })
    }
  }, [search])

  function onSuccess() {
    props.history.push(routes.home.path)
  }

  function getErrorMessageByResponseCode(code) {
    switch (code) {
      case UNAUTHORIZED_CODE:
        return tx('auth.errors.invalidUserOrPassword')
      case UNAVAILABLE_CODE:
        return tx('status.errors.unavailable')
      default:
        return tx('auth.errors.default')
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <AuthLayout
        minimal={minimal}
        title={tx('auth.messages.signIn')}
        content={<Content />}
        location={props.location}
        layout="sign-in"
        form={({ showAlert }) => (
          <SignInForm
            minimal={minimal}
            onSuccess={onSuccess}
            onError={error => {
              const errorMessage = getErrorMessageByResponseCode(
                error.response && error.response.status
              )
              showAlert(errorMessage)
            }}
          />
        )}
      />
    </React.Fragment>
  )
}

function Content() {
  return <></>
}
