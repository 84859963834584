import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { query as q } from 'faunadb'

import { AccessProvider } from '../../../types/faunadbTypes'
import { ProviderFormValues } from '../providers/New'
import routes, { createRoute, createRouteGenerator } from '../../../config/routes'
import * as API from './../../../modules/api'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { showAlert } from '../../../modules/alert/actions'
import { ERROR } from '../../../modules/alert/types'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import ProviderForm from './ProviderForm'
import ContentHeader from '../../layouts/ContentHeader'
import { Tab, Tabs, TabsProvider } from '../../shared/Tabs'
import { evalFQLCode } from '../../../modules/fql/eval'
import { stringify } from '../../../modules/fql/stringify'
import { injectQuery } from '../../../components/pages/roles/queryHelper'
import { removeComments } from '../../../utils/dashboard'

type ProviderEditParams = {
  dbPath: string
  providerId: string
  region?: string
}

const initialValues: ProviderFormValues = {
  name: '',
  issuer: '',
  jwksUri: '',
  audience: '',
  roles: []
}

function createRolesArrayForCore(roles: RoleRef[]) {
  return roles.map(role => {
    const predicateWithoutComments = removeComments(role.predicate)
    const memberRoleId = role.role ? role.role : role.value.id

    const fql = predicateWithoutComments.length
      ? evalFQLCode(injectQuery(predicateWithoutComments))
      : null

    // Core does not accept null predicates, so we just send the Role
    // reference is removeComments returns an empty string.
    return fql
      ? {
          role: q.Role(memberRoleId),
          predicate: fql
        }
      : q.Role(memberRoleId)
  })
}

function createRolesForDisplay(roles: RoleRef[] | RoleWithPredicate[]) {
  return roles
    ? roles.map(role => {
        const roleId = role.role ? role.role : role.value.id

        return role.role
          ? {
              role: roleId.value.id,
              predicate: stringify(role.predicate)
            }
          : role
      })
    : []
}

function ProviderEdit() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeProviderTab, setActiveProviderTab] = useState('simple')

  const params: ProviderEditParams = useParams()
  const databasePath: string = params.dbPath || ''
  const providerId: string = params.providerId || ''
  const region = params.region

  const { data: provider } = useSWR<AccessProvider>([databasePath, providerId, 'provider'], () => {
    API.selectDatabase(databasePath)
    return API.getAccessProvider(providerId)
  })

  async function handleFormSubmit(formState: ProviderFormValues) {
    try {
      const updatedProviderData: AccessProvider = {
        name: formState.name,
        issuer: formState.issuer,
        jwks_uri: formState.jwksUri,
        audience: formState.audience,
        roles: createRolesArrayForCore(formState.roles)
      }

      API.selectDatabase(databasePath)
      const updatedProvider: AccessProvider = await API.updateAccessProvider(
        providerId,
        updatedProviderData as AccessProvider
      )
      dispatch(showAlert(tx('provider.actions.updateSuccess')))

      mutate(`${databasePath}/providers`, async providers => {
        if (!providers) return
        const updatedProviderList = await providers.filter(provider => provider.id !== providerId)
        updatedProviderList.push(updatedProvider.ref)

        return updatedProviderList
      })

      history.push(createRoute(routes.providers.index.path, databasePath, region))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), ERROR))
    }
  }

  function handleCancelClick() {
    history.goBack()
  }

  const renderTabs = () => {
    return (
      <Tabs position="right">
        <Tab id="simple">{tx('tabs.simple')}</Tab>
        <Tab id="fql">{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeProviderTab} setActiveTab={setActiveProviderTab}>
      <ContentHeader tabs={renderTabs()}>{tx('provider.editProvider')}</ContentHeader>
      <div className="padding-y-3">
        <ProviderForm
          dbPath={databasePath}
          provider={
            provider && {
              name: provider.name,
              issuer: provider.issuer,
              jwksUri: provider.jwks_uri,
              roles: createRolesForDisplay(provider.roles),
              audience: provider.audience
            }
          }
          initialValues={initialValues}
          onCancel={handleCancelClick}
          onSubmit={handleFormSubmit}
        />
      </div>
    </TabsProvider>
  )
}

export default withBreadcrumbs(({ location, match }) => {
  const { dbPath, region } = match.params
  const createRoute = createRouteGenerator(dbPath, region)
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path)
    },
    {
      label: tx('provider.title'),
      path: createRoute(routes.providers.index.path)
    },
    {
      label: tx('provider.editProvider'),
      path: location.pathname
    }
  ]
})(ProviderEdit)
