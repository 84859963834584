import { useEffect, useState } from 'react'

import Icon from '../../shared/Icon'
import LazyFQLEditor from '../../../components/shared/LazyFQLEditor'
import { extractQueryFunction } from '../../../components/pages/roles/queryHelper'

type ProviderRoleCardProps = {
  roleId: string
  selectedRoles: RoleWithPredicate[]
  setSelectedRoles: Function
  predicate?: string
}

const defaultPredicate = `// Edit the template below to add a predicate condition
// Lambda("token", true)
`

function ProviderRoleCard(props: ProviderRoleCardProps) {
  const [open, setOpen] = useState(false)
  const { predicate, roleId, selectedRoles, setSelectedRoles } = props
  const [displayPredicate, setDisplayPredicate] = useState(defaultPredicate.trim())

  useEffect(() => {
    const predicateInputValue =
      predicate === null ? defaultPredicate.trim() : extractQueryFunction(predicate)

    setDisplayPredicate(predicateInputValue)
  }, [predicate])

  return (
    <div key={roleId} className="ProviderRoleCard card grid">
      <div className="card-body">
        <div className="row">
          <div className="data-row margin-right-2">
            <Icon
              name={open ? 'chevron-down' : 'chevron-right'}
              onClick={() => setOpen(!open)}
              className="ProviderRoleCard__chevron clickable"
            />
            <span>{roleId}</span>
          </div>
          <Icon
            data-testid="provider-form-delete-role"
            name="trash"
            className="muted clickable"
            onClick={() => {
              const updatedRoles = selectedRoles.filter(allowedRole => {
                const allowedRoleId = allowedRole.predicate
                  ? allowedRole.role
                  : allowedRole.value.id
                return allowedRoleId !== roleId
              })
              setSelectedRoles('roles', updatedRoles)
            }}
          />
        </div>
      </div>
      {open && (
        <>
          <LazyFQLEditor
            value={displayPredicate}
            height="224px"
            onChange={async value => {
              const newValue = await value
              const updatedRoles = selectedRoles.map(role => {
                const selectedRoleId = role.role ? role.role : role.value.id

                if (selectedRoleId !== roleId) return role

                return { role: selectedRoleId, predicate: newValue }
              })

              setSelectedRoles('roles', updatedRoles)
            }}
          />
        </>
      )}
    </div>
  )
}

export default ProviderRoleCard
