import React, { useState } from 'react'
import { Plug } from 'react-outlet'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../modules/alert/actions'
import { RequestStatus } from '../../../modules/api'
import { BillingInfo } from '../../../modules/api/auth-service/billingInfo'
import useBillingSources, {
  BillingSource,
  useBillingSource
} from '../../../modules/api/auth-service/billingSources'
import { getErrorMessage } from '../../../utils/error'
import Icon from '../../shared/Icon'
import { ModalConfirm } from '../../shared/ModalConfirm'
import NewPaymentMethodModal, { NewPaymentMethodModalProps } from './NewPaymentMethodModal'
import PaymentLoading from './PaymentLoading'
import { formatExpiry } from './utils'

function maskNumber(paymentMethod: BillingSource) {
  return `**** **** **** ${paymentMethod.last4}`
}

export default function PaymentMethods({
  value,
  onChange,
  onAdd
}: {
  value: BillingInfo['payment_method_id']
  onChange: (value: BillingInfo['payment_method_id']) => void
  onAdd: NewPaymentMethodModalProps['onAdd']
}) {
  const { sources } = useBillingSources()
  const [isNewModalOpen, setIsNewModalOpen] = useState(false)

  const toggleNewModal = () => setIsNewModalOpen(value => !value)

  return (
    <div className="billing__payment-methods">
      {!sources.data && <PaymentLoading />}

      {sources.data && sources.data.length === 0 && (
        <button
          type="button"
          className="empty-payment-method"
          data-testid="addNewPaymentMethod"
          onClick={toggleNewModal}
        >
          <Icon name="plus-circle" className="empty-payment-method__icon" />
          Add a payment method to upgrade your plan
        </button>
      )}

      {sources.data && sources.data.length > 0 && (
        <>
          {sources.data.map(source => (
            <PaymentMethod
              key={source.id}
              paymentMethod={source}
              checked={source.id === value}
              onSelect={onChange}
            />
          ))}

          <button
            type="button"
            data-testid="addNewPaymentMethod"
            onClick={toggleNewModal}
            className="add-payment-method"
          >
            + Add new payment method
          </button>
        </>
      )}

      <Plug outletId="afterPaymentForm">
        <NewPaymentMethodModal isOpen={isNewModalOpen} onClose={toggleNewModal} onAdd={onAdd} />
      </Plug>
    </div>
  )
}

export function PaymentMethod({
  paymentMethod,
  checked,
  onSelect
}: {
  paymentMethod: BillingSource
  checked: boolean
  onSelect: (value: string) => void
}) {
  const dispatch = useDispatch()
  const [isConfirming, setIsConfirming] = useState(false)
  const { remove } = useBillingSource(paymentMethod.id)

  const handleRemoveClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsConfirming(true)
  }

  const handleRemoveConfirmation = async () => {
    try {
      await remove.call()
      setIsConfirming(false)
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
    }
  }

  return (
    <>
      <label className={`payment-method ${checked ? 'selected' : ''}`}>
        <input
          required
          type="radio"
          name="paymentMethod"
          value={paymentMethod.id}
          className="payment-method__radio"
          checked={checked}
          onChange={event => onSelect(event.currentTarget.value)}
        />
        <PaymentMethodInfo paymentMethod={paymentMethod} />
        <div className="payment-method__actions">
          <button
            type="button"
            title="Remove payment method"
            data-testid="removePaymentMethod"
            onClick={handleRemoveClick}
          >
            <Icon name="trash-alt" mode="far" />
          </button>
        </div>
      </label>

      <ModalConfirm
        isLoading={remove.status === RequestStatus.loading}
        loadingText="Removing card..."
        confirmButtonProps={{
          'data-testid': 'confirmRemovingPaymentMethod'
        }}
        modal={{
          isLoading: remove.status === RequestStatus.loading,
          show: isConfirming,
          content: ` Are you sure you want to remove ${maskNumber(paymentMethod)} card?`,
          cancelText: 'Cancel',
          okText: 'Yes, Remove card',
          onCancel: () => setIsConfirming(false),
          onOk: handleRemoveConfirmation
        }}
      />
    </>
  )
}

export function PaymentMethodInfo({ paymentMethod }: { paymentMethod: BillingSource }) {
  return (
    <div className="payment-method__info" data-testid="paymentMethodInfo">
      <Icon mode="far" name="credit-card" className="payment-method__icon" />
      <span className="payment-method__number">{maskNumber(paymentMethod)}</span>
      <span className="payment-method__expiry">
        {formatExpiry(paymentMethod.exp_month, paymentMethod.exp_year)}
      </span>
    </div>
  )
}
