import { Suspense } from 'react'
import * as React from 'react'
import { FQLEditorProps } from './FQLEditor'
const FQLEditor = React.lazy(() => import('./FQLEditor'))

// eslint-disable-next-line react/prop-types
const FQLEditorPlaceholder: React.FC<FQLEditorProps> = ({ height }) => {
  return (
    <div className="code-editor code-editor--placeholder" style={{ height }}>
      <div className="placeholder placeholder--block placeholder-dark placeholder--text placeholder--w-lg"></div>
      <div className="placeholder placeholder--block placeholder-dark placeholder--text placeholder--w-sm"></div>
      <div className="placeholder placeholder--block placeholder-dark placeholder--text placeholder--w-md"></div>
    </div>
  )
}

export default function LazyFQLEditor(props: FQLEditorProps) {
  return (
    <Suspense fallback={<FQLEditorPlaceholder {...props} />}>
      <FQLEditor {...props} />
    </Suspense>
  )
}
