import useSWR from 'swr'

import { useParams } from 'react-router-dom'
import { ContextRouter } from 'react-router-dom'
import * as API from '../../../modules/api'
import Empty from './Empty'
import Show from './Show'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'

const FunctionsIndex = (props: ContextRouter) => {
  const { dbPath, region } = useParams()

  const { data: functions } = useSWR([dbPath, 'functions'], () => {
    API.selectDatabase(dbPath)
    return API.functions()
  })

  if (!functions) {
    return (
      <div className="spinner--bar">
        <div className="spinner--bar__bar"></div>
      </div>
    )
  }

  if (functions && functions.length === 0) {
    return <Empty {...props} />
  }

  return <Show {...props} id={functions[0].ref.id} />
}

export default withBreadcrumbs(({ location }) => {
  return [
    {
      label: 'Functions',
      path: location.pathname
    }
  ]
})(FunctionsIndex)
