import React, { useEffect, useState } from 'react'
import DashboardTooltip from '../functions/DashboardTooltip'
import { Match } from 'react-router-dom'
import * as API from '../../../modules/api'
import { databaseIdFromPath, databaseParentPathFromPath } from '../../../modules/databaseResource'
import events from '../../../modules/events'
import { uploadGQLSchema } from '../../../modules/graphql'
import { tx } from '../../../modules/translate'
import { useFileBrowser } from '../../../utils/filebrowser'
import ContentHeader from '../../layouts/ContentHeader'
import Alert from '../../shared/Alert'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import IconButton from '../../shared/IconButton'
import { ModalConfirm } from '../../shared/ModalConfirm'
import GraphQLEmptyCard from './GraphQLEmptyCard'
import LazyGraphQLPlayground from './LazyGraphQLPlayground'
import getRegion from '../../../modules/auth/session/regions'
import { useGraphQLAccess } from '../../../config/useGraphQLAccess'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'

export const GRAPHQL_SCHEMA_IMPORT_SUCCESS = 'GRAPHQL_SCHEMA_IMPORT_SUCCESS'

type GraphQLIndexProps = {
  match: Match
}

function getGraphQLAccessBanner(status: 'expired' | 'expires', expirationTime: DateTime) {
  return (
    <InfoBox
      body={
        <div>
          {`Your GraphQL Access ${status} at ${expirationTime.toFormat('yyyy-MM-dd HH:mm z')}. `}
          Contact our{' '}
          <a
            href="https://support.fauna.com/hc/en-us/requests/new"
            target="_blank"
            rel="noreferrer"
          >
            Support Team
          </a>{' '}
          to extend your access.
        </div>
      }
      iconName="info-circle"
      type={status === 'expires' ? InfoBoxType.warning : InfoBoxType.danger}
    />
  )
}

const beforeunloadHandler = event => (event.returnValue = true)

function GraphQLIndex({ match }: GraphQLIndexProps) {
  const { hasAccess: hasGraphQLAccess, expirationTime } = useGraphQLAccess()
  const databasePath = match.params.dbPath || ''
  const regionParam = match.params.region
  const region = getRegion(regionParam)
  const rootSecret = region.key
  const databaseSecret = `${rootSecret}:${databasePath}:${'admin'}`
  const [database, setDatabase] = useState()
  const [isProcessing, setIsProcessing] = useState<boolean | 'merge' | 'replace'>(false)
  const [toConfirm, setToConfirm] = useState<null | 'merge' | 'replace'>(null)
  const [alert, setAlert] = useState({ active: false })
  const { getFile } = useFileBrowser({ accept: '.gql, .graphql' })
  const hasGraphQL = database && database.data && database.data.gql
  const isLoading = !!isProcessing

  useEffect(() => {
    const parentDatabasePath = databaseParentPathFromPath(databasePath)
    const databaseId = databaseIdFromPath(databasePath) || ''

    API.selectDatabase(parentDatabasePath)
    API.database(databaseId).then(setDatabase)
  }, [databasePath])

  useEffect(() => {
    document.title = `${tx('graphql.pageTitle')} - Fauna`
  }, [])

  useEffect(() => {
    if (isLoading) {
      window.addEventListener('beforeunload', beforeunloadHandler)
    } else {
      window.removeEventListener('beforeunload', beforeunloadHandler, false)
    }

    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler, false)
    }
  }, [isLoading])

  if (!hasGraphQLAccess()) {
    return getGraphQLAccessBanner('expired', expirationTime)
  }

  function showAlert(message, level, options = {}) {
    setAlert({
      active: true,
      message,
      level,
      details: options.details
    })
  }

  function handleCloseAlert() {
    setAlert({
      ...alert,
      active: false
    })
  }

  function handleImportSchema() {
    getFile().then(schemaFile => {
      setIsProcessing(true)

      uploadGQLSchema({
        schema: schemaFile,
        secret: databaseSecret,
        region: region.regionPrefix
      })
        .then(() => {
          setIsProcessing(false)
          events.emit(GRAPHQL_SCHEMA_IMPORT_SUCCESS)

          if (!hasGraphQL) {
            setDatabase({
              ...database,
              data: {
                gql: {}
              }
            })
          }
        })
        .catch(error => {
          setIsProcessing(false)
          showAlert(tx('graphql.importError'), 'error', {
            details: error.response?.data
          })
        })
    })
  }

  async function handleConfirm() {
    try {
      const schemaFile = await getFile()

      setToConfirm(null)
      setIsProcessing(toConfirm)
      await uploadGQLSchema({
        secret: databaseSecret,
        schema: schemaFile,
        mode: toConfirm,
        region: region.regionPrefix
      })
      updateSchema()
      showAlert(tx('graphql.updateSuccess'), 'success')
    } catch (err) {
      showAlert(tx('graphql.updateError'), 'error', {
        details: err?.response?.data ?? 'Something went wrong'
      })
    } finally {
      setIsProcessing(false)
    }
  }

  // Work Around
  // https://github.com/prisma/graphql-playground/issues/1033
  function updateSchema() {
    const reloadButton = document.querySelector('.graphql-playground *[title="Reload Schema"]')

    if (reloadButton) {
      reloadButton.click()
    }
  }

  function renderContent() {
    if (!database) return tx('loading.simple')
    if (!hasGraphQL)
      return (
        <GraphQLEmptyCard
          onImport={handleImportSchema}
          databaseSecret={databaseSecret}
          isLoading={isLoading}
        />
      )

    return <LazyGraphQLPlayground isLoading={isLoading} databaseSecret={databaseSecret} />
  }

  function renderHeaderActions() {
    if (!hasGraphQL) return null

    return (
      <div className="graphql-header-actions">
        <div className="graphql-header-action-item">
          <IconButton
            icon="arrow-up"
            label={tx('graphql.mergeSchema')}
            onClick={() => setToConfirm('merge')}
            loading={isProcessing === 'merge'}
          />
          <DashboardTooltip
            id="mergeschemaid"
            placement="left"
            contentText={
              <div>
                Combines your new schema with the existing schema, creating collections indexes, and
                functions as needed. Aborts if existing indexes are incompatible with the merged
                schema. Documents are not modified or deleted. Schema elements cannot be removed
                because they are persisted by the existing schema (use Replace Schema instead). See{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.fauna.com/fauna/v4/API/GraphQL/endpoints.html#modes"
                >
                  Modes
                </a>{' '}
                for details.
              </div>
            }
          />
        </div>
        <div className="graphql-header-action-item">
          <IconButton
            icon="sync"
            label={tx('graphql.replaceSchema')}
            loading={isProcessing === 'replace'}
            onClick={() => setToConfirm('replace')}
          />
          <DashboardTooltip
            id="replaceschemaid"
            placement="left"
            contentText={
              <div>
                Replaces your existing schema with the new schema, creating collections, indexes,
                and functions as needed. Aborts if existing indexes are incompatible with the new
                schema. Documents are not modified or deleted. See{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.fauna.com/fauna/v4/API/GraphQL/endpoints.html#modes"
                >
                  Modes
                </a>{' '}
                for details.
              </div>
            }
          />
        </div>
      </div>
    )
  }

  return (
    <>
      {getGraphQLAccessBanner('expires', expirationTime)}
      <ContentHeader actions={renderHeaderActions()} divider={false} className="margin-bottom-4">
        {tx('graphql.pageTitle')}
      </ContentHeader>
      {renderContent()}
      <ModalConfirm
        modal={{
          show: toConfirm != null,
          content: (
            <div className="text-center">
              <b className="danger">Be careful!</b>{' '}
              {toConfirm === 'replace'
                ? 'After your existing schema has been replaced, the underlying data may no longer ' +
                  'work with existing queries. Fields that exist in documents that are not declared ' +
                  'in the schema are not accessible via GraphQL queries, and fields that have new ' +
                  'types may cause existing queries to fail.'
                : 'After merging a new schema with your existing schema, the underlying data may ' +
                  'no longer work with existing queries. Fields that have new types may cause ' +
                  'existing queries to fail.'}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.fauna.com/fauna/v4/api/graphql/endpoints#modes"
              >
                Read more
              </a>
            </div>
          ),
          okText: tx(`graphql.${toConfirm}`),
          cancelText: tx('actions.cancel'),
          onOk: handleConfirm,
          onCancel: () => setToConfirm(null)
        }}
      />
      <Alert {...alert} onClose={handleCloseAlert} />
    </>
  )
}

export default withBreadcrumbs(({ location }) => {
  return [
    {
      label: tx('graphql.crumb'),
      path: location.pathname
    }
  ]
})(GraphQLIndex)
