import { useDispatch } from 'react-redux'
import * as API from '../../../modules/api'
import { hideModal, showModal } from '../../../modules/modal/actions'
import { tx } from '../../../modules/translate'
import IconButton from '../../shared/IconButton'

type DeleteRoleButtonProps = {
  roleId: string
  databasePath: string
  onDelete: () => void
}

export default function DeleteRoleButton({ roleId, onDelete }: DeleteRoleButtonProps) {
  const dispatch = useDispatch()

  function handleDelete() {
    dispatch(
      showModal({
        content: tx('role.confirmDelete').replace(':roleId', roleId),
        onOk: handleDeleteConfirm,
        onCancel: handleCancelDelete,
        showOk: true,
        showCancel: true,
        okText: tx('role.removeRole'),
        cancelText: tx('actions.cancel')
      })
    )
  }

  function handleCancelDelete() {
    dispatch(hideModal())
  }

  function handleDeleteConfirm() {
    API.deleteRole(roleId).then(() => {
      dispatch(hideModal())
      onDelete()
    })
  }

  return (
    <IconButton color="muted" onClick={handleDelete} label={tx('actions.remove')} icon="trash" />
  )
}
