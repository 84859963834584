import { produce } from 'immer'
import { Action, ResourceConfig, MapperConfig } from './types'

export default function createReducer(
  types: Record<string, any>,
  { namespace, name, plural }: ResourceConfig,
  reduceMap: MapperConfig = {}
) {
  return (state: {} = {}, action: Action): {} =>
    produce<{}>(state, draft => {
      switch (action.type) {
        case types.collection.fetch.success:
          if (!draft[action.payload[namespace]]) {
            draft[action.payload[namespace]] = {}
          } // write fields to draft

          if (reduceMap.fetch) {
            reduceMap.fetch(draft, action)
          }

          break

        case types.collection.fetchList.success:
          if (reduceMap.fetchList) {
            reduceMap.fetchList(draft, action)
          } else {
            draft[action.payload[namespace]] = action.payload[plural]
          }

          break

        case types.collection.fetchDocumentsByTerms.success:
          if (reduceMap.fetchDocumentsByTerms) {
            reduceMap.fetchDocumentsByTerms(draft, action)
          } else {
            draft[action.payload[namespace]] = action.payload[plural]
          }

          break
        case types.collection.fetchDocumentByRef.success:
          if (reduceMap.fetchDocumentByRef) {
            reduceMap.fetchDocumentByRef(draft, action)
          } else {
            //action.payload.ref = { value : { id: action.payload.referenceId } }
            draft[action.payload['referenceId']] = action.payload
          }

          break
        case types.resource.create.success:
          if (reduceMap.create) {
            reduceMap.create(draft, action)
          } else {
            const path = action.payload[namespace]
            if (!draft[path]) {
              draft[path] = []
            }
            draft[path].unshift(action.payload[name])
          }

          break

        case types.resource.remove.success:
          if (reduceMap.remove) {
            reduceMap.remove(draft, action)
          } else {
            const path = action.payload[namespace]
            const resource = action.payload[name]

            draft[path] = draft[path].filter(item => item.ref.value.id !== resource.ref.value.id)
          }

          break

        case types.resource.update.success:
          if (reduceMap.update) {
            reduceMap.update(draft, action)
          } else {
            const path = action.payload[namespace]

            if (!path) return

            const updatedResource = action.payload[name]

            draft[path] = draft[path].map(resource => {
              const hasTheSameId = resource.ref.value.id === updatedResource.ref.value.id
              const originalNameWasUpdated =
                action.payload.values &&
                action.payload.values.originalName === resource.ref.value.id
              const shouldUpdateResource = hasTheSameId || originalNameWasUpdated

              return shouldUpdateResource ? updatedResource : resource
            })
          }

          break

        // no default
      }
    })
}
