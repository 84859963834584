import React, { useContext } from 'react'
import { UserIdentityContext } from '../global-state/UserIdentityContext'
import { match, Route, Switch } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import routes from '../config/routes'
import { RouteValidationResult } from '../config/types'
import DownloadsSidebar from './layouts/DownloadsSidebar'
import FullScreenLayout from './layouts/FullScreenLayout'
import NavLayout from './layouts/NavLayout'
import RedirectLayout from './layouts/RedirectLayout'
import Authorize from './pages/addons/authorize'
import Callback from './pages/auth/Callback'
import OAuthCallback from './pages/auth/OAuthCallback'
import SignIn from './pages/auth/SignIn'
import BackupIndex from './pages/backups/Index'
import ClassIndex from './pages/classes/Index'
import ClassNew from './pages/classes/New'
import ClassSettings from './pages/classes/Settings'
import ClassShow from './pages/classes/Show'
import ConsoleIndex from './pages/console/Index'
import DashboardIndex from './pages/dashboard/Index'
import DatabasesEdit from './pages/databases/Edit'
import DatabasesIndex from './pages/databases/Index'
import DatabasesSettings from './pages/databases/Settings'
import InstanceIndex from './pages/documents/Index'
import DownloadsIndex from './pages/downloads/Index'
import FunctionsIndex from './pages/functions/Index'
import FunctionsNew from './pages/functions/New'
import FunctionsShow from './pages/functions/Show'
import GraphQLIndex from './pages/graphql/GraphQLIndex'
import IndexesEdit from './pages/indexes/Edit'
import IndexesIndex from './pages/indexes/Index'
import IndexesNew from './pages/indexes/New'
import IndexesSettings from './pages/indexes/Settings'
import IndexesShow from './pages/indexes/Show'
import AcceptInvite from './pages/invites/AcceptInvite'
import KeysIndex from './pages/keys/Index'
import KeysNew from './pages/keys/New'
import ProvidersEdit from './pages/providers/Edit'
import ProvidersIndex from './pages/providers/Index'
import ProvidersNew from './pages/providers/New'
import RolesEdit from './pages/roles/Edit'
import RolesIndex from './pages/roles/Index'
import RolesNew from './pages/roles/New'
import RolesShow from './pages/roles/Show'
import AccountSettings from './pages/settings/AccountSettings'
import BackupSettings from './pages/settings/BackupSettings'
import QueryLogs from './pages/settings/Logs/QueryLogs'
import Billing from './pages/settings/Billing'
import Invoices from './pages/settings/Invoices'
import Members from './pages/settings/Members'
import PersonalSettings from './pages/settings/PersonalSettings'
import ProfileSecurity from './pages/settings/ProfileSecurity'
import ApiKeys from './pages/settings/AccountKeys'
import SettingsSidebar from './pages/settings/SettingsSidebar'
import WebShell from './pages/webShell/Index'
import DisplayStatusPage from './StatusPage'
import NoV4AccessPage from './NoV4AccessPage'
import { AuthProtect } from './layouts/AuthProtect'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import Loading from './shared/Loading'
import { UserIdentityProvider } from '../global-state/UserIdentityContext'

type RouteWithLayoutProps = {
  component: any
  layout: any
  sidebar?: React.ReactType | null
  validator?: any
  requireSession?: boolean
}

type RouteWithDefinedLayout = {
  component: any
  computedMatch?: match
  sidebar?: React.ReactType | null
  validator?: any
}

type RedirectProps = {
  redirectUrl: string
}

export const Routes = () => {
  const hasGrant = (service: string, userIdentityContext: UserIdentityContext) => {
    return (userIdentityContext.view_controls[service] ?? []).length > 0
  }

  const hasDeny = (service: string, userIdentityContext: UserIdentityContext) => {
    return userIdentityContext.view_controls[service]?.length === 0
  }

  const hasPermission = (service: string, userIdentityContext: UserIdentityContext) => {
    return (
      !hasDeny(service, userIdentityContext) &&
      (hasGrant(service, userIdentityContext) || hasGrant('*', userIdentityContext))
    )
  }

  // Wrap route renders in a validation component. If a validator is passed in,
  //  this will execute the validation function and get the correct render data using
  //  onUpdate
  const RouteValidation = ({ component, validator, ...routeProps }) => {
    const userIdentity = useContext(UserIdentityContext)
    if (userIdentity.id !== '' && !hasPermission('v4', userIdentity)) {
      return <NoV4AccessPage />
    }
    // if no validator function was given, render the child component
    if (!validator) return component
    const {
      validationStatus,
      validationMessage,
      validationFailureReasons,
      validationFailureUserSuggestion
    } = validator(routeProps.match.params, routeProps.location.search)
    return renderStatusCodePage(
      validationStatus,
      validationMessage,
      validationFailureReasons,
      validationFailureUserSuggestion,
      component
    )
  }

  // Based on some status and some string message, render custom 404, 401, etc pages.
  //  Status can be passed into the page components to build a more custom page based
  //  on the URL that was being accessed
  const renderStatusCodePage = (
    validationStatus: RouteValidationResult,
    validationMessage: string,
    validationFailureReasons: string[],
    validationFailureUserSuggestion: string,
    component
  ) => {
    const props = {
      resourceDescription: validationMessage,
      failureReasons: validationFailureReasons,
      userSuggestion: validationFailureUserSuggestion
    }
    switch (validationStatus) {
      case RouteValidationResult.NOT_FOUND:
      case RouteValidationResult.INTERNAL_ERROR:
      case RouteValidationResult.PERMISSION_DENIED:
        return <DisplayStatusPage {...props} />
      case RouteValidationResult.SUCCESS:
        return component
      case RouteValidationResult.PENDING:
        return <Loading />
      default:
        // we can throw an error to be caught by the error boundary if needed
        return component
    }
  }

  const RouteWithLayout = ({
    component,
    sidebar,
    validator,
    layout,
    requireSession = true,
    ...rest
  }: RouteWithLayoutProps) => {
    return (
      <Route
        {...rest}
        render={props => {
          const renderedComponent = React.createElement(
            layout,
            { ...props, sidebar },
            React.createElement(component, props)
          )

          datadogRum.startView(renderedComponent.props.match.path)

          // Some routes do not require a session cookie to be present. AuthProtect will not render
          //  its children without a session, thus we make that check here.
          return requireSession ? (
            // AuthProtect initializes some global state variables that may be used by the
            //  route validators (e.g. user role and plan). Thus AuthProtect must be rendered
            //  outside of the conditional validation render
            <UserIdentityProvider>
              <AuthProtect {...renderedComponent.props}>
                {/*
                if the route in route.ts passed in a validator, then wrap the child component
                  in the RouteValidation component. This ensures any status pages we show fill
                  the entire app window, and don't allow for any further bad navigation. This also
                  ensures we do not display an attempted rendering of the child components and then
                  flash quickly back to an error page.
              */}
                <RouteValidation component={renderedComponent} {...props} validator={validator} />
              </AuthProtect>
            </UserIdentityProvider>
          ) : (
            <RouteValidation component={renderedComponent} {...props} validator={validator} />
          )
        }}
      />
    )
  }

  const RouteWithNavLayout = ({ component, validator, ...routeProps }: RouteWithDefinedLayout) => {
    return (
      <RouteWithLayout
        component={component}
        layout={NavLayout}
        validator={validator}
        requireSession={true}
        {...enforceDbPathAndRegion(routeProps)}
      />
    )
  }

  const RouteWithFullScreenLayout = ({ component, ...rest }: RouteWithDefinedLayout) => {
    return (
      <RouteWithLayout
        component={component}
        layout={FullScreenLayout}
        requireSession={false}
        {...rest}
      />
    )
  }

  const RedirectWithFullScreenLayout = ({ redirectUrl }: RedirectProps) => {
    return (
      <RouteWithLayout
        component={() => {
          window.location.href = redirectUrl
          return null
        }}
        layout={RedirectLayout}
        requireSession={false}
      />
    )
  }

  return (
    <Switch>
      <RouteWithFullScreenLayout {...routes.auth.oauth.callback} component={OAuthCallback} />
      <RouteWithFullScreenLayout {...routes.auth.callback} component={Callback} />
      <RouteWithFullScreenLayout {...routes.invites.accept} component={AcceptInvite} />
      <RouteWithFullScreenLayout {...routes.auth.signIn} component={SignIn} />≥
      <RouteWithFullScreenLayout {...routes.auth.forgotPassword} component={ForgotPassword} />
      <RouteWithFullScreenLayout {...routes.auth.resetPassword} component={ResetPassword} />
      <RouteWithNavLayout {...routes.home} component={DashboardIndex} sidebar={null} />
      <RouteWithNavLayout {...routes.addons.authorize} component={Authorize} sidebar={null} />
      <RouteWithNavLayout {...routes.db.index} component={DatabasesIndex} />
      <RouteWithNavLayout {...routes.db.edit} component={DatabasesEdit} />
      <RouteWithNavLayout {...routes.db.show} component={DatabasesSettings} />
      <RouteWithNavLayout {...routes.db.byId} component={DatabasesIndex} />
      <RouteWithNavLayout {...routes.classes.index} component={ClassIndex} />
      <RouteWithNavLayout {...routes.classes.new} component={ClassNew} />
      <RouteWithNavLayout {...routes.classes.byId} component={ClassShow} />
      <RouteWithNavLayout {...routes.classes.settings} component={ClassSettings} />
      <RouteWithNavLayout {...routes.instances.new} component={InstanceIndex} />
      <RouteWithNavLayout {...routes.instances.edit} component={InstanceIndex} />
      <RouteWithNavLayout {...routes.instances.show} component={InstanceIndex} />
      <RouteWithNavLayout {...routes.classes.byIdWithoutDb} component={ClassShow} />
      <RouteWithNavLayout {...routes.indexes.new} component={IndexesNew} />
      <RouteWithNavLayout {...routes.indexes.index} component={IndexesIndex} />
      <RouteWithNavLayout {...routes.indexes.settings} component={IndexesSettings} />
      <RouteWithNavLayout {...routes.indexes.edit} component={IndexesEdit} />
      <RouteWithNavLayout {...routes.indexes.byId} component={IndexesShow} />
      <RouteWithNavLayout {...routes.indexes.byIdWithoutDb} component={IndexesShow} />
      <RouteWithNavLayout {...routes.webshell.index} component={WebShell} />
      <RouteWithNavLayout {...routes.webshell.legacy} component={ConsoleIndex} />
      <RouteWithNavLayout {...routes.keys.index} component={KeysIndex} />
      <RouteWithNavLayout {...routes.backup.index} component={BackupIndex} />
      <RouteWithNavLayout {...routes.keys.indexWithoutDb} component={KeysIndex} sidebar={null} />
      <RouteWithNavLayout {...routes.keys.new} component={KeysNew} />
      <RouteWithNavLayout {...routes.keys.newWithoutDb} component={KeysNew} sidebar={null} />
      <RouteWithNavLayout {...routes.roles.index} component={RolesIndex} />
      <RouteWithNavLayout {...routes.roles.new} component={RolesNew} />
      <RouteWithNavLayout {...routes.roles.show} component={RolesShow} />
      <RouteWithNavLayout {...routes.roles.edit} component={RolesEdit} />
      <RouteWithNavLayout {...routes.functions.index} component={FunctionsIndex} />
      <RouteWithNavLayout {...routes.functions.new} component={FunctionsNew} />
      <RouteWithNavLayout {...routes.functions.byId} component={FunctionsShow} />
      <RouteWithNavLayout
        {...routes.settings.profile}
        component={PersonalSettings}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout
        {...routes.settings.account}
        component={AccountSettings}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout
        {...routes.settings.members}
        component={Members}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout
        {...routes.settings.billing}
        component={Billing}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout
        {...routes.settings.invoices}
        component={Invoices}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout
        {...routes.settings.security}
        component={ProfileSecurity}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout {...routes.settings.keys} component={ApiKeys} sidebar={SettingsSidebar} />
      <RouteWithNavLayout
        {...routes.settings.backups}
        component={BackupSettings}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout
        {...routes.settings.logs.query_logs}
        component={QueryLogs}
        sidebar={SettingsSidebar}
      />
      <RouteWithNavLayout {...routes.graphql.index} component={GraphQLIndex} />
      <RouteWithNavLayout
        {...routes.downloads.index}
        component={DownloadsIndex}
        sidebar={DownloadsSidebar}
      />
      <RouteWithNavLayout {...routes.providers.index} component={ProvidersIndex} />
      <RouteWithNavLayout {...routes.providers.new} component={ProvidersNew} />
      <RouteWithNavLayout {...routes.providers.edit} component={ProvidersEdit} />
      <RouteWithNavLayout component={() => <h1>404</h1>} />
    </Switch>
  )
}

export const enforceDbPathAndRegion = (routeProps: { computedMatch?: match }) => {
  const { computedMatch } = routeProps
  if (!computedMatch) return routeProps

  const { params } = computedMatch
  const { dbPath, region } = params as any

  if (region && !dbPath) {
    // if you have Region param but not DbPath,
    // it probably means that you had this url open before region groups URL were created.
    return {
      ...routeProps,
      computedMatch: {
        ...computedMatch,
        params: {
          ...params,
          dbPath: region || '',
          region: 'global'
        }
      }
    }
  }

  return {
    ...routeProps,
    computedMatch: {
      ...computedMatch,
      params: {
        ...params,
        dbPath: dbPath || ''
      }
    }
  }
}

export default Routes
