import axios from 'axios'
import { useState } from 'react'
import useSWR, { mutate } from 'swr'
import { RequestStatus } from '..'
import { logAxiosError } from '../../../utils/log-helper'
import { rootSecret } from '../index'

export type BillingSource = {
  id: string
  name: string
  last4: number
  exp_month: number
  exp_year: number
  address_line1: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
}

export type NewBillingSource = {
  name: string
  number: string
  exp_month: number
  exp_year: number
  cvc: string
  address_line1: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
  captcha_token: string
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/account/billing/sources`
export const CACHE = '/settings/billing/sources'

async function getSources() {
  try {
    const res = await axios.get<BillingSource[]>(API_URL, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export async function createSource(data: NewBillingSource, apiUrl = API_URL) {
  try {
    const res = await axios.post<BillingSource>(apiUrl, data, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function removeSource(sourceId: string) {
  try {
    const res = await axios.delete(`${API_URL}/${sourceId}`, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export default function useBillingSources() {
  const [createStatus, setCreateStatus] = useState<RequestStatus>(RequestStatus.idle)

  const sources = useSWR(CACHE, getSources)

  const create = {
    call: async (data: NewBillingSource) => {
      try {
        if (createStatus !== RequestStatus.idle) return

        setCreateStatus(RequestStatus.loading)

        const newSource = await createSource(data)
        sources.mutate((srcs: BillingSource[]) => {
          return [...srcs, newSource]
        })

        setCreateStatus(RequestStatus.idle)
        return newSource
      } catch (error) {
        setCreateStatus(RequestStatus.idle)
        throw error
      }
    },
    status: createStatus
  }

  return {
    sources,
    create
  }
}

export function useBillingSource(sourceId: string) {
  const [removeStatus, setRemoveStatus] = useState<RequestStatus>(RequestStatus.idle)

  const remove = {
    call: async () => {
      try {
        if (removeStatus !== RequestStatus.idle) return

        setRemoveStatus(RequestStatus.loading)
        await removeSource(sourceId)
        mutate(CACHE, (sources: BillingSource[]) =>
          sources.filter(source => source.id !== sourceId)
        )
        setRemoveStatus(RequestStatus.idle)
      } catch (error) {
        setRemoveStatus(RequestStatus.idle)
        throw error
      }
    },
    status: removeStatus
  }

  return {
    remove
  }
}
