import { evalFQLCode } from '../../../modules/fql/eval'
import { injectQuery } from './queryHelper'

export default function roleFormValuesToFQLObject(roleFormValues: any) {
  return {
    ...roleFormValues,
    membership: parseMembership(roleFormValues.membership),
    privileges: parsePrivileges(roleFormValues.privileges)
  }
}

function parseMembership(membership) {
  return membership.map(memberClass => ({
    ...memberClass,
    predicate: parsePredicate(memberClass.predicate)
  }))
}

function parsePrivileges(privileges) {
  return privileges.map(privilege => {
    return {
      ...privilege,
      actions: Object.keys(privilege.actions).reduce((newActions, actionKey) => {
        return {
          ...newActions,
          [actionKey]: parsePredicate(privilege.actions[actionKey])
        }
      }, {})
    }
  })
}

function parsePredicate(predicateValue) {
  if (predicateValue === null) return undefined
  if (typeof predicateValue === 'string') return evalFQLCode(injectQuery(predicateValue))
  return predicateValue
}
