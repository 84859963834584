import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Button from '../Button'
import Icon from '../Icon'
import { Crumb } from './types'

export type BreadcrumbsProps = {
  crumbs?: Crumb[]
  match?: any
  location?: any
}

const Breadcrumbs = ({ crumbs = [] }) => {
  const { dbPath: databasePath, region } = useParams<{ dbPath: string; region: string }>()
  const history = useHistory()

  function mountCrumbs(): Crumb[] {
    const homeCrumb: Crumb = {
      label: 'Home',
      path: '/'
    }
    const databaseCrumbs = mountParentDatabasesCrumbs()

    if (databaseCrumbs) {
      return [homeCrumb, ...databaseCrumbs, ...crumbs]
    }
    return [homeCrumb, ...crumbs]
  }

  function mountParentDatabasesCrumbs(): Crumb[] | null | undefined {
    if (!databasePath) return undefined

    const databases = databasePath.split('/')
    let parentPath = '/db'
    if (region) {
      parentPath = parentPath + '/' + region
    }

    return databases.map(databaseName => {
      const databasePath = `${parentPath}/${databaseName}`
      parentPath = databasePath

      return {
        label: databaseName,
        path: databasePath
      }
    })
  }

  crumbs = mountCrumbs()
  const previousCrumb = crumbs[crumbs.length - 2]

  return (
    <div id="breadcrumbs">
      {previousCrumb && (
        <Button onClick={() => history.push(previousCrumb.path)}>
          <Icon name="arrow-left" />
        </Button>
      )}

      <ul className="crumbs">
        {crumbs.map((crumb: Crumb) => (
          <li key={crumb.label}>
            <Link to={crumb.path} data-hj-suppress>
              {crumb.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Breadcrumbs
