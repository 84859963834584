import * as React from 'react'
import { CSSProperties } from 'react'

type Props = {
  title: string
  icon: React.ReactNode
  action: React.ReactNode | null | undefined
  shadow: boolean
  muted: boolean
  centered: boolean
  description?: React.ReactNode
  growDescription?: boolean
}

const Well = ({
  title,
  icon,
  action,
  shadow,
  muted,
  centered,
  description,
  growDescription = false
}: Props): React.ReactElement<any> => {
  const className = `
    well
    ${muted ? 'well--muted' : ''}
    ${centered ? 'well--centered' : ''}
  `
  const styles: CSSProperties = growDescription ? { flexGrow: 1 } : {}

  return (
    <div className={className}>
      <div className="well__icon">
        {icon}
        {shadow && <div className="shadow" />}
      </div>
      <div className="well__text" style={styles}>
        <h4 className="well__title">{title}</h4>
        <div className="well__description">{description}</div>
        {action}
      </div>
    </div>
  )
}

Well.defaultProps = {
  shadow: false,
  action: <></>,
  muted: false,
  centered: false
}

export const WellTextIcon = ({ children }) => <span className="well__texticon">{children}</span>

export default Well
