import * as Numeric from './numeric'

export const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
export const CONVERSION_NUMBER = 1024

export function toGB(bytes: number): number {
  return bytes / Math.pow(CONVERSION_NUMBER, 3)
}

export function toMB(bytes) {
  return bytes / 1048576
}

export function format(bytes) {
  if (!bytes || bytes <= 0) return `0 ${SIZES[0]}`

  const exponent = Math.floor(Math.log(bytes) / Math.log(CONVERSION_NUMBER))
  // default to bytes (if "bytes" < 1 "exponent" will be -1)
  const bestSize = SIZES[exponent] ?? SIZES[0]
  const decimalCases = exponent <= 0 ? 0 : 2
  const formattedNumber = Numeric.format(transform(bytes, bestSize), {
    decimalCases
  })

  return `${formattedNumber} ${bestSize}`
}

export function transform(bytes: number, size) {
  if (!SIZES.includes(size)) {
    throw new Error(`Invalid size "${size}". Size should be [${SIZES.join(', ')}].`)
  }

  const exponent = SIZES.indexOf(size)

  return bytes / Math.pow(CONVERSION_NUMBER, exponent)
}
