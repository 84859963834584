import * as cookies from './index'

export type OptInCookieContents = { accepted: Boolean }

export const OPT_IN_COOKIE_NAME = 'fauna-opt-in'

export const OPT_IN_COOKIE_CONFIG: cookies.CookieConfig = {
  secure: false,
  expires: 30, // days
  domain: cookies.COOKIE_DOMAIN
}

export const get = (): OptInCookieContents => cookies.get(OPT_IN_COOKIE_NAME)

export const remove = (): void => cookies.remove(OPT_IN_COOKIE_NAME)

export const save = (contents: OptInCookieContents): void =>
  cookies.save(OPT_IN_COOKIE_NAME, contents, OPT_IN_COOKIE_CONFIG)

export const exists = (): boolean => cookies.exists(OPT_IN_COOKIE_NAME)
