import _ from 'lodash'
import moment from 'moment-timezone'
import React, { FC, useState } from 'react'

import { tx } from '../../../modules/translate'

import Dropdown from '../../shared/Dropdown'
import DashboardTooltip from '../functions/DashboardTooltip'
import BetaAnnouncement from '../../shared/BetaAnnouncement'

const makeRanges = () => {
  const VALUE_DATE_FORMAT = 'YYYY-MM'
  const LABEL_DATE_FORMAT = 'MMMM YYYY'
  const today = moment()
  const defaultValue = today.diff(moment().startOf('month'), 'days') + 1

  return {
    defaultValue,
    items: [
      {
        value: defaultValue,
        label: today.format(LABEL_DATE_FORMAT)
      },
      {
        value: today.subtract(1, 'months').format(VALUE_DATE_FORMAT),
        label: today.format(LABEL_DATE_FORMAT)
      },
      {
        value: 7,
        label: tx('period.last7Days')
      },
      {
        value: 30,
        label: tx('period.last30Days')
      }
    ],
    altValue: today.format(VALUE_DATE_FORMAT)
  }
}

export default function UsageBreakdownChart() {
  const { items, defaultValue, altValue } = makeRanges()
  const [rangeValue, setRangeValue] = useState<string | number>(defaultValue)
  const alternativeTooltipText = 'Total cost in the selected period'
  const defaultTooltipText = `${alternativeTooltipText}, up to today`
  const [tooltipText, setTooltipText] = useState(defaultTooltipText)

  return (
    <div className="card card-sm card-flex">
      <div className="card-header">
        <b>Usage breakdown</b>
        <DashboardTooltip id="metric-select-tooltip" contentText={<div>{tooltipText}</div>} />
        <div className="margin-left-auto display-small-block">
          <Dropdown
            size="sm"
            position="right"
            items={items}
            value={rangeValue}
            onChange={e => {
              setRangeValue(e)
              if (e === altValue) {
                setTooltipText(alternativeTooltipText)
              } else {
                setTooltipText(defaultTooltipText)
              }
            }}
          />
        </div>
      </div>
      <div className="card-body">
        <BetaAnnouncement message="Usage breakdown" overflow="wrap" />
      </div>
    </div>
  )
}
