import { Action } from '../../utils/commonTypes'
import * as LoaderTypes from './types'

export function startLoad(id: string): Action {
  return {
    type: LoaderTypes.START_LOAD,
    payload: { id }
  }
}

export function finishLoad(id: string): Action {
  return {
    type: LoaderTypes.FINISH_LOAD,
    payload: { id }
  }
}
