import { RoleResource, Membership, Privilege } from '../../../modules/roles'
import { stringify } from '../../../modules/fql/stringify'
import { extractQueryFunction } from './queryHelper'

export const roleToRoleFormValues = (role: RoleResource) => {
  return {
    ...role,
    membership: parseMembership(role.membership),
    privileges: parsePrivileges(role.privileges)
  }
}

function parseMembership(membership: Membership | null | undefined): any[] {
  if (!membership) return []

  if (Array.isArray(membership)) {
    return membership.map(memberClass => ({
      ...memberClass,
      predicate: parsePredicate(memberClass.predicate)
    }))
  }

  return [
    {
      ...membership,
      predicate: parsePredicate(membership.predicate)
    }
  ]
}

function parsePrivileges(privileges: Privilege[] | Privilege): any[] {
  if (Array.isArray(privileges)) {
    return privileges.map(parsePrivilege)
  }

  return [parsePrivilege(privileges)]
}

function parsePrivilege(privilege: Privilege) {
  return {
    ...privilege,
    actions: Object.keys(privilege.actions).reduce((normalizedActions, actionKey: string) => {
      return {
        ...normalizedActions,
        [actionKey]: parsePredicate(privilege.actions[actionKey])
      }
    }, {})
  }
}

function parsePredicate(code) {
  if (typeof code === 'object') return extractQueryFunction(stringify(code))
  return code
}

export default roleToRoleFormValues
