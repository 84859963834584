import { NavLink, matchPath } from 'react-router-dom'
import routes from '../../config/routes'
import Icon from '../shared/Icon'
import VsIcon from '../../assets/images/vs-icon.svg'

const DownloadsSidebar = () => {
  return (
    <nav className="sidebar">
      <NavLink
        to={routes.downloads.index.path}
        isActive={(_, location) => !!matchPath(location.pathname, routes.downloads.index)}
      >
        <Icon name="laptop" />
        <span className="display-none display-xsmall-block">Fauna Dev</span>
      </NavLink>
      <a
        className="dropdown-item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.fauna.com/fauna/v4/drivers/"
      >
        <Icon name="cog" />
        <span className="display-none display-xsmall-block link-description">Drivers/SDKs</span>
        <Icon className="display-none display-xsmall-block" name="external-link-alt" />
      </a>

      <a
        className="dropdown-item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.fauna.com/fauna/v4/build/tools/vs_code"
      >
        <span>
          <img src={VsIcon} alt="Vs Icon" className="sidebar-icon" />
        </span>
        <span className="display-none display-xsmall-block link-description">
          vs code extension
        </span>
        <Icon className="display-none display-xsmall-block" name="external-link-alt" />
      </a>
    </nav>
  )
}

export default DownloadsSidebar
