import { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { ContextRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { tx } from '../../../modules/translate'
import Icon from '../../shared/Icon'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes, { createRoute } from '../../../config/routes'
import * as DocumentResource from '../../../modules/documentResource'
import LazyJSONDisplay from '../../shared/LazyJSONDisplay'
import { stringify } from '../../../modules/fql/stringify'
import DocumentForm from './DocumentForm'
import { evalFQLCode } from '../../../modules/fql/eval'
import { showAlert, hideAlert } from '../../../modules/alert/actions'
import events from '../../../modules/events'

type MapStateToProps = {
  doc: { data: Record<string, any> }
  documents: Record<string, any>[]
}

type MapDispatchToProps = {
  fetchDocumentByRef: Function
  createDocument: Function
  updateDocument: Function
  removeDocument: Function
  showAlert: typeof showAlert
  hideAlert: typeof hideAlert
}

type Props = ContextRouter & MapStateToProps & MapDispatchToProps

export const DOCUMENT_CREATED = 'DOCUMENT_CREATED'

function ClassesRef({
  doc,
  fetchDocumentByRef,
  createDocument,
  updateDocument,
  removeDocument,
  match,
  history,
  showAlert,
  hideAlert,
  documents
}: Props) {
  const databasePath = String(match.params.dbPath)
  const region = String(match.params.region)
  const classId = String(match.params.classId)
  const referenceId = String(match.params.referenceId)

  const mode = useMemo(() => {
    switch (match.path) {
      case routes.instances.new.path:
        return 'new'
      case routes.instances.edit.path:
        return 'edit'
      case routes.instances.show.path:
        return 'show'
      default:
        return null
    }
  }, [match.path])

  useEffect(() => {
    const { referenceId } = match.params

    if (referenceId) {
      document.title = `${tx('document_.name')} ${String(referenceId)} - Fauna`
    } else {
      document.title = `${tx('document_.actions.new')} - Fauna`
    }
  }, [match.params])

  useEffect(() => {
    if (mode && mode !== 'new' && referenceId) {
      fetchDocumentByRef({ databasePath, classId, referenceId })
    }
  }, [databasePath, classId, referenceId, mode, fetchDocumentByRef])

  function onSubmit(values, redirect) {
    hideAlert()

    const onComplete = (response, error) => {
      if (error) return
      events.emit(DOCUMENT_CREATED)

      const referenceId =
        (response.values && response.values.referenceId) || response.result.ref.value.id

      const redirectPath = createRoute(
        redirect
          ? routes.classes.byId.path.replace(':classId', classId)
          : routes.instances.show.path
              .replace(':classId', classId)
              .replace(':referenceId', referenceId),
        databasePath,
        region
      )

      history.push(redirectPath)
    }

    try {
      if (mode === 'new') {
        createDocument({
          parentPath: databasePath,
          values: {
            data: evalFQLCode(values.json),
            classId
          },
          onComplete
        })
      } else {
        updateDocument({
          parentPath: databasePath,
          values: {
            data: evalFQLCode(values.json),
            classId,
            referenceId
          },
          onComplete
        })
      }
    } catch (error) {
      showAlert(error.message, 'error')
    }
  }

  function onDeleteClick() {
    const onRemoveComplete = (response, error) => {
      if (error) {
        return
      }
      const redirectPath = createRoute(routes.classes.byId.path, databasePath, region).replace(
        ':classId',
        classId
      )

      history.push(redirectPath)
    }

    const shouldDelete = window.confirm(tx('document_.actions.confirmDelete'))

    if (shouldDelete) {
      removeDocument({
        parentPath: databasePath,
        classId,
        referenceId,
        onRemoveComplete
      })
    }
  }

  function onCancel() {
    history.goBack()
  }

  if (!mode) {
    return <></>
  }

  return (
    <>
      <ContentHeader>
        {mode === 'edit' && <>Edit {referenceId}</>}
        {mode === 'new' && <>{tx('document_.actions.new')}</>}
        {mode === 'show' && (
          <>
            {tx('document_.name')} {referenceId}
            {doc && (
              <div className="content-header__links">
                <Link
                  className="btn btn-subtle-link"
                  to={createRoute(routes.instances.edit.path, databasePath, region)
                    .replace(':referenceId', referenceId)
                    .replace(':classId', classId)}
                >
                  <Icon name="pen" />
                  Edit
                </Link>
              </div>
            )}
          </>
        )}
      </ContentHeader>

      <div className="padding-y-4">
        {mode === 'show' && doc && (
          <div className="json-display-container" data-hj-suppress>
            <LazyJSONDisplay value={stringify(doc.data)} />
          </div>
        )}
        {mode === 'show' && !doc && documents && (
          <div className="tip">
            <div className="tip__label">Document not found</div>
            <p className="tip__text">
              The instance <b>{referenceId}</b> was not found. Please check the ID and try again.
            </p>
          </div>
        )}

        {mode === 'edit' && doc && (
          <DocumentForm
            initialValues={{ json: stringify(doc.data) }}
            isEdit={true}
            onCancel={onCancel}
            onSubmit={onSubmit}
            onDeleteClick={onDeleteClick}
          />
        )}
        {mode === 'new' && (
          <DocumentForm
            initialValues={{ json: '{}' }}
            isEdit={false}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  const { referenceId } = props.match.params
  const data = DocumentResource.ref(state, referenceId)
  const doc =
    data && data.documents && data.documents.length && data.documents[0].data
      ? data.documents[0]
      : undefined
  return {
    doc,
    documents: data && data.documents
  }
}

export default connect(mapStateToProps, {
  fetchDocumentByRef: DocumentResource.actions.fetchDocumentByRef,
  createDocument: DocumentResource.actions.create,
  updateDocument: DocumentResource.actions.update,
  removeDocument: DocumentResource.actions.remove,
  showAlert,
  hideAlert
})(
  withBreadcrumbs(({ location, match }, routes) => {
    const { referenceId, dbPath, classId, region } = match.params
    let mode = ''
    switch (match.path) {
      case routes.instances.new.path:
        mode = 'New'
        break
      case routes.instances.edit.path:
        mode = 'Edit'
        break
      default:
        mode = 'Show'
    }

    const label = `${mode} Document`

    let breadcrumbs = [
      {
        label: classId,
        path: createRoute(routes.classes.byId.path.replace(':classId', classId), dbPath, region)
      }
    ]

    if (mode === 'Edit') {
      breadcrumbs = breadcrumbs.concat([
        {
          label: referenceId,
          path: createRoute(routes.instances.show.path, dbPath, region)
            .replace(':classId', classId)
            .replace(':referenceId', referenceId)
        },
        {
          label: label,
          path: location.pathname
        }
      ])
    } else if (mode === 'show') {
      breadcrumbs = breadcrumbs.concat([
        {
          label: referenceId,
          path: location.pathname
        }
      ])
    } else {
      breadcrumbs.push({
        label: label,
        path: location.pathname
      })
    }

    return breadcrumbs
  })(ClassesRef)
)
