import { CreditCardValues } from './types'

export function formatCardNumber(last4Digits: number) {
  return `**** **** **** ${last4Digits}`
}

export function formatExpiry(month: number, year: number) {
  return `${month}/${year.toString().substr(-2)}`
}

export function formatPrice(value: number) {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
}

export function parseCreditCardForm({ expiry, ...values }: CreditCardValues) {
  const [expMonth, expYear] = expiry.split('/')
  const yearPrefix = new Date()
    .getFullYear()
    .toString()
    .substring(0, 2)

  return {
    ...values,
    exp_month: Number(expMonth),
    exp_year: Number(`${yearPrefix}${expYear}`)
  }
}
