import { all } from 'redux-saga/effects'

import { saga as watchDatabases } from './modules/databaseResource'
import { saga as watchClasses } from './modules/classResource'
import { saga as watchIndexes } from './modules/indexResource'
import { saga as watchDocuments } from './modules/documentResource'
import { saga as watchKeys } from './modules/keyResource'
import { watchAlerts } from './modules/alert/saga'
import { watchModals } from './modules/modal/saga'

export default function* mainSaga(): Iterable<any> {
  yield all([
    watchDatabases(),
    watchClasses(),
    watchIndexes(),
    watchAlerts(),
    watchModals(),
    watchDocuments(),
    watchKeys()
  ])
}
