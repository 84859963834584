import React, { useState } from 'react'
import { ContextRouter, useHistory } from 'react-router-dom'
import AuthLayout from './Layout'
import { tx } from '../../../modules/translate'
import * as auth from '../../../modules/auth'
import { useFormik } from 'formik'
import { FormValidationLabel } from '../providers/ProviderForm'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  confirmPasswordInputClassName,
  MIN_PASSWORD_LENGTH,
  passwordInputClassName,
  validatePassword
} from './SignUp'
import { useContext, useRef } from 'react'
import CaptchaBranding from '../../shared/CaptchaBranding'
import Button from '../../shared/Button'
import PasswordInput from './PasswordInput'
import { showAlert } from '../../../modules/alert/actions'
import { logAxiosError } from '../../../utils/log-helper'
import { GlobalStateContext } from '../../../global-state'

type FormFields = {
  password: string
  confirmPassword: string
}

type ForgotPasswordFormErrors = {
  password?: string
  confirmPassword?: string
}

const initialValues = {
  password: '',
  confirmPassword: ''
}

export default function ResetPassword(props: ContextRouter): React.ReactNode {
  const globalState = useContext(GlobalStateContext)
  const email = globalState.passwordResetEmail
  const reset_token = globalState.passwordResetToken
  const ResetPasswordForm = () => {
    const recaptchaRef = useRef(null)
    const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true'

    const [isTokenValid, setIsTokenValid] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
    const history = useHistory()

    React.useEffect(() => {
      if (passwordResetSuccess) {
        setTimeout(() => {
          history.push('/accounts/login')
        }, 2000)
      }
    }, [passwordResetSuccess, history])

    const { handleSubmit, handleChange, setFieldTouched, values, errors, touched } = useFormik({
      initialValues: initialValues,
      initialErrors: {},
      initialTouched: {
        password: false,
        confirmPassword: false
      },
      onSubmit: async (values: FormFields) => {
        setIsSubmitting(true)
        // Generate captcha token
        let captcha_token = undefined
        if (recaptchaEnabled) {
          captcha_token = await recaptchaRef.current.executeAsync()
          recaptchaRef.current.reset()
        }
        // Call API
        auth
          .resetPassword({
            password: values.password,
            captcha_token,
            reset_token,
            email
          })
          .then(() => setPasswordResetSuccess(true))
          .catch(error => {
            if (error.response.status == 400) {
              setIsTokenValid(false)
            } else if (error.response.status == 500) {
              logAxiosError(error)
              showAlert(
                'We encountered a problem when resetting your password. Please submit another request.'
              )
            }
          })
          .finally(() => setIsSubmitting(false))
      },
      validate: (values: FormFields) => {
        const errors: ForgotPasswordFormErrors = {}

        if (!validatePassword(values.password)) {
          errors.password = `Your password must be longer than ${MIN_PASSWORD_LENGTH -
            1} characters`
        }

        if (!values.password) {
          errors.password = 'Please enter a password'
        }

        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Ensure your passwords match'
        }

        return errors
      }
    })

    if (isTokenValid) {
      if (passwordResetSuccess) {
        return (
          <div className="text-left padding-2">
            <h3>Successfully reset password! Redirecting to login...</h3>
          </div>
        )
      } else {
        return (
          <form onSubmit={handleSubmit} className="text-left">
            <div className="form-group">
              <h3>Reset Password</h3>
              <span className="font-size-14 secondary-neutral">Please enter your new password</span>
            </div>
            <PasswordInput
              password={values.password}
              passwordError={errors.password}
              passwordTouched={touched.password}
              passwordLabel={tx('attributes.password')}
              id={'password'}
              className={passwordInputClassName(errors.password, touched.password)}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              validationLabel={
                <FormValidationLabel
                  isInvalid={Boolean(errors.password)}
                  labelText={`${errors.password && touched.password ? errors.password : ''}`}
                />
              }
            />

            <PasswordInput
              password={values.confirmPassword}
              passwordError={errors.confirmPassword}
              passwordTouched={touched.confirmPassword}
              passwordLabel={tx('attributes.confirmPassword')}
              id={'confirmPassword'}
              className={confirmPasswordInputClassName(
                errors.confirmPassword,
                touched.confirmPassword,
                values.password
              )}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              validationLabel={
                <FormValidationLabel
                  isInvalid={Boolean(errors.confirmPassword)}
                  labelText={`${
                    errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ''
                  }`}
                />
              }
            />

            {recaptchaEnabled && (
              <div className="form-group">
                <div className="form-group">
                  <CaptchaBranding />
                </div>
                <ReCAPTCHA
                  size="invisible"
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                />
              </div>
            )}

            <div className="form-group">
              <Button
                id="password-reset-button"
                data-testid="password-reset-button"
                type="submit"
                className="btn-success"
                block
                loading={isSubmitting}
              >
                Reset Password
              </Button>
            </div>
          </form>
        )
      }
    } else {
      return (
        <div className="text-left padding-2">
          <h3>Your password reset token is invalid.</h3>
          <span>
            Please submit a new <a href="/auth/forgot-password">password reset request</a>.
          </span>
        </div>
      )
    }
  }

  return (
    <AuthLayout
      minimal={false}
      title={tx('auth.messages.signIn')}
      content={<Content />}
      location={props.location}
      layout="sign-in"
      form={() => <ResetPasswordForm />}
    />
  )
}

function Content() {
  return <></>
}
