import { Action } from '../../utils/commonTypes'
import * as AlertTypes from './types'

export function showAlert(message: string, level: string = AlertTypes.SUCCESS): Action {
  return {
    type: AlertTypes.SHOW_ALERT,
    payload: {
      level,
      message
    }
  }
}

export function hideAlert(): Action {
  return {
    type: AlertTypes.HIDE_ALERT
  }
}

export function hideAlertDelay(delay: number = AlertTypes.HIDE_ALERT_DELAY_AMOUNT): Action {
  return {
    type: AlertTypes.HIDE_ALERT_DELAY,
    payload: delay
  }
}
