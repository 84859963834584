import { useState } from 'react'
import Icon from '../../shared/Icon'
import LazyFQLEditor from '../../shared/LazyFQLEditor'
import { stringify } from '../../../modules/fql/stringify'

type Props = {
  document_: Record<string, any>
}

function DocumentResultCard({ document_ }: Props) {
  const initialData = stringify(document_)

  const [open, setOpen] = useState(false)

  function toggleCard() {
    setOpen(!open)
  }

  return (
    <div className="card">
      <div className="card-body clickable" onClick={toggleCard}>
        <button className="documentEditToggle">
          <Icon name={`${open ? 'chevron-down' : 'chevron-right'}`} />
        </button>
        <div className="card-col documentPreview">
          <small data-hj-suppress>{stringify(document_)}</small>
        </div>
      </div>

      <div className={`card-collapse ${open ? 'open' : ''}`}>
        <div className="card-collapse-container" data-hj-suppress>
          <LazyFQLEditor value={initialData} />
        </div>
      </div>
    </div>
  )
}

export default DocumentResultCard
