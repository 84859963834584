import * as React from 'react'
import { Plug } from 'react-outlet'
import { Crumb } from './types'
import Breadcrumb from './'
import routes from '../../../config/routes'
import { BreadcrumbsProps } from './Breadcrumb'

type GetCrumbs = (props: BreadcrumbsProps, routes: Record<string, any>) => Crumb[]

const withBreadcrumbs = (getCrumbs?: GetCrumbs) => (
  Component: React.ComponentType<BreadcrumbsProps>
) => {
  function BreadcrumbWrapper(props: BreadcrumbsProps) {
    const crumbs = getCrumbs && getCrumbs(props, routes)

    return (
      <div className="breadcrumb-wrapper">
        <Plug outletId="breadcrumb">
          <Breadcrumb crumbs={crumbs} />
        </Plug>

        <Component {...props} />
      </div>
    )
  }

  return BreadcrumbWrapper
}

export default withBreadcrumbs
