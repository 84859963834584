import _ from 'lodash'
import React, { useState } from 'react'

import { getMetricDisplayName } from '../../../utils/usage'
import { MetricName, CompoundMetricName } from '../../../modules/usage'

import Dropdown from '../../shared/Dropdown'
import RangeDropdown from './RangeDropdown'
import BetaAnnouncement from '../../shared/BetaAnnouncement'
type Props = {
  defaultHistogramType: string
}

const DEFAULT_RANGE = 7
const DEFAULT_HISTOGRAM = CompoundMetricName.ReadWrite

export default function UsageBarChartCard({ defaultHistogramType }: Props) {
  const [range, setRange] = useState<number>(DEFAULT_RANGE)
  const [histogramType, setHistogramType] = useState(defaultHistogramType)

  return (
    <div className="card card-sm card-flex">
      <div className="card-header">
        <Dropdown items={getDropdownItems()} value={histogramType} onChange={setHistogramType} />

        <div className="margin-left-auto display-none display-small-block">
          <RangeDropdown value={range} onChange={setRange} />
        </div>
      </div>

      <div className="card-body center">
        <BetaAnnouncement message="Usage breakdown" overflow="wrap" />
      </div>
    </div>
  )
}

UsageBarChartCard.defaultProps = {
  defaultHistogramType: DEFAULT_HISTOGRAM
}

function getDropdownItems(): { value: string; label: string }[] {
  return [
    {
      value: CompoundMetricName.ReadWrite,
      label: getMetricDisplayName(CompoundMetricName.ReadWrite)
    },
    { value: MetricName.DatabaseCompute, label: getMetricDisplayName(MetricName.DatabaseCompute) },
    { value: CompoundMetricName.Storage, label: getMetricDisplayName(CompoundMetricName.Storage) }
  ]
}
