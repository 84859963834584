import { ReactElement, useState } from 'react'
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import useSWR, { mutate } from 'swr'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { AccessProviderRef } from '../../../types/faunadbTypes'
import * as API from './../../../modules/api'
import Icon from '../../shared/Icon'
import routes, { createRouteGenerator, createRoute } from '../../../config/routes'
import { tx } from '../../../modules/translate'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import SecurityTabs from '../../layouts/SecurityTabs'
import Button from '../../shared/Button'
import { ModalConfirm } from '../../shared/ModalConfirm'
import { showAlert } from '../../../modules/alert/actions'
import { getErrorMessage } from '../../../utils/error'
import { ERROR } from '../../../modules/alert/types'

type ProviderIndexParams = {
  dbPath: string
  region?: string
}

function EmptyProviderList(): ReactElement {
  const params: ProviderIndexParams = useParams()
  const databasePath: string = params.dbPath || ''
  const createRoute = createRouteGenerator(databasePath, params.region)
  const history = useHistory()

  return (
    <div key="provider-empty" className="card">
      <div className="well">
        <div className="well__text">
          <h4 className="well__title">Add new access provider</h4>
          <div className="well__description">
            <div className="container container--small">
              <p>
                {tx('provider.noProviders')} See the{' '}
                <a
                  href="https://docs.fauna.com/fauna/v4/security/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fauna docs
                </a>{' '}
                for more detailed instructions.
              </p>

              <Button
                type="submit"
                color="success"
                onClick={() => {
                  history.push(createRoute(routes.providers.new.path))
                }}
              >
                {tx('provider.newProvider')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProviderList({
  providers,
  dbPath,
  region
}: {
  providers: AccessProviderRef[]
  dbPath: string
  setModalOpen?: Function
  region?: string
}): React.ReactNodeArray | JSX.Element {
  const history = useHistory()
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null)

  if (providers.length === 0) {
    return <EmptyProviderList />
  }

  return (
    <>
      <ModalConfirm
        modal={{
          show: modalOpen,
          content: `Are you sure you want to delete ${selectedProviderId}?`,
          cancelText: 'Cancel',
          okText: 'Delete',
          onCancel: () => setModalOpen(false),
          onOk: async () => {
            try {
              API.selectDatabase(dbPath)
              await API.deleteAccessProvider(selectedProviderId)

              mutate(`${dbPath}/providers`, async providers => {
                return await providers.filter(provider => {
                  return provider.id !== selectedProviderId
                })
              })

              dispatch(showAlert(tx('provider.actions.deleteSuccess')))
            } catch (err) {
              dispatch(showAlert(getErrorMessage(err), ERROR))
            }

            setModalOpen(false)
          }
        }}
      />
      {providers.map(provider => {
        return (
          <div key={provider.id} className="card grid">
            <div className="card-body">
              <div className="row">
                <div className="col">{provider.id}</div>
                <div className="col">
                  <Icon
                    name="cog"
                    className="clickable muted"
                    onClick={() => {
                      history.push(
                        routes.providers.edit.path
                          .replace(':region', region)
                          .replace(':dbPath*', dbPath)
                          .replace(':providerId', provider.id)
                      )
                    }}
                  />
                  <Icon
                    name="trash"
                    data-testid="provider-delete-button"
                    className="margin-left-4 clickable muted"
                    onClick={() => {
                      setModalOpen(true)
                      setSelectedProviderId(provider.id)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

function ProviderIndex(): React.ReactFragment {
  const params: ProviderIndexParams = useParams()
  const databasePath: string = params.dbPath || ''
  const region: string = params.region || ''

  const { data: providers, error, isValidating } = useSWR<AccessProviderRef[]>(
    `${databasePath}/providers`,
    () => {
      API.selectDatabase(databasePath)
      return API.providers()
    }
  )

  return (
    <>
      <Helmet>
        <title>{tx('provider.title')} - Fauna</title>
      </Helmet>

      <SecurityTabs />
      <div className="grid margin-bottom-2">
        <div className="row row--space-between">
          <Link
            to={createRoute(routes.providers.new.path, databasePath, region)}
            className="btn btn-small btn-subtle-link"
          >
            <span className="fas fa-plus-circle" />
            {tx('provider.newProvider')}
          </Link>
        </div>
      </div>

      <section className="margin-bottom-4">
        <div className="card-list">
          <div className="card-list-body">
            {/* Loading state */}
            {isValidating && !providers && (
              <div key="provider-loading" className="card grid">
                <div className="card-body">
                  <div className="row">
                    <div className="col">{tx('loading.noData')}</div>
                  </div>
                </div>
              </div>
            )}

            {/* Display list of providers */}
            {!isValidating && providers && (
              <ProviderList providers={providers} dbPath={databasePath} region={region} />
            )}

            {/* Error state */}
            {error && (
              <div key="provider-loading" className="card grid">
                <div className="card-body">
                  <div className="row">
                    <div className="col">{tx('provider.form.error')}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default withBreadcrumbs(({ match }) => {
  const createRoute = createRouteGenerator(match.params.dbPath, match.params.region)
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path)
    },
    {
      label: tx('provider.title'),
      path: createRoute(routes.providers.index.path)
    }
  ]
})(ProviderIndex)
