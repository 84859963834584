// values examples are ['data.user', 'data.age']
export function mountIndexFields(values: string[]) {
  if (values.length === 0) {
    return undefined
  }

  return values.filter(hasValue).map(value => ({
    field: value.split('.')
  }))
}

function hasValue(field: string[] | string | undefined | null) {
  if (!field || field === '') return false
  return true
}
