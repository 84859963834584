import axios from 'axios'
import useSWR from 'swr'
import { logAxiosError } from '../../../utils/log-helper'
import { rootSecret } from '../index'

export type Invoice = {
  date: string
  amount: number
  id: string
  payment_method: string
  status: string
  url: string
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/account/billing/invoices`
export const CACHE = '/settings/invoices'

async function getInvoices() {
  try {
    const res = await axios.get<Invoice[]>(API_URL, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export default function useInvoices() {
  return useSWR(CACHE, getInvoices)
}
