import { useState, useEffect } from 'react'
import Icon from '../../shared/Icon'
import Button from '../../shared/Button'
import Clipboard from 'react-clipboard.js'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'
import DashboardTooltip from '../functions/DashboardTooltip'
import * as documentResource from '../../../modules/documentResource'
import * as AlertActions from '../../../modules/alert/actions'
import { tx } from '../../../modules/translate'
import routes, { createRoute } from '../../../config/routes'
import LazyFQLEditor from '../../shared/LazyFQLEditor'
import { stringify } from '../../../modules/fql/stringify'
import { evalFQLCode } from '../../../modules/fql/eval'

type Props = {
  document_: Record<string, any>
  browseIndex: Record<string, any>
  create: boolean
  classId: Record<string, any>
  referenceId: string
  fetchListDocuments: Function
  fetchDocument: Function
  createDocument: Function
  removeDocument: Function
  updateDocument: Function
  showAlert: Function
  hideAlertDelay: Function
  databasePath: string
  history: Record<string, any>
}

function DocumentClassCard({
  document_,
  browseIndex,
  create,
  classId,
  referenceId,
  fetchListDocuments,
  fetchDocument,
  createDocument,
  removeDocument,
  updateDocument,
  showAlert,
  hideAlertDelay,
  databasePath,
  history
}: Props) {
  const initialData = document_ ? stringify(document_.data) : '{}'
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(initialData)

  useEffect(() => {
    if (!create) {
      fetchDocument({ databasePath, classId, referenceId })
      if (browseIndex) {
        fetchListDocuments({
          databasePath,
          indexName: browseIndex.name
        })
      }
    }
  }, [browseIndex, classId, create, databasePath, fetchDocument, fetchListDocuments, referenceId])

  useEffect(() => {
    if (create) {
      setOpen(true)
    } else {
      if (document_) {
        setData(stringify(document_.data))
      }
    }
  }, [create, document_])

  function toggleCard() {
    setOpen(!open)
  }

  function handleCreate(event) {
    event.preventDefault()

    const indexName = browseIndex ? browseIndex.name : null

    createDocument({
      databasePath,
      indexName,
      values: {
        classId,
        data: evalFQLCode(data)
      },
      onComplete: (payload, error) => {
        if (!error) {
          const redirect =
            createRoute(routes.classes.byId.path, databasePath, region).replace(
              ':classId',
              classId
            ) + `?action=byReference&referenceId=${payload._resourceName}`
          history.push(redirect)
        }
      }
    })
  }

  function handleCreateCancel(event) {
    event.preventDefault()
    history.push(
      createRoute(routes.classes.byId.path.replace(':classId', classId), databasePath, region)
    )
  }

  function handleDelete(document_) {
    const shouldDelete = window.confirm(tx('document_.actions.confirmDelete'))

    if (shouldDelete) {
      removeDocument({
        parentPath: databasePath,
        document: document_
      })
    }
  }

  function handleSave() {
    setLoading(true)

    updateDocument({
      parentPath: databasePath,
      values: {
        data: evalFQLCode(data),
        ref: document_.ref
      },
      onComplete: (payload, error) => {
        setLoading(false)
        if (!error) {
          fetchDocument({ databasePath, classId, referenceId })
        }
      }
    })
  }

  function handleClipboardClick(event) {
    event.stopPropagation()
    showAlert('Copied to Clipboard')
    hideAlertDelay()
  }

  return (
    <div className="card">
      {create && (
        <div className="card-body">
          <b>Javascript</b>
          <sup>
            <DashboardTooltip
              id="оavascriptid"
              placement="left"
              contentText={
                <div>
                  <div className="margin-bottom-2">You can use Javascript syntax. For example:</div>
                  <div className="code-snippet">
                    {/*TODOLANG: Move tooltip text to language file*/}
                    <div>{'{'}</div>
                    <div>&nbsp;&nbsp;itemName: &quot;apple&quot;,</div>
                    <div>&nbsp;&nbsp;price: 0.42 + 1.50,</div>
                    <div>&nbsp;&nbsp;perishable: true</div>
                    <div>{'}'} </div>
                  </div>
                </div>
              }
            />
          </sup>
        </div>
      )}
      {!create && document_ && (
        <div className="card-body clickable" onClick={toggleCard}>
          <span className="documentEditToggle">
            <Icon name={`${open ? 'chevron-down' : 'chevron-right'}`} />
          </span>
          <div className="card-col documentPreview">
            <small data-hj-suppress>{stringify(document_.data)}</small>
          </div>
          {
            //TODOLANG: also, convert this tooltip to a popover
          }
          <Clipboard
            onClick={handleClipboardClick}
            data-clipboard-text={document_.ref.value.id}
            className="documentRef"
          >
            <div data-tip="Copy Ref to clipboard" className="card-col documentRef">
              <span>{document_.ref.value.id} </span>
              <span title="Copy to clipboard" className="clipboard display-small-inline-block">
                <Icon name="copy" />
              </span>
            </div>
          </Clipboard>

          <ReactTooltip />
        </div>
      )}
      <div className={`card-collapse ${open ? 'open' : ''}`}>
        <div className="card-collapse-container" data-hj-suppress>
          <LazyFQLEditor value={data} onChange={setData} />

          {create && (
            <div className="card-footer text-right">
              <div className="button-group">
                <button onClick={handleCreateCancel} className="btn btn-tertiary">
                  {tx('actions.cancel')}
                </button>

                <Button loading={loading} onClick={handleCreate} color="primary">
                  {tx('actions.create')}
                </Button>
              </div>
            </div>
          )}

          {!create && (
            <div className="card-footer text-right">
              <div className="button-group">
                <button
                  title={tx('document_.actions.delete')}
                  onClick={() => handleDelete(document_)}
                  className="button-group-left"
                >
                  <Icon name="trash-alt" />
                </button>
                <button onClick={toggleCard} className="btn btn-tertiary">
                  {tx('actions.cancel')}
                </button>

                <Button loading={loading} onClick={handleSave} color="primary">
                  {tx('actions.save')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const create = props.referenceId ? false : true
  const instance = documentResource.all(state, props.referenceId)
  const document_ =
    instance && instance.documents && instance.documents.length === 1 ? instance.documents[0] : null
  return {
    create,
    document_
  }
}

export default connect(mapStateToProps, {
  createDocument: documentResource.actions.create,
  removeDocument: documentResource.actions.remove,
  updateDocument: documentResource.actions.update,
  fetchDocument: documentResource.actions.fetchDocumentByRef,
  fetchListDocuments: documentResource.actions.fetchList,
  ...AlertActions
})(withRouter(DocumentClassCard))
