import { useState } from 'react'
import * as React from 'react'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import * as IndexResource from '../../../modules/indexResource'

type Props = {
  name: string
  addField: Function
  values: any[]
  removeField: Function
  getValueByIndex: Function
  onFieldChange: Function
}

const ENTER_KEY = 13
const ENABLE_KEYS = [ENTER_KEY]

export default function TermsValuesField({
  name,
  addField,
  values,
  removeField,
  getValueByIndex,
  onFieldChange
}: Props) {
  const [newValue, setNewValue] = useState('')

  function addValue(overrideValue?: any) {
    addField(overrideValue || newValue)
    setNewValue('')
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (ENABLE_KEYS.includes(event.keyCode)) {
      event.preventDefault()
      onFieldBlur(event)
    }
  }

  function handlePlusClick() {
    addValue()
  }

  function onFieldBlur(event) {
    const value = event.target.value.trim()
    if (value.length) {
      const token = IndexResource.addDataPrefixIfItNeeds(value)
      addValue(token)
    }
  }

  return (
    <>
      {values &&
        values.map((item, index) => (
          <div className="row form-field__line" key={index}>
            <div className="col">
              <input
                type="text"
                value={getValueByIndex(index) || ''}
                onChange={event => onFieldChange(index, event.currentTarget.value)}
              />
            </div>

            <div className="col col--auto">
              <Button
                icon
                className="form-field__button form-field__button--remove"
                onClick={() => {
                  removeField(index)
                }}
              >
                <Icon name="times" />
              </Button>
            </div>
          </div>
        ))}

      <div className="row form-field__line">
        <div className="col">
          <input
            type="text"
            value={newValue}
            onChange={event => setNewValue(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            data-testid={`${name}-field`}
            onBlur={onFieldBlur}
          />
        </div>

        <div className="col col--auto">
          <Button
            icon
            className="form-field__button"
            onClick={newValue.length ? handlePlusClick : null}
            disabled={newValue.length < 1}
          >
            <Icon name="plus-circle" className={newValue.length ? '' : 'disabled'} />
          </Button>
        </div>
      </div>
    </>
  )
}
