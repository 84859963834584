import { useMemo } from 'react'
import { listAccountKeys } from './frontdoor/keys'
import { usePagination } from '../../components/shared/usePagination'

const useAccountKeys = (page_size: number) => {
  const keyPrefix = 'api_keys'
  const cacheKey = useMemo(() => [keyPrefix, page_size], [keyPrefix, page_size])

  const data = usePagination(cacheKey, page_size, (_, page_size, after) => {
    return listAccountKeys({ after, page_size })
  })
  return data
}

export default useAccountKeys
