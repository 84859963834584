import { useState, useEffect } from 'react'
import { Match, RouterHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import routes, { createRoute } from '../../../config/routes'
import ContentHeader from '../../layouts/ContentHeader'
import ClassForm, { FormTabIDs, DefaultFormValues } from './ClassForm'
import { FormValues } from './ClassForm'
import * as ClassResource from '../../../modules/classResource'
import * as IndexResource from '../../../modules/indexResource'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'
import { query } from 'faunadb'
import * as ModalActions from '../../../modules/modal/actions'

type Props = {
  match: Match
  history: RouterHistory
  updateClass: typeof ClassResource.actions.update
  fetchClasses: typeof IndexResource.actions.fetchList
  class_?: typeof query.Class
  removeClass: typeof ClassResource.actions.remove
  showModal: typeof ModalActions.showModal
  hideModal: typeof ModalActions.hideModal
}

function ClassesSettings({
  match,
  history,
  updateClass,
  fetchClasses,
  class_,
  removeClass,
  showModal,
  hideModal
}: Props) {
  const databasePath = match.params.dbPath
  const [isSaving, setIsSaving] = useState(false)
  const [activeTab, setActiveTab] = useState(FormTabIDs.simple)

  useEffect(() => {
    fetchClasses({
      databasePath
    })
  }, [databasePath, fetchClasses])

  function onCancel() {
    history.goBack()
  }

  function onDeleteClick() {
    if (!class_) return

    const onOk = () => {
      removeClass({
        databasePath,
        parentPath: databasePath,
        name: class_.name,
        onRemoveComplete: () => {
          hideModal()
          history.push(createRoute(routes.classes.index.path, databasePath, match.params.region))
        }
      })
    }

    showModal({
      content: tx('class_.messages.deleteConfirm', [class_.name]),
      onOk,
      onCancel: hideModal,
      showOk: true,
      showCancel: true,
      okText: tx('actions.remove'),
      cancelText: tx('actions.cancel')
    })
  }

  function onSubmit(values) {
    if (!class_) return

    const hasUpdatedName = values.name !== class_.name

    setIsSaving(true)
    updateClass({
      values: {
        ...values,
        originalName: class_.name
      },
      databasePath,
      parentPath: databasePath,
      onComplete: () => {
        redirectToClass(values.name, hasUpdatedName)
      }
    })
  }

  function redirectToClass(className: string, hasUpdatedName = false) {
    let classPath = createRoute(
      routes.classes.byId.path,
      databasePath,
      match.params.region
    ).replace(':classId', className)

    if (hasUpdatedName) {
      classPath += '?nameChanged=true'
    }

    history.push(classPath)
  }

  function onFailure() {
    setActiveTab(FormTabIDs.simple)
  }

  function getInitialValues(): FormValues {
    if (!class_) return DefaultFormValues

    return {
      name: class_.name,
      ttl_days: class_.ttl_days,
      history_days: class_.history_days
    }
  }

  function renderTabs() {
    return (
      <Tabs position="right">
        <Tab id={FormTabIDs.simple}>{tx('tabs.simple')}</Tab>
        <Tab id={FormTabIDs.fql}>{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader tabs={renderTabs()}>
        {tx('class_.name')} {tx('info.settings')}
      </ContentHeader>

      <div className="padding-y-3">
        {!class_ ? (
          <>{tx('loading.loading')}</>
        ) : (
          <ClassForm
            loading={isSaving}
            databasePath={databasePath}
            onCancel={onCancel}
            onSubmit={onSubmit}
            onFailure={onFailure}
            initialValues={getInitialValues()}
            onDeleteClick={onDeleteClick}
            isEdit
          />
        )}
      </div>
    </TabsProvider>
  )
}

export default connect(
  (state, { match }) => ({
    class_: ClassResource.find(state, match.params.dbPath, match.params.classId)
  }),
  {
    updateClass: ClassResource.actions.update,
    fetchClasses: ClassResource.actions.fetchList,
    removeClass: ClassResource.actions.remove,
    ...ModalActions
  }
)(
  withBreadcrumbs(({ location, match }) => {
    const { classId, dbPath, region } = match.params

    return [
      {
        label: classId,
        path: createRoute(routes.classes.byId.path, dbPath, region).replace(':classId', classId)
      },
      {
        label: tx('info.settings'),
        path: location.pathname
      }
    ]
  })(ClassesSettings)
)
