import React, { FC, useState, useEffect } from 'react'
import useSwr from 'swr'
import Popover from 'react-awesome-popover'
import { tx } from '../../../modules/translate'
import IconButton from '../../shared/IconButton'
import Icon from '../../shared/Icon'
import { Select } from '../../shared/Select'
import { builtInRoles, isBuiltInRole } from '../../../modules/roles'
import * as API from '../../../modules/api'
import DashboardTooltip from '../functions/DashboardTooltip'

export type AuthDoc = {
  collection: string
  id: string
}

export type RunAsApi = ReturnType<typeof useRunAs>

export type WithDbPath = {
  dbPath: string
}

export type RunAsProps = WithDbPath & Omit<RunAsApi, 'executionDisabled' | 'runAsOption'>

export type RunAsOption = null | { secret: string } | { role: string } | { authDoc: AuthDoc }

type RunAsState = typeof initial

type RoleOption = {
  label: string
  value: string
}

export const RUN_AS_LS_KEY = 'runAsOption'
export const DEFAULT_ROLE = builtInRoles[0].id
export const AUTH_DOCUMENT_OPTION = '@@AUTH_DOCUMENT_OPTION'
export const SECRET_OPTION = '@@SECRET_OPTION'
export const DEFAULT_OPTION = {
  label: builtInRoles[0].name,
  value: builtInRoles[0].id
}

export const makeLsKey = (db: string) => `${db}::${RUN_AS_LS_KEY}`

const isCustomOption = (option: string) => [AUTH_DOCUMENT_OPTION, SECRET_OPTION].includes(option)

const initial = {
  enabled: false,
  role: DEFAULT_OPTION,
  showPopover: false,
  authDoc: {
    collection: '',
    id: ''
  },
  secret: ''
}

export const useRunAs = ({ dbPath }: WithDbPath) => {
  const [runAs, setRunAs] = useState<RunAsState>(() => {
    const data = localStorage.getItem(makeLsKey(dbPath))

    return data
      ? {
          ...JSON.parse(data),
          showPopover: false
        }
      : initial
  })
  const { data: roles } = useSwr<API.RolesDataResponse>([dbPath, 'roles'], () => {
    API.selectDatabase(dbPath)

    return API.roles()
  })

  const isAuthDocFilled = Object.values(runAs.authDoc).every(Boolean)
  const isSecretProvided = Boolean(runAs.secret)
  const lacksFillableData =
    (runAs.role.value === AUTH_DOCUMENT_OPTION && !isAuthDocFilled) ||
    (runAs.role.value === SECRET_OPTION && !isSecretProvided)
  const executionDisabled = runAs.enabled && lacksFillableData
  const runAsOption: RunAsOption = lacksFillableData
    ? null
    : {
        [AUTH_DOCUMENT_OPTION]: {
          authDoc: runAs.authDoc
        },
        [SECRET_OPTION]: {
          secret: runAs.secret
        }
      }[runAs.role.value] ?? { role: runAs.role.value }

  useEffect(() => {
    const exit =
      !roles ||
      isCustomOption(runAs.role.value) ||
      isBuiltInRole(runAs.role.value) ||
      roles.some(({ id }) => id === runAs.role.value)

    if (exit) {
      return
    }

    setRunAsPersistent({
      ...runAs,
      role: DEFAULT_OPTION
    })
  }, [roles])

  const setRunAsPersistent = (value: RunAsState) => {
    setRunAs(value)

    const { showPopover: _, ...toPersist } = value

    localStorage.setItem(makeLsKey(dbPath), JSON.stringify(toPersist))
  }

  const onEnable = () =>
    setRunAsPersistent({
      ...runAs,
      enabled: true
    })

  const onRoleChange = (role: RoleOption) =>
    setRunAsPersistent({
      ...runAs,
      role
    })

  const onPopoverSwitch = () =>
    setRunAs({
      ...runAs,
      showPopover: !runAs.showPopover
    })

  const makeOnAuthDocChange = (field: 'collection' | 'id') => (value: string) =>
    setRunAsPersistent({
      ...runAs,
      authDoc: {
        ...runAs.authDoc,
        [field]: value
      }
    })

  const onSecretChange = (secret: string) =>
    setRunAsPersistent({
      ...runAs,
      secret
    })

  const onReset = () => {
    setRunAs(initial)
    localStorage.removeItem(makeLsKey(dbPath))
  }

  return {
    ...runAs,
    roles,
    runAsOption,
    executionDisabled,
    onEnable,
    onRoleChange,
    onPopoverSwitch,
    onAuthCollectionChange: makeOnAuthDocChange('collection'),
    onAuthIdChange: makeOnAuthDocChange('id'),
    onSecretChange,
    onReset
  }
}

interface RoleSelectProps {
  roles?: API.RolesDataResponse
  value: RoleOption
  onChange: (role: RoleOption) => void
}

const RoleSelect: FC<RoleSelectProps> = ({ roles, value, onChange }) => (
  <Select
    menuPlacement="auto"
    placeholder="Specify a role"
    value={value}
    onChange={onChange}
    data-testid="roleSelect"
    options={[
      {
        label: 'SPECIFY',
        options: [
          {
            label: 'Specify a document',
            value: AUTH_DOCUMENT_OPTION
          },
          {
            label: 'Specify a secret',
            value: SECRET_OPTION
          }
        ]
      },
      {
        label: 'BUILT-IN',
        options: builtInRoles.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      },
      ...(!roles
        ? []
        : [
            {
              label: 'CUSTOM',
              options: roles.map(({ id }) => ({
                label: id,
                value: id
              }))
            }
          ])
    ]}
  />
)

export const RunAs: FC<RunAsProps> = props => {
  const tooltip = (
    <DashboardTooltip
      id="runas"
      placement="left"
      contentText={
        <>
          Verify your{' '}
          <a rel="noreferrer" target="_blank" href="https://docs.fauna.com/fauna/v4/security/abac">
            ABAC
          </a>{' '}
          configuration by running your query with an Admin or Server{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://docs.fauna.com/fauna/v4/security/keys#scoped-keys"
          >
            key
          </a>
          , a specific{' '}
          <a rel="noreferrer" target="_blank" href="https://docs.fauna.com/fauna/v4/security/roles">
            Role
          </a>
          , or a specific identity document. See{' '}
          <a rel="noreferrer" target="_blank" href="https://docs.fauna.com/fauna/v4/security/">
            Fauna Security
          </a>{' '}
          for details.
        </>
      }
    />
  )

  if (!props.enabled) {
    return (
      <>
        <IconButton
          className="run-as-btn"
          icon="user-lock"
          label={tx('query.actions.runAs')}
          data-testid="enable"
          onClick={props.onEnable}
        />
        {tooltip}
      </>
    )
  }

  return (
    <div className="run-as">
      <RoleSelect roles={props.roles} value={props.role} onChange={props.onRoleChange} />
      {props.role.value === AUTH_DOCUMENT_OPTION && (
        <Popover
          arrow={false}
          open={props.showPopover}
          onOpen={props.onPopoverSwitch}
          onClose={props.onPopoverSwitch}
        >
          <div data-testid="popoverTrigger" className="webshell-toolbar-buttons-document">
            {props.authDoc.collection || 'collection'}/{props.authDoc.id || 'id'}
          </div>
          <div data-testid="popoverContent" className="webshell-toolbar-buttons-popover">
            <input
              required
              autoFocus
              placeholder={tx('query.inputPlaceholders.collection')}
              value={props.authDoc.collection}
              onChange={({ target }) => props.onAuthCollectionChange(target.value)}
            />
            <input
              required
              placeholder={tx('query.inputPlaceholders.id')}
              value={props.authDoc.id}
              onChange={({ target }) => props.onAuthIdChange(target.value)}
            />
          </div>
        </Popover>
      )}
      {props.role.value === SECRET_OPTION && (
        <input
          required
          className="secret-input"
          placeholder="Secret"
          value={props.secret}
          onChange={({ target }) => props.onSecretChange(target.value)}
        />
      )}
      <Icon
        className="webshell-toolbar-buttons-cancel"
        name="times"
        data-testid="close"
        onClick={props.onReset}
      />
      {tooltip}
    </div>
  )
}
