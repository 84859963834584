import _, { round } from 'lodash'
import * as Byte from '../utils/byte'

export type BaseUsageData = {
  byte_read_ops: number
  byte_write_ops: number
  compute_ops: number
  versions?: number
  indexes?: number
  storage?: number
  restore_size?: number
  snapshot_size?: number
  acc_snapshot_size: number
  snapshot_count_tcos: number
  snapshot_bytes_taken_tcos: number
  restore_bytes_performed_tcos: number
}

export const emptyUsage: BaseUsageData = {
  byte_read_ops: 0,
  byte_write_ops: 0,
  compute_ops: 0,
  versions: 0,
  indexes: 0,
  storage: 0,
  restore_size: 0,
  snapshot_size: 0,
  acc_snapshot_size: 0,
  snapshot_count_tcos: 0,
  snapshot_bytes_taken_tcos: 0,
  restore_bytes_performed_tcos: 0
}

export type UsageData = BaseUsageData & {
  aggregate?: BaseUsageData
}

export type UsageDataByDB = Record<string, UsageData>

export enum MetricName {
  Read = 'read',
  Write = 'write',
  DatabaseCompute = 'compute',
  DatabaseStorage = 'database_storage',
  BackupsGeneratedSurcharge = 'backups_generated_surcharge',
  BackupsGeneratedCompute = 'backups_generated_compute',
  BackupsRecoveredCompute = 'backups_recovered_compute',
  BackupStorage = 'backup_storage',
  BackupSnapshot = 'backup_snapshot',
  BackupRestore = 'backup_restore'
}

export enum CompoundMetricName {
  Storage = 'storage',
  ReadWrite = 'read_write'
}

const DEFAULT_ROUNDING_PRECISION = 1
const MAXIMUM_ROUNDING_PRECISION = 5
const MINIMUM_STRINGIFIED_DECIMAL = 0.01

const USAGE_REGION_PREFIX_MAP = {
  eu: '/eu-std',
  us: '/us-std',
  global: ''
}

const ROOT_DATABASES = Object.values(USAGE_REGION_PREFIX_MAP)

type metricFormatter = (metric: number, shouldRound?: boolean) => number

// Calculate rounding precision for metric values less than 1. For example 0.0023
//  should be rounded to 0.002.
const calculateRoundingPrecision = (num: number) => {
  if (num >= 1 || num <= 0) return DEFAULT_ROUNDING_PRECISION
  return Math.min(-Math.floor(Math.log10(num)), MAXIMUM_ROUNDING_PRECISION)
}

const formatOpsMetric = (ops: number): number => {
  const k = ops / 1000
  return round(k, calculateRoundingPrecision(k))
}

const formatStorageMetric = (bytes: number): number => {
  const g = Byte.toGB(bytes)
  return round(g, calculateRoundingPrecision(g))
}

const METRICS_BY_NAME: Record<MetricName, keyof BaseUsageData> = {
  [MetricName.Read]: 'byte_read_ops',
  [MetricName.Write]: 'byte_write_ops',
  [MetricName.DatabaseCompute]: 'compute_ops',
  [MetricName.DatabaseStorage]: 'storage',
  [MetricName.BackupsGeneratedSurcharge]: 'snapshot_count_tcos',
  [MetricName.BackupsGeneratedCompute]: 'snapshot_bytes_taken_tcos',
  [MetricName.BackupsRecoveredCompute]: 'restore_bytes_performed_tcos',
  [MetricName.BackupStorage]: 'acc_snapshot_size',
  [MetricName.BackupSnapshot]: 'snapshot_tcos',
  [MetricName.BackupRestore]: 'restore_tcos'
}

const FORMAT_METRICS_BY_NAME: Record<MetricName | CompoundMetricName, metricFormatter> = {
  [MetricName.Read]: formatOpsMetric,
  [MetricName.Write]: formatOpsMetric,
  [MetricName.DatabaseCompute]: formatOpsMetric,
  [MetricName.DatabaseStorage]: formatStorageMetric,
  [MetricName.BackupsGeneratedCompute]: formatOpsMetric,
  [MetricName.BackupsGeneratedSurcharge]: formatOpsMetric,
  [MetricName.BackupsRecoveredCompute]: formatOpsMetric,
  [MetricName.BackupStorage]: formatStorageMetric,
  [CompoundMetricName.Storage]: formatStorageMetric,
  [CompoundMetricName.ReadWrite]: formatOpsMetric
}

function usageKeyIsNotRootOrChild(key: string) {
  // Ignore entires that represent the root DB or child DBs
  const split = key.split('/').filter(k => k !== '')
  // The classic region will be of the form "db-name"
  const cond1 = split.length === 1 && !ROOT_DATABASES.includes(key)
  // The eu or us region will be of the form "db-name/eu-std"
  const cond2 = split.length === 2 && (split[1] === 'eu-std' || split[1] === 'us-std')
  return cond1 || cond2
}

function normalizeUsage(usage: Partial<UsageData>): UsageData {
  const normalizedUsage = {
    ...emptyUsage,
    ...usage
  }

  if (usage.aggregate) {
    return {
      ...normalizedUsage,
      aggregate: normalizeUsage(usage.aggregate)
    }
  } else return normalizedUsage
}

function selectDatabaseStorage(usage: UsageData): number {
  const key = METRICS_BY_NAME[MetricName.DatabaseStorage]
  if (usage[key]) return usage[key]
  const { versions, indexes } = usage
  return versions + indexes
}

function selectBackupsGeneratedCompute(usage: UsageData): number {
  const generated = METRICS_BY_NAME[MetricName.BackupsGeneratedCompute]
  const generatedSurcharge = METRICS_BY_NAME[MetricName.BackupsGeneratedSurcharge]
  const { [generated]: backupsGenerated, [generatedSurcharge]: backupsGeneratedSurcharge } = usage
  return backupsGenerated + backupsGeneratedSurcharge
}

export function selectMetric(
  usage: UsageData,
  metricName: MetricName | CompoundMetricName
): number {
  const normalizedUsage = normalizeUsage(usage)
  switch (metricName) {
    case MetricName.DatabaseStorage:
      return selectDatabaseStorage(normalizedUsage)
    case MetricName.BackupsGeneratedCompute:
      return selectBackupsGeneratedCompute(normalizedUsage)
    case CompoundMetricName.Storage:
      return (
        selectMetric(usage, MetricName.BackupStorage) +
        selectMetric(usage, MetricName.DatabaseStorage)
      )
    default:
      const metric = METRICS_BY_NAME[metricName]
      return normalizedUsage[metric]
  }
}

export function formatMetric({
  metric,
  metricName,
  stringify = false
}: {
  metric: number
  metricName: MetricName | CompoundMetricName
  stringify?: boolean
}) {
  const formatted = FORMAT_METRICS_BY_NAME[metricName](metric)
  return stringify ? stringifyMetric(formatted) : formatted
}

export function selectAndFormatMetric({
  usage,
  metricName,
  stringify = false
}: {
  usage: UsageData
  metricName: MetricName | CompoundMetricName
  stringify?: boolean
}) {
  const formatted = FORMAT_METRICS_BY_NAME[metricName](selectMetric(usage, metricName))
  return stringify ? stringifyMetric(formatted) : formatted
}

export function stringifyMetric(metric: number) {
  if (metric === 0) return '--'
  if (metric < MINIMUM_STRINGIFIED_DECIMAL) return `~${0}`
  return metric
}

export function selectMetricFieldName(metricName: MetricName | CompoundMetricName) {
  return METRICS_BY_NAME[metricName]
}

export function addUsageData(a: UsageData, b: UsageData): UsageData {
  return {
    byte_read_ops: a.byte_read_ops + b.byte_read_ops,
    byte_write_ops: a.byte_write_ops + b.byte_write_ops,
    compute_ops: a.compute_ops + b.compute_ops,
    storage: selectDatabaseStorage(a) + selectDatabaseStorage(b),
    restore_size: a.restore_size + b.restore_size,
    snapshot_size: a.snapshot_size + b.snapshot_size,
    acc_snapshot_size: a.acc_snapshot_size + b.acc_snapshot_size,
    snapshot_count_tcos: a.snapshot_count_tcos + b.snapshot_count_tcos,
    snapshot_bytes_taken_tcos: a.snapshot_bytes_taken_tcos + b.snapshot_bytes_taken_tcos,
    restore_bytes_performed_tcos: a.restore_bytes_performed_tcos + b.restore_bytes_performed_tcos
  }
}

export function subUsageData(a: UsageData, b: UsageData): UsageData {
  return {
    byte_read_ops: a.byte_read_ops - b.byte_read_ops,
    byte_write_ops: a.byte_write_ops - b.byte_write_ops,
    compute_ops: a.compute_ops - b.compute_ops,
    storage: selectDatabaseStorage(a) - selectDatabaseStorage(b),
    restore_size: a.restore_size - b.restore_size,
    snapshot_size: a.snapshot_size - b.snapshot_size,
    acc_snapshot_size: a.acc_snapshot_size - b.acc_snapshot_size,
    snapshot_count_tcos: a.snapshot_count_tcos - b.snapshot_count_tcos,
    snapshot_bytes_taken_tcos: a.snapshot_bytes_taken_tcos - b.snapshot_bytes_taken_tcos,
    restore_bytes_performed_tcos: a.restore_bytes_performed_tcos - b.restore_bytes_performed_tcos
  }
}

export function getUsageKey(dbPath: string, regionPrefix: string): string {
  if (dbPath === undefined) return null
  else return dbPath + USAGE_REGION_PREFIX_MAP[regionPrefix]
}

export function totalMetric(
  usageByDb: UsageDataByDB,
  metricName: MetricName | CompoundMetricName
): number {
  return Object.values(usageByDb)
    .map(usage => selectMetric(usage, metricName))
    .reduce((acc, item) => acc + item, 0)
}

export function totalMetrics(usageByDb: UsageDataByDB): UsageData {
  return Object.values(usageByDb).reduce((acc, item) => addUsageData(acc, item), emptyUsage)
}

export function calculateAccountUsage(
  usageByDb: UsageDataByDB
): { accountUsage: UsageData; rootUsage: UsageData } {
  const accountUsageByDb = Object.fromEntries(
    Object.entries(usageByDb).filter(([key, _]) =>
      Object.values(USAGE_REGION_PREFIX_MAP).includes(key)
    )
  )
  const nonRootUsageByDb: UsageDataByDB = Object.fromEntries(
    Object.entries(usageByDb).filter(([key, _]) => usageKeyIsNotRootOrChild(key))
  )
  const accountUsage = totalMetrics(accountUsageByDb)
  const nonRootUsage = totalMetrics(nonRootUsageByDb)
  // Root usage can be thought of as read/write/compute used by the dashboard and webshell.
  //  It should not include backup and restore usage.
  const rootUsage = normalizeUsage(
    omitBackupAndRestoreUsage(subUsageData(accountUsage, nonRootUsage))
  )
  return { accountUsage, rootUsage }
}

export function getUsageByDB(
  usageByDb: UsageDataByDB,
  dbPath: string,
  regionPrefix: string
): { dbKey: string; usage: UsageData } {
  const dbKey = getUsageKey(dbPath, regionPrefix)
  const hasUsage = !!usageByDb && !!usageByDb[dbKey]
  const usage = hasUsage ? normalizeUsage(usageByDb[dbKey]) : emptyUsage
  return { dbKey, usage }
}

export function omitBackupAndRestoreUsage(usage: UsageData): Partial<UsageData> {
  const legacyFields = ['restore_size', 'snapshot_size', 'acc_snapshot_size']
  const newFields = ['snapshot_tcos', 'restore_tcos']
  const currentFields = [
    METRICS_BY_NAME[MetricName.BackupsGeneratedCompute],
    METRICS_BY_NAME[MetricName.BackupsGeneratedSurcharge],
    METRICS_BY_NAME[MetricName.BackupsRecoveredCompute],
    METRICS_BY_NAME[MetricName.BackupStorage]
  ]
  return _.omit(usage, [...legacyFields, ...newFields, ...currentFields])
}
