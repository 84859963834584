import { useState, useMemo, useRef } from 'react'
import Button from '../../shared/Button'
import Modal, { ModalProps } from '../../shared/Modal'
import ModalHeader from '../../shared/ModalHeader'
import ModalBody from '../../shared/ModalBody'
import 'react-phone-number-input/style.css'
import InputMask from 'react-input-mask'
import useBillingSources from '../../../modules/api/auth-service/billingSources'
import { useFormik } from 'formik'
import { getErrorMessage } from '../../../utils/error'
import { tx } from '../../../modules/translate'
import { RequestStatus } from '../../../modules/api'
import csc from 'country-state-city'
import { BillingSource } from '../../../modules/api/auth-service/billingSources'
import CaptchaBranding from '../../shared/CaptchaBranding'
import ReCAPTCHA from 'react-google-recaptcha'

export type NewPaymentMethodModalProps = Omit<ModalProps, 'children'> & {
  onAdd: (source: BillingSource) => void
}

const NewPaymentMethodModal = ({ onClose, isOpen, onAdd }: NewPaymentMethodModalProps) => {
  const [error, setError] = useState<unknown>()
  const recaptchaRef = useRef(null)
  const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true'
  const { create } = useBillingSources()
  const { resetForm, getFieldProps, handleSubmit, values } = useFormik({
    initialValues: {
      name: '',
      number: '',
      expiry: '',
      cvc: '',
      address_line1: '',
      address_city: '',
      address_country: '',
      address_state: '',
      address_zip: ''
    },
    onSubmit: async ({ expiry, ...data }) => {
      const [expMonth, expYear] = expiry.split('/')
      const yearPrefix = new Date()
        .getFullYear()
        .toString()
        .substring(0, 2)

      try {
        setError(undefined)
        // generate captcha token
        let captcha_token = undefined
        if (recaptchaEnabled) {
          captcha_token = await recaptchaRef.current.executeAsync()
          recaptchaRef.current.reset()
        }

        const source = await create.call({
          ...data,
          cvc: data.cvc,
          exp_month: Number(expMonth),
          exp_year: Number(`${yearPrefix}${expYear}`),
          captcha_token
        })
        onAdd(source)
        onClose()
      } catch (error) {
        setError(getErrorMessage(error))
      }
    }
  })
  const countries = useMemo(() => csc.getAllCountries(), [])

  const states = useMemo(() => {
    const country = countries.find(country => values.address_country === country.name)

    if (!country) return []

    return csc.getStatesOfCountry(country.id)
  }, [countries, values.address_country])

  const handleClose = () => {
    onClose()
    resetForm()
    setError(undefined)
  }

  const cardNumberRegex =
    '^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$'

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader onClose={handleClose}>New payment method</ModalHeader>
        <ModalBody>
          {error && <div className="inline-alert">{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name" className="required">
                Cardholder name
              </label>
              <input autoFocus required {...getFieldProps('name')} />
            </div>

            <div className="form-group grid">
              <div className="row">
                <div className="col width-50">
                  <label htmlFor="number" className="required">
                    Credit card number
                  </label>
                  <InputMask
                    required
                    type="tel"
                    inputmode="numeric"
                    pattern={cardNumberRegex}
                    {...getFieldProps('number')}
                  />
                </div>

                <div className="col width-25">
                  <label htmlFor="expiry" className="required">
                    MM/YY
                  </label>
                  <InputMask
                    required
                    mask="99/99"
                    type="tel"
                    pattern="\d\d/\d\d"
                    {...getFieldProps('expiry')}
                  />
                </div>

                <div className="col width-25">
                  <label htmlFor="cvc" className="required">
                    CVC
                  </label>
                  <input
                    required
                    pattern="\d{3,4}"
                    maxLength="4"
                    inputMode="numeric"
                    type="text"
                    {...getFieldProps('cvc')}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="address_line1" className="required">
                Billing address
              </label>
              <textarea required className="form-text" {...getFieldProps('address_line1')} />
            </div>

            <div className="form-group grid">
              <div className="row">
                <div className="col">
                  <label htmlFor="address_country" className="required">
                    Country
                  </label>
                  <select required id="address_country" {...getFieldProps('address_country')}>
                    <option>Select country</option>
                    {countries.map(country => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col">
                  <label htmlFor="address_state" className="required">
                    State
                  </label>
                  <select required id="address_state" {...getFieldProps('address_state')}>
                    <option />
                    {states.map(state => (
                      <option key={state.id} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group grid">
              <div className="row">
                <div className="col">
                  <label htmlFor="address_city" className="required">
                    City
                  </label>
                  <input
                    type="text"
                    required
                    id="address_city"
                    {...getFieldProps('address_city')}
                  />
                </div>

                <div className="col">
                  <label htmlFor="address_zip" className="required">
                    Zip code
                  </label>
                  <input required {...getFieldProps('address_zip')} />
                </div>
              </div>
            </div>

            <div className="form-group grid ">
              <CaptchaBranding />
            </div>

            <div className="form-group" color="primary">
              <div className="button-group button-group--right">
                <Button color="secondary" type="button" onClick={handleClose}>
                  {tx('actions.cancel')}
                </Button>

                <Button
                  loading={create.status === RequestStatus.loading}
                  color="success"
                  type="submit"
                  data-testid="submitPaymentMethod"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      {recaptchaEnabled && (
        <div className="form-group">
          <ReCAPTCHA
            size="invisible"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
          />
        </div>
      )}
    </>
  )
}

export default NewPaymentMethodModal
