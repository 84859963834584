import * as api from './api'

import { createTypes, createActions, createReducer, createSaga } from './resources'

const resourceConfig = {
  name: 'document',
  plural: 'documents',
  namespace: 'indexName',
  label: 'document'
}

export const types = createTypes(resourceConfig)

export const actions = createActions(types)

export const reducer = createReducer(types, resourceConfig)

export const saga = createSaga(
  types,
  actions,
  {
    fetchList: payload => api.all(payload.indexName),
    fetchDocumentsByTerms: payload => api.documentsByTerms(payload.indexName, payload.terms),
    fetchDocumentByRef: payload => api.documentByRef(payload.classId, payload.referenceId),
    create: payload => api.createDocument(payload.classId, payload.data),
    remove: payload => api.removeDocument(payload.classId, payload.referenceId),
    update: payload => api.updateDocument(payload.classId, payload.referenceId, payload.data)
  },
  resourceConfig
)

export const all = (state: Record<string, any>, indexName: string) => {
  return state.documents[indexName]
}
export const ref = (state: Record<string, any>, referenceId: string) => {
  return state.documents[referenceId]
}
