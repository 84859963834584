import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { query as q } from 'faunadb'

import routes, { createRoute, createRouteGenerator } from '../../../config/routes'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { showAlert } from '../../../modules/alert/actions'
import { ERROR } from '../../../modules/alert/types'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import ProviderForm from './ProviderForm'
import ContentHeader from '../../layouts/ContentHeader'
import { Tab, Tabs, TabsProvider } from '../../shared/Tabs'
import { RoleRef, AccessProvider } from '../../../types/faunadbTypes'
import * as API from './../../../modules/api'
import { evalFQLCode } from '../../../modules/fql/eval'
import { injectQuery } from '../../../components/pages/roles/queryHelper'
import { removeComments } from '../../../utils/dashboard'

type ProviderNewParams = {
  dbPath: string
  region?: string
}

export type ProviderFormValues = {
  name: string
  issuer: string
  jwksUri: string
  audience: string
  roles?: RoleRef[]
}

const initialValues: ProviderFormValues = {
  name: '',
  issuer: '',
  jwksUri: '',
  audience: '',
  roles: []
}

function ProviderNew() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params: ProviderNewParams = useParams()
  const databasePath: string = params.dbPath || ''
  const region = params.region

  async function handleFormSubmit(formState: ProviderFormValues) {
    try {
      const providerParams: AccessProvider = {
        name: formState.name,
        issuer: formState.issuer,
        jwks_uri: formState.jwksUri,
        audience: formState.audience,
        roles: formState.roles.map(role => {
          const predicateWithoutComments = removeComments(role.predicate)
          const membershipRoleId = role.role ? role.role : role.value.id

          const fql = predicateWithoutComments.trim().length
            ? evalFQLCode(injectQuery(predicateWithoutComments))
            : null

          // Core does not accept null predicates, so we just send the Role
          // reference if removeComments returns an empty string.
          return fql
            ? {
                role: q.Role(membershipRoleId),
                predicate: fql
              }
            : q.Role(membershipRoleId)
        })
      }

      API.selectDatabase(databasePath)
      await API.createAccessProvider(providerParams as AccessProvider)

      dispatch(showAlert(tx('provider.actions.createSuccess')))

      history.push(createRoute(routes.providers.index.path, databasePath, region))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), ERROR))
    }
  }

  function handleCancelClick() {
    history.goBack()
  }

  const [activeProviderTab, setActiveProviderTab] = useState('simple')

  const renderTabs = () => {
    return (
      <Tabs position="right">
        <Tab id="simple">{tx('tabs.simple')}</Tab>
        <Tab id="fql">{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeProviderTab} setActiveTab={setActiveProviderTab}>
      <ContentHeader tabs={renderTabs()}>{tx('provider.newProvider')}</ContentHeader>
      <div className="padding-y-3">
        <ProviderForm
          dbPath={databasePath}
          initialValues={initialValues}
          onCancel={handleCancelClick}
          onSubmit={handleFormSubmit}
        />
      </div>
    </TabsProvider>
  )
}

export default withBreadcrumbs(({ location, match }) => {
  const { dbPath, region } = match.params
  const createRoute = createRouteGenerator(dbPath, region)
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path)
    },
    {
      label: tx('provider.title'),
      path: createRoute(routes.providers.index.path)
    },
    {
      label: tx('provider.newProvider'),
      path: location.pathname
    }
  ]
})(ProviderNew)
