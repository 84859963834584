import axios from 'axios'
import { useState } from 'react'
import useSWR, { mutate } from 'swr'
import { RequestStatus, rootSecret } from '..'
import { logAxiosError } from '../../../utils/log-helper'

export type NewInvite = {
  email: string
  name: string
  role: string
}

export type Invite = {
  id: string
  email: string
  name: string
  role: string
  send_by: string
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/account/members/invites`
export const CACHE = '/settings/invites'

async function sendInvite(data: NewInvite) {
  try {
    const res = await axios.post<Invite>(API_URL, data, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function getInvites() {
  try {
    const res = await axios.get<Invite[]>(API_URL, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function resendInvite(inviteId: string) {
  try {
    const res = await axios.put<Invite>(`${API_URL}/${inviteId}`, null, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function removeInvite(inviteId: string) {
  try {
    const res = await axios.delete<Invite>(`${API_URL}/${inviteId}`, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function getInviteByToken(token: string) {
  try {
    const res = await axios.get<Invite>(`${API_URL}/${token}`)
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export default function useInvites() {
  const [invitingStatus, setInvitingStatus] = useState<RequestStatus>(RequestStatus.idle)
  const invites = useSWR(CACHE, getInvites)

  const send = {
    call: async (data: NewInvite) => {
      if (invitingStatus !== RequestStatus.idle) return

      try {
        setInvitingStatus(RequestStatus.loading)
        await invites.mutate(async cache => {
          const newInvite = await sendInvite(data)
          return cache.concat(newInvite)
        })
        setInvitingStatus(RequestStatus.idle)
      } catch (error) {
        setInvitingStatus(RequestStatus.idle)
        throw error
      }
    },
    status: invitingStatus
  }

  return {
    send,
    invites
  }
}

export function useInvite(inviteId: string) {
  const [resendingStatus, setResendingStatus] = useState<RequestStatus>(RequestStatus.idle)
  const [removingStatus, setRemovingStatus] = useState<RequestStatus>(RequestStatus.idle)

  const resend = {
    call: async () => {
      if (resendingStatus !== RequestStatus.idle) return

      setResendingStatus(RequestStatus.loading)
      await resendInvite(inviteId)
      setResendingStatus(RequestStatus.idle)
    },
    status: resendingStatus
  }

  const remove = {
    call: async () => {
      if (removingStatus !== RequestStatus.idle) return

      setRemovingStatus(RequestStatus.loading)

      await mutate(CACHE, async (data: Invite[]) => {
        await removeInvite(inviteId)
        data.filter(invite => invite.id !== inviteId)
      })

      setRemovingStatus(RequestStatus.idle)
    },
    status: removingStatus
  }

  return {
    resend,
    remove
  }
}

export function useInviteByToken(token: string) {
  const invite = useSWR(`${CACHE}/${token}`, () => getInviteByToken(token), {
    shouldRetryOnError: false,
    revalidateOnFocus: false
  })

  return {
    invite
  }
}
