import { useMemo } from 'react'
import { usePagination } from '../../components/shared/usePagination'
import { listQueryLogs, QUERYLOG_STATES_ARRAY } from './frontdoor/queryLogs'

const useQuerylogs = (page_size: number) => {
  const keyPrefix = 'querylogs'
  const cacheKey = useMemo(() => [keyPrefix, page_size], [keyPrefix, page_size])

  const data = usePagination(cacheKey, page_size, (_, page_size, after) => {
    return listQueryLogs(QUERYLOG_STATES_ARRAY, page_size, after)
  })
  return data
}

export default useQuerylogs
