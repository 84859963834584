import React, { useState } from 'react'
import { ContextRouter } from 'react-router-dom'
import AuthLayout from './Layout'
import { tx } from '../../../modules/translate'
import { useFormik } from 'formik'
import * as auth from '../../../modules/auth'
import { FormValidationLabel } from '../providers/ProviderForm'
import EmailInput from './EmailInput'
import ReCAPTCHA from 'react-google-recaptcha'
import { emailInputClassName, validateEmail } from './SignUp'
import { useRef } from 'react'
import CaptchaBranding from '../../shared/CaptchaBranding'
import Button from '../../shared/Button'
import { showAlert } from '../../../modules/alert/actions'

type FormFields = {
  email: string
}

type ForgotPasswordFormErrors = {
  email?: string
}

const initialValues = {
  email: ''
}

export default function ForgotPassword(props: ContextRouter): React.ReactNode {
  const ForgotPasswordForm = () => {
    const recaptchaRef = useRef(null)
    const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true'

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const { handleSubmit, handleChange, setFieldTouched, values, errors, touched } = useFormik({
      initialValues: initialValues,
      initialErrors: {},
      initialTouched: {
        email: false
      },
      onSubmit: async (values: FormFields) => {
        setIsSubmitting(true)
        // Generate captcha token
        let captcha_token = undefined
        if (recaptchaEnabled) {
          captcha_token = await recaptchaRef.current.executeAsync()
          recaptchaRef.current.reset()
        }
        // Call API
        auth
          .forgotPassword({
            ...values,
            captcha_token
          })
          .then(() => setFormSubmitted(true))
          .catch(error => {
            let errorMessage = ''
            errorMessage = error.response ? error.response.status : error.form
            showAlert(errorMessage)
          })
          .finally(() => setIsSubmitting(false))
      },
      validate: (values: FormFields) => {
        const errors: ForgotPasswordFormErrors = {}

        if (!validateEmail(values.email)) {
          errors.email = 'Please enter a valid email address'
        }

        return errors
      }
    })

    if (!formSubmitted) {
      return (
        <form onSubmit={handleSubmit} className="text-left">
          <div className="form-group">
            <h3>Forgot Password</h3>
            <span className="font-size-14 secondary-neutral">
              Enter your email below to reset your password
            </span>
          </div>
          <div className="form-group">
            <EmailInput
              emailError={errors.email}
              emailTouched={touched.email}
              className={emailInputClassName(errors.email, touched.email)}
              email={values.email}
              emailLabel={'Email'}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              validationLabel={
                <FormValidationLabel
                  isInvalid={Boolean(errors.email)}
                  labelText={`${errors.email && touched.email ? errors.email : ''}`}
                />
              }
            />
          </div>
          {recaptchaEnabled && (
            <div className="form-group">
              <CaptchaBranding />
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              />
            </div>
          )}
          <div className="form-group">
            <Button
              id="request-password-reset-button"
              data-testid="request-password-reset-button"
              type="submit"
              className="btn-success"
              block
              loading={isSubmitting}
            >
              Request Reset Password
            </Button>
          </div>
        </form>
      )
    } else {
      return (
        <div className="text-left padding-2">
          <h3>
            Great! If there is an account associated with this email, a password reset link will be
            sent.
          </h3>
        </div>
      )
    }
  }

  return (
    <AuthLayout
      minimal={false}
      title={tx('auth.messages.signIn')}
      content={<Content />}
      location={props.location}
      layout="sign-in"
      form={() => <ForgotPasswordForm />}
    />
  )
}

function Content() {
  return <></>
}
