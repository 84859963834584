import { useContext } from 'react'
import { GlobalDispatchContext, GlobalStateContext } from '../../../global-state'
import { DRAWER_TOGGLE } from '../../../global-state/reducer'

export const useDrawer = (queryParamName: string) => {
  const key = queryParamName
  const globalState = useContext(GlobalStateContext)
  // see if the current key (e.g. rootDBSettings, dbSettings) has been opened
  //  or is currently visible
  const show = !!globalState.drawerVisibility?.[key]

  const dispatch = useContext(GlobalDispatchContext)

  const onSwitch = () => {
    // when a drawer is open or closed, make sure the global state is updated to ensure
    //  the necessary components are aware (e.g. Snapshots.tsx)
    dispatch({
      type: DRAWER_TOGGLE,
      payload: key
    })
  }

  return {
    show,
    onSwitch,
    key
  }
}
