import cn from 'classnames'
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { showAlert } from '../../../modules/alert/actions'
import * as AlertTypes from '../../../modules/alert/types'
import * as API from '../../../modules/api'
import {
  authorizeSession,
  claimSession,
  ClaimSessionResponse,
  createGrant,
  invalidateSession
} from '../../../modules/api/auth-service/addons'
import SessionCookie from '../../../modules/auth/session/cookie'
import getRegion, { Region } from '../../../modules/auth/session/regions'
import { tx } from '../../../modules/translate'
import ContentHeader from '../../layouts/ContentHeader'
import Button from '../../shared/Button'
import Spinner from '../../shared/Spinner'
import { makeOptions, RegionSelect } from '../databases/RegionSelect'

export default function Authorize() {
  const session = SessionCookie.get()
  const session_id = new URLSearchParams(useLocation().search).get('sid')
  const dispatch = useDispatch()
  const defaultRegion = Region.default

  const [databases, setDatabases] = useState([])
  const [dbNames, setDbNames] = useState(new Set())
  const [isSubmitting, setIsSubmitting] = useState(false)

  const regionOptions = makeOptions(Object.values(Region.all))

  const [sessionResponse, setSessionResponse] = useState<ClaimSessionResponse>(undefined)

  // Fetch all user's databases once and initialize state vars
  useEffect(() => {
    API.AllDatabases(Region.all).then(
      allDbs => {
        setDatabases(allDbs)
        setDbNames(new Set(allDbs.map(db => db.db.id)))
      },
      error => dispatch(showAlert(`Error retrieving databases: ${error.message}`, AlertTypes.ERROR))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch session data from Auth service to populate state vars
  useEffect(() => {
    if (session_id) {
      claimSession(session_id).then(
        response => setSessionResponse(response.data),
        error => {
          const isInvalidRole = !['admin', 'owner'].includes(session.data.user.role)
          const message = isInvalidRole
            ? 'Invalid user role. Please, re-sign in with owner or admin user type'
            : `Error claiming client session: ${error.message}`

          dispatch(showAlert(message, AlertTypes.ERROR))
        }
      )
    }
  }, [dispatch, session.data.user.role, session_id])

  const cancelAuthorize = async () => {
    invalidateSession(sessionResponse.id).then(
      response => {
        const redirect_uri = response.data.redirect_uri
        window.location.assign(redirect_uri)
      },
      error => {
        dispatch(showAlert(`Error invalidating client session: ${error.message}`, AlertTypes.ERROR))
        window.close()
      }
    )
  }

  const hasNoVercelProjects = values => {
    return !values.admin || values.admin.length === 0
  }

  const FormContent = props => {
    const { values } = props
    // docs: https://formik.org/docs/api/useFormikContext
    const formikProps = useFormikContext()
    return (
      <Form className="card container--small">
        <div className="card-header primary">
          <b>Select or create a Fauna database for each of your Vercel projects:</b>
        </div>
        <div className="card-body">
          {hasNoVercelProjects(values) && (
            <div>
              No Vercel projects selected! You must have at least one project to setup a Fauna
              integration.
            </div>
          )}
          <FieldArray name="admin">
            {() => (
              <table className="addon-db-admin">
                {
                  // TODO: This table element complicates the layout in an unnecessary way
                }
                <tbody>
                  {values.admin.map((d, index) => (
                    <tr key={d.key}>
                      <td
                        className={cn(
                          'addon-project-name',
                          'secondary',
                          d.blocked ? 'blocked' : ''
                        )}
                      >
                        <b>{d.key}:</b>
                      </td>
                      <td>
                        <ul className="addon-db-selector">
                          {d.blocked ? (
                            <li className="smaller muted">
                              This project already has a FAUNA_ADMIN_KEY environment variable.
                            </li>
                          ) : (
                            <>
                              <li>
                                <Field
                                  className="padding-right-5"
                                  name={`admin.${index}.database`}
                                  as="select"
                                >
                                  <option key="create-new" value="create-new">
                                    Create a New Database
                                  </option>
                                  {databases.map(d => {
                                    const databaseName = d.db.id
                                    const region = getRegion(d.regionPrefix) || {
                                      regionPrefix: d.regionPrefix,
                                      abbr: d.regionPrefix.toUpperCase()
                                    }
                                    const databaseNameWithRegion = `${region.regionPrefix}/${databaseName}`
                                    const databaseDisplayName = `${databaseName} (${region.abbr})`
                                    return (
                                      <option
                                        key={databaseNameWithRegion}
                                        value={databaseNameWithRegion}
                                      >
                                        {databaseDisplayName}
                                      </option>
                                    )
                                  })}
                                </Field>
                              </li>
                              {d.database == 'create-new' ? (
                                <li className="smaller muted">
                                  An admin key for the new database will be stored in the
                                  FAUNA_ADMIN_KEY environment variable.
                                </li>
                              ) : (
                                <li className="smaller muted">
                                  An admin key will be created and stored in the FAUNA_ADMIN_KEY
                                  environment variable.
                                </li>
                              )}
                              {d.database == 'create-new' && (
                                <li data-testid={`region-select-${index}`}>
                                  <Field
                                    options={regionOptions}
                                    name={`admin.${index}.region`}
                                    className="sizing-medium padding-top-3"
                                    value={regionOptions[0].options.find(
                                      ({ value }) => value === values.admin[index].selectedRegion
                                    )}
                                    onChange={({ value }) =>
                                      formikProps.setFieldValue(
                                        `admin.${index}.selectedRegion`,
                                        value
                                      )
                                    }
                                    as={RegionSelect}
                                  />
                                </li>
                              )}
                            </>
                          )}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </FieldArray>
        </div>
        <div className="card-footer display-flex justify-content-space-around addon-install-footer">
          <div className="container container--xsmall addon-install-actions-container">
            <div className="form-actions addon-install-actions">
              <Button color="secondary" onClick={cancelAuthorize}>
                {tx('actions.cancel')}
              </Button>
              <Button
                type="submit"
                color="success"
                loading={isSubmitting}
                data-testid="install-button"
                disabled={hasNoVercelProjects(values)}
              >
                Install
              </Button>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <div>
      <ContentHeader>Installing Vercel/Fauna Connector</ContentHeader>
      <section className="section padding-top-2">
        {!sessionResponse || !databases ? (
          <p>
            Loading... <Spinner size="sm" />
          </p>
        ) : (
          <Formik
            initialValues={{
              admin: sessionResponse.request.databases.admin.map(e => {
                return {
                  ...e,
                  database: e.blocked ? '' : 'create-new',
                  selectedRegion: defaultRegion.regionPrefix
                }
              })
            }}
            validate={values => {
              if (values.admin.every(a => a.database == '')) {
                return { admin: 'Empty' }
              }
            }}
            validateOnMount={true}
            onSubmit={async values => {
              setIsSubmitting(true)
              Promise.all(
                // First submit step: create database(s) if necessary
                values.admin
                  .filter(formValue => {
                    return formValue.database && formValue.database != ''
                  })
                  .map(async va => {
                    if (va.database == 'create-new') {
                      let newDatabaseName = va.key
                      // If there is already a DB by that name, then add _1, _2,
                      // etc. until we find an available name.
                      let num = 0
                      while (dbNames.has(newDatabaseName)) {
                        newDatabaseName = `${va.key}_${++num}`
                      }
                      await API.createDatabase({
                        name: newDatabaseName,
                        region: getRegion(va.selectedRegion)
                      }).catch(error =>
                        dispatch(
                          showAlert(
                            `Error creating database ${newDatabaseName}: ${error.messsage}`,
                            AlertTypes.ERROR
                          )
                        )
                      )
                      return {
                        key: va.key,
                        database: newDatabaseName,
                        region: va.selectedRegion
                      }
                    } else {
                      // database should be of the form 'region/databaseName' eg. 'global/Foo'
                      const [regionPrefix, databaseName] = va.database.split('/')
                      return {
                        key: va.key,
                        database: databaseName,
                        region: regionPrefix
                      }
                    }
                  })
              ).then(databasesAllowed => {
                // Second step: create a grant for the integration for allowed dbs
                createGrant(sessionResponse.client.id, databasesAllowed).then(
                  response => {
                    return authorizeSession(sessionResponse.id, response.data.id).then(
                      response => {
                        const redirect_uri = response.data.redirect_uri
                        window.location.assign(redirect_uri)
                      },
                      error => {
                        const isSessionExpiredError =
                          error?.response?.data?.code === 'invalid_session'
                        const message = isSessionExpiredError
                          ? 'Your session has been expired. ' +
                            'Please, close the window and try again'
                          : `Error authorizing client session: ${error.message}`

                        dispatch(showAlert(message, AlertTypes.ERROR))
                        setIsSubmitting(false)
                      }
                    )
                  },
                  error => {
                    dispatch(
                      showAlert(
                        `Error creating authorization grant: ${error.message}`,
                        AlertTypes.ERROR
                      )
                    )
                    setIsSubmitting(false)
                  }
                )
              })
            }}
          >
            {({ values }) => <FormContent values={values} />}
          </Formik>
        )}
      </section>
    </div>
  )
}
