import * as React from 'react'
//@ts-ignore
import spinner from '../../assets/images/spinner.svg'

type Props = React.HTMLProps<HTMLButtonElement> & {
  color?: string
  block: boolean
  component: React.ComponentType<any> | string
  className?: string
  domProps?: Record<string, any>
  loading: boolean
  children?: React.ReactNode
  size?: string
  icon: boolean
}

const Button = ({
  color,
  block,
  component,
  className,
  loading,
  children,
  size,
  icon,
  ...domProps
}: Props) => {
  const Component = component

  return (
    <Component
      disabled={loading}
      {...domProps}
      className={`
        btn
        ${color ? `btn-${color}` : ''}
        ${size ? `btn-${size}` : ''}
        ${loading ? 'btn-loading' : ''}
        ${block ? 'btn-block' : ''}
        ${className ? className : ''}
        ${icon ? 'btn-icon' : ''}
      `}
    >
      <span className="btn-label">{children}</span>

      <img src={spinner} alt="" className="btn-spinner" />
    </Component>
  )
}

Button.defaultProps = {
  component: 'button',
  block: false,
  loading: false,
  type: 'button',
  icon: false
}

export default Button
