import React, { useEffect, useState } from 'react'
import { tx } from '../../../modules/translate'
import DocumentCard from './DocumentCard'
import DocumentResultCard from './DocumentResultCard'
import { useLocation, Link } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'
import { matchPath, useParams } from 'react-router'
import Button from '../../shared/Button'

type Props = {
  browseIndex?: Record<string, any>
  documents: Record<string, any>[]
  databasePath?: string
  classId?: string
  edit?: boolean
  remove?: boolean
  pageIndex?: number
  pageSize?: number
  isInitialLoading?: boolean
  isLoadingMore?: boolean
  onRemoved?: () => void
  hasPrev?: boolean
  hasNext?: boolean
  onPrev?: () => void
  onNext?: () => void
}

const PARENT_VIEW = {
  COLLECTION: 'collection',
  INDEXES: 'indexes'
}

export default function DocumentsTable({
  documents,
  browseIndex,
  databasePath,
  classId,
  edit,
  remove,
  isInitialLoading,
  isLoadingMore,
  pageSize,
  pageIndex,
  hasPrev,
  hasNext,
  onPrev,
  onNext,
  onRemoved
}: Props) {
  const location = useLocation()
  const { region } = useParams()
  const [view, setView] = useState(null)

  useEffect(() => {
    const isIndexesRoute = matchPath(location.pathname, {
      path: [`${routes.indexes.index.path}`, `${routes.indexes.byId.path}`]
    })

    if (isIndexesRoute && isIndexesRoute.isExact) {
      setView(PARENT_VIEW.INDEXES)
    }

    const isCollectionRoute = matchPath(location.pathname, {
      path: [`${routes.classes.index.path}`, `${routes.classes.byId.path}`]
    })

    if (isCollectionRoute && isCollectionRoute.isExact) {
      setView(PARENT_VIEW.COLLECTION)
    }
  }, [location, setView])

  function createEmptyTipText(): string {
    switch (view) {
      case PARENT_VIEW.COLLECTION:
        return tx('document_.messages.noDocuments')
      case PARENT_VIEW.INDEXES:
        return tx('index.messages.noDocuments')
      default:
        break
    }
  }

  return isInitialLoading ? (
    <div>{tx('loading.loading')}</div>
  ) : !documents || documents.length === 0 ? (
    <div className="tip margin-bottom-4">
      <div className="tip__label">{tx('document_.messages.noDocumentsYet')}</div>
      <p className="tip__text">{createEmptyTipText()}</p>
      {classId && (
        <Link
          className="btn btn-success btn-inline"
          data-testid="newDocument"
          to={createRoute(routes.instances.new.path, databasePath, region).replace(
            ':classId',
            classId
          )}
        >
          {tx('document_.actions.new')}
        </Link>
      )}
    </div>
  ) : (
    <>
      <div className="card-list">
        <div className="card-list-body">
          {documents.map((document_, index) => {
            if (document_.ref) {
              return (
                <DocumentCard
                  databasePath={databasePath}
                  key={document_.ref.value.id}
                  document_={document_}
                  browseIndex={browseIndex}
                  classId={classId}
                  edit={edit}
                  remove={remove}
                  onRemoved={onRemoved}
                />
              )
            }

            return <DocumentResultCard document_={document_} key={index} />
          })}
        </div>
      </div>
      {pageIndex != null && (
        <div className="documents-table-pagination">
          <Button color="secondary" disabled={!hasPrev} onClick={onPrev}>
            Previous Page
          </Button>
          <div className="documents-table-pagination-next-page">
            <span className="display-none display-small-inline-block">
              {pageSize * pageIndex + 1}-{pageSize * pageIndex + documents.length} of{' '}
              {isLoadingMore || hasNext ? 'many' : pageSize * pageIndex + documents.length}
            </span>
            <Button color="secondary" disabled={!hasNext} onClick={onNext}>
              Next Page
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

DocumentsTable.defaultProps = {
  edit: true,
  remove: true
}
