import { useState, FormEvent, useEffect, useCallback } from 'react'

type BaseFormValues = {
  [key: string]: any
}

type CastingFunction = (value: any) => any

export function useForm<T extends BaseFormValues>(initialValues: T) {
  const [formValues, setFormValues] = useState<T>(initialValues)

  useEffect(() => {
    setFormValues(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialValues)])

  const handleChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = event.currentTarget
    const isCheckboxOrRadio = ['radio', 'checkbox'].includes(type)

    if (isCheckboxOrRadio) {
      setFormValue(name, (event.currentTarget as HTMLInputElement).checked)
      return
    }

    setFormValue(name, value)
  }

  const setFormValue = useCallback((attribute: string, value: any) => {
    setFormValues(formValues => ({
      ...formValues,
      [attribute]: value
    }))
  }, [])

  const renderArrayField = (name: string, renderFunction: Function) => {
    function addField(value: any = '') {
      setFormValues(formValues => ({
        ...formValues,
        [name]: formValues[name].concat(value)
      }))
    }

    function removeField(index: string | number) {
      setFormValues(formValues => ({
        ...formValues,
        [name]: (formValues[name] as any[]).filter((_, filterIndex) => filterIndex !== index)
      }))
    }

    function getValueByIndex(index: number) {
      return formValues[name][index]
    }

    function onFieldChange(index: string | number, value: any) {
      setFormValues(formValues => ({
        ...formValues,
        [name]: (formValues[name] as any[]).map((currentValue, mapIndex) =>
          mapIndex === index ? value : currentValue
        )
      }))
    }

    return renderFunction({
      name,
      addField,
      removeField,
      values: formValues[name],
      getValueByIndex,
      onFieldChange
    })
  }

  return {
    formValues,
    setFormValues,
    setFormValue,
    handleChange,
    renderArrayField
  }
}

export function getValue(value: any, casting?: CastingFunction): any {
  // If value is undefined or an empty string, return null
  if (value === undefined || value === '') return null

  // If the value should be cast, then return the value after casting
  if (casting) return casting(value)

  // Finally, return the bare value
  return value
}
