import { useEffect } from 'react'
import DashboardTooltip from '../functions/DashboardTooltip'
import { useDispatch } from 'react-redux'
import { Link, Location, Match, useHistory } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import routes, { createRoute } from '../../../config/routes'
import { showAlert } from '../../../modules/alert/actions'
import { getKeyNameOrId } from '../../../modules/keyResource'
import { hideModal, showModal } from '../../../modules/modal/actions'
import { tx } from '../../../modules/translate'
import SecurityTabs from '../../layouts/SecurityTabs'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import Spinner from '../../shared/Spinner'
import * as API from './../../../modules/api'
import EmptyKey from './EmptyKey'
import { getRoleId } from '../../../modules/roles'

type Props = {
  match: Match
  location: Location
}

function KeysIndex({ location, match }: Props) {
  const history = useHistory()
  const databasePath = match.params.dbPath || ''
  const region = match.params.region
  API.selectDatabase(databasePath)
  const dispatch = useDispatch()

  const { data: keys } = useSWR([databasePath, 'keys'], () => {
    return API.keys()
  })

  useEffect(() => {
    document.title = 'Keys - Fauna'
  }, [])

  function handleRevoke(keyRef) {
    const onOk = () => {
      API.removeKey(keyRef)
        .then(() => {
          dispatch(showAlert('Key revoked with success.'))
          mutate([databasePath, 'keys'])

          const newLocation = {
            pathname: createRoute(routes.keys.index.path, databasePath || '', region),
            state: {}
          }

          history.replace(newLocation)
        })
        .finally(() => {
          dispatch(hideModal())
        })
    }

    const onCancel = () => dispatch(hideModal())

    dispatch(
      showModal({
        content: tx('keys.actions.confirmRevoke'),
        onOk,
        onCancel,
        showOk: true,
        showCancel: true,
        okText: tx('actions.revoke'),
        cancelText: tx('actions.cancel')
      })
    )
  }

  if (!keys)
    return (
      <div className="loader">
        <Spinner />
      </div>
    )

  return (
    <>
      <SecurityTabs />
      <div className="grid margin-bottom-2">
        <div className="row row--space-between">
          <Link
            to={createRoute(routes.keys.new.path, databasePath, region)}
            className="btn btn-small btn-subtle-link"
          >
            <span className="fas fa-plus-circle" />
            {tx('keys.actions.new')}
          </Link>
        </div>
      </div>

      {location.state && location.state.newKey && (
        <div className="tip tip--warning margin-bottom-3">
          <div className="tip__label">
            <Icon name="exclamation-triangle" className="margin-right-2" />
            {tx('keys.messages.new.first')}
          </div>

          <div data-testid="secret" className="tip__title" data-hj-suppress>
            {location.state.newKey.secret}
          </div>

          <p className="tip__text margin-bottom-4">{tx('keys.messages.new.second')}</p>
        </div>
      )}

      {keys.length === 0 ? (
        <div className="card">
          <EmptyKey databasePath={databasePath} />
        </div>
      ) : (
        <div className="card-list">
          <div className="card-list-header grid">
            <div className="row">
              <div className="label col width-small-30">Key Name/ID</div>
              <div className="label col width-30 display-none display-small-block">Role</div>
              <div className="label col width-20 display-none display-small-block">
                {tx('database.child.name')}
              </div>
              <div className="label col width-20 display-none display-small-block" />
            </div>
          </div>
          <div className="card-list-body">
            {keys.map(key => {
              return (
                <div key={key.ref.value.id} className="card grid">
                  <div className="card-body">
                    <div className="row">
                      <div className="text-ellipsis col width-small-30">{getKeyNameOrId(key)}</div>

                      <div className="col width-small-30 display-none display-small-flex">
                        <span className="text-ellipsis">
                          {typeof key.role === 'string'
                            ? key.role
                            : Array.isArray(key.role)
                            ? key.role.map(r => getRoleId(r)).join(', ')
                            : getRoleId(key.role)}
                        </span>

                        {key.role === 'client' ||
                          (key.role === 'server-readonly' && (
                            <DashboardTooltip
                              id="serverreadonlyid"
                              placement="left"
                              contentText={
                                <div>
                                  <div>
                                    The &lsquo;client&lsquo; and &lsquo;server read-only&lsquo;
                                    roles are targeted for deprecation. These roles can be replaced
                                    with user-defined roles. If you have any concerns about this,
                                    please
                                    <a href="mailto:product@fauna.com">contact us</a>.
                                  </div>
                                </div>
                              }
                            />
                          ))}
                      </div>
                      <div className="col width-small-20 text-ellipsis display-none display-small-flex">
                        {key.database ? key.database.value.id : '--'}
                      </div>

                      <div className="col width-small-20">
                        <Button
                          onClick={() => handleRevoke(key.ref)}
                          size="small"
                          className="btn-subtle-link margin-left-auto"
                        >
                          Revoke Key
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default withBreadcrumbs(({ location }) => {
  return [
    {
      label: 'Security',
      path: location.pathname
    },
    {
      label: tx('keys.title'),
      path: location.pathname
    }
  ]
})(KeysIndex)
