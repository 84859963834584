import React, { FormEvent, useState } from 'react'
import FadeIn from 'react-fade-in'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routes from '../../../config/routes'
import { showAlert } from '../../../modules/alert/actions'
import { RequestStatus } from '../../../modules/api'
import { updateOTPEnabled } from '../../../modules/api/auth-service/otp'
import usePassword, { checkPassword } from '../../../modules/api/auth-service/password'
import useProfile, { SignUpMethods } from '../../../modules/api/auth-service/profile'
import SessionCookie from '../../../modules/auth/session/cookie'
import { useForm } from '../../../modules/form'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import ContentHeader from '../../layouts/ContentHeader'
import { MFASetup } from '../../pages/settings/MFASetup'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import { ModalConfirm } from '../../shared/ModalConfirm'
import Spinner from '../../shared/Spinner'

const ProfileSecurity: React.FC = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isPasswordConfirmOpen, setIsPasswordConfirmOpen] = useState(false)
  const dispatch = useDispatch()
  const [isSetupOpen, setIsSetupOpen] = useState(false)

  const session = SessionCookie.get()
  const user = session.data.user
  const { profile } = useProfile()

  if (!profile.data) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  const userHasPassword = profile.data.sign_up_method === SignUpMethods.emailAndPassword

  const disableOTP = () => {
    updateOTPEnabled(false)
      .then(() => {
        setIsConfirmOpen(false)
        dispatch(showAlert('MFA disabled successfully!', 'success'))
      })
      .catch(() => {
        setIsConfirmOpen(false)
        dispatch(showAlert('Disabling MFA was unsuccessful; please try again.', 'error'))
      })
  }

  return (
    <>
      <ContentHeader divider>Security</ContentHeader>

      {userHasPassword ? (
        <ChangePassword />
      ) : (
        <ExternalProviderNote provider={profile.data.sign_up_method} />
      )}
      {userHasPassword && (
        <>
          <ContentHeader divider>Multi-Factor Authentication</ContentHeader>

          <MFASetup isOpen={isSetupOpen} closeModal={() => setIsSetupOpen(false)} />

          <div className="container container--xsmall padding-y-3">
            <section className="section padding-top-0">
              {user.otp_enabled ? (
                <>
                  <p>You currently have multi-factor authentication enabled.</p>

                  <Button
                    block
                    data-testid="mfa-disable"
                    color="primary"
                    disabled={isPasswordConfirmOpen}
                    onClick={() => {
                      setIsPasswordConfirmOpen(true)
                    }}
                  >
                    Disable
                  </Button>
                </>
              ) : (
                <>
                  <p>
                    Multi-factor authentication is an enhanced security measure. Once enabled,
                    you'll be required to give two types of identification when you log into Fauna.
                    Only OTP apps (like Google Authenticator and similar) are supported.{' '}
                  </p>
                  <Button
                    block
                    color="success"
                    disabled={isPasswordConfirmOpen}
                    onClick={() => {
                      setIsPasswordConfirmOpen(true)
                    }}
                  >
                    {' '}
                    Set Up Multi-Factor authentication{' '}
                  </Button>
                </>
              )}

              {isPasswordConfirmOpen && (
                <ConfirmPassword
                  label="Confirm your password to continue:"
                  onSubmit={() => {
                    setIsPasswordConfirmOpen(false)
                    user.otp_enabled ? setIsConfirmOpen(true) : setIsSetupOpen(true)
                  }}
                />
              )}

              <ModalConfirm
                modal={{
                  loadingText: 'Disabling OTP...',
                  show: isConfirmOpen,
                  content: `Turn off primary verification? You'll need to reconfigure it if you decide to start using it again.`,
                  cancelText: 'Cancel',
                  okText: 'Yes, Turn off',
                  onCancel: () => setIsConfirmOpen(false),
                  onOk: () => disableOTP()
                }}
              />
            </section>
          </div>
        </>
      )}
    </>
  )
}

function ConfirmPassword({ onSubmit, label }) {
  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const { handleChange, formValues, setFormValues } = useForm({ password: '' })

  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setHasFailed(false)
      setIsLoading(true)
      await checkPassword(formValues.password)
      setIsLoading(false)
      onSubmit()
    } catch (error) {
      setIsLoading(false)
      setHasFailed(true)
      setFormValues({ password: '' })
    }
  }

  return (
    <FadeIn>
      <section className="margin-y-3">
        <form data-testid="mfa-confirm-password" onSubmit={handleSubmit}>
          <label htmlFor="password">{label}</label>
          <div className="display-flex">
            <input
              className="margin-right-1 width-100"
              autoFocus
              name="password"
              id="password"
              onChange={handleChange}
              value={formValues.password}
              style={hasFailed ? { borderColor: 'red' } : null}
              type="password"
            />
            <Button type="submit" color="success" loading={isLoading}>
              Verify
            </Button>
          </div>
        </form>
      </section>
    </FadeIn>
  )
}

/** Change Password */

const initialValues = {
  password: '',
  new_password: '',
  new_password_confirmation: ''
}

function ChangePassword() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { handleChange, formValues, setFormValues } = useForm(initialValues)
  const { update: updatePassword } = usePassword()

  const resetForm = () => setFormValues(initialValues)

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault()
      await updatePassword.call(formValues)
      resetForm()
      dispatch(showAlert('Password updated successfully!', 'success'))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
    }
  }

  return (
    <div className="container container--xsmall padding-y-3">
      <section className="section padding-top-0">
        <h2 className="uppercase text-normal margin-bottom-3">Change Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">Current password</label>
            <input
              value={formValues.password}
              onChange={handleChange}
              type="password"
              name="password"
              id="password"
              autoFocus
            />
          </div>

          <div className="form-group">
            <label htmlFor="new_password">New password</label>
            <input
              value={formValues.new_password}
              onChange={handleChange}
              type="password"
              name="new_password"
              id="new_password"
            />
          </div>

          <div className="form-group">
            <label htmlFor="new_password_confirmation">Confirm new password</label>
            <input
              value={formValues.new_password_confirmation}
              onChange={handleChange}
              type="password"
              name="new_password_confirmation"
              id="new_password_confirmation"
            />
          </div>

          <div className="form-actions">
            <Button onClick={() => history.goBack()} color="secondary">
              {tx('actions.cancel')}
            </Button>

            <Button
              type="submit"
              color="success"
              loading={updatePassword.status === RequestStatus.loading}
            >
              {tx('actions.save')}
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

/** ExternalProviderNote */

type ExternalProviderNoteProps = {
  provider: SignUpMethods
}

function ExternalProviderNote({ provider }: ExternalProviderNoteProps) {
  const displayName = getProviderDisplayName(provider)

  return (
    <div className="tip tip--info margin-bottom-3">
      <div className="tip__label">
        <Icon name="exclamation-triangle" className="margin-right-2" />
        Note
      </div>

      <div className="tip__title" data-hj-suppress>
        You are using an external provider
      </div>

      <p className="tip__text tip__text--sm margin-bottom-4">
        We detected you are using a {displayName} account to sign in. <br /> To change your
        password, you will need to change it in your {displayName} account.
      </p>
    </div>
  )
}

function getProviderDisplayName(provider: SignUpMethods) {
  switch (provider) {
    case SignUpMethods.github:
      return 'GitHub'
    case SignUpMethods.netlify:
      return 'Netlify'
    default:
      return provider
  }
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Security',
    path: routes.settings.security.path
  }
])(ProfileSecurity)
