import axios from 'axios'
import { logAxiosError } from '../../../utils/log-helper'

export const API_URL = process.env.REACT_APP_FORMSPREE_ENDPOINT

export type RegionRequest = {
  email: string
  reason: string
}

export async function sendRegionRequest(input: RegionRequest) {
  try {
    await axios.post(API_URL, {
      body: input,
      headers: {
        Accept: 'application/json'
      }
    })
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}
