import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Match, RouterHistory } from 'react-router-dom'
import { values as FaunaValues } from 'faunadb'
import { Link } from 'react-router-dom'
import * as API from '../../../modules/api'
import routes, { createRoute } from '../../../config/routes'
import PrivilegesSection from './PrivilegesSection'
import MembershipSection from './MembershipSection'
import LazyCode from '../../shared/LazyCode'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { useFetch, useCallableFetch } from '../../../utils/fetch'
import { Tabs, Tab, TabsProvider, TabContent } from '../../shared/Tabs'
import { byResourceType } from '../../../modules/roles'
import { showAlert } from '../../../modules/alert/actions'
import { showModal, hideModal } from '../../../modules/modal/actions'
import roleToRoleFormValues from './roleToRoleFormValues'
import { tx } from '../../../modules/translate'
const { COLLECTIONS, INDEXES, FUNCTIONS } = FaunaValues.Native

const schemaFilter = privilege => !privilege.resource.value.collection

type ShowRoleProps = {
  match: Match
  history: RouterHistory
}

function ShowRole({ match, history }: ShowRoleProps) {
  const databasePath = match.params.dbPath || ''
  const region = match.params.region || ''
  API.selectDatabase(databasePath)

  const dispatch = useDispatch()
  const [roleActiveTab, setRoleActiveTab] = useState('privileges')
  const [viewModeActiveTab, setViewModeActiveTab] = useState('simple')
  const roleId = match.params.roleId
  // TODO: use 'loading' and 'error' objects returned from useFetch, alert on error
  const { data: role } = useFetch(API.role, [roleId], {
    namespace: `${databasePath}_roles`,
    single: true,
    id: roleId
  })
  const { runFetch: deleteRole } = useCallableFetch(API.deleteRole, {
    namespace: `${databasePath}_roles`,
    remove: true,
    id: roleId
  })

  function handleDelete() {
    dispatch(
      showModal({
        content: tx('role.confirmDelete').replace(':roleId', roleId),
        onOk: handleDeleteConfirm,
        onCancel: handleCancelDelete,
        showOk: true,
        showCancel: true,
        okText: tx('role.removeRole'),
        cancelText: tx('actions.cancel')
      })
    )
  }

  function handleCancelDelete() {
    dispatch(hideModal())
  }

  function handleDeleteConfirm() {
    deleteRole(roleId)
      .then(() => {
        dispatch(hideModal())
        history.push(createRoute(routes.roles.index.path, databasePath, region))
      })
      .catch(error => {
        dispatch(showAlert(error.requestResult?.responseContent, 'error'))
      })
  }

  if (!role) return <div>{tx('loading.simple')}</div>

  const normalizedRole = roleToRoleFormValues(role)

  return (
    <TabsProvider activeTab={viewModeActiveTab} setActiveTab={setViewModeActiveTab}>
      <ContentHeader
        divider={false}
        tabs={
          <Tabs position="right">
            <Tab id="simple">{tx('tabs.simple')}</Tab>
            <Tab id="fql">{tx('tabs.fql')}</Tab>
          </Tabs>
        }
      >
        {roleId}
        <Link
          to={createRoute(routes.roles.edit.path, databasePath, region).replace(':roleId', roleId)}
          className="btn btn-subtle-link"
        >
          <Icon name="cog" />
          {tx('actions.edit')}
        </Link>

        <Button color="subtle-link" onClick={handleDelete}>
          <Icon name="trash" />
          {tx('actions.delete')}
        </Button>
      </ContentHeader>

      <TabContent id="simple">
        <TabsProvider activeTab={roleActiveTab} setActiveTab={setRoleActiveTab}>
          <Tabs>
            <Tab id="privileges">{tx('role.privileges')}</Tab>
            <Tab id="membership">{tx('role.membership')}</Tab>
          </Tabs>
          <div className="padding-y-3">
            <TabContent id="privileges">
              <PrivilegesSection
                title={tx('class_.plural')}
                titlePlaceholder={tx('class_.name')}
                privileges={normalizedRole.privileges}
                filter={byResourceType(COLLECTIONS)}
                actions={{
                  read: tx('role.actions.read'),
                  write: tx('role.actions.write'),
                  create: tx('role.actions.create'),
                  delete: tx('role.actions.delete'),
                  history_read: tx('role.actions.historyRead'),
                  history_write: tx('role.actions.historyWrite'),
                  unrestricted_read: tx('role.actions.unrestricted')
                }}
              />

              <PrivilegesSection
                title={tx('index.plural')}
                titlePlaceholder={tx('index.name')}
                privileges={normalizedRole.privileges}
                filter={byResourceType(INDEXES)}
                actions={{
                  unrestricted_read: tx('role.actions.unrestricted'),
                  read: tx('role.actions.read'),
                  history_read: tx('role.actions.historyRead')
                }}
              />

              <PrivilegesSection
                title={tx('function_.title')}
                titlePlaceholder={tx('function_.name')}
                privileges={normalizedRole.privileges}
                filter={byResourceType(FUNCTIONS)}
                actions={{
                  call: tx('role.actions.call')
                }}
              />

              <PrivilegesSection
                title={tx('schema.title')}
                titlePlaceholder={tx('schema.name')}
                privileges={normalizedRole.privileges}
                filter={schemaFilter}
                actions={{
                  read: tx('role.actions.read'),
                  write: tx('role.actions.write'),
                  create: tx('role.actions.create'),
                  delete: tx('role.actions.delete'),
                  history_write: tx('role.actions.historyWrite')
                }}
              />
            </TabContent>

            <TabContent id="membership">
              <MembershipSection membership={normalizedRole.membership} />
            </TabContent>
          </div>
        </TabsProvider>
      </TabContent>

      <TabContent id="fql">
        <div className="form-group padding-y-3">
          <label htmlFor="">{tx('attributes.faunaData')}</label>
          <LazyCode code={role} />
        </div>
      </TabContent>
    </TabsProvider>
  )
}

export default withBreadcrumbs(({ location, match }) => {
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path, match.params.dbPath, match.params.region)
    },
    {
      label: tx('role.title'),
      path: createRoute(routes.roles.index.path, match.params.dbPath, match.params.region)
    },
    {
      label: match.params.roleId,
      path: location.pathname
    }
  ]
})(ShowRole)
