import React, { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import capitalize from 'lodash/capitalize'
import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import DatabaseSelect from '../../shared/DatabaseSelect'
import { ConsoleDrawer } from '../../shared/ConsoleDrawer'
import { defaultRegion } from '../../../modules/api'
import { RegionSelect, makeOptions } from './RegionSelect'
import { FloraComponents } from 'frontend-components'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { createQueryLog, Querylog } from '../../../modules/api/frontdoor/queryLogs'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../modules/alert/actions'
import { getErrorMessage } from '../../../utils/error'
import getRegion, { Region } from '../../../modules/auth/session/regions'
import { nowUTC, addDays, localToUTC, utcToLocal } from '../../../utils/date'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import { DATE_FORMAT } from '../../../utils/date'
import { SessionCookieContents } from '../../../modules/cookies/sessionCookie'
import SessionCookie from '../../../modules/auth/session/cookie'

import 'react-datepicker/dist/react-datepicker.css'
import './QuerylogDrawer.scss'

const { Switch } = FloraComponents

export interface QuerylogDrawerProps {
  show: boolean
  onClose: () => void
  onSuccess: (bundle: Querylog) => void
  dbPath?: string
  regionPrefix?: string
}

const TIME_FORMAT = 'HH:mm'
const DATE_PICKER_FORMAT = 'MM-dd-yyyy HH:mm:ss'
const LOGS_CUTOFF = moment('2023-01-18T00:00:00Z')
const LOGS_RANGE_LIMIT_DAYS = 90

export const QuerylogDrawer: FC<QuerylogDrawerProps> = ({
  show,
  onClose,
  onSuccess,
  dbPath,
  regionPrefix
}) => {
  const dispatch = useDispatch()
  const [dbSelectKey, setDBSelectKey] = useState(1)
  const [queryRegionSelected, setQueryRegionSelected] = useState(false)
  const [queryRegionSwitchTouched, setQueryRegionSwitchTouched] = useState(false)
  const [prevDBSelectValues, setPrevDBSelectValues] = useState({
    database: '',
    region_group: undefined
  })
  const regionGroupOptions = makeOptions(Object.values(Region.all), false)

  const {
    rolePermission: writeRole,
    planPermission: writePlan,
    acceptableRoles: acceptableWriteRoles
  } = usePermissions(PermissionDomain.QUERYLOG, PermissionLevel.write)
  const hasWritePermissions = writeRole && writePlan

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptableWritePlans: string[]
  if (v2Released) {
    acceptableWritePlans = ['Trial', 'Pro', 'Enterprise']
  } else {
    acceptableWritePlans = ['team', 'business']
  }

  const {
    values,
    errors,
    isSubmitting,
    setValues,
    resetForm,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {
      database: '',
      region_group: defaultRegion()?.regionPrefix,
      time_start: addDays(nowUTC(), -7),
      time_end: nowUTC()
    },
    validate: values => {
      // can't go back more than a year
      // can't have more than 90 days apart
      const errors: any = {}
      const { time_start, time_end, database } = values
      if (moment(time_end).isBefore(time_start)) {
        errors.time_end = 'End date must be after start date'
      } else if (moment(time_end).diff(moment(time_start), 'days', true) > LOGS_RANGE_LIMIT_DAYS) {
        errors.time_end = `Start and end time cannot be more than ${LOGS_RANGE_LIMIT_DAYS} days apart.`
      }
      if (moment(time_start).isBefore(LOGS_CUTOFF)) {
        errors.time_start = `Start time must be greater than ${LOGS_CUTOFF.utc().format(
          DATE_FORMAT
        )} UTC`
      } else if (moment(time_start).isBefore(moment.utc().subtract(1, 'year'))) {
        errors.time_start = 'Start time cannot be more than a year in the past.'
      }
      if (!queryRegionSelected && !database) {
        errors.database = 'You must select a database or region group'
      }
      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      createQueryLog(values)
        .then(response => {
          dispatch(showAlert('Successfully requested log export.', 'success'))
          onSuccess(response)
        })
        .catch(error => {
          dispatch(showAlert(getErrorMessage(error), 'error'))
        })
        .finally(() => {
          onLocalClose()
          setSubmitting(false)
        })
    }
  })

  const onLocalClose = () => {
    onClose()
    setQueryRegionSelected(false)
    setQueryRegionSwitchTouched(false)
    if (dbPath && regionPrefix) {
      setValues({
        ...values,
        database: dbPath,
        region_group: regionPrefix
      })
    } else {
      resetForm()
    }
    // Hacky way of ensuring we re-set the DatabaseSelect component
    setDBSelectKey(prev => prev + 1)
  }

  useEffect(() => {
    // Re-initialize the form if we get a new start database
    if (dbPath && regionPrefix) {
      setValues({ ...values, database: dbPath, region_group: regionPrefix })
    }
  }, [dbPath, regionPrefix])

  useEffect(() => {
    // Make sure we reset the form values if the query region switch is toggled
    if (!queryRegionSwitchTouched) return
    if (queryRegionSelected) {
      setPrevDBSelectValues({ database: values.database, region_group: values.region_group })
      setFieldValue('database', undefined)
    } else {
      setValues({ ...values, ...prevDBSelectValues })
    }
  }, [queryRegionSelected, queryRegionSwitchTouched])

  const renderWritePermissionsDialog = () => {
    if (!writePlan) {
      return (
        <InfoBox
          body={
            <div>
              This feature is only available to users on the following plans:{' '}
              {acceptableWritePlans.map(plan => capitalize(plan)).join(', ') + '. '}
              <a href="/settings/billing">
                <u>Upgrade plan</u>
              </a>
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )
    } else if (!writeRole) {
      return (
        <InfoBox
          body={
            <div>
              You must have one of the following roles to use this feature:{' '}
              {acceptableWriteRoles.map(plan => capitalize(plan)).join(', ')}. Please contact your
              Team Admin for more details.
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )
    }
  }

  return (
    <ConsoleDrawer open={show} onClose={onLocalClose} header={tx('logs.exportTitle')}>
      <div className="padding-y-2 database-form">
        <form data-testid="create-querylog-form" onSubmit={handleSubmit}>
          <div className={`form-group ${errors.database ? 'error' : ''}`}>
            <label>Database</label>
            <DatabaseSelect
              allowRoot={false}
              key={JSON.stringify([regionPrefix, dbPath, dbSelectKey])}
              startDatabasePath={dbPath}
              startRegionPrefix={regionPrefix}
              isDisabled={queryRegionSelected || !hasWritePermissions}
              onChange={(databasePath, regionPrefix) => {
                setValues({ ...values, database: databasePath, region_group: regionPrefix })
              }}
            />
            {errors.database && <span className="database-form-hint">{errors.database}</span>}
          </div>
          <div className="form-group">
            <div className="form-checkbox">
              <label htmlFor="query-log-region">
                <Switch
                  checked={queryRegionSelected}
                  id="query-log-region"
                  name="query-log-region"
                  disabled={!hasWritePermissions}
                  onChange={() => {
                    setQueryRegionSwitchTouched(true)
                    setQueryRegionSelected(prev => !prev)
                  }}
                />
                {tx('logs.querylogs.region')}
              </label>
            </div>
          </div>
          {queryRegionSelected && (
            <div className={classNames('form-group')}>
              <div className="form-label">
                <label>Region Group</label>
              </div>
              <RegionSelect
                value={{
                  value: values.region_group,
                  label: getRegion(values.region_group).label
                }}
                options={regionGroupOptions}
                disabled={!hasWritePermissions}
                onChange={selected => setFieldValue('region_group', selected.value)}
              />
            </div>
          )}
          <div className="form-group error">
            <label htmlFor="query-time-start">Start Time (UTC)</label>
            <DatePicker
              className="query-time-start"
              id="query-time-start"
              selected={utcToLocal(values.time_start)}
              onChange={date => {
                if (!date) return
                setFieldValue('time_start', localToUTC(date))
              }}
              popperPlacement="bottom-end"
              showTimeSelect
              timeIntervals={15}
              timeFormat={TIME_FORMAT}
              dateFormat={DATE_PICKER_FORMAT}
              disabled={!hasWritePermissions}
            />
            {errors.time_start && <span className="database-form-hint">{errors.time_start}</span>}
          </div>
          <div className="form-group error">
            <label htmlFor="query-time-end">End Time (UTC)</label>
            <DatePicker
              className="query-time-end"
              id="query-time-end"
              selected={utcToLocal(values.time_end)}
              onChange={date => {
                if (!date) return
                setFieldValue('time_end', localToUTC(date))
              }}
              popperPlacement="bottom-end"
              showTimeSelect
              timeIntervals={15}
              timeFormat={TIME_FORMAT}
              dateFormat={DATE_PICKER_FORMAT}
              disabled={!hasWritePermissions}
            />
            {errors.time_end && <span className="database-form-hint">{errors.time_end}</span>}
          </div>
          {!hasWritePermissions && (
            <div className="form-group">{renderWritePermissionsDialog()}</div>
          )}
          <div className="form-actions">
            <Button onClick={onLocalClose} color="secondary">
              {tx('actions.cancel')}
            </Button>
            <Button
              type="submit"
              color="success"
              loading={isSubmitting}
              disabled={!hasWritePermissions}
              id="query-log-form-submit-button"
            >
              {tx('logs.actions.export')}
            </Button>
          </div>
        </form>
      </div>
    </ConsoleDrawer>
  )
}
