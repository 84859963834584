import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'
import * as classResource from '../../../modules/classResource'
import * as databaseResource from '../../../modules/databaseResource'
import * as indexResource from '../../../modules/indexResource'
import { tx } from '../../../modules/translate'
import DatabasesTableWithRegionGroup from './DatabasesTableWithRegionGroup'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Icon from '../../shared/Icon'
import ClassesTable from '../classes/ClassesTable'
import DatabaseSettingsDrawer, { Stage } from '../settings/DatabaseSettingsDrawer'
import EmptyIndex from './EmptyIndex'
import { useDrawer } from './useDrawer'
import getRegion from '../../../modules/auth/session/regions'

type Props = {
  fetchListDatabases: Function
  fetchListClasses: Function
  fetchListIndexes: Function
  databases: Record<string, any>[]
  classes: Record<string, any>[]
  indexes: Record<string, any>[]
  match: Record<string, any>
  location: Record<string, any>
}

function DatabasesIndex({ classes, indexes, fetchListClasses, fetchListIndexes, match }: Props) {
  const databasePath = match.params.dbPath
  const regionPrefix = match.params.region
  const region = getRegion(regionPrefix)
  const databaseID = databaseResource.databaseIdFromPath(databasePath)

  useEffect(() => {
    fetchListClasses({ databasePath })
  }, [databasePath, fetchListClasses])

  useEffect(() => {
    fetchListIndexes({ databasePath })
  }, [databasePath, fetchListIndexes])

  useEffect(() => {
    document.title = (databaseID || tx('database.home')) + ' - Fauna'
  }, [databaseID])

  function indexPath(indexId: string): string {
    if (databasePath) {
      return `/indexes/${indexId}/@db/${regionPrefix}/${match.params.dbPath}`
    }
    return `/indexes/${indexId}`
  }

  function renderLoading() {
    return <div className="card-body center">{tx('loading.loading')}</div>
  }

  function renderIndexes(indexes: Record<string, any>[]) {
    return (
      <div className="scrollList">
        <table className="table-card no-table-labels">
          <tbody>
            {indexes.map(index => (
              <tr key={index.name}>
                <td>
                  <div>
                    <Link to={indexPath(index.name)} data-hj-suppress>
                      {index.name}
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const settingsDrawer = useDrawer('rootDbSettings')

  const handleSettingsClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    settingsDrawer.onSwitch()
  }

  return (
    <>
      <div className="display-flex justify-content-space-between align-items-center">
        <ContentHeader className="margin-bottom-4 margin-bottom-small-none" divider={false}>
          {databaseID || tx('database.home')}
          {databaseID && (
            <div className="content-header__links">
              <div className="btn btn-subtle-link" onClick={handleSettingsClick}>
                <Icon name="cog" />
                Settings
              </div>

              <DatabaseSettingsDrawer
                initialStage={Stage.SETTINGS}
                dbSettingsParams={{
                  dbPath: databasePath,
                  region: regionPrefix
                }}
                open={settingsDrawer.show}
                onClose={settingsDrawer.onSwitch}
              />
            </div>
          )}
        </ContentHeader>
        <div>{region ? `Region group: ${region.label}` : ''}</div>
      </div>

      <div className="padding-top-sm-3">
        {!databaseID && <p className="danger">{tx('deprecations.root')}</p>}
        <div className="grid">
          <div className="row">
            <div className="col">
              <section className="card card-flex stretch">
                <div className="card-header">
                  <h4>{tx('class_.plural')}</h4>
                  {classes && classes.length > 0 && (
                    <Link
                      to={createRoute(routes.classes.new.path, databasePath, regionPrefix)}
                      className="btn btn-subtle-link"
                    >
                      <span className="fas fa-table" />
                      {tx('class_.actions.new')}
                    </Link>
                  )}
                </div>

                <ClassesTable shouldFetch={false} databasePath={databasePath} />
              </section>
            </div>

            <div className="col">
              <section className="card card-flex stretch">
                <div className="card-header">
                  <h4>{tx('index.plural')}</h4>
                  {classes && classes.length > 0 && indexes && indexes.length > 0 && (
                    <Link
                      className="btn btn-subtle-link"
                      to={createRoute(routes.indexes.new.path, databasePath, regionPrefix)}
                    >
                      <span className="fas fa-list" />
                      {tx('index.actions.new')}
                    </Link>
                  )}
                </div>

                {!indexes && renderLoading()}
                {indexes && indexes.length === 0 && (
                  <EmptyIndex
                    muted
                    classes={classes}
                    databasePath={databasePath}
                    classEmptyMessage={tx('index.empty.secondaryClassTitle')}
                  />
                )}
                {indexes && indexes.length > 0 && renderIndexes(indexes)}
              </section>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="row">
            <div className="col">
              <DatabasesTableWithRegionGroup
                title={tx('database.plural')}
                databasePath={databasePath}
                region={regionPrefix}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  const { dbPath } = props.match.params

  return {
    classes: classResource.all(state, dbPath),
    indexes: indexResource.all(state, dbPath)
  }
}

const mapDispatchToProps = {
  fetchListClasses: classResource.actions.fetchList,
  fetchListIndexes: indexResource.actions.fetchList
}

export default connect(mapStateToProps, mapDispatchToProps)(withBreadcrumbs()(DatabasesIndex))
