import axios from 'axios'
import { useState } from 'react'
import useSWR from 'swr'
import { RequestStatus } from '..'
import { logAxiosError } from '../../../utils/log-helper'
import { rootSecret } from '../index'

export enum SignUpMethods {
  emailAndPassword = 'email_and_password',
  github = 'github',
  netlify = 'netlify'
}

export type Profile = {
  name: string
  email: string
  sign_up_method: SignUpMethods
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/profile`
export const CACHE = '/settings/profile'

async function getProfile() {
  return axios
    .get<Profile>(API_URL, {
      headers: { secret: rootSecret() }
    })
    .then(res => res.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

async function updateProfile(data: Partial<Profile>) {
  return axios
    .put<Profile>(API_URL, data, {
      headers: { secret: rootSecret() }
    })
    .then(res => res.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export default function useProfile() {
  const [updateStatus, setUpdateStatus] = useState<RequestStatus>(RequestStatus.idle)

  const profile = useSWR(CACHE, getProfile)

  const update = {
    call: async (data: Partial<Profile>) => {
      if (updateStatus !== RequestStatus.idle) return

      setUpdateStatus(RequestStatus.loading)
      await profile.mutate(() => updateProfile(data))
      setUpdateStatus(RequestStatus.idle)
    },
    status: updateStatus
  }

  return {
    profile,
    update
  }
}
