import { useState, useEffect, useReducer } from 'react'
import { logStatusCode, ResponseErrorTypes } from '../utils/log-helper'

// Usage

/*function App() {
  // Similar to useState but first arg is key to the value in local storage.
  const [name, setName] = useLocalStorage('name', 'Bob')

  return (
    <div>
      <input
        type="text"
        placeholder="Enter your name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
    </div>
  )
}*/
// Hook
export function useLocalStorage(key: string, defaultValue?: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return defaultValue
      return item ? JSON.parse(item) : defaultValue
    } catch (error) {
      // If error also return defaultValue
      logStatusCode(
        error.code ? error.code : error.response?.status,
        error,
        ResponseErrorTypes.HANDLED
      )
      return defaultValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      logStatusCode(
        error.code ? error.code : error.response?.status,
        error,
        ResponseErrorTypes.HANDLED
      )
    }
  }

  return [storedValue, setValue]
}

export function useLocalStorageReducer(storageKey, reducer, defaultState, init = null) {
  const hookVars = useReducer(reducer, defaultState, defaultState => {
    const persisted = JSON.parse(localStorage.getItem(storageKey))
    const hasInitFunction = init !== null
    const hasPersistedValue = persisted !== null
    if (hasPersistedValue) {
      return persisted
    }
    return hasInitFunction ? init(defaultState) : defaultState
  })

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(hookVars[0]))
  }, [hookVars, storageKey])

  return hookVars
}
