import { useEffect, useRef } from 'react'
import React from 'react'

type Props = {
  name: string
  className?: string
  wrapperClassName?: string
  mode?: string
} & React.HTMLAttributes<HTMLSpanElement>

const Icon = ({ name, mode, className, wrapperClassName, ...domProps }: Props) => {
  const wrapperRef = useRef(null)
  const iconClassName = `${String(mode)} fa-${name} ${String(className)}`

  useEffect(() => {
    if (wrapperRef) {
      const svg = wrapperRef.current.querySelector('svg')

      svg && svg.setAttribute('class', iconClassName)
    }
  }, [name, className, iconClassName])

  return (
    <span ref={wrapperRef} className={wrapperClassName} {...domProps}>
      <i className={iconClassName} />
    </span>
  )
}

Icon.defaultProps = {
  mode: 'fas'
}

export default Icon
