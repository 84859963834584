import React, { useEffect } from 'react'
import { values } from 'faunadb'
import * as backupApi from '../../../modules/api/frontdoor/backup'
import { FormValidationLabel } from '../../pages/providers/ProviderForm'
import Button from '../../shared/Button'
import { Select } from '../../shared/Select'
import { formatSnapshotDate } from '../../shared/SnapshotDate'
import useRestores from '../../../modules/api/useRestores'
import {
  databaseIdFromPath,
  isValidDatabaseName,
  databaseParentPathFromPath
} from '../../../modules/databaseResource'
import { useDispatch } from 'react-redux'
import * as AlertTypes from '../../../modules/alert/types'
import { showAlert } from '../../../modules/alert/actions'
import { logStatusCode } from '../../../utils/log-helper'
import { makeOptions, RegionSelect } from '../databases/RegionSelect'
import DatabaseSelect from '../../shared/DatabaseSelect'
import { useGlobalFormik } from '../../../utils/useGlobalFormik'
import getRegion, { Region } from '../../../modules/auth/session/regions'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

type CopyDataProps = {
  onClose: () => void
  onCancel: () => void
  dbPath: string
  region: string
  dbName?: string
  snapshot: { data: backupApi.Snapshot; ref: values.Ref }
}

const CopyData: React.FC<CopyDataProps> = ({
  onClose,
  onCancel,
  dbPath,
  region,
  snapshot,
  dbName: databaseName
}) => {
  const dispatch = useDispatch()
  const dbName = databaseName || databaseIdFromPath(dbPath)
  const parentPath = databaseParentPathFromPath(dbPath) || ''

  const { revalidate: refreshRestores } = useRestores(parentPath, region, () => {})
  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.write
  )
  const hasWritePermissions = rolePermission && planPermission

  const regionGroupOptions = makeOptions(Object.values(Region.all), false)

  useEffect(() => {
    setFieldValue('snapshotRefId', snapshot?.ref['@ref']?.id)
  }, [snapshot])

  const {
    values,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    isSubmitting,
    errors
  } = useGlobalFormik({
    initialValues: {
      confirm: false,
      destinationRegionPrefix: region,
      destinationDbPath: parentPath,
      destinationDbName: `Copy_${dbName}`,
      snapshotRefId: snapshot?.ref['@ref']?.id
    },
    validate: values => {
      const errors: any = {}
      if (!values.confirm) {
        errors.confirm = 'Please check the box to confirm.'
      } else if (!values.destinationRegionPrefix) {
        errors.destinationRegionPrefix = 'You must choose a region.'
      } else if (values.destinationDbPath === null || values.destinationDbPath === undefined) {
        errors.destinationDbPath = 'You must choose a destination database.'
      } else if (!values.snapshotRefId) {
        errors.snapshotRefId = 'You must choose a snapshot.'
      } else if (snapshot?.data?.state !== 'Complete') {
        errors.snapshotRefId = 'Cannot copy from a snapshot that has not successfully completed.'
      } else if (snapshot.ref['@ref']?.id !== values.snapshotRefId) {
        errors.snapshotRefId = 'Something unexpected happened, please try again.'
      } else if (!values.destinationDbName) {
        errors.destinationDbName = 'You must choose a name.'
      } else if (!isValidDatabaseName(values.destinationDbName)) {
        errors.destinationDbName = 'That name is invalid.'
      }
      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      const {
        snapshotRefId,
        destinationDbPath,
        destinationDbName,
        destinationRegionPrefix
      } = values
      backupApi
        .createRestore({
          region: destinationRegionPrefix,
          path: destinationDbPath,
          snapshot_ref_id: snapshotRefId,
          type: 'Copy',
          destination_db_name: destinationDbName,
          hasRequiredPermissions: hasWritePermissions
        })
        .then(() => {
          refreshRestores()
          onClose()
        })
        .catch(error => {
          const code = error.response?.code || error.response?.status
          logStatusCode(code, error)
          if ([400, 403].includes(code))
            if (error.response?.data?.reason)
              dispatch(showAlert(error.response.data.reason, AlertTypes.ERROR))
            else dispatch(showAlert('Unable to initiate copy request.', AlertTypes.ERROR))
          else dispatch(showAlert('Something went wrong!', AlertTypes.ERROR))
        })
        .finally(() => setSubmitting(false))
    }
  })

  return (
    <div className="database-form">
      <p className="font-size-14">This will copy data from an existing snapshot.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Source snapshot</label>
          <Select
            isDisabled={true}
            placeholder={`${formatSnapshotDate(snapshot?.data?.snapshot_ts['@ts'])} ${dbName}`}
          />
          {errors.snapshotRefId && (
            <FormValidationLabel
              isInvalid={Boolean(errors.snapshotRefId)}
              labelText={`${errors.snapshotRefId}`}
            />
          )}
        </div>

        <div className="form-group">
          <label>Destination</label>
          <DatabaseSelect
            startDatabasePath={parentPath}
            startRegionPrefix={region}
            onChange={(databasePath, regionPrefix) => {
              setFieldValue('destinationDbPath', databasePath)
              setFieldValue('destinationRegionPrefix', regionPrefix)
            }}
          />
          {!errors.destinationDbPath && (
            <span className="database-form-hint">
              This will create a new database in this location
            </span>
          )}
          <FormValidationLabel
            isInvalid={Boolean(errors.destinationDbPath)}
            labelText={`${errors.destinationDbPath ? errors.destinationDbPath : ''}`}
          />
        </div>

        {values.destinationDbPath === '' && (
          <div className="form-group">
            <label>Region Group</label>
            <RegionSelect
              isDisabled={false}
              options={regionGroupOptions}
              value={{
                value: values.destinationRegionPrefix,
                label: getRegion(values.destinationRegionPrefix).label
              }}
              onChange={selected => setFieldValue('destinationRegionPrefix', selected.value)}
            />
            <FormValidationLabel
              isInvalid={Boolean(errors.destinationRegionPrefix)}
              labelText={`${errors.destinationRegionPrefix ? errors.destinationRegionPrefix : ''}`}
            />
          </div>
        )}

        <div className="form-group">
          <label>Name</label>
          <input
            id="destinationDbName"
            type="text"
            name="destinationDbName"
            autoFocus
            value={values.destinationDbName}
            onChange={handleChange}
          />
          <FormValidationLabel
            isInvalid={Boolean(errors.destinationDbName)}
            labelText={`${
              errors.destinationDbName && touched.destinationDbName ? errors.destinationDbName : ''
            }`}
          />
        </div>

        <div className="form-group">
          <div className="form-checkbox">
            <label htmlFor="confirm">
              <input
                checked={values.confirm}
                type="checkbox"
                id="confirm"
                name="confirm"
                onChange={handleChange}
              />
              I understand the destination database will be unavailable until the data copy is
              completed
            </label>
          </div>
          <FormValidationLabel
            isInvalid={Boolean(errors.confirm)}
            labelText={`${errors.confirm && touched.confirm ? errors.confirm : ''}`}
          />
        </div>

        <div className="container container--xsmall">
          <div className="form-actions">
            <Button onClick={() => onCancel()} color="secondary">
              CANCEL
            </Button>
            <Button type="submit" color="success" loading={isSubmitting}>
              BEGIN COPY
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CopyData
