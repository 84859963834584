import React, { useEffect } from 'react'
import useSWR from 'swr'
import { builtInRoles } from '../../../modules/roles'
import * as API from './../../../modules/api'
import { RolesDataResponse } from './../../../modules/api'

type SelectRoleProps = {
  dbPath: string
  value?: string
  onChange: (value: string) => void
  onOptionsLoad?: (items: Record<'id', string>[]) => void
} & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange'>

export default function SelectRole({
  value,
  onChange,
  dbPath,
  children,
  onOptionsLoad,
  ...selectProps
}: SelectRoleProps) {
  const { data: roles } = useSWR<RolesDataResponse>([dbPath, 'roles'], () => {
    API.selectDatabase(dbPath)
    return API.roles()
  })

  useEffect(() => {
    if (roles && onOptionsLoad) {
      onOptionsLoad(roles)
    }
  }, [roles, onOptionsLoad])

  function handleChange(event: React.InputEvent<HTMLSelectElement>) {
    onChange(event.currentTarget.value)
  }

  return (
    <select value={value} onChange={handleChange} id="roleId" name="roleId" {...selectProps}>
      {roles ? (
        <>
          {children}

          {builtInRoles.map(option => (
            <option key={option.name} value={option.id}>
              {option.name}
            </option>
          ))}

          {roles.map(role => (
            <option key={role.id} value={role.id}>
              {role.id}
            </option>
          ))}
        </>
      ) : (
        <option value="">Loading roles...</option>
      )}
    </select>
  )
}
