import React from 'react'

import { tx } from '../../../modules/translate'
import CardListItem from './CardListItem'

import faunaIcon from '../../../assets/images/fauna-icon-blue.svg'

const GettingStartedCard: React.FC = () => {
  return (
    <div className="GettingStartedCard card card-sm">
      <div className="card-header">{tx('gettingStarted.gettingStartedTitle')}</div>
      <div className="card-body">
        <ul className="list list-block">
          <CardListItem
            href="https://docs.fauna.com/fauna/v4/learn/quick_start/"
            iconName="laptop-code"
            title={tx('gettingStarted.faunaQuickStart.title')}
            subtitle={tx('gettingStarted.faunaQuickStart.subtitle')}
          />

          <CardListItem
            href="https://docs.fauna.com/fauna/v4/learn/sample_apps/"
            iconRender={() => (
              <img alt="Fauna logo" src={faunaIcon} className="fauna-logo-desktop" />
            )}
            title={tx('gettingStarted.faunaSampleApps.title')}
            subtitle={tx('gettingStarted.faunaSampleApps.subtitle')}
          />

          <CardListItem
            href="https://docs.fauna.com/fauna/v4/drivers/"
            iconName="wrench"
            title={tx('gettingStarted.driverInstallation.title')}
            subtitle={tx('gettingStarted.driverInstallation.subtitle')}
          />
        </ul>
      </div>
    </div>
  )
}

export default GettingStartedCard
