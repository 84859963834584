import LazyCode from '../../shared/LazyCode'

export default [
  {
    title: 'Pull Latest Docker Image',
    description: (
      <>
        <p>To get a docker image of Fauna, simply run:</p>
        <LazyCode code={`$ docker pull fauna/faunadb:latest`} />
      </>
    )
  },
  {
    title: 'Usage',
    description: (
      <>
        <LazyCode
          code={`$ docker run fauna/faunadb --help
    Fauna Enterprise Docker Image

    Options:
    --help               Print this message and exit.
    --init               Initialize the node (default action).
    --no-init            Doesn't initialize the node.
    --join host[:port]   Join a cluster through an active node specified in host and port.
    --config path        Specify a custom config file. Should be accessible inside the docker image.`}
        />
        <p>
          If you are a developer and want a Fauna instance up and running, the simplest way to do it
          is by running:
        </p>
        <LazyCode code={`$ docker run --rm --name faunadb -p 8443:8443 fauna/faunadb`} />
      </>
    )
  },
  {
    title: 'Persisted data',
    description: (
      <>
        <p>
          The above command will start a Fauna instance and initialize the cluster. However, when
          you kill the docker container, all your data will be lost. In order to prevent this, you
          can map a volume to the folder <code>/var/lib/faunadb</code> and all data stored in Fauna
          will be persisted between executions.
        </p>
        <LazyCode
          code={`$ docker run --rm --name faunadb -p 8443:8443
    -v <host-directory or named-volume>:/var/lib/faunadb
    fauna/faunadb`}
        />
      </>
    )
  },
  {
    title: 'Fauna logs',
    description: (
      <>
        <p>
          To access the Fauna logs, you can also map a volume to the folder{' '}
          <code>/var/log/faunadb</code>:
        </p>
        <LazyCode
          code={`$ docker run --rm --name faunadb -p 8443:8443
    -v <host-directory or named-volume>:/var/lib/faunadb
    -v <host-directory>:/var/log/faunadb
    fauna/faunadb`}
        />
      </>
    )
  },
  {
    title: 'Fauna config',
    description: (
      <>
        <p>
          The previous command will start a Fauna instance using the default configurations. If you
          want to specify your own parameters, you can map a config file and pass it to the command
          line:
        </p>
        <LazyCode
          code={`$ docker run --rm --name faunadb -p 8443:8443
    -v <host-directory or named-volume>:/var/lib/faunadb
    -v <host-directory>:/var/log/faunadb
    -v <path-to-config-file>:/etc/faunadb.yml
    fauna/faunadb --config /etc/faunadb.yml`}
        />

        <p>This is an example config file:</p>

        <LazyCode
          code={`auth_root_key: secret
network_datacenter_name: NoDc
storage_data_path: /storage/data
log_path: /storage/log
network_datacenter_name: NoDc
shutdown_grace_period_seconds: 0
network_listen_address: 172.17.0.2
network_broadcast_address: 172.17.0.2
network_admin_http_address: 172.17.0.2
network_coordinator_http_address: 172.17.0.2`}
        />
        <p>
          For more information about the above configurations and others, visit{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.fauna.com/fauna/v4/build/tools/dev"
          >
            {' '}
            How to Operate Fauna{' '}
          </a>
        </p>
      </>
    )
  }
]
