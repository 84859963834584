import { useMemo } from 'react'
import * as React from 'react'

import LazyJSONDisplay from '../../shared/LazyJSONDisplay'
import { JSONDisplayProps } from '../../shared/JSONDisplay'

type QueryMetrics = {
  queryBytesIn: string
  queryBytesOut: string
  queryTime: string
  storageBytesRead: string
  storageBytesWrite: string
  transactionRetries: string
}

type QueryData = {
  query: string
  result: string
  error: string
  metrics: QueryMetrics
}[]

type QueryElementProps = Pick<JSONDisplayProps, 'onMount'> & {
  data: QueryData
  editorRef: React.RefObject<HTMLElement>
}

function QueryElement({ data, onMount, editorRef }: QueryElementProps) {
  const value = useMemo(() => serializeData(data), [data])
  const metrics = useMemo(() => data.map(item => item.metrics), [data])

  return (
    <LazyJSONDisplay
      onMount={onMount}
      value={value}
      className="ace-webshell"
      theme="ace-webshell"
      showGutter={true}
      metrics={metrics}
      editorRef={editorRef}
    />
  )
}

export default QueryElement

function serializeData(data: QueryData): string {
  let history = ''

  if (data.length) {
    data.forEach(item => {
      const { metrics } = item

      history += `${item.query}\n`
      if (item.error) {
        history += `Error: ${item.error}\n\n`
      } else {
        history += `${item.result}\n\n`

        if (metrics && Object.keys(metrics).length) {
          const queryString = `>> Time elapsed: ${metrics.queryTime}ms`
          history += `${queryString}\n\n`
        }
      }
    })
  } else {
    history = ' '
  }

  return sanitizeWhitespace(history)
}

function sanitizeWhitespace(text: string) {
  return text.replace(/\\n/g, '\r\n').replace(/\\"/g, '"')
}
