import React, { FC } from 'react'

import { UsagePlan } from '../../../config/usagePlans'
import { useLocalStorage } from '../../../modules/localStorage'
import useBillingInfo from '../../../modules/api/auth-service/billingInfo'

import Icon from '../../shared/Icon'

const DISMISSED_AT_MONTH_LS_KEY = 'freePlanBannerDismissedAtMonth'
const BILLING_SETTINGS_LINK = `${location.origin}/settings/billing`

const whetherToShowBanner = (cents: number) => {
  const THRESHOLDS = [
    {
      value: 2,
      dismissible: false
    },
    {
      value: 1.6,
      dismissible: true
    }
  ]

  const found = THRESHOLDS.find(({ value }) => cents >= value * 100)

  return {
    show: found != null,
    dismissible: !!found?.dismissible
  }
}

const PricingBanner: FC = () => {
  const [dismissedAtMonth, setDismissedAtMonth] = useLocalStorage(DISMISSED_AT_MONTH_LS_KEY)
  const { billingInfo } = useBillingInfo()
  const plan = billingInfo.data?.plans.usage
  const freeUsageAmount = billingInfo.data?.free_usage_amount as number | undefined
  const whetherToShow =
    freeUsageAmount && plan === UsagePlan.free && whetherToShowBanner(freeUsageAmount)
  const dismissed = dismissedAtMonth != null && dismissedAtMonth === new Date().getMonth() + 1
  const showBanner = !!whetherToShow?.show && !(whetherToShow.dismissible && dismissed)

  if (!showBanner) {
    return null
  }

  const onDismiss = () => setDismissedAtMonth(new Date().getMonth() + 1)

  return (
    <div className="PricingBanner card margin-top-4 font-size-14">
      <div className="well align-items-center">
        <Icon name="exclamation-circle" className="PricingBanner__icon margin-right-4" />
        <p className="well__text margin-bottom-0">
          {!whetherToShow.dismissible && <strong>Action required: </strong>}
          Your current Fauna usage {whetherToShow.dismissible
            ? 'is approaching'
            : 'has exceeded'}{' '}
          the Free plan limits. Please upgrade your plan by clicking{' '}
          <a href={BILLING_SETTINGS_LINK}>here</a> so that we can continue to serve your traffic.
        </p>
      </div>
      {whetherToShow.dismissible && (
        <div className="PricingBanner__close-wrapper" onClick={onDismiss}>
          <Icon name="times muted" className="PricingBanner__close" />
        </div>
      )}
    </div>
  )
}

export default PricingBanner
