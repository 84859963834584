import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { tx } from '../../../modules/translate'
import Icon from '../../shared/Icon'
import DashboardTooltip from '../functions/DashboardTooltip'
import { MetricName } from '../../../modules/usage'
import { Color } from '../../../utils/colors'
import { DatabaseDetails } from '../../../modules/fauna/useDatabaseDetails'
import useBackupConfiguration from '../../../modules/api/useBackupConfiguration'
import getRegion from '../../../modules/auth/session/regions'

type Props = {
  color: Color
  isRoot: boolean
  dbDetails: DatabaseDetails
  usageId: string
  handleRowClick: (regionPrefix: string, db: any) => void
  handleQuerylogClick: (event: React.MouseEvent, db: any, regionPrefix: string) => void
  handleSettingsClick: (event: React.MouseEvent, db: any, regionPrefix: string) => void
  getUsageRowClassName: (histogramType: string) => string
  getMetricDisplayName: (metric: MetricName) => string
}

const DISABLED_OPACITY = 0.75

const DatabaseTableRow = ({
  color,
  isRoot,
  dbDetails,
  usageId,
  handleRowClick,
  handleQuerylogClick,
  handleSettingsClick,
  getUsageRowClassName,
  getMetricDisplayName
}: Props) => {
  const database = dbDetails.db
  const dbPath = dbDetails.databasePath
  const regionPrefix = dbDetails.regionPrefix
  const restore = dbDetails.restore

  const [hoveringRow, setHoveringRow] = useState(false)
  const [backupsEnabled, setBackupsEnabled] = useState(false)

  const isCopy = restore?.type === 'Copy'
  const { data: backupConfiguration } = useBackupConfiguration(regionPrefix, dbPath, isCopy)

  useEffect(() => {
    setBackupsEnabled(backupConfiguration?.enabled)
  }, [backupConfiguration])

  const renderDatabaseName = (name: string) => {
    if (restore) {
      return (
        <span
          className="firstColumn text-ellipsis"
          style={{ fontStyle: 'italic', opacity: DISABLED_OPACITY, paddingRight: '1px' }}
          data-hj-suppress
        >
          {restore
            ? `${name} (${restore.type}: ${_.startCase(restore.state)})`
            : name + ` (working...)`}
        </span>
      )
    } else {
      return (
        <span className="firstColumn text-ellipsis" data-hj-suppress>
          {name}
        </span>
      )
    }
  }

  return (
    <div
      key={usageId}
      className={`flex-table content ${hoveringRow && 'hovering'}`}
      onClick={() => (restore ? null : handleRowClick(regionPrefix, database))}
      onMouseEnter={() => setHoveringRow(true)}
      onMouseLeave={() => setHoveringRow(false)}
    >
      <div
        className="width-25 flex-table-card tr-link-flex dbNameColumn flex-item selected"
        style={{
          borderLeftColor: color
        }}
        data-hj-suppress
      >
        <span className="mobile-only">{tx('database.name')}</span>
        {renderDatabaseName(database.id)}
      </div>
      <div className={getUsageRowClassName('read')}>
        <span className="mobile-only">{getMetricDisplayName(MetricName.Read)}</span>
        <span>--</span>
      </div>
      <div className={getUsageRowClassName('write')}>
        <span className="mobile-only">{getMetricDisplayName(MetricName.Write)}</span>
        <span>--</span>
      </div>
      <div className={getUsageRowClassName('storage')}>
        <span className="mobile-only">{getMetricDisplayName(MetricName.DatabaseStorage)}</span>
        <span>--</span>
      </div>
      <div className={getUsageRowClassName('compute')}>
        <span className="mobile-only">{getMetricDisplayName(MetricName.DatabaseCompute)}</span>
        <span>--</span>
      </div>
      {isRoot && (
        <div className={getUsageRowClassName('regionGroup')}>
          <span className="mobile-only">Region Group</span>
          <span className="text-ellipsis text-right">{getRegion(regionPrefix).displayName}</span>
        </div>
      )}

      <>
        <div className={getUsageRowClassName('backups')}>
          <span className="mobile-only">Backups</span>
          {backupsEnabled && (
            <DashboardTooltip
              id={`${regionPrefix}/${dbPath}`}
              placement="top"
              className="actions-popover"
              iconName="check"
              iconClassName="action-control"
              contentText="Backups Enabled"
              rapOn={false}
            />
          )}
          {!backupsEnabled && (
            <DashboardTooltip
              id={`${regionPrefix}/${dbPath}`}
              placement="top"
              className="actions-popover"
              iconName="ban"
              iconClassName="action-control"
              contentText="Backups Disabled"
              rapOn={false}
            />
          )}
        </div>
        <div className={getUsageRowClassName('actions')}>
          <span className="mobile-only">{tx('databaseTable.columns.actions')}</span>
          <div style={{ display: 'flex' }}>
            <span
              onMouseEnter={() => setHoveringRow(false)}
              onMouseLeave={() => setHoveringRow(true)}
              onClick={e => handleQuerylogClick(e, database, regionPrefix)}
              id={'export-logs-' + database.id}
            >
              <DashboardTooltip
                id={`logs/${regionPrefix}/${dbPath}`}
                placement="left"
                className="actions-popover"
                iconName="chart-bar"
                iconHoverable={true}
                iconClassName="action-control"
                contentText="Export Logs"
                rapOn={false}
              />
            </span>
            <span
              onMouseEnter={() => setHoveringRow(false)}
              onMouseLeave={() => setHoveringRow(true)}
              onClick={e => (restore ? null : handleSettingsClick(e, database, regionPrefix))}
            >
              {restore ? (
                <Icon
                  name="cog"
                  wrapperClassName="icon--hoverable"
                  style={{ opacity: DISABLED_OPACITY }}
                />
              ) : (
                <DashboardTooltip
                  id={`settings/${regionPrefix}/${dbPath}`}
                  placement="left"
                  className="actions-popover"
                  iconName="cog"
                  iconHoverable={true}
                  iconClassName="action-control"
                  contentText="Settings"
                  rapOn={false}
                />
              )}
            </span>
          </div>
        </div>
      </>
    </div>
  )
}

export default DatabaseTableRow
