import axios from 'axios'
import { useState } from 'react'
import useSWR, { ConfigInterface } from 'swr'
import { RequestStatus } from '../'
import {
  FREE_USAGE_PLANS,
  TEAM_USAGE_PLANS,
  UPGRADEABLE_USAGE_PLANS,
  UsagePlan
} from '../../../config/usagePlans'
import { logAxiosError } from '../../../utils/log-helper'
import { rootSecret } from '../index'

export type BillingInfo = {
  plans: {
    usage: UsagePlan
  }
  payment_method_id: string
  company_name: string
  billing_email: string
  phone_number?: string
  free_usage_amount: number
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/account/billing`
export const CACHE = '/settings/billing'

export async function getBillingInfo() {
  try {
    const res = await axios.get<BillingInfo>(API_URL, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function updateBillingInfo(data: Partial<BillingInfo>) {
  return axios
    .put<BillingInfo>(API_URL, data, {
      headers: { secret: rootSecret() }
    })
    .then(res => res.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export default function useBillingInfo(options: ConfigInterface = {}) {
  const [updateStatus, setUpdateStatus] = useState<RequestStatus>(RequestStatus.idle)

  const billingInfo = useSWR(CACHE, getBillingInfo, options)

  const currentPlan = billingInfo.data?.plans?.usage
  const hasTeamAccess = TEAM_USAGE_PLANS.includes(currentPlan)
  const allowUpgrade = UPGRADEABLE_USAGE_PLANS.includes(currentPlan)
  const isFreePlan = FREE_USAGE_PLANS.includes(currentPlan)

  const update = {
    call: async (data: BillingInfo) => {
      try {
        if (updateStatus !== RequestStatus.idle) return

        setUpdateStatus(RequestStatus.loading)
        await billingInfo.mutate(await updateBillingInfo(data))
        setUpdateStatus(RequestStatus.idle)
      } catch (error) {
        setUpdateStatus(RequestStatus.idle)
        throw error
      }
    },
    status: updateStatus
  }

  return {
    currentPlan,
    hasTeamAccess,
    allowUpgrade,
    isFreePlan,
    billingInfo,
    update
  }
}
