import React from 'react'
import { DatabaseDetails } from '../../../modules/fauna/useDatabaseDetails'
import { MetricName, UsageData, UsageDataByDB, CompoundMetricName } from '../../../modules/usage'
import { Color } from '../../../utils/colors'
import BetaAnnouncement from '../../shared/BetaAnnouncement'

type Props = {
  histogramType: MetricName | CompoundMetricName
  colors: Color[]
  dbDetails: DatabaseDetails[]
  label: string
  rootUsage?: UsageData
  usageData: UsageDataByDB
  accountUsage: UsageData
  usageLoading: boolean
}

export default function UsageChart({
  label,
  colors,
  histogramType,
  dbDetails,
  usageData,
  rootUsage,
  accountUsage,
  usageLoading
}: Props) {
  function renderContent() {
    return <BetaAnnouncement message="Usage breakdown" overflow="wrap" />
  }

  return <div className="beta-annouce-chart-wrapper">{renderContent()}</div>
}
