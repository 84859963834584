import { useMemo } from 'react'
import { getSnapshots } from './frontdoor/backup'
import { usePagination } from '../../components/shared/usePagination'
import usePermissions from '../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

/**
 * Custom hook function to fetch snapshots using pagination. We might be trying to access
 * only the latest snapshot, thus using a different cache key.
 * @param path string database path
 * @param region string current region
 * @param pageSize number max size of result set
 * @param latestOnly boolean determines which cache key to use.
 * @param withBackupConfiguration boolean determines if snaps should have backup configs attached.
 * @returns Promise<Page>
 */
const useSnapshots = ({
  path,
  region,
  states,
  pageSize = 5,
  latestOnly = false,
  withBackupConfiguration = false
}: {
  path?: string
  region?: string
  states?: Array<string>
  pageSize?: number
  latestOnly?: boolean
  withBackupConfiguration?: boolean
}) => {
  const keyPrefix = latestOnly ? 'latestSnapshot' : 'snapshots'
  const cacheKey = useMemo(() => [keyPrefix, path, region], [path, region, keyPrefix])
  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.read
  )
  const hasReadPermissions = rolePermission && planPermission
  const data = usePagination(cacheKey, pageSize, (_, path, region, after) => {
    return getSnapshots({
      path,
      region,
      states,
      pageSize,
      after,
      with_backup_configuration: withBackupConfiguration,
      hasRequiredPermissions: hasReadPermissions
    })
  })
  return data
}

export default useSnapshots
