import { useEffect, useState } from 'react'
import { Privilege } from '../../../modules/roles'
import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import LazyFQLEditor from '../../shared/LazyFQLEditor'

type PrivilegeCardProps = {
  privilege: Privilege
  actions: {
    [key: string]: string
  }
  onRemove: (privilege: Privilege) => void
  onChange: (privilege: Privilege) => void
  isEditable?: boolean
}

export default function PrivilegeCard({
  privilege,
  actions,
  onRemove,
  onChange,
  isEditable
}: PrivilegeCardProps) {
  const [toggleIsOpen, setToggleIsOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState(null)
  const [initialActionValues, setInitialActionValues] = useState(null)
  const toggleIcon = toggleIsOpen ? 'angle-down' : 'angle-right'
  const selectedActionValue = selectedAction && privilege.actions[selectedAction]
  const schemaType = privilege.resource.collection ? privilege.resource.collection.id : 'schemas'

  useEffect(() => {
    setInitialActionValues(privilege.actions)
    // Remember the initial actions and don't update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function updateActionValue(actionName: string, actionValue: boolean | string) {
    onChange({
      ...privilege,
      actions: {
        ...privilege.actions,
        [actionName]: actionValue
      }
    })
  }

  function handleNameClick() {
    setToggleIsOpen(!toggleIsOpen)
  }

  function handleActionClick(actionName) {
    const currentActionValue = privilege.actions[actionName]

    if (isPredicate(currentActionValue)) {
      setSelectedAction(actionName)
      !toggleIsOpen && setToggleIsOpen(true)
      return
    }

    if (isEditable) {
      updateActionValue(actionName, !currentActionValue)
    }
  }

  function handleActionTabClick(actionName) {
    setSelectedAction(actionName)
  }

  function handleCodeChange(value) {
    if (!selectedAction) return
    updateActionValue(selectedAction, value)
  }

  function handleClearClick() {
    if (!selectedAction) return
    updateActionValue(selectedAction, false)
  }

  function actionTabIsActive(actionName) {
    return actionName === selectedAction
  }

  function renderActionIcon(actionName) {
    if (!initialActionValues) return

    let icon = 'code'
    let color = 'muted'
    const mode = 'fas'
    const value = privilege.actions[actionName]
    const wasChanged = value !== initialActionValues[actionName]

    if (typeof value === 'boolean' || typeof value === 'undefined') {
      if (value) {
        icon = 'check'
        color = 'success'
      } else {
        icon = 'times'
        color = 'danger'
      }
    }

    return (
      <Icon
        onClick={() => handleActionClick(actionName)}
        name={icon}
        className={`${color} clickable ${wasChanged ? 'dirty' : ''}`}
        mode={mode}
      />
    )
  }

  function renderActionTab(actionName) {
    let width = 'width-15'

    if (longKeys.includes(actionName)) {
      width = 'width-20 width-small-15'
    }

    const isActive = actionTabIsActive(actionName)
    const tabClassName = `
      card-role__code__tab
      clickable
      ${isActive ? 'is-active' : ''}
      ${width}
    `

    return (
      <div
        key={actionName}
        data-testid={`${privilege.resource.id}-${actionName}-code`}
        className={tabClassName}
        onClick={() => handleActionTabClick(actionName)}
      >
        <Icon name="code" />
      </div>
    )
  }

  function renderOption(actionKey) {
    let width = 'width-15'

    if (longKeys.includes(actionKey)) {
      width = 'width-20 width-small-15'
    }

    return (
      <div
        data-testid={`${privilege.resource.id}-${actionKey}`}
        key={actionKey}
        className={`card-role__option ${width}`}
      >
        {renderActionIcon(actionKey)}
      </div>
    )
  }

  return (
    <div data-testid={privilege.resource.id} className="card card-role">
      <div className="card-role__header">
        <div className="card-role__name width-80 clickable" onClick={handleNameClick}>
          <Icon name={toggleIcon} />
          {privilege.resource.value.id}
        </div>

        {isEditable && (
          <div className="card-role__remove width-20 display-small-none">
            <Button className="btn-icon">
              <Icon name="trash muted" />
            </Button>
          </div>
        )}
      </div>

      <div className="card-role__options">
        {Object.keys(actions).map(renderOption)}

        {isEditable && (
          <div className="card-role__remove width-small-10 display-none display-small-block">
            <Button className="btn-icon" onClick={() => onRemove(privilege)}>
              <Icon name="trash muted" />
            </Button>
          </div>
        )}
      </div>

      {toggleIsOpen && (
        <div className="card-role__code">
          <div className="card-role__code__tabs">
            {Object.keys(actions).map(renderActionTab)}
            {isEditable && (
              <div className="card-role__code__tab width-small-10 display-none display-small-block" />
            )}
          </div>

          {!selectedAction && (
            <div className="card-role__code__editor card-role__code__editor--empty">
              <p>Click in the code icon tab to see the related predicate.</p>
            </div>
          )}

          {selectedAction &&
            Object.keys(actions).map((actionName: string) => {
              if (selectedAction !== actionName) return <div key={actionName} />

              return (
                <div
                  data-testid={`${privilege.resource.id}-${actionName}-code-editor`}
                  className="card-role__code__editor"
                  key={actionName}
                  data-hj-suppress
                >
                  <LazyFQLEditor
                    value={
                      isPredicate(selectedActionValue)
                        ? selectedActionValue
                        : predicateTemplates[schemaType][selectedAction]
                    }
                    onChange={handleCodeChange}
                    height="224px"
                    readOnly={!isEditable}
                  />

                  {isPredicate(selectedActionValue) && isEditable && (
                    <Button
                      data-testid={`${privilege.resource.id}-${actionName}-code-clear`}
                      onClick={handleClearClick}
                      color="transparent card-role__code__btn-clear"
                    >
                      {tx('actions.clear')}
                    </Button>
                  )}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

const longKeys = ['unrestricted_read', 'history_read', 'history_write']

const isPredicate = value => {
  return typeof value === 'string' || typeof value === 'object'
}

const collectionTemplates = {
  read: `//Edit the template below to add a predicate condition
// Only read your own data.
/*
Lambda("ref", Equals(
  CurrentIdentity(), // logged in user
  Select(["data", "owner"], Get(Var("ref")))
))
*/`,
  write: `// Only write to your own data but
// don't change the owner of the data.
/*
Lambda(
  ["oldData", "newData"],
  And(
    Equals(CurrentIdentity(), Select(["data", "owner"], Var("oldData"))),
    Equals(
      Select(["data", "owner"], Var("oldData")),
      Select(["data", "owner"], Var("newData"))
    )
  )
)
*/`,
  create: `// Only write to your own data but
// only create data to youself.
/*
Lambda("values", Equals(CurrentIdentity(), Select(["data", "owner"], Var("values"))))
*/`,
  delete: `//Edit the template below to add a predicate condition
// Only delete your own data.
/*
Lambda("ref", Equals(
  CurrentIdentity(), // logged in user
  Select(["data", "owner"], Get(Var("ref")))
))
*/`,
  history_read: `//Edit the template below to add a predicate condition
// Only see history of your own data.
/*
Lambda("ref", Equals(
  CurrentIdentity(), // logged in user
  Select(["data", "owner"], Get(Var("ref")))
))
*/`,
  history_write: `//Edit the template below to add a predicate condition
// Only update history of your own data but
// don't change the owner of the data.
/*
Lambda(
  ["ref", "ts", "action", "data"],
  And(
    Equals(
      CurrentIdentity(), // logged in user
      Select(["data", "owner"], Get(Var("ref")))
    ),
    Equals(
      Select(["data", "owner"], Var("data")),
      Select(["data", "owner"], Get(Var("ref")))
    )
  )
)
*/`,
  unrestricted_read: `//Edit the template below to add a predicate condition
// Only allow admin users to have unrestricted access to the collection references
/*
Lambda("_", Select(["data", "isAdmin"], Get(CurrentIdentity())))
*/`
}

const indexTemplates = {
  unrestricted_read: `//Edit the template below to add a predicate condition
// Provides unrestricted read to indexe's covered values if quering your own data.
/*
Lambda("terms", Equals(
  Var("terms"),
  [CurrentIdentity()], // logged in user
))
*/`,
  read: `//Edit the template below to add a predicate condition
// Only read if querying your own data.
/*
Lambda("terms", Equals(
  Var("terms"),
  [CurrentIdentity()], // logged in user
))
*/`
}

const functionTemplates = {
  call: `//Edit the template below to add a predicate condition
// Only call the function for your own data.
//Lambda("args", Equals(Var("args"), [CurrentIdentity()]))`
}

const predicateTemplates = {
  collections: collectionTemplates,
  indexes: indexTemplates,
  functions: functionTemplates,
  schemas: collectionTemplates
}
