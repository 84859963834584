export const removePII = (path: string): string => {
  const regexPII = new RegExp(
    '^(/)' + // starts with a slash (captured)
    '(collections|indexes|keys|query-notebook|graphql|console|functions|roles|providers|webshell)' + // has one of these words (captured)
    '(-new|-show|-edit)?' + // word above may have suffix (captured)
    '(/documents(?:-edit|-new)?)?' + // may be a second url group for documents (captured)
    '(?:[^@]*)' + // match anything from here on that isn't a @ (NOT captured)
    '(/@db)' + // find the @db anchor as a point of reference (captured)
      '(?:/.*)$'
  ) // everything after the @db (NOT captured)

  const dbRegexPII = new RegExp('^(/db)(-new|-edit|-show)?/.+$')

  return path.replace(regexPII, '$1$2$3$4$5').replace(dbRegexPII, '$1$2')
}
