import chroma from 'chroma-js'

export type Color = string

type ColorConfig = {
  scale?: Color[]
  mode?: chroma.InterpolationMode
  error?: Color
  warning?: Color
  default?: Color
  gray?: Color
}

export let config: ColorConfig = {
  scale: [],
  mode: 'lch',
  error: null,
  warning: null,
  default: null,
  gray: null
}

export function setConfig(newConfig: ColorConfig) {
  config = {
    ...config,
    ...newConfig
  }
}

export function generate(size: number): Color[] {
  return chroma
    .scale(config.scale)
    .mode(config.mode)
    .colors(size)
}

export function fromDataList(dataList: number[]): {} {
  const colors = chroma
    .scale(config.scale)
    .mode(config.mode)
    .colors(dataList.length)

  return dataList.reduce((obj, currentData, index) => {
    return {
      ...obj,
      [currentData]: colors[index]
    }
  }, {})
}

export function get(option: string) {
  return config[option]
}
