const TableListPlaceholder = () => {
  return (
    <>
      <tr>
        <td>
          <a role="presentation" href="!#">
            <div className="placeholder placeholder--block placeholder-dark placeholder--text placeholder--w-md"></div>
          </a>
        </td>
      </tr>
      <tr>
        <td>
          <a role="presentation" href="!#">
            <div className="placeholder placeholder--block placeholder-dark placeholder--text placeholder--w-md"></div>
          </a>
        </td>
      </tr>
      <tr>
        <td>
          <a role="presentation" href="!#">
            <div className="placeholder placeholder--block placeholder-dark placeholder--text placeholder--w-md"></div>
          </a>
        </td>
      </tr>
    </>
  )
}

export default TableListPlaceholder
