import useSWR from 'swr'
import { values } from 'faunadb'
import { withRouter } from 'react-router-dom'
import DashboardTooltip from '../functions/DashboardTooltip'
import LazyCode from '../../shared/LazyCode'
import Button from '../../shared/Button'
import * as API from './../../../modules/api'
import { tx } from '../../../modules/translate'
import { TabContent } from '../../shared/Tabs'
import { useForm } from '../../../modules/form'
import { databaseIdFromPath } from '../../../modules/databaseResource'
import { DatabasesDataResponse } from './../../../modules/api'
import { createRoleValue } from '../../../modules/roles'
import SelectRole from '../roles/SelectRole'

type FormValues = {
  name: string
  database: string
  roleId: string
}

type SubmitValues = {
  name: string
  database: values.Ref
  role: values.Ref | string
}

type Props = {
  region: string
  databasePath: string
  onSubmit: (values: SubmitValues) => void
  onCancel: () => void
  loading: boolean
}

const initialValues: FormValues = {
  name: '',
  database: '',
  roleId: 'admin'
}

function KeyForm({ databasePath, onSubmit, onCancel, loading }: Props) {
  const databaseId = databaseIdFromPath(databasePath)
  const { data: databases } = useSWR<DatabasesDataResponse>([databasePath, 'databases'], () => {
    API.selectDatabase(databasePath)
    return API.databases()
  })
  const { formValues, handleChange, setFormValue } = useForm<FormValues>(initialValues)
  const keyValues = getKeyValues()

  function handleSubmit(event) {
    event.preventDefault()
    onSubmit(keyValues)
  }

  function getKeyValues() {
    const { roleId, name } = formValues

    return {
      database: getSelectedDatabaseRef(),
      role: createRoleValue(roleId),
      data: {
        name
      }
    }
  }

  function getSelectedDatabaseRef() {
    if (!databases) return null

    const selectedDatabase = databases.find(database => database.id === formValues.database)

    return selectedDatabase || null
  }

  function getDatabaseOptions() {
    if (!databases)
      return [
        {
          id: null,
          name: 'Loading...'
        }
      ]

    return databases.map(database => {
      return {
        id: database.id,
        name: database.id
      }
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <TabContent id="simple">
        <div className="container container--xsmall">
          <div className="form-group">
            <label htmlFor="database" className="required">
              {tx('database.name')}
            </label>
            <select
              data-hj-suppress
              value={formValues.database}
              onChange={handleChange}
              id="database"
              name="database"
            >
              <option value="">{databaseId} (Current database)</option>
              {getDatabaseOptions().map(option => (
                <option key={option.id} value={option.id}>
                  {option.id}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <div className="form-label--tooltip">
              <label htmlFor="roleId" className="required">
                {tx('attributes.role')}
              </label>
              <DashboardTooltip
                id="roleid"
                placement="left"
                contentText={
                  <div>
                    <div>
                      <b>Admin:</b> {tx('tooltips.keyTypes.admin')}
                    </div>
                    <div>
                      <b>Server:</b> {tx('tooltips.keyTypes.server')}
                    </div>
                  </div>
                }
              />
            </div>

            <SelectRole
              required
              dbPath={databasePath}
              onChange={roleId => setFormValue('roleId', roleId)}
              value={formValues.roleId}
            />
          </div>

          <div className="form-group">
            <label htmlFor="name" className="optional">
              {tx('keys.name')} {tx('attributes.name')}
            </label>
            <input
              onChange={handleChange}
              value={formValues.name}
              id="name"
              name="name"
              type="text"
              autoFocus
            />
          </div>
        </div>
      </TabContent>

      <TabContent id="fql">
        <div className="form-group">
          <label htmlFor="">{tx('attributes.faunaData')}</label>
          <LazyCode code={keyValues} />
        </div>
      </TabContent>

      <div className="container container--xsmall">
        <div className="form-actions">
          <Button onClick={onCancel} color="secondary">
            {tx('actions.cancel')}
          </Button>

          <Button type="submit" color="success" loading={loading}>
            {tx('actions.save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

KeyForm.defaultProps = {
  loading: false
}

export default withRouter(KeyForm)
