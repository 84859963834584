import { useMemo } from 'react'
import Modal from '../../shared/Modal'
import ModalHeader from '../../shared/ModalHeader'
import ModalBody from '../../shared/ModalBody'
import routes from '../../../config/routes'

type OAuthErrorProps = {
  isOpen: boolean
  toggle: () => void
  method: string
}

const METHOD_NAMES = {
  github: 'GitHub',
  netlify: 'Netlify'
}

const getMethodName = method => {
  return method in METHOD_NAMES ? METHOD_NAMES[method] : method
}

const OAuthError = ({ isOpen, toggle, method }: OAuthErrorProps) => {
  const methodName = useMemo(() => getMethodName(method), [method])

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader onClose={toggle}>Email address already registered</ModalHeader>
      <ModalBody>
        <p className="margin-bottom-4 line-height-150">
          Our records show that someone with this email address has already signed up via{' '}
          {methodName}. Please{' '}
          <a href={routes.auth.signIn.path} onClick={toggle}>
            return to sign in
          </a>
          , and click the {methodName} button to sign in.
        </p>
        <button onClick={toggle} type="button" className="btn btn-success btn-block btn-large">
          Return to sign in
        </button>
        <a href="http://www2.fauna.com/contact-us" className="center padding-y-3 font-size-14">
          Contact support
        </a>
      </ModalBody>
    </Modal>
  )
}

export default OAuthError
