import { ResourceConfig } from './types'

export default function createTypes({ name, plural }: ResourceConfig) {
  const label = name.toUpperCase()
  const pluralLabel = plural.toUpperCase()

  return {
    collection: {
      fetch: {
        request: `${pluralLabel}_FETCH_REQUEST`,
        success: `${pluralLabel}_FETCH_SUCCESS`,
        error: `${pluralLabel}_FETCH_ERROR`
      },
      fetchList: {
        request: `${pluralLabel}_FETCHLIST_REQUEST`,
        success: `${pluralLabel}_FETCHLIST_SUCCESS`,
        error: `${pluralLabel}_FETCHLIST_ERROR`
      },
      fetchDocumentsByTerms: {
        request: `${pluralLabel}_FETCHDOCUMENTSBYTERMS_REQUEST`,
        success: `${pluralLabel}_FETCHDOCUMENTSBYTERMS_SUCCESS`,
        error: `${pluralLabel}_FETCHDOCUMENTSBYTERMS_ERROR`
      },
      fetchDocumentByRef: {
        request: `${pluralLabel}_FETCHDOCUMENTBYREF_REQUEST`,
        success: `${pluralLabel}_FETCHDOCUMENTBYREF_SUCCESS`,
        error: `${pluralLabel}_FETCHDOCUMENTBYREF_ERROR`
      }
    },
    resource: {
      create: {
        request: `CREATE_${label}_REQUEST`,
        success: `CREATE_${label}_SUCCESS`,
        error: `CREATE_${label}_ERROR`,
        load: `CREATE_${label}_LOAD`
      },
      update: {
        request: `UPDATE_${label}_REQUEST`,
        success: `UPDATE_${label}_SUCCESS`,
        error: `UPDATE_${label}_ERROR`,
        load: `UPDATE_${label}_LOAD`
      },
      remove: {
        request: `REMOVE_${label}_REQUEST`,
        success: `REMOVE_${label}_SUCCESS`,
        error: `REMOVE_${label}_ERROR`,
        load: `REMOVE_${label}_LOAD`
      }
    }
  }
}
