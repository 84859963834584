import moment from 'moment'
import { values } from 'faunadb'
import { useState, useMemo, useContext } from 'react'
import * as backupApi from '../../../modules/api/frontdoor/backup'
import useSnapshots from '../../../modules/api/useSnapshots'
import useRestores from '../../../modules/api/useRestores'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import { Select } from '../../shared/Select'
import { FormValidationLabel } from '../../pages/providers/ProviderForm'
import { useHistory } from 'react-router'
import routes, { createRoute } from '../../../config/routes'
import { databaseParentPathFromPath } from '../../../modules/databaseResource'
import { useDispatch } from 'react-redux'
import * as AlertTypes from '../../../modules/alert/types'
import { showAlert } from '../../../modules/alert/actions'
import { logStatusCode } from '../../../utils/log-helper'
import { useGlobalFormik } from '../../../utils/useGlobalFormik'
import { GlobalDispatchContext } from '../../../global-state'
import { DRAWER_TOGGLE } from '../../../global-state/reducer'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

type RestoreProps = {
  onClose: () => void
  onCancel: () => void
  dbPath: string
  region: string
  snapshot?: { data: backupApi.Snapshot; ref: values.Ref }
  shouldRedirectOnSubmit?: boolean
}

const Restore: React.FC<RestoreProps> = ({
  onClose,
  onCancel,
  region,
  dbPath,
  snapshot,
  shouldRedirectOnSubmit = true
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const parentPath = databaseParentPathFromPath(dbPath)

  const { revalidate: refreshRestores } = useRestores(parentPath, region, () => {})

  const { rolePermission, planPermission } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.write
  )
  const hasWritePermissions = rolePermission && planPermission

  const [selectedSnapshot, setSelectedSnapshot] = useState(snapshot)

  const contextDispatch = useContext(GlobalDispatchContext)

  const {
    values,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    isSubmitting,
    errors
  } = useGlobalFormik({
    initialValues: {
      confirm: false,
      snapshotRefId: snapshot ? snapshot.ref['@ref']?.id : ''
    },
    validate: values => {
      const errors: any = {}
      if (!values.confirm) {
        errors.confirm = 'Please check the box to confirm.'
      } else if (!values.snapshotRefId) {
        errors.snapshotRefId = 'You must choose a snapshot.'
      } else if (selectedSnapshot?.data?.state !== 'Complete') {
        errors.snapshotRefId =
          'Cannot perform a restore from a snapshot that has not successfully completed.'
      } else if (selectedSnapshot.ref['@ref']?.id !== values.snapshotRefId) {
        errors.snapshotRefId = 'Something unexpected happened, please try again.'
      }
      return errors
    },
    onSubmit: ({ snapshotRefId }, { setSubmitting }) => {
      backupApi
        .createRestore({
          region,
          path: dbPath,
          snapshot_ref_id: snapshotRefId,
          hasRequiredPermissions: hasWritePermissions
        })
        .then(() => {
          const destinationIsRoot = parentPath ? false : true
          refreshRestores()
          // We want to redirect to the parent if we kick off a restore on a specific database page.
          //  In other words not from the backups page or databases table.
          const redirectPath = destinationIsRoot
            ? routes.home.path
            : createRoute(routes.db.byId.path, parentPath, region)
          if (shouldRedirectOnSubmit) {
            history.push(redirectPath)
            // set all drawers to not visible
            contextDispatch({
              type: DRAWER_TOGGLE,
              payload: ''
            })
          } else onClose()
        })
        .catch(error => {
          const code = error.response?.code || error.response?.status
          logStatusCode(code, error)
          if ([400, 403].includes(code))
            if (error.response?.data?.reason)
              dispatch(showAlert(error.response.data.reason, AlertTypes.ERROR))
            else dispatch(showAlert('Unable to initiate restore request.', AlertTypes.ERROR))
          else dispatch(showAlert('Something went wrong!', AlertTypes.ERROR))
        })
        .finally(() => setSubmitting(false))
    }
  })

  // fetch snapshots if no snapshot is given
  const paginatedSnapshots = useSnapshots({
    path: dbPath,
    region
  })

  const snapshots = useMemo(() => {
    return paginatedSnapshots?.list ? paginatedSnapshots.list : []
  }, [paginatedSnapshots.list])

  const snapshotOptions = snapshot
    ? [
        {
          value: snapshot.ref['@ref']?.id,
          label: moment(snapshot.data?.snapshot_ts['@ts']).format('MMM D, YYYY h:mm a')
        }
      ]
    : (snapshots || []).map(d => ({
        value: d.ref['@ref']?.id,
        label: moment(d.data?.snapshot_ts['@ts']).format('MMM D, YYYY h:mm a')
      }))

  return (
    <div className="database-form">
      <p className="font-size-14">
        Once the restore process has begun, your database will be unavailable until complete. Any
        data stored after the snapshot was taken will be lost.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Database</label>
          <p>{dbPath}</p>
        </div>
        <div className="form-group">
          <label>Snapshot</label>
          <Select
            isDisabled={snapshotOptions.length === 1 ? true : false}
            options={snapshotOptions}
            value={snapshotOptions.find(s => s.value === values.snapshotRefId)}
            onChange={({ value }) => {
              setFieldValue('snapshotRefId', value)
              // @ts-ignore
              setSelectedSnapshot(snapshots.find(s => s.ref['@ref']?.id === value))
            }}
          />
          {errors.snapshotRefId && (
            <FormValidationLabel
              isInvalid={Boolean(errors.snapshotRefId)}
              labelText={`${errors.snapshotRefId}`}
            />
          )}
        </div>

        <div className="form-group tip tip--warning">
          <p className="tip__label">
            <Icon name="exclamation-triangle" className="margin-right-2" />
            Warning
          </p>
          <p className="tip__text">
            This will stop all traffic to this database. Are you sure you wish to proceed?
          </p>
        </div>

        <div className="form-group">
          <div className="form-checkbox">
            <label htmlFor="confirm">
              <input
                checked={values.confirm}
                type="checkbox"
                id="confirm"
                name="confirm"
                onChange={handleChange}
              />
              I understand this database will be unavailable until the restore process is completed
            </label>
          </div>
          <FormValidationLabel
            isInvalid={Boolean(errors.confirm)}
            labelText={`${errors.confirm && touched.confirm ? errors.confirm : ''}`}
          />
        </div>

        <div className="container container--xsmall">
          <div className="form-actions">
            <Button onClick={onCancel} color="secondary">
              CANCEL
            </Button>
            <Button type="submit" color="success" loading={isSubmitting}>
              BEGIN RESTORE
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Restore
