import { useState, useEffect } from 'react'
import { Match, RouterHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { query } from 'faunadb'
import ContentHeader from '../../layouts/ContentHeader'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'
import routes, { createRoute } from '../../../config/routes'
import IndexForm from './IndexForm'
import * as IndexResource from '../../../modules/indexResource'

type Props = {
  history: RouterHistory
  match: Match
  updateIndex: typeof IndexResource.actions.update
  fetchIndexes: typeof IndexResource.actions.fetchList
  browseIndex: typeof query.Index
}

function IndexesEdit({ history, match, updateIndex, fetchIndexes, browseIndex }: Props) {
  const databasePath = match.params.dbPath
  const region = match.params.region
  const indexId = String(match.params.indexId)
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('simple')

  useEffect(() => {
    document.title = `${tx('index.actions.edit')} - Fauna`
  }, [])

  useEffect(() => {
    fetchIndexes({ databasePath })
  }, [indexId, databasePath, fetchIndexes])

  function onCancel() {
    history.goBack()
  }

  function onSubmit(values) {
    setIsLoading(true)

    updateIndex({
      values: {
        ref: browseIndex.ref,
        data: values
      },
      parentPath: databasePath,
      databasePath,
      onComplete: (payload, error) => {
        if (error) {
          setIsLoading(false)
          return
        }

        history.push(
          createRoute(routes.indexes.byId.path, databasePath, region).replace(
            ':indexId',
            values.name
          )
        )
      }
    })
  }

  function onRemove() {
    history.push(createRoute(routes.indexes.index.path, databasePath, region))
  }

  function getValues() {
    return {
      name: browseIndex.name,
      unique: browseIndex.unique,
      serialized: browseIndex.serialized,
      source: IndexResource.getClassIdFromIndex(browseIndex),
      terms: browseIndex.terms,
      values: browseIndex.values
    }
  }

  function renderTabs() {
    return (
      <Tabs position="right">
        <Tab id="simple">{tx('tabs.simple')}</Tab>
        <Tab id="fql">{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  if (!browseIndex) {
    return <div className="section">{tx('loading.loading')}</div>
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader tabs={renderTabs()}>{tx('index.actions.edit')}</ContentHeader>

      <div className="padding-y-3">
        <IndexForm
          isEdit
          initialValues={getValues()}
          databasePath={databasePath}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onRemove={onRemove}
          loading={isLoading}
        />
      </div>
    </TabsProvider>
  )
}

export default connect(
  (state, { match }) => {
    const { dbPath, indexId } = match.params
    const indexes = IndexResource.all(state, dbPath)
    const browseIndex = indexes && IndexResource.byName(indexId, state, dbPath)

    return {
      browseIndex
    }
  },
  {
    fetchIndexes: IndexResource.actions.fetchList,
    updateIndex: IndexResource.actions.update
  }
)(
  withBreadcrumbs(({ location, match }, routes) => {
    return [
      {
        label: match.params.indexId,
        path: createRoute(
          routes.indexes.byId.path,
          match.params.dbPath,
          match.params.region
        ).replace(':indexId', match.params.indexId)
      },
      {
        label: 'Edit',
        path: location.pathname
      }
    ]
  })(IndexesEdit)
)
