import axios from 'axios'
import getRegion from '../../auth/session/regions'
import { defaultRegion, bearerToken } from '../index'
import { logAxiosError } from '../../../utils/log-helper'

const BASE_FDS_URL = `${process.env.REACT_APP_FRONTDOOR_URL}/api/v1/logs`

export const QUERYLOG_STATES_ARRAY = [
  'Pending',
  'Requested',
  'DoesNotExist',
  'CreatingNewUrl',
  'Complete',
  'Failed',
  'TimedOut'
]

// Pretty names for displaying status
export const LOG_STATE_NAMES = {
  CreatingNewUrl: 'In Progress',
  Requested: 'In Progress',
  Pending: 'In Progress',
  DoesNotExist: 'No Logs',
  TimedOut: 'Timed Out'
}

export type QuerylogState = typeof QUERYLOG_STATES_ARRAY[number]

export type Querylog = {
  request_id: string
  database?: string
  region_group: string
  state: string
  time_start: string
  time_end: string
  updated_at: string
}

export type CreateQuerylogInput = {
  database?: string
  region_group: string
  time_start: string
  time_end: string
}

export type ListQuerylogInput = {
  states: typeof QUERYLOG_STATES_ARRAY
  time_start?: string
  time_end?: string
  max_results?: number
  next_token?: string
}

export type ListQuerylogResultMember = Querylog & {
  version: number
  presigned_url?: string
  presigned_url_expiration_time?: string
}

export type ListQuerylogResult = { data: Array<ListQuerylogResultMember>; after?: string }

const getHeaders = regionPrefix => {
  const authorizationToken = bearerToken(regionPrefix)
  const transformedRegion = getRegion(regionPrefix)?.frontdoorPrefix
  return { Authorization: authorizationToken, region_group: transformedRegion }
}

export const createQueryLog = async ({
  region_group,
  database,
  time_end,
  time_start
}: CreateQuerylogInput): Promise<Querylog> => {
  // no-op if the user lacks permissions
  const url = `${BASE_FDS_URL}?type=query`
  const transformedRegion = getRegion(region_group).frontdoorPrefix
  const body: CreateQuerylogInput = {
    region_group: transformedRegion,
    time_end,
    time_start
  }
  if (database) body.database = `${transformedRegion}/${database}`
  const { data } = await axios
    .post<Querylog>(url, body, {
      headers: getHeaders(region_group)
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
  return data
}

export const listQueryLogs = async (
  states: QuerylogState[],
  page_size: number,
  after: string
): Promise<ListQuerylogResult> => {
  // no-op if the user lacks permissions
  const url = BASE_FDS_URL + `/_list?type=query`
  const body = { states, max_results: page_size }
  if (after) body['next_token'] = after
  const { data } = await axios
    .post<{ results: ListQuerylogResultMember[]; next_token?: string }>(url, body, {
      headers: getHeaders(defaultRegion().regionPrefix)
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
  return {
    data: data.results,
    after: data?.next_token
  }
}

export const createLogUrl = async (request_id: string) => {
  if (!request_id) return
  const url = BASE_FDS_URL + `/${request_id}/url?type=query`
  const body = { request_id }
  return axios
    .post<Querylog>(url, body, {
      headers: getHeaders(defaultRegion().regionPrefix)
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export const getQueryLog = async (request_id: string) => {
  if (!request_id) return
  const url = BASE_FDS_URL + `/${request_id}?type=query`
  return axios
    .get<ListQuerylogResultMember>(url, {
      headers: getHeaders(defaultRegion().regionPrefix)
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}
