import { Link, useParams } from 'react-router-dom'
import Well from '../../shared/Well'
import Icon from '../../shared/Icon'
import routes, { createRoute } from '../../../config/routes'
import { tx } from '../../../modules/translate'

type EmptyRolesProps = {
  databasePath?: string | null
}

export default function EmptyRoles({ databasePath }: EmptyRolesProps) {
  const { region } = useParams()
  return (
    <div className="card">
      <Well
        shadow
        title={tx('role.firstRole')}
        icon={<Icon name="user-lock" />}
        action={
          <Link
            className="btn btn-success"
            to={createRoute(routes.roles.new.path, databasePath || '', region)}
          >
            {tx('role.newCustomRole')}
          </Link>
        }
        description={
          <div className="container container--small">
            <p>
              Users and requests are associated with a built-in or custom role defining actions that
              can be performed and the resources that can be acted upon.
            </p>
          </div>
        }
      />
    </div>
  )
}
