import { tx } from '../../modules/translate'

export default function Footer() {
  const WEBSITE_TERMS_OF_USE_URL = 'https://fauna.com/terms'
  const WEBSITE_PRIVACY_POLICY = 'https://fauna.com/privacy'

  return (
    <div className="footer">
      <ul className="links">
        <li>{tx('footer.copyright', [new Date().getFullYear().toString()])}</li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={WEBSITE_TERMS_OF_USE_URL}>
            {tx('footer.terms')}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={WEBSITE_PRIVACY_POLICY}>
            {tx('footer.privacy')}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_STATUS_URL}>
            Status
          </a>
        </li>
      </ul>
    </div>
  )
}
