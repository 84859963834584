import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { tx } from '../../../modules/translate'
import { ConsoleDrawer } from '../../shared/ConsoleDrawer'
import Button from '../../shared/Button'
import { showAlert } from '../../../modules/alert/actions'
import { getErrorMessage } from '../../../utils/error'
import { deleteAccountKey, AccountKey } from '../../../modules/api/frontdoor/keys'

export interface AccountKeysDrawerProps {
  show: boolean
  onClose: () => void
  onSuccess: () => void
  accountKey: AccountKey
}

export const AccountKeysDeleteDrawer: FC<AccountKeysDrawerProps> = ({
  show,
  accountKey,
  onClose,
  onSuccess
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onLocalClose = () => {
    onClose()
  }

  const handleSubmit = async () => {
    setLoading(true)
    const { key_name, user_id } = accountKey
    await deleteAccountKey({ name: key_name, user_id })
      .then(() => {
        dispatch(showAlert('Key deleted successfully!', 'success'))
        onSuccess()
      })
      .catch(error => {
        dispatch(showAlert(getErrorMessage(error), 'error'))
      })
      .finally(() => {
        onLocalClose()
        setLoading(false)
      })
  }

  return (
    <ConsoleDrawer open={show} onClose={onLocalClose} header={tx('accountKeys.actions.delete')}>
      <p>{tx('accountKeys.messages.delete.first', [accountKey?.key_name])}</p>
      <p>
        <strong>{tx('accountKeys.messages.delete.second')}</strong>
      </p>
      <div className="form-actions">
        <Button onClick={onLocalClose} color="secondary">
          {tx('actions.cancel')}
        </Button>
        <Button type="submit" color="success" loading={loading} onClick={handleSubmit}>
          {tx('accountKeys.actions.deleteConfirm')}
        </Button>
      </div>
    </ConsoleDrawer>
  )
}
