import * as React from 'react'

type Props = {
  children?: React.ReactNode
  tabs?: React.ReactNode
  actions?: React.ReactNode
  divider?: boolean
  className?: string
}

export default function ContentHeader({ children, tabs, divider, className, actions }: Props) {
  const dividerClassName = divider ? 'content-header--divider' : ''

  return (
    <div className={`content-header ${dividerClassName} ${className}`}>
      <div className="content-header__title" data-testid="db-name-header">
        {children}
      </div>
      <div className="content-header__actions">{actions}</div>
      <div className="content-header__tabs">{tabs}</div>
    </div>
  )
}
