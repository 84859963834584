import * as React from 'react'
import Well from '../../shared/Well'
import Icon from '../../shared/Icon'
import { Link } from 'react-router-dom'
import { tx } from '../../../modules/translate'
import routes, { createRoute } from '../../../config/routes'

type Props = {
  displayDescription: boolean
  databasePath: string
  region?: string
}

export default function EmptyClass({ displayDescription, databasePath, region }: Props) {
  const newClassPath = createRoute(routes.classes.new.path, databasePath, region)

  return (
    <Well
      shadow
      title={tx('class_.empty.title')}
      icon={<Icon name="table" />}
      description={
        displayDescription && (
          <div className="container container--small">
            <p>{tx('class_.messages.empty')}</p>
          </div>
        )
      }
      action={
        <Link to={newClassPath} className="btn btn-success" data-testid="newCollection">
          {tx('class_.actions.new')}
        </Link>
      }
    />
  )
}

EmptyClass.defaultProps = {
  displayDescription: true
}
