import { useForm } from '../../../modules/form'
import { tx } from '../../../modules/translate'
import LazyFQLEditor from '../../shared/LazyFQLEditor'
import LazyCode from '../../shared/LazyCode'
import Button from '../../shared/Button'
import { TabContent } from '../../shared/Tabs'
import { useMemo } from 'react'
import SelectRole from '../roles/SelectRole'
import { createRoleValue } from '../../../modules/roles'

type FunctionFormValues = {
  name: string
  body: string
  roleId: string
}

type FunctionFormProps = {
  dbPath: string
  initialValues?: FunctionFormValues
  isLoading?: boolean
  onSubmit: (values: FunctionFormValues) => void
  onCancel?: () => void
}

export const defaultValues = {
  name: '',
  body: 'Query(Lambda(["x"], Add(Var("x"), Var("x"))))',
  roleId: ''
}

const FunctionForm = ({
  initialValues,
  onSubmit,
  isLoading,
  onCancel,
  dbPath
}: FunctionFormProps) => {
  const { formValues, handleChange, setFormValue } = useForm<FunctionFormValues>(
    initialValues || defaultValues
  )

  const fqlFormValues = useMemo(() => {
    return {
      name: formValues.name,
      role: createRoleValue(formValues.roleId),
      body: {
        '@code': formValues.body
      }
    }
  }, [formValues.body, formValues.name, formValues.roleId])

  const handleSumit = event => {
    event.preventDefault()
    onSubmit(formValues)
  }

  return (
    <form action="" onSubmit={handleSumit}>
      <TabContent id="simple">
        <div className="form-group">
          <div className="container container--xsmall">
            <label className="required" htmlFor="name">
              Function Name
            </label>
            <input
              onChange={handleChange}
              value={formValues.name}
              id="name"
              name="name"
              type="text"
              required
              autoFocus
            />
          </div>
        </div>

        <div className="form-group">
          <div className="container container--xsmall">
            <label className="optional" htmlFor="roleId">
              Role
            </label>

            <SelectRole
              dbPath={dbPath}
              onChange={roleId => setFormValue('roleId', roleId)}
              value={formValues.roleId || ''}
            >
              <option value="">None</option>
            </SelectRole>
          </div>
        </div>

        <div className="form-group" data-hj-suppress>
          <label className="required" htmlFor="name">
            Function Body
          </label>
          <LazyFQLEditor value={formValues.body} onChange={value => setFormValue('body', value)} />
        </div>
      </TabContent>

      <TabContent id="fql">
        <div className="form-group">
          <label htmlFor="">{tx('attributes.faunaData')}</label>
          <LazyCode code={fqlFormValues} />
        </div>
      </TabContent>

      <div className="form-actions container container--xsmall">
        {onCancel && (
          <Button onClick={onCancel} color="secondary">
            {tx('actions.cancel')}
          </Button>
        )}

        <Button type="submit" color="success" loading={isLoading}>
          {tx('actions.save')}
        </Button>
      </div>
    </form>
  )
}

export default FunctionForm
