import * as React from 'react'
import cn from 'classnames'

type Props = {
  password: string
  passwordError: string
  passwordTouched: boolean
  passwordLabel: string
  id: string
  className: string
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  setFieldTouched: Function
  validationLabel: React.ReactElement
}

export default function PasswordInput(props: Props): React.ReactElement {
  return (
    <div className="form-group">
      <label className="required" htmlFor={props.id}>
        {props.passwordLabel}
      </label>
      <input
        value={props.password}
        onChange={props.handleChange}
        onBlur={e => {
          e.preventDefault()
          props.setFieldTouched(props.id, true)
        }}
        className={cn({
          'form-input': true,
          'valid--border': !props.passwordError && props.passwordTouched,
          'invalid--border': props.passwordError && props.passwordTouched
        })}
        id={props.id}
        name={props.id}
        type="password"
        required
      />
      {props.validationLabel}
    </div>
  )
}
