import { useContext } from 'react'
import { GlobalStateContext } from '../global-state'
import cookie from '../modules/auth/session/cookie'
import {
  Plan,
  Role,
  hasPermissions,
  PermissionDomain,
  PermissionLevel
} from 'dx-feature-permissions'

type PermissionResponse = {
  loading: boolean
  rolePermission: boolean
  planPermission: boolean
  acceptablePlans: Array<Plan>
  acceptableRoles: Array<Role>
}

/**
 * Any component or function can use this hook to check if a user has certain access rights to
 *  a feature.
 * @param domain The domain wherein the feature falls
 * @param requestedPermissionLevel Which permissions the calling component or function
 *  (read or write) requires the user to have
 * @returns PermissionResponse
 */
const usePermissions = (
  domain: PermissionDomain,
  requestedPermissionLevel: PermissionLevel
): PermissionResponse => {
  const { userRole, userPlan } = useContext(GlobalStateContext)

  const { rolePermission, planPermission, acceptablePlans, acceptableRoles } = hasPermissions({
    domain,
    role: userRole,
    plan: userPlan,
    requestedPermissionLevel,
    accountId: cookie.get().data?.account?.account_id
  })

  return {
    rolePermission,
    planPermission,
    acceptablePlans,
    acceptableRoles,
    loading: !userRole || !userPlan
  }
}

export default usePermissions
