import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { tx } from '../../../modules/translate'
import { ConsoleDrawer } from '../../shared/ConsoleDrawer'
import { DatabasesEditWithoutBreadcrumbs } from '../databases/Edit'
import CopyData from './CopyData'
import { DeleteDatabase } from './DeleteDatabase'
import Restore from './Restore'
import { Snapshot } from '../../../modules/api/frontdoor/backup'
import useBackupConfiguration from '../../../modules/api/useBackupConfiguration'

interface DatabaseSettingsDrawerProps {
  open: boolean
  onClose: () => void
  initialStage: Stage
  dbSettingsParams: {
    dbPath: string
    region: string
    snapshot?: any
    dbName?: string
  }
  showBackupSettingsOnly?: boolean
  shouldRedirectOnSubmit?: boolean
}

export enum Stage {
  SETTINGS,
  RESTORE,
  COPY,
  DELETE
}

const DatabaseSettingsDrawer: React.FC<DatabaseSettingsDrawerProps> = ({
  open,
  onClose,
  dbSettingsParams,
  initialStage,
  showBackupSettingsOnly,
  shouldRedirectOnSubmit
}) => {
  const history = useHistory()
  const [stage, setStage] = useState(initialStage)
  const [header, setHeader] = useState<string>('')
  const [snapshot, setSnapshot] = useState(dbSettingsParams.snapshot)

  const { data: backupConfiguration } = useBackupConfiguration(
    dbSettingsParams.region,
    dbSettingsParams.dbPath
  )

  useEffect(() => {
    setStage(initialStage)
  }, [initialStage])

  useEffect(() => {
    switch (stage) {
      case Stage.COPY:
        setHeader('Copy Data')
        break
      case Stage.RESTORE:
        setHeader('Restore Data')
        break
      case Stage.DELETE:
        setHeader([tx('actions.delete'), tx('database.name')].join(' '))
        break
      case Stage.SETTINGS:
        showBackupSettingsOnly
          ? setHeader('Backup Settings')
          : setHeader([tx('database.name'), tx('info.settings')].join(' '))
        break
      default:
        setHeader([tx('database.name'), tx('info.settings')].join(' '))
    }
  }, [stage])

  useEffect(() => {
    setSnapshot(dbSettingsParams.snapshot)
  }, [dbSettingsParams.snapshot])

  const onCancel = () => (initialStage === Stage.SETTINGS ? setStage(Stage.SETTINGS) : onClose())

  const handleClose = () => {
    onClose()
  }

  return (
    <ConsoleDrawer
      header={header}
      open={open}
      onClose={onClose}
      onChange={isOpen => {
        if (isOpen) {
          setStage(initialStage)
        }
      }}
    >
      {stage === Stage.RESTORE && (
        <Restore
          {...dbSettingsParams}
          snapshot={snapshot}
          onClose={handleClose}
          onCancel={onCancel}
          shouldRedirectOnSubmit={shouldRedirectOnSubmit}
        />
      )}
      {stage === Stage.COPY && (
        <CopyData
          {...dbSettingsParams}
          snapshot={snapshot}
          onClose={handleClose}
          onCancel={onCancel}
          // Hack to get the component to re-render when
          //  individual fields in dbSettingsParams get changed.
          //  Long term fix should be to destructure the dbSettingsParams
          //  object into individual states which can trigger a re-render.
          key={JSON.stringify(dbSettingsParams)}
        />
      )}
      {stage === Stage.DELETE && (
        <DeleteDatabase
          dbPath={dbSettingsParams.dbPath}
          region={dbSettingsParams.region}
          onCancel={() => {
            setStage(Stage.SETTINGS)
          }}
          onRemoveComplete={handleClose}
        />
      )}

      {stage === Stage.SETTINGS && (
        <DatabasesEditWithoutBreadcrumbs
          backupConfiguration={backupConfiguration}
          onRestore={(snapshot: Snapshot) => {
            setStage(Stage.RESTORE)
            setSnapshot(snapshot)
          }}
          onCopy={(snapshot: Snapshot) => {
            setStage(Stage.COPY)
            setSnapshot(snapshot)
          }}
          onDelete={() => {
            setStage(Stage.DELETE)
          }}
          onClose={handleClose}
          match={{ params: dbSettingsParams }}
          history={history}
          showBackupSettingsOnly={showBackupSettingsOnly}
          shouldRedirectOnSubmit={shouldRedirectOnSubmit}
        />
      )}
    </ConsoleDrawer>
  )
}

export default DatabaseSettingsDrawer
