import { useState } from 'react'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import LazyFQLEditor from '../../shared/LazyFQLEditor'
import { MemberClass } from '../../../modules/roles'

type MemberClassCardProps = {
  memberClass: MemberClass
  isEditable: boolean
  onRemove: (memberClass: MemberClass) => void
  onChange: (memberClass: MemberClass) => void
}

export default function MemberClassCard({
  memberClass,
  isEditable,
  onRemove,
  onChange
}: MemberClassCardProps) {
  const [toggleIsOpen, setToggleIsOpen] = useState(false)
  const toggleIcon = toggleIsOpen ? 'angle-down' : 'angle-right'

  function handleNameClick() {
    setToggleIsOpen(!toggleIsOpen)
  }

  function handleCodeChange(value) {
    onChange({
      ...memberClass,
      predicate: value
    })
  }

  function handleRemoveClick() {
    onRemove(memberClass)
  }

  return (
    <div className="card card-role">
      <div className="card-role__header card-role__header--inline">
        <div className="card-role__name clickable" onClick={handleNameClick}>
          <Icon name={toggleIcon} />
          {memberClass.resource.value.id}
        </div>

        {isEditable && (
          <div className="card-role__remove">
            <Button className="btn-icon" onClick={handleRemoveClick}>
              <Icon name="trash muted" />
            </Button>
          </div>
        )}
      </div>

      {toggleIsOpen && (
        <div className="card-role__code">
          <div className="card-role__code__editor" data-hj-suppress>
            <LazyFQLEditor
              readOnly={!isEditable}
              value={memberClass.predicate || memberClassTemplate}
              onChange={handleCodeChange}
              height="224px"
            />
          </div>
        </div>
      )}
    </div>
  )
}

const memberClassTemplate = `//Edit the template below to add a predicate condition
// Only vip users are members
//Lambda("ref", Select(["data", "vip"], Get(Var("ref"))))
`
