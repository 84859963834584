import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ContextRouter } from 'react-router-dom'
import * as IndexResource from '../../../modules/indexResource'
import { tx } from '../../../modules/translate'
import { Link } from 'react-router-dom'
import Icon from '../../shared/Icon'
import IndexesTable from './IndexesTable'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes, { createRoute } from '../../../config/routes'
import { TabsProvider, Tabs, Tab, TabContent } from '../../shared/Tabs'
import LazyCode from '../../shared/LazyCode'

type MapStateToProps = {
  browseIndex: Record<string, any>
  indexes: Record<string, any>[]
}

type MapDispatchToProps = {
  fetchIndexes: Function
}

type Props = ContextRouter & MapStateToProps & MapDispatchToProps

function IndexesSettings({ indexes, browseIndex, fetchIndexes, match }: Props) {
  const databasePath = String(match.params.dbPath)
  const region = String(match.params.region)
  const indexId = String(match.params.indexId)
  const [activeTab, setActiveTab] = useState('simple')

  useEffect(() => {
    document.title = `${indexId} Settings - Fauna`
  }, [indexId])

  useEffect(() => {
    fetchIndexes({ databasePath })
  }, [indexId, databasePath, fetchIndexes])

  // Waiting for the class index be active after creation
  useEffect(() => {
    const REFETCH_INDEXES_INTERVAL = 5000
    let interval

    if (browseIndex && !browseIndex.active) {
      interval = setInterval(() => {
        fetchIndexes({ databasePath })
      }, REFETCH_INDEXES_INTERVAL)
    }

    return () => clearInterval(interval)
  }, [browseIndex, databasePath, fetchIndexes])

  function renderTabs() {
    return (
      <Tabs position="right">
        <Tab id="simple">{tx('tabs.simple')}</Tab>
        <Tab id="fql">{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <div className="panel">
      <div className="panel-left">
        <div className="panel-left-header">
          <h4>{tx('index.plural')}</h4>

          <div className="panel-left-header-actions">
            <Link
              to={createRoute(routes.indexes.new.path, databasePath, region)}
              className="btn btn-subtle-link"
            >
              {tx('index.actions.new')}
            </Link>
          </div>
        </div>

        <div className="panel-left-content">
          <IndexesTable selectedIndexes={[indexId]} databasePath={databasePath} border={false} />
        </div>
      </div>

      <div className="panel-content">
        <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
          <ContentHeader tabs={renderTabs()}>
            Index Settings
            <Link
              className="btn btn-subtle-link"
              to={createRoute(routes.indexes.edit.path, databasePath, region).replace(
                ':indexId',
                indexId
              )}
            >
              <Icon name="cog" />
              Edit
            </Link>
          </ContentHeader>

          {browseIndex && (
            <div className="padding-y-4">
              <TabContent id="simple">
                <div className="form-group">
                  <label htmlFor="">Source Class</label>
                  <p data-hj-suppress>{IndexResource.getClassIdFromIndex(browseIndex)}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="">Index Name</label>
                  <p data-hj-suppress>{browseIndex.name}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="">Terms</label>
                  {browseIndex.terms && browseIndex.terms.length > 0 ? (
                    <ul>
                      {browseIndex.terms.map((term, index) => (
                        <li key={index}>{IndexResource.getTermOrValueName(term)}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>Not set</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="">Values</label>
                  {browseIndex.values && browseIndex.values.length > 0 ? (
                    <ul>
                      {browseIndex.values.map((term, index) => (
                        <li key={index}>{IndexResource.getTermOrValueName(term)}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>
                      Not set (using <code>ref</code> by default)
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <p>
                    {browseIndex.unique ? (
                      <Icon name="check-square" />
                    ) : (
                      <Icon mode="far" name="square" />
                    )}{' '}
                    {tx('attributes.unique')}
                  </p>
                </div>

                <div className="form-group">
                  <p>
                    {browseIndex.unique || browseIndex.serialized ? (
                      <Icon name="check-square" />
                    ) : (
                      <Icon mode="far" name="square" />
                    )}{' '}
                    {tx('attributes.serialized')}
                  </p>
                </div>
              </TabContent>

              <TabContent id="fql">
                <div className="form-group">
                  <label htmlFor="">{tx('attributes.faunaData')}</label>
                  <LazyCode code={browseIndex} />
                </div>
              </TabContent>
            </div>
          )}
        </TabsProvider>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { dbPath, indexId } = props.match.params
  const indexes = IndexResource.all(state, dbPath)
  const browseIndex = indexes && IndexResource.byName(indexId, state, dbPath)

  return {
    browseIndex
  }
}

const mapDispatchToProps = {
  fetchIndexes: IndexResource.actions.fetchList
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withBreadcrumbs(({ location, match }, routes) => {
    return [
      {
        label: match.params.indexId,
        path: createRoute(
          routes.indexes.byId.path,
          match.params.dbPath,
          match.params.region
        ).replace(':indexId', match.params.indexId)
      },
      {
        label: 'Settings',
        path: location.pathname
      }
    ]
  })(IndexesSettings)
)
