import { FormEvent, useEffect } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import ContentHeader from '../../layouts/ContentHeader'
import Spinner from '../../shared/Spinner'
import useProfile from '../../../modules/api/auth-service/profile'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../modules/alert/actions'
import { getErrorMessage } from '../../../utils/error'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes from '../../../config/routes'
import Button from '../../shared/Button'
import { tx } from '../../../modules/translate'
import { useForm } from '../../../modules/form'
import { RequestStatus } from '../../../modules/api'

const PersonalSettings: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { profile, update } = useProfile()
  const { handleChange, formValues, setFormValues } = useForm({
    name: '',
    email: ''
  })

  useEffect(() => {
    if (profile.data) {
      const { sign_up_method, ...formValues } = profile.data
      setFormValues(formValues)
    }
  }, [profile.data, setFormValues])

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault()
      await update.call(formValues)
      dispatch(showAlert('Profile updated successfully!', 'success'))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
    }
  }

  if (!profile.data) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <ContentHeader divider>Personal Settings</ContentHeader>

      <div className="container container--xsmall padding-y-3">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              value={formValues.name}
              onChange={handleChange}
              type="text"
              name="name"
              id="name"
              autoFocus
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="required">
              Email
            </label>
            <input
              value={formValues.email}
              onChange={handleChange}
              type="email"
              name="email"
              id="email"
              required
            />
          </div>

          <div className="form-actions">
            <Button onClick={() => history.goBack()} color="secondary">
              {tx('actions.cancel')}
            </Button>

            <Button type="submit" color="success" loading={update.status === RequestStatus.loading}>
              {tx('actions.save')}
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Personal Settings',
    path: routes.settings.profile.path
  }
])(PersonalSettings)
