import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserIdentity, getUserIdentity } from '../modules/api/auth-service/authz'
import useSWR from 'swr'
import SessionCookie from '../modules/auth/session/cookie'
import { MemberRole } from '../modules/api/auth-service/members'
import { mergeSessionData } from '../modules/cookies/sessionCookie'
import { Session } from '../modules/auth/session'

export type UserIdentityContext = UserIdentity & {
  revalidateUserIdentity?: () => Promise<UserIdentity>
  loading?: boolean
  pollUserIdentity: React.Dispatch<React.SetStateAction<boolean>>
}

const initialState: UserIdentityContext = {
  role: MemberRole.owner,
  plan: 'free',
  planSynced: true,
  plan_display_name: 'Free',
  limits: {},
  view_controls: { '*': [] },
  is_plan_deprecated: false,
  id: '',
  loading: true,
  feature_flags: { pricing_v2: false },
  pollUserIdentity: () => {}
}

export const UserIdentityContext = React.createContext<UserIdentityContext>(initialState)

/**
 * Combine the initial session data with the user identity data from the auth service
 * which will be polled every 10 seconds.
 * @param props children which will have access to userIdentity context
 * @returns react context provider
 */
export const UserIdentityProvider: React.FC<{ children: any }> = props => {
  const [userIdentity, setUserIdentity] = useState<UserIdentity>(initialState)
  const [shouldPollUserIdentity, pollUserIdentity] = useState<boolean>(false)
  const [loadingIdentity, setLoadingIdentity] = useState<boolean>(true)
  const location = useLocation()

  // We should check for backend plan updates when the user navigates to a new page
  //  and when they submit a plan upgrade request. Because this is a shared context,
  //  all pages within this route won't cause this component to re-mount so we must
  //  trigger manually using location changes.
  useEffect(() => {
    if (mutate && !loadingIdentity) {
      setLoadingIdentity(true)
      mutate()
    }
  }, [location])

  // Polling should only be kicked off manually by components that need it (PlanChangeProgress.tsx)
  //  This ensures however that polling stops if planSynced is true, regardless of which component
  //  started the polling via `polluserIdentity(true)`
  useEffect(() => {
    if (userIdentity.planSynced) {
      pollUserIdentity(false)
    }
  }, [userIdentity])

  const { data, error, isValidating, mutate } = useSWR(
    'user_identity',
    async () => {
      return await getUserIdentity()
    },
    {
      refreshInterval: shouldPollUserIdentity ? 5000 : undefined
    }
  )
  const session = SessionCookie.get()
  useEffect(() => {
    if (data && !error && !isValidating && session) {
      // Prevent component re-renders if the data hasn't changed
      //  Every update to userIdentity variable will trigger a re-render
      //  for components and pages within the ProtectedRoute which depends on
      //  this Context Provider
      if (JSON.stringify(data) !== JSON.stringify(userIdentity)) {
        setUserIdentity(data)
        // Keep local session object in sync with out-of-band plan/role/limit updates
        updateSessionUserIdentity(session.data, data)
      }
    }
    if (error) {
      // If we fail to fetch updated identity info durring polling, don't break the page,
      //  but display permissions according to the last good state which would
      //  be set when logging in and/or navigating to a new page.
      console.error('Failed to fetch user identity, using last good state', error)
    }
    if (!isValidating) {
      setLoadingIdentity(false)
    }
  }, [data, error, isValidating])

  return (
    <UserIdentityContext.Provider
      value={{
        ...userIdentity,
        revalidateUserIdentity: mutate,
        loading: loadingIdentity,
        pollUserIdentity
      }}
    >
      {props.children}
    </UserIdentityContext.Provider>
  )
}

const updateSessionUserIdentity = (session: Session, userIdentity: UserIdentity) => {
  const sessionDataAttributesToUpdate: Partial<Session> = {
    user: {
      ...session.user,
      role: userIdentity.role,
      plan: userIdentity.plan,
      planSynced: userIdentity.planSynced,
      limits: userIdentity.limits
    },
    view_controls: userIdentity.view_controls,
    feature_flags: userIdentity.feature_flags
  }
  mergeSessionData(sessionDataAttributesToUpdate)
}
