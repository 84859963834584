import axios from 'axios'
import { defaultKey } from '../'
import { logAxiosError } from '../../../utils/log-helper'

export type ClaimSessionResponse = {
  id: number
  client: {
    id: number
    name: string
  }
  request: {
    databases: {
      admin: {
        key: string
        blocked?: boolean
      }[]
    }
  }
}

const ADDONS_URL = `${process.env.REACT_APP_ACCOUNT_URL}/auth/addons`
const SESSIONS_URL = `${ADDONS_URL}/sessions`
const GRANTS_URL = `${ADDONS_URL}/grants`

export async function claimSession(sessionId: string): Promise<{ data: ClaimSessionResponse }> {
  try {
    return await axios.post(
      SESSIONS_URL,
      {
        session_id: sessionId
      },
      {
        headers: { secret: defaultKey() }
      }
    )
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

type DatabaseAllowed = {
  key: string
  database: string
  region: string
}

export async function createGrant(clientID: number, databasesAllowed: DatabaseAllowed[]) {
  try {
    return await axios.post(
      GRANTS_URL,
      {
        client_id: clientID,
        permissions: {
          databases: {
            admin: databasesAllowed
          }
        }
      },
      { headers: { secret: defaultKey() } }
    )
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export async function authorizeSession(sessionID: number, grantID: number) {
  try {
    return await axios.put(
      SESSIONS_URL,
      { session_id: sessionID, grant_id: grantID },
      { headers: { secret: defaultKey() } }
    )
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export async function invalidateSession(sessionID: number) {
  try {
    return await axios.delete(`${SESSIONS_URL}?session_id=${sessionID}`, {
      headers: { secret: defaultKey() }
    })
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}
