import * as React from 'react'
import onClickOutside from 'react-onclickoutside'

type Props = {
  children: React.ReactNode
  position?: string
  size?: string
  theme?: 'light'
  inline?: boolean
  renderItems: (toggle: () => void) => React.ReactNode
}

type State = {
  open: boolean
}

class SimpleDropdown extends React.Component<Props, State> {
  state: State = {
    open: false
  }

  static defaultProps = {
    position: 'left'
  }

  handleClickOutside = () => {
    this.setState({ open: false })
  }

  handleItemsClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  toggleDropdown = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { children, position, size, renderItems, theme, inline } = this.props
    return (
      <div
        onClick={this.toggleDropdown}
        className={`
        dropdown
        dropdown-${String(position)}
        dropdown-${String(size)}
        ${String(theme)}
        ${inline ? 'dropdown--inline' : ''}
        ${String(this.state.open && 'active')}`}
      >
        <div className="dropdown-value">{children}</div>

        <div className="dropdown-items" onClick={this.handleItemsClick}>
          {renderItems(this.toggleDropdown)}
        </div>
      </div>
    )
  }
}

export const SimpleDropdownItem: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  return <div {...props} className="dropdown-item" />
}

export default onClickOutside(SimpleDropdown)
