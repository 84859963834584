import React, { createContext, useReducer } from 'react'
import { GlobalStateInterface, initialState } from './initial-global-state'
import reducer from './reducer'

// restricting the state context to a typed interface ensures we know which values
//  are expected in the global state at any given time.
export const GlobalStateContext = createContext<GlobalStateInterface>(initialState)
// By separating the globalStateContext and globalDispatchContext, we can give components the
//  freedom to decide if they need to only read,write or both on the global state. Components
//  do this by importing GlobalStateContext or GlobalDispatchContext or both
export const GlobalDispatchContext = createContext(null)

// any component that wishes to use the global state, must pass in the children component tree
//  which will have access to the global state.
type GlobalStateProps = {
  children: React.ReactNode
}

const GlobalStateProvider: React.FC<GlobalStateProps> = props => {
  // the global state should be updated based on actions performed in the reducer.
  //  reducerState variable is a new state object which is a result of operations performed
  //  in the reducer. thus, we use reducerState as the value when building
  //  the GlobalStateContext.provider. This also ensures that writes to the global state
  //  are controlled solely by the reducer and can be isolated to one place for simplicity.
  const [reducerState, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={reducerState}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {props.children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateProvider
