import { UsagePlan } from '../config/usagePlans'
import { MemberRole } from '../modules/api/auth-service/members'

type drawerKeyVisibility = {
  [drawerKey: string]: boolean
}

// define the types for the various initial state values that will be used globally
export interface GlobalStateInterface {
  // drawerVisibility represents if a popover/drawer is currently visible on the site,
  //  regardless of which page it was opened from. Tracks the key used when creating the drawer
  //  as multiple instances of the same drawer component can exist
  //  e.g. ('dbSettings' vs 'rootDBSettings')
  drawerVisibility: drawerKeyVisibility
  // the popovers use forms. The forms should be reset on open/close actions. The open/
  //  close trigger may originate from an ambiguous ancestor component, not the form itself.
  //  must be able to trigger form reset from outside any form. see useGlobalFormik
  currentFormReset: Function
  // role and plan will affect UI conditional rendering across the app
  userRole: MemberRole
  userPlan: UsagePlan
  // when visiting the password reset page, the token and email are pulled out of the url params
  //  and stored here. Don't want them living longer in the session cookie and don't want them in
  //  the url
  passwordResetToken: string
  passwordResetEmail: string
}

export const initialState = {
  drawerVisibility: {
    '': false
  },
  currentFormReset: () => {},
  userRole: MemberRole.developer,
  userPlan: UsagePlan.free,
  passwordResetToken: '',
  passwordResetEmail: ''
}
