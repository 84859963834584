import * as React from 'react'

type Context = {
  activeTab: string
  setActiveTab: Function
}

type ProviderProps = {
  children: React.ReactNode
  activeTab: string
  setActiveTab: (arg0: string) => void
}

type TabsProps = {
  position?: string
  children: React.ReactNode
}

type TabProps = {
  id: string
  children: React.ReactNode
}

const TabsContext = React.createContext<Context>({
  activeTab: '',
  setActiveTab: () => {}
})

export function TabsProvider({ children, activeTab, setActiveTab }: ProviderProps) {
  return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabsContext.Provider>
}

export function Tabs({ position, children }: TabsProps) {
  return <ul className={`tabs tabs--${String(position)}`}>{children}</ul>
}

export function Tab({ id, children }: TabProps) {
  return (
    <TabsContext.Consumer>
      {({ activeTab, setActiveTab }) => {
        const classList = ['tabs__item']
        const isActive = activeTab === id

        if (isActive) {
          classList.push('tabs__item--active')
        }

        return (
          <li className={classList.join(' ')} onClick={() => setActiveTab(id)}>
            {children}
          </li>
        )
      }}
    </TabsContext.Consumer>
  )
}

export function TabContent({ id, children }: TabProps) {
  return (
    <TabsContext.Consumer>
      {({ activeTab }) => {
        const isActive = activeTab === id

        return <div className={!isActive ? 'display-none' : ''}>{children}</div>
      }}
    </TabsContext.Consumer>
  )
}
