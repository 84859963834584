import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

// Hook to interface with both URL and local storage for FF support
// Will return True if the FF is present in URL or true in storage
export const useFeatureFlag = (featureName: string): boolean => {
  const { search } = useLocation()
  const currentFeatures = JSON.parse(localStorage.getItem('features'))
  return useMemo(() => {
    const query = new URLSearchParams(search)
    return (
      currentFeatures[featureName] || (query.get('ff') && query.get('ff').includes(featureName))
    )
  }, [search, featureName, currentFeatures])
}
