import { createBrowserHistory } from 'history'
import { hideAlert } from './modules/alert/actions'
import { sendPageView } from './utils/analytics'

export const createHistory = (store: any) => {
  const history = createBrowserHistory()

  history.listen(location => {
    const { alert } = store.getState()
    const content = document.querySelector('.content__main')

    sendPageView(location)

    if (alert.active) {
      const timeToClose = 2500

      setTimeout(() => {
        store.dispatch(hideAlert())
      }, timeToClose)
    }

    // It works on Edge
    // .scrollTo() is not supported.
    // https://stackoverflow.com/questions/51517324/scrollto-method-doesnt-work-in-edge
    if (content) content.scrollTop = 0
  })

  return history
}
