import * as React from 'react'
import Lottie from 'react-lottie'
import lockLottie from '../../../assets/lotties/lock.json'
import ReactOtpInput from 'react-otp-input'

const animationOptions = {
  loop: false,
  autoplay: false,
  animationData: lockLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

type OtpInputProps = {
  isOtpCodeInvalid: boolean
  isDisabled: boolean
  isAnimationPaused: boolean
  onChange: (otp: string) => void
  customErrorMessage?: string
}

const OtpInput = (props: OtpInputProps) => {
  const [otpCode, setOtpCode] = React.useState('')

  React.useEffect(() => {
    setOtpCode('')
  }, [props.isOtpCodeInvalid])

  return (
    <>
      <Lottie
        options={animationOptions}
        height={200}
        width={200}
        isPaused={props.isAnimationPaused}
      />

      <div className="verbiage center">
        <h2>Enter the 6-digit code from your Authenticator app below.</h2>
      </div>

      <ReactOtpInput
        onChange={otp => {
          setOtpCode(otp)
          props.onChange(otp)
        }}
        numInputs={6}
        value={otpCode}
        containerStyle="otp-container"
        inputStyle="otp-input opacity-100"
        errorStyle="otp-input--error"
        hasErrored={props.isOtpCodeInvalid}
        shouldAutoFocus={true}
        isDisabled={props.isDisabled}
      />

      {props.isOtpCodeInvalid && (
        <p className="otp-invalid-message"> {props.customErrorMessage || 'OTP Code Invalid'} </p>
      )}
    </>
  )
}

export default OtpInput
