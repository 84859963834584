import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Match, RouterHistory, Location } from 'react-router-dom'
import * as IndexResource from '../../../modules/indexResource'
import * as ClassResource from '../../../modules/classResource'
import { tx } from '../../../modules/translate'
import { sortByName } from '../../../utils/array'
import EmptyIndex from '../databases/EmptyIndex'
import IndexesShow from './Show'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'

type IndexesIndexProps = {
  indexes: Record<string, any>[]
  classes: Record<string, any>[]
  fetchIndexes: typeof IndexResource.actions.fetchList
  fetchClasses: typeof ClassResource.actions.fetchList
  match: Match
  history: RouterHistory
  location: Location
}

function IndexesIndex({
  indexes,
  classes,
  fetchIndexes,
  fetchClasses,
  match,
  history,
  location
}: IndexesIndexProps) {
  const databasePath = match.params.dbPath
  const hasIndexes = indexes && indexes.length > 0
  const [isLoadingIndexes, setIsLoadingIndexes] = useState(true)
  const [isLoadingClasses, setIsLoadingClasses] = useState(false)
  const [firstIndex, setFirstIndex] = useState(null)

  useEffect(() => {
    setIsLoadingIndexes(true)

    fetchIndexes({
      databasePath,
      onComplete: () => setIsLoadingIndexes(false)
    })
  }, [databasePath, fetchIndexes])

  useEffect(() => {
    if (hasIndexes) {
      setFirstIndex(sortByName(indexes)[0])
      return
    }

    if (!classes) {
      setIsLoadingClasses(true)
      fetchClasses({
        databasePath,
        onComplete: () => setIsLoadingClasses(false)
      })
    }
  }, [databasePath, fetchClasses, hasIndexes, indexes, classes])

  const shouldDisplayEmptyMessage = !isLoadingIndexes && !hasIndexes && !isLoadingClasses

  if (shouldDisplayEmptyMessage) {
    return (
      <div className="card margin-top-4">
        <EmptyIndex displayAction classes={classes} databasePath={databasePath || 'root'} />
      </div>
    )
  }

  if (firstIndex) {
    const params = {
      history,
      location,
      match: {
        ...match,
        params: {
          ...match.params,
          indexId: firstIndex.name
        }
      }
    }

    return <IndexesShow {...params} />
  }

  return <div className="section center">{tx('loading.loading')}</div>
}

const mapStateToProps = (state, { match }) => {
  return {
    indexes: IndexResource.all(state, match.params.dbPath),
    classes: ClassResource.all(state, match.params.dbPath)
  }
}

const mapDispatchToProps = {
  fetchIndexes: IndexResource.actions.fetchList,
  fetchClasses: ClassResource.actions.fetchList
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withBreadcrumbs(({ location, indexes }) => {
    const hasIndexes = indexes && indexes.length > 0

    return !hasIndexes
      ? [
          {
            label: tx('index.plural'),
            path: location.pathname
          }
        ]
      : []
  })(IndexesIndex)
)
