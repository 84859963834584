import { Component } from 'react'
import Icon from './Icon'
import Button from './Button'
import onClickOutside from 'react-onclickoutside'
import React from 'react'

type Item = {
  value: string
  label: string
}

type Props = {
  items: Item[]
  value: string
  position?: 'left' | 'right'
  size?: string
  onChange: (value: string) => void
  button?: boolean
}

type State = {
  open: boolean
}

class Dropdown extends Component<Props, State> {
  state: State = {
    open: false
  }

  static defaultProps = {
    position: 'left',
    button: false
  }

  handleClickOutside = () => {
    this.setState({ open: false })
  }

  toggleDropdown = () => {
    this.setState({ open: !this.state.open })
  }

  handleItemClick = value => {
    this.props.onChange(value)
    this.toggleDropdown()
  }

  currentValue = () => {
    const { items, value } = this.props
    let current = items.find(item => item.value === value)

    if (!current) {
      current = items[0]
    }

    return current
  }

  render() {
    const { items, value, position, size, button } = this.props
    const currentValue = this.currentValue()
    const validItems = items.filter(item => item.value !== value)

    return (
      <div
        className={`
          dropdown
          dropdown-${String(position)}
          dropdown-${String(size)}
          ${String(this.state.open && 'active')}
          ${String(this.props.button && 'dropdown-button')}
        `}
      >
        {button ? (
          <Button color="primary" type="button" onClick={this.toggleDropdown}>
            {this.renderDropdownLabel(currentValue)}
          </Button>
        ) : (
          <div className="dropdown-value" onClick={this.toggleDropdown}>
            {this.renderDropdownLabel(currentValue)}
          </div>
        )}

        <div className="dropdown-items">
          {validItems.map(item => (
            <div
              key={item.value}
              className="dropdown-item"
              onClick={this.handleItemClick.bind(this, item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderDropdownLabel(currentValue) {
    return (
      <>
        {currentValue && String(currentValue.label)}
        <Icon wrapperClassName="dropdown-chevron margin-left-1" name="chevron-down" />
      </>
    )
  }
}

export default onClickOutside(Dropdown)
