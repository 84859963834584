import React, { useEffect } from 'react'
//@ts-ignore
import { Match } from 'react-router-dom'
import { tx } from '../../../modules/translate'
import ContentHeader from '../../layouts/ContentHeader'
import DatabasesTableWithRegionGroup from '../databases/DatabasesTableWithRegionGroup'
import GettingStartedCard from './GettingStartedCard'
import HelpfulResourcesCard from './HelpfulResourcesCard'
import PricingBanner from './PricingBanner'
import UsageBarChartCard from './UsageBarChartCard'
import UsageBreakdownChart from './UsageBreakdownChart'

type Props = {
  match: Match
}

export default function DashboardIndex({ match }: Props): React.ReactNode {
  const databasePath = match.params.dbPath

  useEffect(() => {
    document.title = tx('database.home') + ' - Fauna'
  }, [])

  return (
    <>
      <PricingBanner />
      <div className="display-flex justify-content-space-between align-items-center">
        <ContentHeader
          divider={false}
          className="margin-bottom-4 margin-bottom-small-none display-flex"
        >
          {tx('database.home')}
        </ContentHeader>
      </div>

      <DatabasesTableWithRegionGroup
        title={tx('database.plural')}
        databasePath={databasePath}
        region="global"
      />

      <div className="moduleRow">
        <UsageBreakdownChart />
        <UsageBarChartCard />
        <GettingStartedCard />
        <HelpfulResourcesCard />
      </div>
    </>
  )
}
