import * as React from 'react'

type Props = {
  children: React.ReactNode
}

export default function RedirectLayout(props: Props): React.ReactNode {
  return (
    <div className="auth">
      <div className="center fillScreen">
        <div className="panel--redirect">
          <div>Redirecting...</div>
        </div>
      </div>
      {props.children}
    </div>
  )
}
