import { renderSpecialType } from './specialTypes'

export const stringify = obj => {
  const replacements = []

  let string = JSON.stringify(
    obj,
    (key, value) => {
      const parsed = renderSpecialType(value)

      if (parsed) {
        const placeHolder = '$$dash_replacement_$' + replacements.length + '$$'
        replacements.push(parsed)
        return placeHolder
      }

      return value
    },
    2
  )

  replacements.forEach((replace, index) => {
    string = string.replace('"$$dash_replacement_$' + index + '$$"', replace)
  })

  if (string) {
    string = string.replace(/\(null\)/g, '()')
  }

  return string
}
