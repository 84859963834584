import { query as q } from 'faunadb'
import * as API from '../../../modules/api'
import { Region } from '../../../modules/auth/session/regions'
import { uploadGQLSchema } from '../../../modules/graphql'
import { logFaunaError } from '../../../utils/log-helper'
//@ts-ignore
import ecommerceGqlUrl from '../../ecommerce.gql'

const SAMPLE_PASSWORD = 'fauna-demo'

const EqualsCurrentIdentity = () =>
  q.Query(q.Lambda('ref', q.Equals(q.CurrentIdentity(), q.Var('ref'))))

const OwnedDocument = (ownerRefPath: string | string[]) =>
  q.Query(
    q.Lambda(
      'ref',
      q.Let(
        {
          doc: q.Get(q.Var('ref'))
        },
        q.Equals(q.CurrentIdentity(), q.Select(ownerRefPath, q.Var('doc')))
      )
    )
  )

export default async (databasePath: string, region: Region) => {
  const rootSecret = region.secret.split(':')[0]
  const secret = `${rootSecret}:${databasePath}:${'admin'}`
  const regionConfig = { secret: secret, urlString: region.url }
  const client = API.createClient(regionConfig)

  await client
    .query(
      q.Do(
        q.CreateCollection({ name: 'stores' }),
        q.CreateCollection({ name: 'products' }),
        q.CreateCollection({ name: 'customers' }),
        q.CreateCollection({ name: 'managers' }),
        q.CreateCollection({ name: 'orders' })
      )
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })

  await client
    .query(
      q.Do(
        q.CreateIndex({
          name: 'products_by_customer',
          source: q.Collection('orders'),
          terms: [{ field: ['data', 'customer'] }],
          values: [{ field: ['data', 'cart', 'product'] }]
        }),
        q.CreateIndex({
          name: 'products_by_store',
          source: q.Collection('products'),
          terms: [{ field: ['data', 'store'] }],
          values: [
            { field: ['data', 'name'] },
            { field: ['data', 'description'] },
            { field: ['data', 'price'] }
          ]
        }),
        q.CreateIndex({
          name: 'inventory_by_product',
          source: q.Collection('products'),
          terms: [{ field: ['data', 'name'] }],
          values: [
            { field: ['data', 'quantity'], reverse: true },
            { field: ['data', 'description'] },
            { field: ['ref'] }
          ]
        }),
        q.CreateIndex({
          name: 'products_by_price_high_to_low',
          source: q.Collection('products'),
          values: [
            { field: ['data', 'price'], reverse: true },
            { field: ['data', 'name'] },
            { field: ['data', 'description'] },
            { field: ['ref'] }
          ]
        }),
        q.CreateIndex({
          name: 'products_by_price_low_to_high',
          source: q.Collection('products'),
          values: [
            { field: ['data', 'price'] },
            { field: ['data', 'name'] },
            { field: ['data', 'description'] },
            { field: ['ref'] }
          ]
        })
      )
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })

  await client
    .query(
      q.CreateFunction({
        name: 'submit_order',
        role: 'server',
        body: q.Query(
          q.Lambda(
            ['customerId', 'products'], // 1- q.Get Customer and Products
            // The first step is to make sure instances exist within the
            // database for the given parameters. Therefore, we try to get
            // the Customer and all of the Products for the given Ids. q.If
            // they exist, we bind them to variables using the q.Let function
            // in order to make them available within the scope of the
            // function.
            q.Let(
              {
                customer: q.Get(q.Ref(q.Collection('customers'), q.Var('customerId'))),
                products: q.Map(
                  q.Var('products'),
                  q.Lambda(
                    'requestedProduct',
                    q.Let(
                      {
                        product: q.Get(
                          q.Ref(
                            q.Collection('products'),
                            q.Select('productId', q.Var('requestedProduct'))
                          )
                        )
                      }, // Build up a new temporal product object containing
                      // the data given as parameter together with the
                      // data retrieved from the database.
                      {
                        ref: q.Select('ref', q.Var('product')),
                        price: q.Select(['data', 'price'], q.Var('product')),
                        currentQuantity: q.Select(['data', 'quantity'], q.Var('product')),
                        requestedQuantity: q.Select(['quantity'], q.Var('requestedProduct')),
                        backorderLimit: q.Select(['data', 'backorderLimit'], q.Var('product'))
                      }
                    )
                  )
                )
              },
              q.Do(
                // 2- Check if there's enough stock
                // Next, we need to verify if there is enough stock for the
                // requested products. To do so, we evaluate all of the
                // requested products and compare their requested quantity
                // value against the current quantity value. When there is
                // not enough stock for any of the products, we print a
                // message and cancel the whole transaction with the q.Abort
                // function.
                q.Foreach(
                  q.Var('products'),
                  q.Lambda(
                    'product',
                    q.If(
                      q.LTE(
                        q.Select('requestedQuantity', q.Var('product')),
                        q.Select('currentQuantity', q.Var('product'))
                      ),
                      q.Var('product'),
                      q.Abort(
                        q.Concat([
                          'Stock quantity for Product [',
                          q.Select(['ref', 'id'], q.Var('product')),
                          '] not enough – requested at [',
                          q.ToString(q.Time('now')),
                          ']'
                        ])
                      )
                    )
                  )
                ), // 3- q.Update products stock
                // Then, we need to update the product stock quantity
                // accordingly. To do this, we update each product instance
                // through the q.Update function subtracting the requested
                // quantity from its current quantity.
                q.Foreach(
                  q.Var('products'),
                  q.Lambda(
                    'product',
                    q.Update(q.Select('ref', q.Var('product')), {
                      data: {
                        quantity: q.Subtract(
                          q.Select('currentQuantity', q.Var('product')),
                          q.Select('requestedQuantity', q.Var('product'))
                        )
                      }
                    })
                  )
                ), // 4- q.Update backordered status
                // Moving forward, we verify if the backordered status needs
                // to be updated. For that, we check if the updated stock
                // quantity is lower than the backorderLimit threshold and
                // set the backordered flag to true if so.
                q.Foreach(
                  q.Var('products'),
                  q.Lambda(
                    'product',
                    q.If(
                      q.LTE(
                        q.Subtract(
                          q.Select('currentQuantity', q.Var('product')),
                          q.Select('requestedQuantity', q.Var('product'))
                        ),
                        q.Select('backorderLimit', q.Var('product'))
                      ),
                      q.Update(q.Select('ref', q.Var('product')), {
                        data: {
                          backordered: true
                        }
                      }),
                      q.Var('product')
                    )
                  )
                ), // 5- Create Order
                // Last, we create a new Order instance with the provided
                // and retrieved data. As this is the last query to be
                // executed, the function will output the newly created
                // Order as result.
                q.Let(
                  {
                    // Build shoppingCart from products variable.
                    shoppingCart: q.Map(
                      q.Var('products'),
                      q.Lambda('product', {
                        product: q.Select('ref', q.Var('product')),
                        quantity: q.Select('requestedQuantity', q.Var('product')),
                        price: q.Select('price', q.Var('product'))
                      })
                    )
                  },
                  q.Create(q.Collection('orders'), {
                    data: {
                      customer: q.Select('ref', q.Var('customer')),
                      cart: q.Var('shoppingCart'),
                      status: 'processing',
                      creationDate: q.Time('now'),
                      shipDate: null,
                      deliveryAddress: q.Select(['data', 'address'], q.Var('customer')),
                      creditCard: q.Select(['data', 'creditCard'], q.Var('customer'))
                    }
                  })
                )
              )
            )
          )
        )
      })
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })

  await client
    .query(
      q.Let(
        {
          store1: q.Create(q.Ref(q.Collection('stores'), '301'), {
            data: {
              name: 'DC Fruits',
              address: {
                street: '13 Pierstorff Drive',
                city: 'Washington',
                state: 'DC',
                zipCode: '20220'
              }
            }
          }),
          store2: q.Create(q.Ref(q.Collection('stores'), '302'), {
            data: {
              name: 'Party Supplies',
              address: {
                street: '7529 Capitalsaurus Court',
                city: 'Washington',
                state: 'DC',
                zipCode: '20002'
              }
            }
          }),
          store3: q.Create(q.Ref(q.Collection('stores'), '303'), {
            data: {
              name: 'Foggy Bottom Market',
              address: {
                street: '4 Florida Ave',
                city: 'Washington',
                state: 'DC',
                zipCode: '20037'
              }
            }
          })
        },
        q.Let(
          {
            product1: q.Create(q.Ref(q.Collection('products'), '201'), {
              data: {
                name: 'cups',
                description: 'Translucent 9 Oz, 100 ct',
                price: 6.98,
                quantity: 100,
                store: q.Select('ref', q.Var('store2')),
                backorderLimit: 5,
                backordered: false
              }
            }),
            product2: q.Create(q.Ref(q.Collection('products'), '202'), {
              data: {
                name: 'pinata',
                description: 'Original Classic Donkey Pinata',
                price: 24.99,
                quantity: 20,
                store: q.Select('ref', q.Var('store2')),
                backorderLimit: 10,
                backordered: false
              }
            }),
            product3: q.Create(q.Ref(q.Collection('products'), '203'), {
              data: {
                name: 'pizza',
                description: 'Frozen Cheese',
                price: 4.99,
                quantity: 100,
                store: q.Select('ref', q.Var('store3')),
                backorderLimit: 15,
                backordered: false
              }
            }),
            product4: q.Create(q.Ref(q.Collection('products'), '204'), {
              data: {
                name: 'avocados',
                description: 'Conventional Hass, 4ct bag',
                price: 3.99,
                quantity: 1000,
                store: q.Select('ref', q.Var('store1')),
                backorderLimit: 15,
                backordered: false
              }
            }),
            product5: q.Create(q.Ref(q.Collection('products'), '205'), {
              data: {
                name: 'limes',
                description: 'Conventional, 1 ct',
                price: 0.35,
                quantity: 1000,
                store: q.Select('ref', q.Var('store1')),
                backorderLimit: 15,
                backordered: false
              }
            }),
            product6: q.Create(q.Ref(q.Collection('products'), '206'), {
              data: {
                name: 'limes',
                description: 'Organic, 16 oz bag',
                price: 3.49,
                quantity: 50,
                store: q.Select('ref', q.Var('store1')),
                backorderLimit: 15,
                backordered: false
              }
            }),
            product7: q.Create(q.Ref(q.Collection('products'), '207'), {
              data: {
                name: 'limes',
                description: 'Conventional, 16 oz bag',
                price: 2.99,
                quantity: 30,
                store: q.Select('ref', q.Var('store3')),
                backorderLimit: 15,
                backordered: false
              }
            }),
            product8: q.Create(q.Ref(q.Collection('products'), '208'), {
              data: {
                name: 'cilantro',
                description: 'Organic, 1 bunch',
                price: 1.49,
                quantity: 100,
                store: q.Select('ref', q.Var('store1')),
                backorderLimit: 15,
                backordered: false
              }
            }),
            product9: q.Create(q.Ref(q.Collection('products'), '209'), {
              data: {
                name: 'pinata',
                description: 'Giant Taco Pinata',
                price: 23.99,
                quantity: 10,
                store: q.Select('ref', q.Var('store2')),
                backorderLimit: 10,
                backordered: false
              }
            })
          },
          q.Let(
            {
              manager1: q.Create(q.Ref(q.Collection('managers'), '101'), {
                data: {
                  firstName: 'John',
                  lastName: 'Bender',
                  address: {
                    street: '3609  Walnut Street',
                    city: 'Jackson',
                    state: 'MS',
                    zipCode: '39201'
                  },
                  telephone: '601-576-5711'
                },
                credentials: {
                  password: SAMPLE_PASSWORD
                }
              }),
              customer1: q.Create(q.Ref(q.Collection('customers'), '101'), {
                data: {
                  firstName: 'Alice',
                  lastName: 'Appleseed',
                  address: {
                    street: '87856 Mendota Court',
                    city: 'Washington',
                    state: 'DC',
                    zipCode: '20220'
                  },
                  telephone: '208-346-0715',
                  creditCard: {
                    network: 'Visa',
                    number: '4556781272473393'
                  }
                },
                credentials: {
                  password: SAMPLE_PASSWORD
                }
              }),
              customer2: q.Create(q.Ref(q.Collection('customers'), '102'), {
                data: {
                  firstName: 'Bob',
                  lastName: 'Brown',
                  address: {
                    street: '72 Waxwing Terrace',
                    city: 'Washington',
                    state: 'DC',
                    zipCode: '20002'
                  },
                  telephone: '719-872-8799',
                  creditCard: {
                    network: 'Visa',
                    number: '4916112310613672'
                  }
                },
                credentials: {
                  password: SAMPLE_PASSWORD
                }
              }),
              customer3: q.Create(q.Ref(q.Collection('customers'), '103'), {
                data: {
                  firstName: 'Carol',
                  lastName: 'Clark',
                  address: {
                    street: '5 Troy Trail',
                    city: 'Washington',
                    state: 'DC',
                    zipCode: '20220'
                  },
                  telephone: '907-949-4470',
                  creditCard: {
                    network: 'Visa',
                    number: '4532636730015542'
                  }
                },
                credentials: {
                  password: SAMPLE_PASSWORD
                }
              })
            },
            q.Do(
              q.Create(q.Collection('orders'), {
                data: {
                  customer: q.Select('ref', q.Var('customer1')),
                  cart: [
                    {
                      product: q.Select('ref', q.Var('product4')),
                      quantity: 10,
                      price: q.Select(['data', 'price'], q.Var('product4'))
                    },
                    {
                      product: q.Select('ref', q.Var('product6')),
                      quantity: 5,
                      price: q.Select(['data', 'price'], q.Var('product6'))
                    },
                    {
                      product: q.Select('ref', q.Var('product8')),
                      quantity: 20,
                      price: q.Select(['data', 'price'], q.Var('product8'))
                    }
                  ],
                  status: 'processing',
                  creationDate: q.Time('now'),
                  deliveryAddress: q.Select(['data', 'address'], q.Var('customer1')),
                  creditCard: q.Select(['data', 'creditCard'], q.Var('customer1'))
                }
              }),
              q.Create(q.Collection('orders'), {
                data: {
                  customer: q.Select('ref', q.Var('customer3')),
                  cart: [
                    {
                      product: q.Select('ref', q.Var('product1')),
                      quantity: 25,
                      price: q.Select(['data', 'price'], q.Var('product1'))
                    },
                    {
                      product: q.Select('ref', q.Var('product3')),
                      quantity: 10,
                      price: q.Select(['data', 'price'], q.Var('product3'))
                    }
                  ],
                  status: 'processing',
                  creationDate: q.Time('now'),
                  deliveryAddress: q.Select(['data', 'address'], q.Var('customer3')),
                  creditCard: q.Select(['data', 'creditCard'], q.Var('customer3'))
                }
              }),
              q.Create(q.Collection('orders'), {
                data: {
                  customer: q.Select('ref', q.Var('customer2')),
                  cart: [
                    {
                      product: q.Select('ref', q.Var('product3')),
                      quantity: 15,
                      price: q.Select(['data', 'price'], q.Var('product3'))
                    },
                    {
                      product: q.Select('ref', q.Var('product2')),
                      quantity: 45,
                      price: q.Select(['data', 'price'], q.Var('product2'))
                    }
                  ],
                  status: 'processing',
                  creationDate: q.Time('now'),
                  deliveryAddress: q.Select(['data', 'address'], q.Var('customer2')),
                  creditCard: q.Select(['data', 'creditCard'], q.Var('customer2'))
                }
              })
            )
          )
        )
      )
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })

  const allowCrud = {
    create: true,
    read: true,
    write: true,
    delete: true
  }
  const readIndexesPrivileges = [
    'products_by_price_high_to_low',
    'products_by_price_low_to_high',
    'products_by_store',
    'inventory_by_product'
  ].map(name => ({
    resource: q.Index(name),
    actions: { read: true }
  }))

  await client
    .query(
      q.Do(
        q.CreateRole({
          name: 'manager',
          membership: {
            resource: q.Collection('managers')
          },
          privileges: [
            {
              resource: q.Collection('stores'),
              actions: allowCrud
            },
            {
              resource: q.Collection('products'),
              actions: allowCrud
            },
            {
              resource: q.Collection('orders'),
              actions: {
                read: true,
                write: true
              }
            },
            {
              resource: q.Collection('customers'),
              actions: {
                read: true
              }
            },
            {
              resource: q.Collection('managers'),
              actions: {
                read: EqualsCurrentIdentity()
              }
            },
            {
              resource: q.Index('products_by_customer'),
              actions: {
                read: true
              }
            },
            ...readIndexesPrivileges
          ]
        }),
        q.CreateRole({
          name: 'customer',
          membership: {
            resource: q.Collection('customers')
          },
          privileges: [
            {
              resource: q.Collection('stores'),
              actions: { read: true }
            },
            {
              resource: q.Collection('products'),
              actions: { read: true }
            },
            {
              resource: q.Collection('orders'),
              actions: {
                read: OwnedDocument(['data', 'customer'])
              }
            },
            {
              resource: q.Collection('customers'),
              actions: {
                read: EqualsCurrentIdentity()
              }
            },
            {
              resource: q.Function('submit_order'),
              actions: {
                call: q.Query(
                  q.Lambda(
                    ['customerId', '_'],
                    q.Equals(q.Var('customerId'), q.Select('id', q.CurrentIdentity()))
                  )
                )
              }
            },
            {
              resource: q.Index('products_by_customer'),
              actions: {
                read: q.Query(
                  q.Lambda(['customerRef'], q.Equals(q.Var('customerRef'), q.CurrentIdentity()))
                )
              }
            },
            ...readIndexesPrivileges
          ]
        })
      )
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })

  await fetch(ecommerceGqlUrl)
    .then(response => response.blob())
    .then(gqlBlob =>
      uploadGQLSchema({ schema: gqlBlob, secret: secret, region: region.regionPrefix })
    )
}
