import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { Match } from 'react-router-dom'
import DashboardTooltip from '../functions/DashboardTooltip'
import routes, { createRoute } from '../../../config/routes'
import Icon from '../../shared/Icon'
import EmptyRoles from './EmptyRoles'
import * as API from './../../../modules/api'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'
import { builtInRoles } from '../../../modules/roles'
import SecurityTabs from '../../layouts/SecurityTabs'

type RolesIndexProps = {
  match: Match
}

function RolesIndex({ match }: RolesIndexProps) {
  const { dbPath, region } = match.params
  const { data: roles } = useSWR([dbPath, 'roles'], () => {
    API.selectDatabase(dbPath)
    return API.roles()
  })

  return (
    <>
      <SecurityTabs />
      <div className="grid margin-bottom-2">
        <div className="row row--space-between">
          <Link
            to={createRoute(routes.roles.new.path, dbPath, region)}
            className="btn btn-small btn-subtle-link"
          >
            <span className="fas fa-plus-circle" />
            {tx('role.newRole')}
          </Link>
        </div>
      </div>

      <section className="margin-bottom-3">
        <div className="card-list">
          <div className="card-list-body">
            {!roles && 'Loading...'}
            {roles && roles.length === 0 && <EmptyRoles databasePath={dbPath} />}
            {roles &&
              roles.length > 0 &&
              roles.map(role => (
                <div className="card" key={role.id}>
                  <div className="card-body grid center">
                    <div className="row">
                      <div className="col">{role.id}</div>
                      <div className="col col--auto">
                        <Link
                          to={createRoute(routes.roles.edit.path, dbPath, region).replace(
                            ':roleId',
                            role.id
                          )}
                          className="btn btn-small btn-icon"
                        >
                          <Icon name="cog" className="muted" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section>
        <ul className="list list-group">
          <li className="label">{tx('role.builtInRoles')}</li>
          {builtInRoles.map(role => (
            <li key={role.id}>
              <div className="grid">
                <div className="row row--no-wrap">
                  <div className="col">{role.id}</div>
                  <div className="col col--auto">
                    <DashboardTooltip
                      id="readops"
                      placement="left"
                      iconClassName="muted"
                      contentText={
                        <div>
                          <p>{tx(`tooltips.keyTypes.${role.id}`)}</p>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  )
}

export default withBreadcrumbs(({ location, match }) => {
  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path, match.params.dbPath, match.params.region)
    },
    {
      label: tx('role.title'),
      path: location.pathname
    }
  ]
})(RolesIndex)
