import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga'
import { removePII } from '../modules/pii'
import { Location } from 'history'

let isGAInitialized = false

export const initializeAnalytics = ({ gtmId, gaId }: { gtmId?: string; gaId?: string }) => {
  if (gtmId) {
    TagManager.initialize({ gtmId })
  }

  if (gaId) {
    ReactGA.initialize(gaId)
    isGAInitialized = true
    sendPageView()
  }
}

export const sendPageView = (location: Location | typeof window.location = window.location) => {
  if (!isGAInitialized) return

  ReactGA.pageview(removePII(location.pathname) + location.search, null, '')
}
