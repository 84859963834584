import * as api from './api'
import { createTypes, createActions, createReducer, createSaga } from './resources'

export type Key = {
  name?: string
  database?: {
    value: {
      id: string
    }
  }
  data?: {
    name?: string
  }
  role: string | string[]
  ref: {
    value: {
      id: string
    }
  }
}

const resourceConfig = {
  name: 'key',
  plural: 'keys',
  namespace: 'databasePath'
}

export const types = createTypes(resourceConfig)
export const actions = createActions(types)
export const reducer = createReducer(types, resourceConfig)
export const saga = createSaga(
  types,
  actions,
  {
    fetchList: () => {
      return api.keys()
    },
    create: values => {
      return api.createKey(values)
    },
    remove: payload => {
      return api.removeKey(payload.ref)
    }
  },
  resourceConfig
)

export const all = (state: Record<string, any>, databasePath: string) => {
  return state.keys[databasePath]
}

export function getKeyNameOrId(key: Key) {
  const name = key.name || (key.data && key.data.name)

  if (name) return name

  return key.ref.value.id
}
