import { Action } from '../../utils/commonTypes'
import * as LoaderTypes from './types'

export default function reducer(state: string[] = [], action: Action): string[] {
  switch (action.type) {
    case LoaderTypes.START_LOAD:
      return [...state, action.payload.id]

    case LoaderTypes.FINISH_LOAD:
      return state.filter(id => action.payload.id !== id)

    default:
      return state
  }
}
