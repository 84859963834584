import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import * as API from './../../../modules/api'
import { ContextRouter } from 'react-router-dom'
import queryString from 'query-string'
import useSWR, { cache } from 'swr'
import * as IndexResource from '../../../modules/indexResource'
import ClassesTable from '../classes/ClassesTable'
import { tx } from '../../../modules/translate'
import { Link } from 'react-router-dom'
import Icon from '../../shared/Icon'
import Button from '../../shared/Button'
import DocumentClassCard from '../documents/DocumentClassCard'
import DocumentsTable from '../documents/DocumentsTable'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes, { createRoute } from '../../../config/routes'
import { Plug } from 'react-outlet'

type MapStateToProps = {
  databases: Record<string, any>[]
  indexes: Record<string, any>[]
}

type MapDispatchToProps = {
  fetchIndexes: Function
  createIndex: typeof IndexResource.actions.create
}

type Props = ContextRouter & MapStateToProps & MapDispatchToProps

type Page<List extends unknown[]> = {
  data: List
  after: unknown
}

const PAGE_SIZE = 50

function usePagination<List extends unknown[]>(
  key: unknown[],
  fetcher: (...args: unknown[]) => Promise<Page<List>>
) {
  const lastPageRef = useRef<null | Page<List>>(null)
  const [afters, setAfters] = useState<unknown[]>([])
  const after = afters[afters.length - 1] ?? null
  const keyWithAfter = [...key, after]
  const { error, data: page, mutate } = useSWR(keyWithAfter, fetcher, {
    dedupingInterval: 0
  })
  const isLoadingMore = !page && !error
  const isInitialLoading = isLoadingMore && !lastPageRef.current

  useEffect(() => {
    if (page) {
      lastPageRef.current = page
    }
  })

  useEffect(
    () => () => {
      lastPageRef.current = null
      setAfters([])
    },
    key
  )

  const pushAfter = () => {
    if (page?.after) {
      setAfters([...afters, page.after])
    }
  }

  const popAfter = () => {
    setAfters(afters.slice(0, -1))
  }

  const afterRemove = () => {
    mutate(async prev => {
      const page = await fetcher(...keyWithAfter)

      if (page.data.length) {
        return page
      }

      cache.delete(keyWithAfter)

      if (afters.length) {
        popAfter()

        return prev
      }

      return {
        ...prev,
        data: [] as List
      }
    }, false)
  }

  return {
    isLoadingMore,
    isInitialLoading,
    pageIndex: afters.length,
    hasPrev: afters.length > 0,
    hasNext: page?.after != null,
    list: page?.data ?? lastPageRef.current?.data ?? ([] as List),
    onPrev: popAfter,
    onNext: pushAfter,
    afterRemove
  }
}

function ClassesShow({ indexes, fetchIndexes, match, history, location }: Props) {
  const region = String(match.params.region)
  const databasePath = String(match.params.dbPath)
  const classId = String(match.params.classId)
  const { action } = queryString.parse(location.search)
  const newPath = createRoute(routes.classes.new.path, databasePath, region)
  const isLoadingIndexes = indexes === undefined
  const [filterValue, setFilterValue] = useState('')
  const page = usePagination(
    ['documents', databasePath, classId],
    (_, databasePath: string, collectionName: string, after) => {
      API.selectDatabase(databasePath)

      return API.documentsByCollection({
        collectionName,
        pageSize: PAGE_SIZE,
        after
      })
    }
  )

  useEffect(() => {
    document.title = `${String(classId)} - Fauna`
  }, [classId])

  useEffect(() => {
    fetchIndexes({ databasePath })
  }, [databasePath, fetchIndexes])

  //clear filter when classid change
  useEffect(() => {
    setFilterValue('')
  }, [classId])

  function onSearchChange(event) {
    setFilterValue(event.currentTarget.value)
  }

  function onSearchSubmit(event) {
    event.preventDefault()

    const redirectPath = createRoute(routes.instances.edit.path, databasePath, region)
      .replace(':classId', classId)
      .replace(':referenceId', filterValue)

    history.push(redirectPath)
  }

  return (
    <>
      <Plug outletId="leftPanel">
        <div className="panel">
          <div className="panel__header">
            <h4>{tx('class_.plural')}</h4>

            <div className="panel__header__actions">
              <Link to={newPath} className="btn" data-testid="newCollection">
                {tx('class_.actions.new')}
              </Link>
            </div>
          </div>

          <div className="panel__content">
            <ClassesTable border={false} selectedClasses={[classId]} databasePath={databasePath} />
          </div>
        </div>
      </Plug>

      <ContentHeader>
        {classId}
        <div className="content-header__links">
          <Link
            className="btn btn-subtle-link"
            to={createRoute(routes.classes.settings.path, databasePath, region).replace(
              ':classId',
              classId
            )}
          >
            <Icon name="cog" />
            {tx('actions.settings')}
          </Link>

          <Link
            to={createRoute(routes.indexes.new.path, databasePath, region) + `?classId=${classId}`}
            className="btn btn-subtle-link"
          >
            <Icon name="plus-circle" />
            {tx('index.actions.new')}
          </Link>
        </div>
      </ContentHeader>

      <div className="page-action-bar margin-bottom-2">
        <h4>{tx('document_.plural')}</h4>
        <Link
          className="btn btn-subtle-link"
          to={createRoute(routes.instances.new.path, databasePath, region).replace(
            ':classId',
            classId
          )}
        >
          <Icon name="plus-circle" />
          {tx('document_.actions.new')}
        </Link>
        <form
          className="form--inline form--search margin-small-left-auto"
          onSubmit={onSearchSubmit}
        >
          <div className="input-group">
            <input
              type="text"
              className="input--search"
              onChange={onSearchChange}
              value={filterValue}
              placeholder="Ref ID (e.g. 76635552112221)"
              data-testid="filter"
              required
            />
            <Button type="submit" color="primary" icon>
              <span className="fas fa-search" />
            </Button>
          </div>
        </form>
      </div>

      <div className="padding-bottom-4">
        {!isLoadingIndexes && action === 'newInstance' && (
          <DocumentClassCard classId={classId} databasePath={databasePath} />
        )}

        {!isLoadingIndexes && (
          <DocumentsTable
            databasePath={databasePath}
            documents={page.list}
            classId={classId}
            isInitialLoading={page.isInitialLoading}
            isLoadingMore={page.isLoadingMore}
            pageIndex={page.pageIndex}
            pageSize={PAGE_SIZE}
            hasPrev={page.hasPrev}
            hasNext={page.hasNext}
            onPrev={page.onPrev}
            onNext={page.onNext}
            onRemoved={page.afterRemove}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  const { dbPath } = props.match.params
  const indexes = IndexResource.all(state, dbPath)

  return {
    indexes
  }
}

export default connect(mapStateToProps, {
  createIndex: IndexResource.actions.create,
  fetchIndexes: IndexResource.actions.fetchList
})(
  withBreadcrumbs(({ location, match }) => {
    return [
      {
        label: match.params.classId,
        path: location.pathname
      }
    ]
  })(ClassesShow)
)
