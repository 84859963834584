import { useState, useEffect, useCallback } from 'react'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { ContextRouter } from 'react-router-dom'
import Modal from './shared/Modal'
import ModalHeader from './shared/ModalHeader'
import ModalBody from './shared/ModalBody'
import Button from './shared/Button'
import { fetchSiteInfo, importSiteDatabase } from '../modules/netlifyAddonApi'
import { useForm } from '../modules/form'
import routes from '../config/routes'
import { tx } from '../modules/translate'

const initialSiteData = {
  isLoading: false,
  data: undefined
}

const initialFormValues = {
  databaseName: ''
}

const NetlifyAddon = ({ location, history }: ContextRouter) => {
  const [shouldDisplay, setShouldDisplay] = useState(false)
  const [siteData, setSiteData] = useState(initialSiteData)
  const [isImporting, setIsImporting] = useState(false)
  const [error, setError] = useState()
  const { formValues, handleChange, setFormValue } = useForm(initialFormValues)

  useEffect(() => {
    const { auth: authToken } = queryString.parse(location.hash)

    if (!authToken) return

    setShouldDisplay(true)
    setSiteData({ isLoading: true })

    fetchSiteInfo(authToken)
      .then(response => {
        setFormValue('databaseName', response.data.site_id)
        setSiteData({
          isLoading: false,
          data: response.data
        })
      })
      .catch(error => {
        if (error.request && error.request.status === 404) {
          setError(tx('addons.netlify.notFoundError'))
        } else {
          setError(tx('addons.netlify.genericError'))
        }

        setSiteData({ isLoading: false })
      })

    // Bug: setFormValue is not being cached properly
    // which is causing loop when declared as a deep dependency
    // Fix: useForm should be refactored to use useCallback and
    // replace states by reducers
    // https://faunadb.atlassian.net/browse/CON-524
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggle = useCallback(() => {
    setShouldDisplay(!shouldDisplay)
  }, [shouldDisplay])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      const { auth: authToken } = queryString.parse(location.hash)

      setIsImporting(true)
      importSiteDatabase(formValues.databaseName, authToken)
        .then(() => {
          setIsImporting(false)
          history.push(routes.home.path, { hash: undefined })
          window.location.reload()
        })
        .catch(() => setIsImporting(false))
    },
    [formValues.databaseName, history, location.hash]
  )

  const renderModalBody = useCallback(() => {
    if (error) return <p>{error}</p>
    if (siteData.isLoading || !siteData.data) return <p>{tx('loading.simple')}</p>

    return (
      <>
        <p>
          {tx('addons.netlify.importDatabaseMessage1') + ' '}
          <b>
            <a target="blank" href={siteData.data.site_url}>
              {siteData.data.site_url}
            </a>
          </b>
          {' ' + tx('addons.netlify.importDatabaseMessage2')}
        </p>

        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="databaseName">{tx('database.fields.name')}</label>
            <input
              value={formValues.databaseName}
              onChange={handleChange}
              type="text"
              name="databaseName"
              id="databaseName"
              required
            />
          </div>

          <div className="form-group">
            <Button loading={isImporting} block color="success" size="large" type="submit">
              {isImporting
                ? tx('addons.netlify.importingDatabase')
                : tx('addons.netlify.importDatabase')}
            </Button>
          </div>
        </form>
      </>
    )
  }, [
    error,
    formValues.databaseName,
    handleChange,
    handleSubmit,
    isImporting,
    siteData.data,
    siteData.isLoading
  ])

  return (
    <Modal isOpen={shouldDisplay}>
      <ModalHeader onClose={toggle}>{tx('addons.netlify.importNetlifyDatabase')}</ModalHeader>
      <ModalBody>{renderModalBody()}</ModalBody>
    </Modal>
  )
}

export default withRouter(NetlifyAddon)
