import { useState } from 'react'
import { Select } from './Select'
import {
  getFullDatabasePath,
  databaseParentPathFromPath,
  isRootDatabase
} from '../../modules/databaseResource'
import Icon from './Icon'
import { components as reactSelectComponents, ControlProps } from 'react-select'
import useDatabases from '../../modules/fauna/useDatabases'
import getRegion from '../../modules/auth/session/regions'

type DatabaseSelectProps = {
  startDatabasePath: string
  startRegionPrefix: string
  onChange: (databasePath: string, regionPrefix: string) => void
  isDisabled?: boolean
  allowRoot?: boolean
}

type DatabaseOptionProps = {
  value: string
  label: string
  isParent: boolean
  onSelect: () => void
  onLevelChange: () => void
}

const CustomSingleValue = props => (
  <reactSelectComponents.SingleValue {...props}>
    {props.data.label}
  </reactSelectComponents.SingleValue>
)

const CustomControl = ({ children, ...props }: ControlProps<{}, false>) => {
  const { onControlClick } = props.selectProps
  return (
    <div onClick={onControlClick}>
      <reactSelectComponents.Control {...props}>{children}</reactSelectComponents.Control>
    </div>
  )
}

const DatabaseOption: React.FC<DatabaseOptionProps> = ({
  value,
  label,
  onSelect,
  isParent,
  onLevelChange
}) => {
  const [hoveringIcon, setHoveringIcon] = useState(false)
  const isEmptyParent = isParent && label === ''
  if (isParent)
    return (
      <div
        className={`select-option ${hoveringIcon || isEmptyParent ? 'no-focus' : ''}`}
        data-testid={value}
      >
        <span className="select-row">
          <span
            className="select-navigation"
            onClick={onLevelChange}
            onMouseEnter={() => setHoveringIcon(true)}
            onMouseLeave={() => setHoveringIcon(false)}
          >
            <Icon name="chevron-left" className="select-navigation-icon" />
          </span>
          <div className="select-divider" />
          <span className="select-option-label" onClick={onSelect}>
            {label}
          </span>
        </span>
      </div>
    )
  return (
    <div className={`select-option ${hoveringIcon ? 'no-focus' : ''}`} data-testid={value}>
      <span className="select-option-label" onClick={onSelect}>
        {label}
      </span>
      {onLevelChange && (
        <span className="select-row">
          <div className="select-divider" />
          <span
            className="select-navigation"
            onClick={onLevelChange}
            onMouseEnter={() => setHoveringIcon(true)}
            onMouseLeave={() => setHoveringIcon(false)}
          >
            <Icon name="chevron-right" className="select-navigation-icon" />
          </span>
        </span>
      )}
    </div>
  )
}

const DatabaseSelect: React.FC<DatabaseSelectProps> = ({
  startRegionPrefix,
  startDatabasePath = '',
  onChange,
  isDisabled,
  allowRoot = true
}) => {
  const [regionPrefix, setRegionPrefix] = useState(startRegionPrefix)
  const [currentLevelPath, setCurrentLevelPath] = useState(startDatabasePath)
  const [parentLevelPath, setParentLevelPath] = useState(
    databaseParentPathFromPath(startDatabasePath)
  )
  const [databaseSelectIsOpen, setDatabaseSelectIsOpen] = useState(false)

  const { data: databases, error } = useDatabases(currentLevelPath, regionPrefix)
  const areDatabasesLoading = !error && !databases

  // child db options
  const databaseOptions = (databases || []).map(d => {
    const fullPath = getFullDatabasePath(currentLevelPath, d.db)
    return {
      value: fullPath,
      label: `${fullPath} (${getRegion(d.regionPrefix).abbr})`,
      isParent: false,
      regionPrefix: d.regionPrefix,
      onLevelChange: () => {
        setDatabaseSelectIsOpen(true)
        setParentLevelPath(currentLevelPath)
        setCurrentLevelPath(fullPath)
        setRegionPrefix(d.regionPrefix)
      },
      onSelect: () => {
        setDatabaseSelectIsOpen(false)
        setRegionPrefix(d.regionPrefix)
      }
    }
  })

  // root db option
  if (allowRoot && parentLevelPath === undefined && !areDatabasesLoading && !error)
    databaseOptions.unshift({
      value: '',
      label: 'Root',
      isParent: false,
      regionPrefix: startRegionPrefix,
      onLevelChange: null,
      onSelect: () => setDatabaseSelectIsOpen(false)
    })

  // parent db option
  if (parentLevelPath !== undefined && !areDatabasesLoading && !error)
    databaseOptions.unshift({
      value: parentLevelPath,
      label: isRootDatabase(parentLevelPath)
        ? allowRoot
          ? 'Root'
          : ''
        : `${parentLevelPath} (${getRegion(regionPrefix).abbr})`,
      isParent: true,
      regionPrefix: isRootDatabase(parentLevelPath) ? startRegionPrefix : regionPrefix,
      onLevelChange: () => {
        setDatabaseSelectIsOpen(true)
        setCurrentLevelPath(parentLevelPath)
        setParentLevelPath(databaseParentPathFromPath(parentLevelPath))
      },
      onSelect: () => setDatabaseSelectIsOpen(false)
    })

  return (
    <Select
      required
      isDisabled={isDisabled}
      isSearchable={false}
      isLoading={areDatabasesLoading}
      options={databaseOptions}
      menuIsOpen={databaseSelectIsOpen}
      loadingMessage={() => 'Loading databases...'}
      noOptionsMessage={() => 'Unable to load databases'}
      onBlur={() => setDatabaseSelectIsOpen(false)}
      onControlClick={() => setDatabaseSelectIsOpen(prev => !prev)}
      customComponents={{
        Control: CustomControl,
        SingleValue: CustomSingleValue
      }}
      customFormatOptionLabel={(option: DatabaseOptionProps) => {
        return (
          <DatabaseOption
            value={option.value}
            label={option.label}
            isParent={option.isParent}
            onLevelChange={option.onLevelChange}
            onSelect={option.onSelect}
          />
        )
      }}
      className={'database-select'}
      defaultValue={
        isRootDatabase(currentLevelPath) && !allowRoot
          ? undefined
          : {
              label: isRootDatabase(currentLevelPath)
                ? 'Root'
                : `${currentLevelPath} (${getRegion(regionPrefix).abbr})`,
              value: currentLevelPath
            }
      }
      placeholder="Select a Database"
      // @ts-ignore
      onChange={({ value, regionPrefix }) => onChange(value, regionPrefix)}
    />
  )
}

export default DatabaseSelect
