import { values } from 'faunadb'
import * as api from './api'
import { createTypes, createActions, createReducer, createSaga } from './resources'

const resourceConfig = {
  name: 'index',
  plural: 'indexes',
  namespace: 'databasePath'
}

export const types = createTypes(resourceConfig)
export const actions = createActions(types)
export const reducer = createReducer(types, resourceConfig)
export const saga = createSaga(
  types,
  actions,
  {
    fetchList: () => {
      return api.indexes()
    },
    create: values => {
      return api.createIndex(values)
    },
    update: values => {
      return api.updateIndex(values.ref, values.data)
    },
    remove: payload => api.removeIndex(payload.index.ref)
  },
  resourceConfig
)

export function addDataPrefixIfItNeeds(term: string): string {
  const reservedWords = ['ts', 'ref', 'data']
  const [firstWord] = term.split('.')

  if (reservedWords.includes(firstWord)) return term
  return `data.${term}`
}

export const all = (state: Record<string, any>, databasePath: string) => {
  const indexes = state.indexes[databasePath]

  if (!indexes) return indexes

  return indexes.slice().sort((a, b) => a.name.localeCompare(b.name))
}

export const byName = (name: string, state: Record<string, any>, databasePath: string) => {
  const indexes = all(state, databasePath)

  if (!indexes) return undefined

  return indexes.find(index => index.name === name)
}

export const browseIndex = (indexes: Record<string, any>[], classId: string) => {
  if (!indexes) return undefined

  return indexes.find(index => isClassIndex(index, classId) && isBrowseIndex(index))
}

export const isClassIndex = (index: Record<string, any>, classId: string) => {
  if (Array.isArray(index.source)) {
    return false
  }

  return getClassIdFromIndex(index) === classId
}

export const isBrowseIndex = (index: Record<string, any>) => {
  const termsAreEmpty = !index.values || index.values.length === 0
  const valuesAreEmpty = !index.terms || index.terms.length === 0

  return termsAreEmpty && valuesAreEmpty
}

export const getClassIdFromIndex = (index: Record<string, any>) => {
  /*
    handle multiple source classes
    { source: [Collection("cls1"), Collection("cls2")] }
  */

  if (Array.isArray(index.source)) {
    return index.source.map(source => source.id).join(', ')
  }

  /*
    handle direct source class
    { source: Collection("cls1") }
  */
  if (index.source instanceof values.Ref) {
    return index.source.id
  }

  /*
    handle binding values
    {
      source: {
        collection: Collection("cls1"),
        fields: { x: Query(Lambda("i", Select(["data", "x"], Var("i")))) }
      }
    }
  */
  return index.source.collection.id
}

export const getTermOrValueName = (termOrValue: Record<string, any>) => {
  if (!termOrValue.field) return termOrValue.binding
  if (Array.isArray(termOrValue.field)) return termOrValue.field.join('.')
  return termOrValue.field
}

export const hasTerms = (index: Record<string, any>) => {
  return index && index.terms && index.terms.length > 0
}
