// Actions
export const DRAWER_TOGGLE = 'DRAWER_TOGGLE'
export const SET_FORM_RESET_FUNCTION = 'SET_FORM_RESET_FUNCTION'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_USER_PLAN = 'SET_USER_PLAN'
export const SET_PWD_RESET = 'SET_PWD_RESET'

// Reducer
export default (state, action) => {
  const newState = { ...state }
  const { type, payload } = action
  switch (type) {
    case DRAWER_TOGGLE:
      const drawerKey = payload
      const { drawerVisibility } = newState
      // set all but the payload key to false, no other drawer should be visible.
      //  if the payload key is empty string, the result will be all named drawers hidden
      Object.keys(drawerVisibility).forEach(key => {
        if (key !== drawerKey) drawerVisibility[key] = false
      })
      // toggle the visibility of the drawer with the payload key
      drawerVisibility[drawerKey] = !state?.drawerVisibility?.[drawerKey]
      newState.drawerVisibility = drawerVisibility
      // TODO: on DB home page, two drawers can open, rootDBSettings and dbSettings
      //  opening the second one overrides the formik form reset variable so
      //  only one form on the page gets reset during the toggle.
      if (state.currentFormReset) state.currentFormReset()
      break
    case SET_FORM_RESET_FUNCTION:
      newState.currentFormReset = payload
      break
    case SET_USER_ROLE:
      newState.userRole = payload
      break
    case SET_USER_PLAN:
      newState.userPlan = payload
      break
    case SET_PWD_RESET:
      newState.passwordResetEmail = payload.email
      newState.passwordResetToken = payload.token
      break
    default:
      throw new Error(`unknown action ${type} in context reducer`)
  }
  return newState
}
