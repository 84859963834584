import * as React from 'react'

type ModalBodyProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
}

export default function ModalBody({ children, className, ...rest }: ModalBodyProps) {
  return (
    <div {...rest} className={`modal__body ${className || ''}`}>
      {children}
    </div>
  )
}
