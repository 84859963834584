import { useState } from 'react'
import { Match, RouterHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import routes, { createRoute } from '../../../config/routes'
import ContentHeader from '../../layouts/ContentHeader'
import ClassForm, { FormTabIDs } from './ClassForm'
import * as ClassResource from '../../../modules/classResource'
import * as IndexResource from '../../../modules/indexResource'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'

type Props = {
  match: Match
  history: RouterHistory
  createClass: typeof ClassResource.actions.create
  createIndex: typeof IndexResource.actions.create
}

function ClassesNew({ match, history, createClass, createIndex }: Props) {
  const databasePath = match.params.dbPath
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(FormTabIDs.simple)

  function onCancel() {
    history.goBack()
  }

  function onSubmit(values) {
    setIsLoading(true)
    createClass({
      values,
      databasePath,
      onComplete: (_, error) => {
        if (error) {
          setIsLoading(false)
          return
        }

        redirectToClass(values.name)
      }
    })
  }

  function redirectToClass(className: string) {
    history.push(
      createRoute(routes.classes.byId.path, databasePath, match.params.region).replace(
        ':classId',
        className
      )
    )
  }

  function onFailure() {
    setActiveTab(FormTabIDs.simple)
  }

  function renderTabs() {
    return (
      <Tabs position="right">
        <Tab id={FormTabIDs.simple}>{tx('tabs.simple')}</Tab>
        <Tab id={FormTabIDs.fql}>{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader tabs={renderTabs()}>{tx('class_.actions.new')}</ContentHeader>

      <div className="padding-y-3">
        <ClassForm
          loading={isLoading}
          databasePath={databasePath}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onFailure={onFailure}
        />
      </div>
    </TabsProvider>
  )
}

export default connect(null, {
  createClass: ClassResource.actions.create,
  createIndex: IndexResource.actions.create
})(
  withBreadcrumbs(({ location, match }) => {
    return [
      {
        label: tx('class_.plural'),
        path: createRoute(routes.classes.index.path, match.params.dbPath, match.params.region)
      },
      {
        label: tx('class_.actions.new'),
        path: location.pathname
      }
    ]
  })(ClassesNew)
)
