import { useState } from 'react'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import routes, { createRoute } from '../../../config/routes'
import ContentHeader from '../../layouts/ContentHeader'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import FunctionForm from './FunctionForm'
import { createFunction, selectDatabase } from '../../../modules/api'
import { evalFQLCode } from '../../../modules/fql/eval'
import { useHistory, useParams } from 'react-router-dom'
import { showAlert, hideAlert } from '../../../modules/alert/actions'
import { useDispatch } from 'react-redux'
import { getErrorMessage } from '../../../utils/error'
import { createRoleValue } from '../../../modules/roles'

const FunctionsNew = () => {
  const [activeTab, setActiveTab] = useState('simple')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { dbPath, region } = useParams()
  const dispatch = useDispatch()

  const handleSubmit = async formValues => {
    dispatch(hideAlert())
    setIsLoading(true)
    selectDatabase(dbPath)

    try {
      await createFunction({
        name: formValues.name,
        body: evalFQLCode(formValues.body),
        role: createRoleValue(formValues.roleId)
      })

      history.push(
        createRoute(routes.functions.byId.path, dbPath, region).replace(
          ':functionId',
          formValues.name
        )
      )
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
      setIsLoading(false)
    }
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader
        tabs={
          <Tabs position="right">
            <Tab id="simple">Simple</Tab>
            <Tab id="fql">FQL</Tab>
          </Tabs>
        }
      >
        New Function
      </ContentHeader>

      <div className="padding-y-3">
        <FunctionForm
          dbPath={dbPath}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          onCancel={() => history.goBack()}
        />
      </div>
    </TabsProvider>
  )
}

export default withBreadcrumbs(({ location, match }) => {
  const { dbPath, region } = match.params

  return [
    {
      label: 'Functions',
      path: createRoute(routes.functions.index.path, dbPath, region)
    },
    {
      label: 'New Function',
      path: location.pathname
    }
  ]
})(FunctionsNew)
