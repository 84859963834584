import * as React from 'react'
import { values } from 'faunadb'
import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import DeleteIndexButton from './DeleteIndexButton'
import DashboardTooltip from '../functions/DashboardTooltip'
import { useForm, getValue } from '../../../modules/form'
import TermsValuesField from './TermsValuesField'
import LazyCode from '../../shared/LazyCode'
import { TabContent } from '../../shared/Tabs'
import * as IndexResource from '../../../modules/indexResource'
import { mountIndexFields } from '../../../utils/dbIndex'

type FormValues = {
  name: string
  source: string
  values?: []
  terms?: []
  unique?: boolean
  serialized?: boolean
}

type Props = {
  onSubmit: Function
  initialValues: FormValues
  databasePath: string | null | undefined
  classes?: { name: string; ref: values.Ref }[]
  loading: boolean
  isEdit: boolean
  onRemove?: Function
  onCancel: (event: React.MouseEvent) => void
}

export default function IndexForm({
  onSubmit,
  initialValues,
  databasePath,
  classes,
  loading,
  isEdit,
  onRemove,
  onCancel
}: Props) {
  const { formValues, handleChange, renderArrayField } = useForm(initialValues)

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault()
    onSubmit(formValues)
  }

  function getClassesOptions(): React.ReactNode {
    if (!classes) return <></>

    return classes.map(class_ => (
      <option key={class_.name} value={class_.name}>
        {class_.name}
      </option>
    ))
  }

  function getIndexFQLValues() {
    if (isEdit) return formValues

    return {
      ...formValues,
      source: getValue(formValues.source),
      serialized: formValues.unique || formValues.serialized,
      terms: mountIndexFields(formValues.terms),
      values: mountIndexFields(formValues.values)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TabContent id="simple">
          <div className="container container--xsmall">
            <div className="form-group">
              <div className="form-label--tooltip">
                <label className="required" htmlFor="source">
                  {tx('index.messages.source')} {tx('class_.name')}
                </label>
                <DashboardTooltip
                  id="classnameid"
                  placement="left"
                  contentText={<div>{tx('tooltips.indexSourceClasses')}</div>}
                />
              </div>

              {isEdit ? (
                <p>{initialValues.source}</p>
              ) : (
                <select
                  data-hj-suppress
                  onChange={handleChange}
                  required
                  id="source"
                  name="source"
                  value={formValues.source}
                >
                  {!classes ? (
                    <option value="">{tx('loading.loading')}</option>
                  ) : (
                    <>
                      <option value="">{tx('class_.actions.select')}</option>
                      {getClassesOptions()}
                    </>
                  )}
                </select>
              )}
            </div>

            <div className="form-group">
              <div className="form-label--tooltip">
                <label className="required" htmlFor="name">
                  {tx('index.name')} {tx('attributes.name')}
                </label>
                <DashboardTooltip
                  id="indexnameid"
                  placement="left"
                  contentText={<div>{tx('tooltips.indexName')}</div>}
                />
              </div>
              <div className="formField--tooltip">
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={formValues.name}
                  required
                  autoFocus
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-label--tooltip">
                <label className="optional">{tx('attributes.terms')}</label>
                <DashboardTooltip
                  id="attributestermid"
                  placement="left"
                  contentText={<div>{tx('tooltips.indexTerms')}</div>}
                />
              </div>
              {isEdit ? (
                <>
                  {initialValues.terms && initialValues.terms.length > 0 ? (
                    <ul>
                      {initialValues.terms.map((term, index) => (
                        <li key={index}>{IndexResource.getTermOrValueName(term)}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>Not set</p>
                  )}
                </>
              ) : (
                <div className="formField--tooltip">
                  <div className="grid width-100">
                    {renderArrayField('terms', TermsValuesField)}
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <div className="form-label--tooltip">
                <label className="optional" htmlFor="values">
                  {tx('attributes.values')}
                </label>
                <DashboardTooltip
                  id="attributesvaluesid"
                  placement="left"
                  contentText={<div>{tx('tooltips.indexValues')}</div>}
                />
              </div>
              {isEdit ? (
                <>
                  {initialValues.values && initialValues.values.length > 0 ? (
                    <ul>
                      {initialValues.values.map((value, index) => (
                        <li key={index}>{IndexResource.getTermOrValueName(value)}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>
                      Not set (using <code>ref</code> by default)
                    </p>
                  )}
                </>
              ) : (
                <div className="formField--tooltip">
                  <div className="grid width-100">
                    {renderArrayField('values', TermsValuesField)}
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <div className="formField--tooltip">
                <div className="form-checkbox">
                  <label className="optional" htmlFor="unique">
                    <input
                      name="unique"
                      type="checkbox"
                      id="unique"
                      checked={formValues.unique}
                      onChange={handleChange}
                    />
                    {tx('attributes.unique')}
                  </label>
                  <DashboardTooltip
                    id="attributesuniqueid"
                    placement="left"
                    contentText={<div>{tx('tooltips.indexUnique')}</div>}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="formField--tooltip">
                <div className="form-checkbox">
                  <label className="optional" htmlFor="serialized">
                    <input
                      type="checkbox"
                      id="serialized"
                      name="serialized"
                      checked={formValues.unique || formValues.serialized}
                      onChange={handleChange}
                    />
                    {tx('attributes.serialized')}
                  </label>
                  <DashboardTooltip
                    id="attributesserializedid"
                    placement="left"
                    contentText={<div>{tx('tooltips.indexSerialized')}</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabContent>

        <TabContent id="fql">
          <div className="form-group">
            <label htmlFor="">{tx('attributes.faunaData')}</label>
            <LazyCode code={getIndexFQLValues()} />
          </div>
        </TabContent>

        <div className="form-actions container container--xsmall">
          <Button onClick={onCancel} color="secondary">
            {tx('actions.cancel')}
          </Button>

          <Button type="submit" color="success" loading={loading}>
            {tx('actions.save')}
          </Button>
        </div>

        {isEdit && (
          <div className="container container--xsmall">
            <hr className="margin-top-4 margin-bottom-3" />

            <DeleteIndexButton
              indexName={initialValues.name}
              databasePath={databasePath}
              onRemove={onRemove}
            />
          </div>
        )}
      </form>
    </>
  )
}

IndexForm.defaultProps = {
  initialValues: {},
  isEdit: false
}
