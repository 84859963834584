import * as React from 'react'
import { useTransition, animated } from 'react-spring'

export type ModalProps = {
  isOpen: boolean
  children: React.ReactNode
  className?: string
  onClose?: () => void
} & React.HTMLAttributes<HTMLDivElement>

export default function Modal({ isOpen, children, className, onClose, ...rootProps }: ModalProps) {
  const overlayTransitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 150
    }
  })

  const modalTransitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: 'translateY(-10px) scale(0.9)' },
    enter: { opacity: 1, transform: 'translateY(0) scale(1)' },
    leave: { opacity: 0, transform: 'translateY(-10px) scale(0.9)' }
  })

  const handleModalClick = event => {
    event.stopPropagation()
  }

  return (
    <>
      {overlayTransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className={`modal-overlay ${className || ''}`}
              onClick={onClose}
            >
              {modalTransitions.map(
                ({ item, key, props }) =>
                  item && (
                    <animated.div
                      {...rootProps}
                      key={key}
                      style={props}
                      className="modal"
                      onClick={handleModalClick}
                    >
                      {children}
                    </animated.div>
                  )
              )}
            </animated.div>
          )
      )}
    </>
  )
}
