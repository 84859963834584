import axios from 'axios'
import getRegion from '../../auth/session/regions'
import { defaultRegion, bearerToken } from '../index'
import { logAxiosError } from '../../../utils/log-helper'
import { ScheduleCategory } from '../../../components/pages/databases/common'

// Valid regionKey() inputs are 'us', 'eu', and 'global'

const BASE_FDS_URL = `${process.env.REACT_APP_FRONTDOOR_URL}/api/v1`
const RESTORE_TYPE_TO_ENDPOINT = { Restore: 'restore', Copy: 'copy' }

export const INDEFINITE_TTL_DAYS = 365000
export const DEFAULT_RESTORE = { progress: -0.99, state: 'Pending' }
export const DEFAULT_BACKUP_CONFIGURATION = {
  enabled: false,
  ttl_days: 30,
  frequency: '0 0 * * *',
  category: ScheduleCategory.DAILY
}

export type BackupConfigurationControls = {
  ts: string
  ttl_days: number
  frequency: string
  enabled: boolean
}

export type BackupConfiguration = {
  ttl_days: number
  frequency: string
  enabled: boolean
  category: ScheduleCategory
  metadata: {
    region_group: string
  }
  ts: string
}

export type Snapshot = {
  snapshot_ts: object
  snapshot_size: number
  state: string
}

export type Restore = {
  created_at: Date
  snapshot_ref_id: string
  destination_metadata: {
    region_group: string
    database_path: string
  }
  type: string
  state: string
  copy_destination_db_name?: string
}

export const getBackupConfiguration = async (
  path: string,
  region: string,
  hasRequiredPermissions: boolean
): Promise<BackupConfiguration> => {
  // no-op if the user lacks permissions
  if (!hasRequiredPermissions) return
  if (path === '') return
  const authorizationToken = bearerToken(region)
  const transformedRegion = getRegion(region).frontdoorPrefix
  const dbPath = transformedRegion + '/' + path
  const url = BASE_FDS_URL + `/backup_configuration?database_path=${dbPath}`
  const { data } = await axios
    .get<BackupConfiguration>(url, {
      headers: { Authorization: authorizationToken, region_group: transformedRegion }
    })
    .catch(e => {
      const { status } = JSON.parse(JSON.stringify(e))
      if (status == 404) {
        // to satisfy https://faunadb.atlassian.net/browse/FE-2180
        // TODO: instead of using RUM log monitors to build metrics, we should
        //  emit DataDog metrics from here
        throw e
      } else {
        logAxiosError(e)
        throw e
      }
    })
  return data
}

export const createBackupConfiguration = async (
  path: string,
  region: string,
  data: BackupConfigurationControls,
  hasRequiredPermissions: boolean
): Promise<void> => {
  // no-op if the user lacks permissions
  if (!hasRequiredPermissions) return
  const authorizationToken = bearerToken(region)
  const transformedRegion = getRegion(region).frontdoorPrefix
  const body = { ...data } || DEFAULT_BACKUP_CONFIGURATION
  const dbPath = transformedRegion + '/' + path
  const url = BASE_FDS_URL + `/backup_configuration?database_path=${dbPath}`
  await axios
    .post<BackupConfiguration>(url, body, {
      headers: { Authorization: authorizationToken, region_group: transformedRegion }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export const updateBackupConfiguration = async (
  path: string,
  region: string,
  data: BackupConfigurationControls,
  hasRequiredPermissions: boolean
): Promise<void> => {
  // no-op if the user lacks permissions
  if (!hasRequiredPermissions) return
  const authorizationToken = bearerToken(region)
  const transformedRegion = getRegion(region).frontdoorPrefix
  const dbPath = transformedRegion + '/' + path
  const body = { ...data }
  const url = BASE_FDS_URL + `/backup_configuration?database_path=${dbPath}`
  await axios
    .put<BackupConfiguration>(url, body, {
      headers: { Authorization: authorizationToken, region_group: transformedRegion }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export const getSnapshots = async ({
  path,
  region,
  after,
  pageSize = 5,
  states = ['Complete'],
  with_backup_configuration = false,
  hasRequiredPermissions
}: {
  path?: string
  region?: string
  after?: unknown
  pageSize?: number
  states?: string[]
  with_backup_configuration?: boolean
  hasRequiredPermissions: boolean
}): Promise<{
  nextToken: number
  results:
    | Array<{ data: Snapshot }>
    | Array<{ snapshot: Snapshot; backup_configuration: BackupConfiguration }>
}> => {
  // no-op if the user lacks permissions
  if (!hasRequiredPermissions) return
  const regionPrefix = region || defaultRegion()['regionPrefix']
  const authorizationToken = bearerToken(regionPrefix)
  const transformedRegion = getRegion(regionPrefix).frontdoorPrefix
  const body = { size: pageSize, states, with_backup_configuration }
  if (after) body['nextToken'] = after
  const urlParams = path ? `?database_path=${transformedRegion}/${path}` : ''
  const url = `${BASE_FDS_URL}/snapshots/_list${urlParams}`
  const { data } = await axios
    .post(url, body, {
      headers: { Authorization: authorizationToken, region_group: transformedRegion }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
  return data
}

export const getRestores = async ({
  path,
  region,
  after,
  pageSize = 15,
  states = ['Pending', 'InProgress', 'Complete', 'CancelRequested', 'Canceled', 'Failed'],
  hasRequiredPermissions
}: {
  path?: string
  region?: string
  after?: unknown
  pageSize?: number
  states?: string[]
  hasRequiredPermissions: boolean
}): Promise<{ nextToken: number; results: { data: Restore }[] }> => {
  // no-op if the user lacks permissions
  if (!hasRequiredPermissions) return
  // if no region is provided use the default for auth
  const regionPrefix = region || defaultRegion()['regionPrefix']
  const authorizationToken = bearerToken(regionPrefix)
  const transformedRegion = getRegion(regionPrefix).frontdoorPrefix
  const body = { size: pageSize, states: states }
  if (after) body['nextToken'] = after
  // if no path is provided get all restores for the given tenant
  const urlParams = path ? `?database_path=${transformedRegion}/${path}` : ''
  const url = `${BASE_FDS_URL}/restores/_list${urlParams}`
  const { data } = await axios
    .post(url, body, {
      headers: { Authorization: authorizationToken, region_group: transformedRegion }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
  return data
}

export const createRestore = async ({
  path,
  region,
  snapshot_ref_id,
  type = 'Restore',
  destination_db_name,
  hasRequiredPermissions
}: {
  path?: string
  region: string
  snapshot_ref_id: string
  type?: string
  destination_db_name?: string
  hasRequiredPermissions: boolean
}): Promise<void> => {
  // no-op if the user lacks permissions
  if (!hasRequiredPermissions) return
  const authorizationToken = bearerToken(region)
  const transformedRegion = getRegion(region).frontdoorPrefix
  const body = {
    ...DEFAULT_RESTORE,
    type,
    snapshot_ref_id,
    ...(destination_db_name && { copy_destination_db_name: destination_db_name })
  }
  const urlParams = path
    ? `?database_path=${transformedRegion}/${path}`
    : `?database_path=${transformedRegion}`
  const url = `${BASE_FDS_URL}/${RESTORE_TYPE_TO_ENDPOINT[type]}${urlParams}`
  await axios
    .post<BackupConfiguration>(url, body, {
      headers: { Authorization: authorizationToken, region_group: transformedRegion }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}
