import { datadogRum } from '@datadog/browser-rum'
import axios from 'axios'
import moment from 'moment-timezone'
import ReactDOM from 'react-dom'
import App from './components/App'
import english from './config/languages/en-us'
import { setFeatures, FQL_X_FF, COOKIE_BANNER_FF } from './modules/features'
import * as translate from './modules/translate'
import { initializeAnalytics } from './utils/analytics'
import * as colors from './utils/colors'
import { getBoolEnv } from './utils/envVariables'

moment.tz.setDefault('Etc/UTC')

translate.setDictionary(english)

axios.defaults.headers['Content-Type'] = 'application/json'

colors.setConfig({
  scale: [
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5'
  ],
  error: '#DF5D84',
  warning: '#EC9F45',
  default: '#5280B1',
  gray: '#f4f5f9'
})

initializeAnalytics({
  gtmId: process.env.REACT_APP_GTM_ID,
  gaId: process.env.REACT_APP_GA_ID
})

const dataDogConfig =
  process.env.REACT_APP_DD_APPLICATION_ID && process.env.REACT_APP_DD_CLIENT_TOKEN
    ? {
        applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        env: process.env.REACT_APP_DD_ENV
      }
    : null

if (dataDogConfig) {
  datadogRum.init({
    ...dataDogConfig,
    service: 'dashboard',
    version: process.env.REACT_APP_GIT_SHA,
    sampleRate: 100,
    trackInteractions: true,
    trackViewsManually: true,
    beforeSend: event => {
      if (
        event.type === 'error' &&
        event.error?.msg?.startsWith('Uncaught "Object Not Found Matching Id:"')
      ) {
        return false
      }
      // Redact sensitive information from what we send to DD
      const redact = (s = '') => s.replace(/token=[^&]*/, 'token=REDACTED')
      const { view, resource, error } = event
      view.url = redact(view.url)
      if (view?.referrer) view.referrer = redact(view.referrer)
      if (resource?.url) resource.url = redact(resource?.url)
      if (error?.resource?.url) error.resource.url = redact(error.resource.url)
    }
  })
}

// Only set features on initial load
// Default values here, many loaded from netlify.toml
if (!JSON.parse(localStorage.getItem('features'))) {
  setFeatures({
    usage: getBoolEnv('REACT_APP_FEAT_USAGE', true),
    [FQL_X_FF]: getBoolEnv('REACT_APP_FQL_X_ENABLED', false),
    [COOKIE_BANNER_FF]: getBoolEnv('REACT_APP_COOKIE_BANNER_ENABLED', false)
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
