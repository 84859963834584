import React, { useState } from 'react'
import routes from '../../../config/routes'
import { tx } from '../../../modules/translate'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Icon from '../../shared/Icon'
import { AccountKeysCreateDrawer } from './AccountKeysCreateDrawer'
import { AccountKeysDeleteDrawer } from './AccountKeysDeleteDrawer'
import Well from '../../shared/Well'
import useAccountKeys from '../../../modules/api/useAccountKeys'
import Spinner from '../../shared/Spinner'
import Table, { AlignmentWrapper } from '../../shared/Table'
import usePermissions from '../../../config/usePermissions'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import { formatDate } from '../../../utils/date'
import capitalize from 'lodash/capitalize'
import { CreateAccountKeyOutput, AccountKey } from '../../../modules/api/frontdoor/keys'
import Button from '../../shared/Button'
import { makeToast } from '../../shared/toast'
import { SessionCookieContents } from '../../../modules/cookies/sessionCookie'
import SessionCookie from '../../../modules/auth/session/cookie'

const PAGE_SIZE = 8

export const AccountKeys = () => {
  const [newKey, setNewKey] = useState<CreateAccountKeyOutput>(undefined)
  const [isCreateDrawerShown, setIsCreateDrawerShown] = useState(false)

  const {
    rolePermission: roleRead,
    planPermission: planRead,
    loading: loadingReadPermissions,
    acceptableRoles: acceptableReadRoles
  } = usePermissions(PermissionDomain.API_TOKEN, PermissionLevel.read)

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptableReadPlans: string[]
  if (v2Released) {
    acceptableReadPlans = ['Trial', 'Pro', 'Enterprise']
  } else {
    acceptableReadPlans = ['team', 'business']
  }

  const hasReadPermissions = roleRead && planRead

  const paginatedData = useAccountKeys(PAGE_SIZE)

  const handleCreateSuccess = (response: CreateAccountKeyOutput) => {
    setNewKey(response)
    paginatedData.mutate()
  }

  const handleDeleteSuccess = (deletedKey: AccountKey) => {
    if (deletedKey?.key_name === newKey?.name) setNewKey(undefined)
  }

  const renderPermissionsDialog = () => {
    if (loadingReadPermissions) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    } else {
      if (!planRead) {
        return (
          <InfoBox
            body={
              <div>
                This feature is only available to users on the following plans:{' '}
                {acceptableReadPlans.map(plan => capitalize(plan)).join(', ') + '. '}
                <a href="/settings/billing">
                  <u>Upgrade plan</u>
                </a>
              </div>
            }
            iconName="info-circle"
            type={InfoBoxType.primary}
          />
        )
      } else if (!roleRead) {
        return (
          <InfoBox
            body={
              <div>
                You must have one of the following roles to use this feature:{' '}
                {acceptableReadRoles.map(role => capitalize(role)).join(', ')}. Please contact your
                Team Admin for more details.
              </div>
            }
            iconName="info-circle"
            type={InfoBoxType.primary}
          />
        )
      }
    }
  }

  return (
    <>
      <AccountKeysCreateDrawer
        show={isCreateDrawerShown}
        onClose={() => setIsCreateDrawerShown(false)}
        onSuccess={handleCreateSuccess}
      />
      <ContentHeader
        divider
        className="justify-content-space-between"
        actions={
          <span className="btn btn-success" onClick={() => setIsCreateDrawerShown(true)}>
            {tx('accountKeys.actions.new')}
          </span>
        }
      >
        Account Keys
      </ContentHeader>
      <div className="padding-y-3" />
      <p>Account keys allow you to authenticate to the Fauna Logs API.</p>
      {newKey && (
        <div className="tip tip--warning margin-bottom-3">
          <div className="display-flex align-items-center justify-content-space-between margin-bottom-2">
            <div className="tip__label no-margin">
              <Icon name="exclamation-triangle" className="margin-right-2" />
              {tx('accountKeys.messages.new.first')}
            </div>
            <Button
              color="secondary"
              onClick={() =>
                navigator.clipboard.writeText(newKey.secret).then(() => {
                  makeToast({
                    title: 'Copied to clipboard'
                  })
                })
              }
            >
              <Icon name="copy" />
              {tx('actions.copy')}
            </Button>
          </div>
          <div data-testid="secret" className="tip__title wrapped" data-hj-suppress>
            {newKey.secret}
          </div>
          <p className="tip__text margin-bottom-4">{tx('accountKeys.messages.new.second')}</p>
        </div>
      )}
      {!hasReadPermissions ? (
        renderPermissionsDialog()
      ) : (
        <>
          {paginatedData.isInitialLoading ? (
            <div className="loader">
              <Spinner />
            </div>
          ) : (
            <>
              {paginatedData.list.length === 0 ? (
                <div className="card">
                  <Well
                    shadow
                    title="Create your first account key"
                    icon={<Icon name="key" />}
                    action={
                      <span
                        className="btn btn-success"
                        onClick={() => setIsCreateDrawerShown(true)}
                      >
                        {tx('accountKeys.actions.new')}
                      </span>
                    }
                    description={
                      <div className="container container--small">
                        <p className="padding-y-3">
                          Account keys enable programmatic access to APIs administering your Fauna
                          account. Protect them as you do passwords and database secrets.
                        </p>
                      </div>
                    }
                  />
                </div>
              ) : (
                <AccountKeysTable
                  paginatedData={paginatedData}
                  onDeleteSuccess={handleDeleteSuccess}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const AccountKeysTable = ({ paginatedData, onDeleteSuccess }) => {
  const data = paginatedData?.list || []
  const tableData = data.map((row: AccountKey) => {
    return {
      role: row.user_role,
      name: row.key_name,
      user_id: row.user_id,
      email: row.user_email,
      ttl: row.ttl ? formatDate(row.ttl) : 'none',
      actions: (
        <AlignmentWrapper horizontalAlign="end">
          <Icon
            name="trash"
            className="muted"
            wrapperClassName="icon--hoverable"
            onClick={() => {
              // @ts-ignore
              setSelectedKey(row)
              setIsDeleteDrawerShown(true)
            }}
          />
        </AlignmentWrapper>
      )
    }
  })

  const columns = [
    {
      Header: 'NAME',
      accessor: 'name'
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
      minWidth: 150
    },
    {
      Header: 'ROLE',
      accessor: 'role'
    },
    {
      Header: 'TTL (UTC)',
      accessor: 'ttl',
      minWidth: 150,
      Cell: ({ value }) => {
        if (!value) return 'none'
        return value
      }
    },
    {
      Header: <AlignmentWrapper horizontalAlign="end">ACTIONS</AlignmentWrapper>,
      accessor: 'actions'
    }
  ]

  const [selectedKey, setSelectedKey] = useState<AccountKey>(undefined)
  const [isDeleteDrawerShown, setIsDeleteDrawerShown] = useState(false)

  const handleDeleteSuccess = () => {
    paginatedData.mutate()
    onDeleteSuccess(selectedKey)
  }

  if (paginatedData.isLoadingMore)
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  if (paginatedData.error) {
    if (paginatedData.error.response.status === 403)
      return (
        <InfoBox
          body={
            <div>
              You have insufficient privileges to view account keys. Please contact your admin for
              more details.
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )
    return (
      <InfoBox
        body={<div>Unable to fetch account keys.</div>}
        iconName="info-circle"
        type={InfoBoxType.danger}
      />
    )
  }
  return (
    <>
      <AccountKeysDeleteDrawer
        accountKey={selectedKey}
        show={isDeleteDrawerShown}
        onSuccess={handleDeleteSuccess}
        onClose={() => setIsDeleteDrawerShown(false)}
      />
      <Table data={tableData} columns={columns} paginatedData={paginatedData} />
    </>
  )
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Account Keys',
    path: routes.settings.keys.path
  }
])(AccountKeys)
