import Well from '../../shared/Well'
import Icon from '../../shared/Icon'
import { Link, useParams } from 'react-router-dom'
import routes, { createRoute } from '../../../config/routes'

const EmptyFunctions = () => {
  const { dbPath, region } = useParams()

  return (
    <div className="card margin-top-4">
      <Well
        shadow
        title="Create first function"
        icon={<Icon name="code" />}
        description={
          <div className="container container--small">
            <p>Functions are great to improve code reusability and avoid large data transfer.</p>
          </div>
        }
        action={
          <Link
            to={createRoute(routes.functions.new.path, dbPath, region)}
            className="btn btn-success"
          >
            New function
          </Link>
        }
      />
    </div>
  )
}

export default EmptyFunctions
