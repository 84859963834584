import Icon from './Icon'

const NEW_DASHBOARD_URL = process.env.REACT_APP_NEW_DASHBOARD_URL

export default function BetaAnnouncement(opts: {
  message: 'Beta' | 'Billing' | 'Team' | 'Usage breakdown'
  overflow?: 'wrap' | 'ellipsis'
}) {
  const overflowEllipsis = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
  const overflow = opts.overflow === 'wrap' ? {} : overflowEllipsis

  return (
    <div
      style={{
        margin: '0 1rem',
        padding: '.3rem 1rem',
        border: '1px solid #492fb1',
        borderRadius: '.5rem',
        backgroundColor: '#f5f2fa',
        fontSize: '.9rem',
        color: '#2b305f',
        ...overflow
      }}
    >
      {opts.message === 'Beta' && (
        <span>
          <Icon name="info-circle" style={{ color: '#492fb1' }} />
          <span> </span>
          FQL v10 is now generally available! Try the new{' '}
          <a
            href={`${NEW_DASHBOARD_URL}`}
            style={{ color: '#492fb1' }}
            target="_blank"
            rel="noreferrer"
          >
            <strong>dashboard</strong>
          </a>{' '}
          or view our{' '}
          <a
            href="https://fauna.com/blog/new-database-language-and-seamless-developer-experience"
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: '#492fb1' }}
          >
            <strong>blog</strong>
          </a>{' '}
          and{' '}
          <a
            href="https://docs.fauna.com/fauna/current"
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: '#492fb1' }}
          >
            <strong>docs</strong>
          </a>{' '}
          to learn more.
        </span>
      )}
      {opts.message === 'Billing' && (
        <span>
          <Icon name="info-circle" style={{ color: '#492fb1' }} />
          <span> </span>
          New plans & pricing are now available and can be managed in our new{' '}
          <a
            href={`${NEW_DASHBOARD_URL}/resources/account-settings/billing`}
            style={{ color: '#492fb1' }}
            target="_blank"
            rel="noreferrer"
          >
            <strong>Plans & Billing page</strong>
          </a>
          .
        </span>
      )}
      {opts.message === 'Team' && (
        <span>
          <Icon name="info-circle" style={{ color: '#492fb1' }} />
          <span> </span>
          Teams can be managed in our new{' '}
          <a
            href={`${NEW_DASHBOARD_URL}/resources/account-settings/team-management`}
            target="_blank"
            style={{ color: '#492fb1' }}
            rel="noreferrer"
          >
            <strong>Team Management page</strong>
          </a>
          .
        </span>
      )}
      {opts.message === 'Usage breakdown' && (
        <span>
          <Icon name="info-circle" style={{ color: '#492fb1' }} />
          <span> </span>
          Usage breakdown can be viewed in{' '}
          <a
            href={`${NEW_DASHBOARD_URL}/resources/home`}
            target="_blank"
            style={{ color: '#492fb1' }}
            rel="noreferrer"
          >
            <strong>v10 Dashboard</strong>
          </a>
          .
        </span>
      )}
    </div>
  )
}
