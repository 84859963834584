import { useState } from 'react'
import { Match, RouterHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import routes, { createRouteGenerator, createRoute } from '../../../config/routes'
import ContentHeader from '../../layouts/ContentHeader'
import KeyForm from './KeyForm'
import { TabsProvider, Tabs, Tab } from '../../shared/Tabs'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import { tx } from '../../../modules/translate'
import { CreateKeyDataResponse } from './../../../modules/api'
import * as API from '../../../modules/api'
import { useCallableFetch } from '../../../utils/fetch'
import { showAlert } from '../../../modules/alert/actions'
import { setFlash } from '../../../modules/flash'

type Props = {
  match: Match
  history: RouterHistory
}

function KeysNew({ match, history }: Props) {
  const databasePath = match.params.dbPath || ''
  const region = match.params.region || ''
  API.selectDatabase(databasePath)

  const [activeTab, setActiveTab] = useState('simple')
  const dispatch = useDispatch()
  const { runFetch: createKey, loading } = useCallableFetch<CreateKeyDataResponse>(API.createKey, {
    namespace: `${databasePath}_keys`,
    create: true
  })

  function onSubmit(values) {
    createKey(values)
      .then(newKey => {
        dispatch(setFlash('newKey', newKey))
        const newLocation = {
          pathname: createRoute(routes.keys.index.path, databasePath || '', region),
          state: {
            newKey: newKey
          }
        }
        history.push(newLocation)
      })
      .catch(error => {
        dispatch(showAlert(error.requestResult?.responseContent, 'error'))
      })
  }

  function renderTabs() {
    return (
      <Tabs position="right">
        <Tab id="simple">{tx('tabs.simple')}</Tab>
        <Tab id="fql">{tx('tabs.fql')}</Tab>
      </Tabs>
    )
  }

  return (
    <TabsProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <ContentHeader tabs={renderTabs()} divider={false}>
        {tx('keys.actions.new')}
      </ContentHeader>

      <div className="padding-y-3">
        <KeyForm
          onSubmit={onSubmit}
          onCancel={history.goBack}
          databasePath={databasePath}
          loading={loading}
        />
      </div>
    </TabsProvider>
  )
}

export default withBreadcrumbs(({ location, match }) => {
  const databasePath = match.params.dbPath || ''
  const createRoute = createRouteGenerator(databasePath, match.params.region)

  return [
    {
      label: tx('security.title'),
      path: createRoute(routes.keys.index.path)
    },
    {
      label: tx('keys.title'),
      path: createRoute(routes.keys.index.path)
    },
    {
      label: tx('keys.actions.new'),
      path: location.pathname
    }
  ]
})(KeysNew)
