import axios from 'axios'
import getRegion from '../../auth/session/regions'
import { defaultRegion, bearerToken } from '../index'
import { logAxiosError } from '../../../utils/log-helper'

const BASE_URL = `${process.env.REACT_APP_FRONTDOOR_URL}/api/v1/keys`

export type AccountKey = {
  user_id: string
  user_role: string
  user_email: string
  key_name: string
  ttl?: string
}

export type CreateAccountKeyInput = {
  name: string
  ttl?: string
}

export type CreateAccountKeyOutput = {
  name: string
  secret: string
  user_id: string
  ttl?: string
}

export type DeleteAccountKeyInput = {
  user_id: string
  name: string
}

export type DeleteAccountKeyOutput = {}

export type ListAccountKeysInput = {
  after?: string
  page_size?: number
}

export type ListAccountKeysOutput = {
  data: Array<AccountKey>
  after?: string
}

const getHeaders = () => {
  const regionPrefix = defaultRegion()?.regionPrefix
  const authorizationToken = bearerToken(regionPrefix)
  const transformedRegion = getRegion(regionPrefix)?.frontdoorPrefix
  return { Authorization: authorizationToken, region_group: transformedRegion }
}

export const createAccountKey = async ({
  ttl,
  name
}: CreateAccountKeyInput): Promise<CreateAccountKeyOutput> => {
  const body = { name, ttl: undefined }
  if (ttl) body['ttl'] = ttl

  try {
    const res = await axios.post(`${BASE_URL}`, body, {
      headers: getHeaders()
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export const deleteAccountKey = async ({
  user_id,
  name
}: DeleteAccountKeyInput): Promise<DeleteAccountKeyOutput> => {
  try {
    const res = await axios.delete(`${BASE_URL}/${user_id}/${name}`, {
      headers: getHeaders()
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export const listAccountKeys = async ({
  after,
  page_size = 10
}: ListAccountKeysInput): Promise<ListAccountKeysOutput> => {
  const body = { max_results: page_size }
  if (after) body['next_token'] = after

  try {
    const res = await axios.post(`${BASE_URL}/_list`, body, {
      headers: getHeaders()
    })
    // Transform response to make it compatible with the usePagination hook.
    return {
      data: res.data.results,
      after: res.data?.next_token
    }
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}
