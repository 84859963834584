import axios from 'axios'
import { logAxiosError } from '../utils/log-helper'
import getRegion from './auth/session/regions'

type UploadSchemaOptions = {
  secret: string
  schema: Blob
  mode?: 'merge' | 'replace'
  region?: string
}

const { REACT_APP_GRAPHQL_URL } = process.env

export const getGraphqlURL = (region?: string): string => {
  return getRegion(region)?.graphqlUrl ?? REACT_APP_GRAPHQL_URL
}

export const uploadGQLSchema = ({ schema, secret, mode = 'merge', region }: UploadSchemaOptions) =>
  axios
    .post(`${getGraphqlURL(region)}/import`, schema, {
      headers: {
        'content-type': 'multipart/form-data',
        authorization: `Bearer ${secret}`
      },
      params: {
        mode
      }
    })
    .catch(e => {
      logAxiosError(e)
      throw e
    })

export class UploadGQLSchemaError extends Error {}
