export const FQL_X_FF = 'fqlx'
export const COOKIE_BANNER_FF = 'cookiebanner'

// TODO: If this isn't used anywhere, remove RG feature
export const features = {
  regionGroups: false
}

// Overwrite the entire features object in local storage
export const setFeatures = newFeatures => {
  localStorage.setItem('features', JSON.stringify(newFeatures))
}

// Add a flag to existing features and set to true
export const manualEnableFeature = (featureName: string) => {
  const featuresFromStorage = JSON.parse(localStorage.getItem('features'))
  featuresFromStorage[featureName] = true
  localStorage.setItem('features', JSON.stringify(featuresFromStorage))
}

// Set an existing feature flag to false
export const manualDisableFeature = (featureName: string) => {
  const featuresFromStorage = JSON.parse(localStorage.getItem('features'))
  featuresFromStorage[featureName] = false
  localStorage.setItem('features', JSON.stringify(featuresFromStorage))
}

// Feature object handler (interface for localstorage)
// Provides read-only access to feature data via `isEnabled()` method
export const feature = (featureName: string) => {
  const featuresFromStorage = JSON.parse(localStorage.getItem('features'))
  let statusValue: boolean = featuresFromStorage[featureName]

  if (statusValue === undefined) {
    displayWarning(featureName)
    statusValue = false
  }

  return {
    isEnabled: () => statusValue
  }
}

// Format warning message for invalid feature flag
const displayWarning = (featureName: string) => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  const availableFeatures = Object.keys(JSON.parse(localStorage.getItem('features')))

  console.warn(
    `${featureName} is not a valid feature. ` +
      `The available features are ${availableFeatures.join(', ')}.`
  )
}
