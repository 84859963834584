import * as api from './api'
import { createTypes, createActions, createReducer, createSaga } from './resources'

const resourceConfig = {
  name: 'collection',
  plural: 'collections',
  namespace: 'databasePath'
}

export const types = createTypes(resourceConfig)
export const actions = createActions(types)
export const reducer = createReducer(types, resourceConfig)
export const saga = createSaga(
  types,
  actions,
  {
    fetchList: () => {
      return api.classes()
    },
    create: payload => {
      return api.createClass(payload)
    },
    update: payload => {
      return api.updateClass(payload)
    },
    remove: payload => {
      return api.removeClass(payload)
    }
  },
  resourceConfig
)

export const all = (state: Record<string, any>, databasePath: string) => {
  const classes = state.classes[databasePath]

  if (!classes) return classes
  return classes.slice().sort((a, b) => a.name.localeCompare(b.name))
}

export const find = (
  state: Record<string, any>,
  databasePath: string,
  className: string
): Record<string, any> | null | undefined => {
  const classes = all(state, databasePath)

  if (!classes) return classes

  return classes.find(class_ => class_.name === className)
}
