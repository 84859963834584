import axios from 'axios'
import { useState } from 'react'
import useSWR, { ConfigInterface, mutate } from 'swr'
import { RequestStatus } from '..'
import { logAxiosError } from '../../../utils/log-helper'
import { rootSecret } from '../index'

export type Member = {
  id: string
  name: string
  email: string
  role: string
}

export type NewMember = {
  token: string
  name: string
  email: string
  password: string
  password_confirmation: string
}

export enum MemberRole {
  owner = 'owner',
  admin = 'admin',
  billing = 'billing',
  developer = 'developer'
}

export const API_URL = `${process.env.REACT_APP_ACCOUNT_URL}/account/members`
export const CACHE = '/settings/members'

async function getMembers(): Promise<Member[]> {
  try {
    const res = await axios.get<Member[]>(API_URL, {
      headers: { secret: rootSecret() }
    })
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function removeMember(memberId: string): Promise<void> {
  try {
    await axios.delete(`${API_URL}/${memberId}`, {
      headers: { secret: rootSecret() }
    })
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

async function createMember(data: NewMember): Promise<Member> {
  try {
    const res = await axios.post<Member>(API_URL, data)
    return res.data
  } catch (e) {
    logAxiosError(e)
    throw e
  }
}

export function useMember(memberId: string) {
  const [removingStatus, setRemovingStatus] = useState<RequestStatus>(RequestStatus.idle)

  const remove = {
    call: async () => {
      if (removingStatus !== RequestStatus.idle) return

      setRemovingStatus(RequestStatus.loading)

      await mutate(CACHE, async (data: Member[]) => {
        await removeMember(memberId)
        data.filter(member => member.id !== memberId)
      })

      setRemovingStatus(RequestStatus.idle)
    },
    status: removingStatus
  }

  return {
    remove
  }
}

export default function useMembers(options?: ConfigInterface) {
  const [creatingStatus, setCreatingStatus] = useState<RequestStatus>(RequestStatus.idle)

  const members = useSWR(CACHE, getMembers, options)

  const create = {
    call: async (data: NewMember) => {
      if (creatingStatus !== RequestStatus.idle) return

      try {
        setCreatingStatus(RequestStatus.loading)
        await createMember(data)
        setCreatingStatus(RequestStatus.idle)
      } catch (error) {
        setCreatingStatus(RequestStatus.idle)
        throw error
      }
    },
    status: creatingStatus
  }

  return {
    members,
    create
  }
}
