import { lazy, Suspense } from 'react'
import React from 'react'

const Code = lazy(() => import('./Code'))

interface LazyCodeProps {
  code: object | string
}

export default function LazyCode(props: LazyCodeProps) {
  return (
    <Suspense fallback={<></>}>
      <Code {...props} />
    </Suspense>
  )
}
