import { lazy, Suspense } from 'react'
const GraphQLPlayground = lazy(() => import('./GraphQLPlayground'))

export default function LazyGraphQLPlayground(props: Record<string, any>) {
  return (
    <Suspense fallback={<>Loading...</>}>
      <GraphQLPlayground {...props} />
    </Suspense>
  )
}
