import { values } from 'faunadb'
import { useEffect, useMemo, useState } from 'react'
import { DatabaseRef } from '../../types/faunadbTypes'
import getRegion from '../auth/session/regions'
import {
  databaseIdFromPath,
  databaseParentPathFromPath,
  getFullDatabasePath,
  getFullDatabasePathWithRegion,
  isRootDatabase,
  stripRegionPrefix
} from '../databaseResource'
import useDatabases from './useDatabases'
import { Restore } from '../api/frontdoor/backup'
import useRestores from '../api/useRestores'

export type DatabaseDetails = {
  db: DatabaseRef
  regionName: string
  regionPrefix: string
  databasePath: string
  restore?: Restore
}

const useDatabaseDetails = (baseDbPath: string, region?: string) => {
  // Retrieve all child databases of the base DB.
  const { data: databasesData, mutate: mutateDatabases, error: databasesError } = useDatabases(
    baseDbPath,
    region
  )

  // Retrieve info about any active restores or copies.
  const { data: restoresData, mutate: mutateRestores, error: restoresError } = useRestores(
    baseDbPath,
    region,
    mutateDatabases
  )

  const restoresLoading = !restoresData && !restoresError
  const databasesLoading = !databasesData && !databasesError

  const databases: DatabaseDetails[] = useMemo(() => {
    if (!databasesData) return []
    return databasesData.map(dbWithRg => {
      return { ...dbWithRg, databasePath: getFullDatabasePath(baseDbPath, dbWithRg.db) }
    })
  }, [baseDbPath, databasesData])

  const restores: DatabaseDetails[] = useMemo(() => {
    const isRoot = isRootDatabase(baseDbPath)
    if (!restoresData) return []
    return restoresData
      .map(r => {
        const destinationRegionPrefix = r.data.destination_metadata?.region_group
        const destinationPath = stripRegionPrefix(
          r.data.destination_metadata?.database_path,
          destinationRegionPrefix
        )
        const parentPath =
          r.data.type === 'Copy' ? destinationPath : databaseParentPathFromPath(destinationPath)
        const destinationIsRoot = isRootDatabase(parentPath)
        // On the root page, filter out copies/restores that aren't in the root scoope.
        if (isRoot && !destinationIsRoot) return null
        const destinationDbName =
          r.data.type === 'Copy'
            ? r.data.copy_destination_db_name
            : databaseIdFromPath(destinationPath)
        const destinationRegion = getRegion(destinationRegionPrefix)
        const db = new values.Ref(destinationDbName, values.Native.DATABASES)
        return {
          db,
          regionName: destinationRegion.regionName,
          regionPrefix: destinationRegion.regionPrefix,
          databasePath:
            r.data.type === 'Copy' ? parentPath + `/${destinationDbName}` : destinationPath,
          restore: r.data
        }
      })
      .filter(x => x) // remove nulls
  }, [baseDbPath, restoresData])

  // The `rows` state variable represents the data in the DB table shown to the user.
  const [rows, setRows] = useState<DatabaseDetails[]>([])

  useEffect(() => {
    // Associate restore info with any existing DatabaseDetails for the target DB.
    const dbsByPath = new Map<string, DatabaseDetails>()
    if (databases)
      databases.forEach(db => {
        const parentPath = databaseParentPathFromPath(db.databasePath)
        const path = getFullDatabasePathWithRegion(parentPath, db.db, db.regionPrefix)
        dbsByPath.set(path, db)
      })
    if (restores)
      restores.forEach(r => {
        const parentPath = databaseParentPathFromPath(r.databasePath)
        const path = getFullDatabasePathWithRegion(parentPath, r.db, r.regionPrefix)
        if (dbsByPath.has(path)) {
          dbsByPath.get(path).restore = r.restore
        } else {
          dbsByPath.set(path, r)
        }
      })
    setRows([...dbsByPath.values()].sort((a, b) => a.db.id.localeCompare(b.db.id)))
  }, [databases, restores])

  return {
    databases,
    mutateDatabases,
    restores,
    mutateRestores,
    rows,
    error: databasesError || restoresError,
    loading: restoresLoading || databasesLoading
  }
}

export default useDatabaseDetails
