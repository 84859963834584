import useSWR from 'swr'

type PollerParams<T> = {
  key: string | null
  fetcher: () => Promise<T>
  delay: number
  shouldFetch?: boolean
}

/**
 *
 * @param key - string cache key for useSWR, when null, SWR doesn't make the request
 * @param fetcher - async function to get data from api
 * @param delay - sleep time between poll iterations
 * @param shouldFetch - conditionally sets the key to null to control polling stop/start
 * @returns
 */
export default function usePolling<FetcherResponseType>({
  key,
  fetcher,
  delay,
  shouldFetch
}: PollerParams<FetcherResponseType>) {
  const cacheKey = shouldFetch ? [key] : null
  const { data, revalidate, mutate, isValidating, error } = useSWR(
    cacheKey,
    async () => {
      return fetcher()
    },
    { refreshInterval: delay }
  )

  return { data, isValidating, mutate, revalidate, error }
}
