import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { ContextRouter } from 'react-router-dom'
import * as auth from '../../../modules/auth'
import * as api from '../../../modules/api'
import routes from '../../../config/routes'
import { tx } from '../../../modules/translate'
import logoIcon from '../../../assets/images/logo-icon-white.svg'
import Icon from '../../shared/Icon'
import strategies from '../../../modules/auth/strategies'
import activateShell from '../../../modules/api/shell/activate'

const DB_URL = process.env.REACT_APP_DB_URL

export default function Callback(props: ContextRouter) {
  const { params } = props.match
  const { strategyName } = params
  const strategy = strategies[strategyName || 'emailAndPassword']
  const queryParams = queryString.parse(props.location.search)
  const [message, setMessage] = useState({
    header: tx('auth.messages.checking'),
    text: tx('auth.messages.waitingRedirect'),
    command: null
  })

  useEffect(() => {
    const handleFaunaShellSignIn = response => {
      const { state } = queryParams
      const { secret } = response.data

      activateShell(Array.isArray(state) ? state[0] : state, secret)
        .then(() => {
          setMessage({
            header: tx('auth.messages.shellLoginComplete'),
            text: tx('auth.messages.closeWindow'),
            command: null
          })

          setTimeout(window.close, 5000)
        })
        .catch(() => {
          const command = `fauna add-endpoint https://db.fauna.com/ --alias=cloud --key="${secret}"`

          setMessage({
            header: tx('auth.messages.actionRequired'),
            text: tx('auth.messages.addEndpointManually'),
            command: command
          })
        })
    }

    auth
      .login(strategy, queryParams)
      .then(response => {
        const isFaunaShell = queryParams.state && queryParams.state.match(/^fauna-shell:(.*)$/)

        if (isFaunaShell) {
          handleFaunaShellSignIn(response)
          return
        }

        api.connect({
          urlString: String(DB_URL),
          secret: response.data.secret
        })

        props.history.push(routes.home.path)
      })
      .catch(() => {
        props.history.push(routes.auth.signIn.path)
      })
  }, [props.history, queryParams, strategy])

  if (!strategyName) {
    throw new Error('You should provide a strategy name param')
  }

  return (
    <>
      <div className="auth-content auth-splash">
        <div className="auth-loader text-center">
          <Icon mode="fab" name="github" className="auth-icon" />
          <h1 className="bold">{message.header}</h1>
          <p>{message.text}</p>
          {message.command && <p style={{ 'background-color': 'grey' }}>$ {message.command}</p>}
        </div>
        <footer className="auth-footer">
          <img alt="Fauna logo" src={logoIcon} />
          {tx('footer.copyright', [new Date().getFullYear().toString()])}
        </footer>
      </div>
    </>
  )
}
