import { values } from 'faunadb'
import _ from 'lodash'
import * as api from './api'
import * as backupApi from './api/frontdoor/backup'
import { UpdateDatabaseParams } from './api'
import { createActions, createReducer, createSaga, createTypes } from './resources'
import { logStatusCode, ResponseErrorTypes } from '../utils/log-helper'

const resourceConfig = {
  name: 'database',
  plural: 'databases',
  namespace: 'databasePath'
}

export const DEFAULT_DATABASE_PRIORITY = 1
export const types = createTypes(resourceConfig)
export const actions = createActions(types)
export const reducer = createReducer(types, resourceConfig, {
  fetch: mapFetchToDraft,
  fetchList: mapFetchListToDraft,
  create: mapCreateToDraft
})
export const saga = createSaga(
  types,
  actions,
  {
    fetch: payload => {
      return api.database(payload)
    },
    fetchList: () => {
      return api.databases()
    },
    create: async payload => api.createDatabase(payload),
    update: async ({
      backupConfigurationControls,
      hasBackupConfigurationPermissions,
      ...payload
    }: UpdateDatabaseParams & {
      backupConfigurationControls: backupApi.BackupConfigurationControls
      hasBackupConfigurationPermissions: boolean
    }) => {
      const res = await api.updateDatabase(payload)

      const parentPath = databaseParentPathFromPath(payload.path)
      const path = parentPath ? parentPath + '/' + payload.name : payload.name

      // timestamp will be undefined if db has no backup configuration
      // TODO: not ideal to rely on the timestamp to find out if backupconfig exists
      const { enabled, ts } = backupConfigurationControls
      const shouldUpdateConfig = ts !== undefined
      const shouldCreateConfig = enabled === true && ts === undefined
      if (shouldCreateConfig)
        await backupApi.createBackupConfiguration(
          path,
          payload.regionGroup,
          backupConfigurationControls,
          hasBackupConfigurationPermissions
        )

      if (shouldUpdateConfig)
        await backupApi.updateBackupConfiguration(
          path,
          payload.regionGroup,
          backupConfigurationControls,
          hasBackupConfigurationPermissions
        )

      return res
    },
    remove: payload => api.removeDatabase(payload)
  },
  resourceConfig
)

function mapFetchToDraft(draft, action) {
  const db = action.payload[resourceConfig.name]

  if (db) {
    const draftItem = draft[action.payload[resourceConfig.namespace]]
    const { ts, name, data } = db
    draftItem.ts = ts
    draftItem.name = name
    draftItem.data = data
  } else {
    logStatusCode(
      null,
      `Unexpected missing db record ${action}, ${draft}`,
      ResponseErrorTypes.HANDLED
    )
  }
}

function mapFetchListToDraft(draft, action) {
  if (!draft[action.payload[resourceConfig.namespace]]) {
    draft[action.payload[resourceConfig.namespace]] = {}
  }
  draft[action.payload[resourceConfig.namespace]].children = action.payload[resourceConfig.plural]
}

function mapCreateToDraft(draft, action) {
  const path = action.payload[resourceConfig.namespace]
  if (!draft[path]) {
    draft[path] = {}
  }
  if (!draft[path].children) {
    draft[path].children = []
  }
  draft[path].children.unshift(action.payload[resourceConfig.name])
}

export const byName = (state: Record<string, any>, databasePath: string) => {
  if (state.databases[databasePath] && state.databases[databasePath].ts) {
    const data = state.databases[databasePath]
    return data
  }
}

export const all = (state: Record<string, any>, databasePath: string) => {
  if (state.databases[databasePath] && state.databases[databasePath].children) {
    const data = state.databases[databasePath].children
    return _.orderBy(data, ['name'], ['asc'])
  }
}

export const databaseIdFromPath = (path: string | null | undefined): string | null | undefined => {
  if (path) {
    const paths = path.split('/')
    return paths[paths.length - 1].toString()
  }
}

export const databaseParentPathFromPath = (
  path: string | null | undefined
): string | null | undefined => {
  if (!path) return undefined
  // strip leading or trailing slashes
  const cleanPath = path.replace(/^\/|\/$/g, '')
  const parts = cleanPath.split('/')
  // use empty string to represent root level database
  return parts.length === 1 ? '' : _.dropRight(parts).join('/')
}

export function getFullDatabasePath(parentPath: string, database: values.Ref) {
  return parentPath ? `${parentPath}/${database.id}` : database.id
}

export function getFullDatabasePathWithRegion(
  parentPath: string,
  database: values.Ref,
  regionPrefix: string
) {
  return `${regionPrefix}/${getFullDatabasePath(parentPath, database)}`
}

export const isRootDatabase = (path: string | null | undefined) => {
  return !path || path === ''
}

export const isValidDatabaseName = name => {
  if (name === '_') return false
  // \w equals [a-zA-Z0-9_]
  return /^[;@+$\-.!~%\w]+$/.test(name)
}

export const stripRegionPrefix = (path: string, regionPrefix: string): string => {
  // strip leading or trailing slashes
  const cleanPath = path.replace(/^\/|\/$/g, '')
  const [prefix, ...parts] = cleanPath.split('/')
  if (prefix != regionPrefix)
    throw `Path '${path}' has unexpected prefix '${prefix}' (expected '${regionPrefix}')`
  return parts.join('/')
}
