import * as React from 'react'
import Spinner from './Spinner'

const Loading = () => {
  return (
    <div className="fillScreen center">
      <Spinner></Spinner>
    </div>
  )
}

export default Loading
