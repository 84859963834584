import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import Icon from '../../shared/Icon'
import Clipboard from 'react-clipboard.js'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import * as documentResource from '../../../modules/documentResource'
import * as AlertActions from '../../../modules/alert/actions'
import { stringify } from '../../../modules/fql/stringify'
import LazyJSONDisplay from '../../shared/LazyJSONDisplay'
import routes, { createRoute } from '../../../config/routes'
import { tx } from '../../../modules/translate'
import IconButton from '../../shared/IconButton'

type Props = {
  document_: Record<string, any>
  browseIndex: Record<string, any>
  classId: Record<string, any>
  removeDocument: Function
  updateDocument: Function
  showAlert: Function
  hideAlertDelay: Function
  databasePath: string
  edit: boolean
  remove: boolean
  onRemoved: Function
}

function DocumentCard({
  document_,
  classId,
  removeDocument,
  showAlert,
  hideAlertDelay,
  databasePath,
  edit,
  remove,
  onRemoved
}: Props) {
  const params = useParams()
  const region = params.region
  const [open, setOpen] = useState(false)
  const showDocumentPath = createRoute(routes.instances.edit.path, databasePath, region)
    .replace(':classId', classId)
    .replace(':referenceId', document_.ref.value.id)

  function toggleCard() {
    setOpen(!open)
  }

  function handleClipboardClick(event) {
    event.stopPropagation()
    showAlert('Copied to Clipboard')
    hideAlertDelay()
  }

  function onDeleteClick(event) {
    event.stopPropagation()
    const onRemoveComplete = (response, error) => {
      if (error) {
        return
      }

      onRemoved(document_)
    }

    const shouldDelete = window.confirm(tx('document_.actions.confirmDelete'))

    if (shouldDelete) {
      removeDocument({
        parentPath: databasePath,
        classId,
        referenceId: document_.ref.id,
        onRemoveComplete
      })
    }
  }

  return (
    <div className="card">
      <div className="card-body clickable" onClick={toggleCard} data-testid="document-card-toggle">
        <div className="data-row">
          <span className="documentEditToggle">
            <Icon name={`${open ? 'chevron-down' : 'chevron-right'}`} />
          </span>
          <div className="card-col documentPreview">
            <small data-hj-suppress data-testid="document-card-preview">
              {stringify(document_.data)}
            </small>
          </div>
        </div>
        <div className="action-row">
          <Link className="card-document-ref" data-tip="Go to document" to={showDocumentPath}>
            <small>{document_.ref.value.id}</small>
          </Link>

          <Clipboard
            onClick={handleClipboardClick}
            data-clipboard-text={document_.ref.value.id}
            className="documentRef"
          >
            <div data-tip="Copy Ref to clipboard" className="card-col">
              <span title="Copy to clipboard" className="clipboard display-small-inline-block">
                <Icon name="copy" />
              </span>
            </div>
          </Clipboard>

          <ReactTooltip />

          {edit && (
            <Link
              className="btn btn-subtle-link"
              to={createRoute(routes.instances.edit.path, databasePath, region)
                .replace(':referenceId', document_.ref.value.id)
                .replace(':classId', document_.ref.value.collection.value.id)}
            >
              <Icon name="pen" />
            </Link>
          )}

          {remove && (
            <IconButton icon="trash" data-testid="document-delete" onClick={onDeleteClick} />
          )}
        </div>
      </div>

      <div className={`card-collapse ${open ? 'open' : ''}`}>
        <div className="card-collapse-container" data-hj-suppress>
          {open && <LazyJSONDisplay maxLines={15} value={stringify(document_)} />}
        </div>
      </div>
    </div>
  )
}

DocumentCard.defaultProps = {
  edit: true
}

export default connect(null, {
  removeDocument: documentResource.actions.remove,
  updateDocument: documentResource.actions.update,
  ...AlertActions
})(DocumentCard)
