function removeComments(textString: string): string {
  if (!textString) return ''
  // a group (  ) that contains 2 patterns with an OR operator in the middle
  // the left half matches the ones that start at the beginning of a line
  // Right accepts \\ only if there is at least 1 space before it
  const regex = new RegExp(/($\s*\/{2}.*$|\s+\/{2}.*$)/gm)
  return textString.replace(regex, '')
}

export { removeComments }
