import * as React from 'react'
import { isWindows } from '../../../utils/platforms'
import { useState, useEffect } from 'react'
import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import IconButton from '../../shared/IconButton'
import DashboardTooltip from '../functions/DashboardTooltip'
import LazyFQLEditor from '../../shared/LazyFQLEditor'
import LazyCode from '../../shared/LazyCode'
import events from '../../../modules/events'

export type FormValues = {
  json: string
}

type Props = {
  loading: boolean
  onCancel: Function
  onSubmit: (arg0: Record<string, any>) => any
  onFailure?: Function
  submitLabel?: string
  initialValues?: FormValues
  isEdit: boolean
  onDeleteClick?: Function
}

export const DOCUMENT_FORM_SET_DATA = 'DOCUMENT_FORM_SET_DATA'
export const DefaultFormValues = {
  json: ''
}

export default function DocumentForm({
  loading,
  onCancel,
  submitLabel,
  onSubmit,
  onFailure,
  initialValues,
  isEdit,
  onDeleteClick
}: Props): React.ReactNode {
  const form = React.createRef()
  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    const handleNewData = (data: string) => {
      setFormValues({ json: data })
    }

    events.on(DOCUMENT_FORM_SET_DATA, handleNewData)
    return () => events.off(DOCUMENT_FORM_SET_DATA, handleNewData)
  }, [])

  function submitForm(formValues: FormValues, redirect: boolean): void {
    if (form.current && !form.current.checkValidity()) {
      onFailure && onFailure()
    } else {
      onSubmit(formValues, redirect)
    }
  }

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault()
    submitForm(formValues, false)
  }

  function handleSubmitAndReturnToCollections(event: React.SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault()
    submitForm(formValues, true)
  }

  function getCursorPosition(): [number, number] | null | undefined {
    const documentIsEmpty = formValues.json === '{}'

    return documentIsEmpty ? [0, 1] : null
  }

  function onFormChange(value) {
    setFormValues({ json: value })
  }

  const CodeSnippet = () => {
    if (isWindows()) {
      const documentData = `{ itemName: 'apple', price: 0.42 + 1.50, perishable: true }`
      return <LazyCode code={documentData} />
    } else {
      return (
        <div className="code-snippet">
          <div>{'{'}</div>
          <div>{`  itemName: "apple",`}</div>
          <div>{`  price: 0.42 + 1.50,`}</div>
          <div>{`  perishable: true,`}</div>
          <div>{'}'} </div>
        </div>
      )
    }
  }

  return (
    <form onSubmit={handleSubmit} ref={form}>
      <div className="form-group">
        <div className="form-label--tooltip">
          <label className="required" htmlFor="code-editor">
            {tx('javascript.name')}
          </label>
          <DashboardTooltip
            id="javascriptheader"
            placement="left"
            contentText={
              <div>
                <div className="margin-bottom-2">You can use Javascript syntax. For example:</div>
                <CodeSnippet />
              </div>
            }
          />
        </div>
        <div data-hj-suppress>
          <LazyFQLEditor
            moveCursorTo={getCursorPosition()}
            value={formValues.json}
            onChange={onFormChange}
          />
        </div>
      </div>

      <div className="form-actions form-actions--document-form container container--xsmall">
        <Button onClick={onCancel} color="secondary">
          {tx('actions.cancel')}
        </Button>

        <Button type="submit" color="success" loading={loading}>
          {submitLabel || tx('actions.save')}
        </Button>

        <Button onClick={handleSubmitAndReturnToCollections} color="subtle-link">
          {tx('document_.messages.saveReturnToCollections')}
        </Button>
      </div>

      {onDeleteClick && (
        <>
          <hr className="margin-top-4 margin-bottom-3" />

          <IconButton
            color="muted"
            onClick={onDeleteClick}
            label={tx('actions.remove')}
            icon="trash"
          />
        </>
      )}
    </form>
  )
}

DocumentForm.defaultProps = {
  loading: false,
  initialValues: DefaultFormValues,
  isEdit: false
}
