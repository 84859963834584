import { useEffect } from 'react'
import { connect } from 'react-redux'

const CREATE_FLASH_MESSAGE = 'CREATE_FLASH_MESSAGE'
const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE'

export const reducer = (state: Record<string, any> = {}, action: Record<string, any>) => {
  switch (action.type) {
    case CREATE_FLASH_MESSAGE:
      return {
        ...state,
        [action.payload.id]: action.payload.value
      }

    case REMOVE_FLASH_MESSAGE:
      delete state[action.payload.id]
      return state

    default:
      return state
  }
}

export function setFlash(id: string, value: any) {
  return {
    type: CREATE_FLASH_MESSAGE,
    payload: { id, value }
  }
}

export function removeFlash(id: string) {
  return {
    type: REMOVE_FLASH_MESSAGE,
    payload: { id }
  }
}

export function getFlash(id: string, state: Record<string, any>) {
  return state.flash[id]
}

type FlashProps = {
  id: string
  message: string
  children: Function
  removeFlash: Function
}

function Flash({ id, children, message, removeFlash }: FlashProps) {
  useEffect(() => {
    return () => removeFlash(id)
  }, [id, removeFlash])

  return children(message)
}

const mapState = (state, { id }) => ({
  message: getFlash(id, state)
})

const mapDispatch = {
  removeFlash
}

export default connect(mapState, mapDispatch)(Flash)
