import React from 'react'
import toast from 'react-hot-toast'

export interface MakeToastInput {
  title: string
  message?: string
}

export const makeToast = ({ title, message }: MakeToastInput) =>
  toast(
    () => (
      <div className="toast-content">
        <h3>{title}</h3>
        {message && <p>{message}</p>}
      </div>
    ),
    {
      position: 'top-right',
      style: {
        color: 'white',
        backgroundColor: '#222222',
        borderRadius: '4px',
        minWidth: '22.5rem',
        padding: '0'
      },
      icon: null
    }
  )
