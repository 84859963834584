import axios from 'axios'
import { logAxiosError } from '../../../utils/log-helper'

export type ForgotPasswordParams = {
  email: string
  captcha_token: string
}

export type ResetPasswordParams = {
  password: string
  captcha_token: string
  reset_token: string
  email: string
}

export type ValidateResetTokenParams = {
  reset_token: string
  email: string
}

export const FORGOT_PASSWORD_URL = `${process.env.REACT_APP_AUTH_URL}/forgot_password`
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_AUTH_URL}/password_reset`
export const VALIDATE_RESET_URL = `${process.env.REACT_APP_AUTH_URL}/password_reset_validate`

export const callForgotPassword = (
  params: ForgotPasswordParams,
  url: string = FORGOT_PASSWORD_URL
): Promise<Record<string, any>> => {
  return axios
    .post(url, {
      ...params
    })
    .then(result => result.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export const callResetPassword = (
  params: ResetPasswordParams,
  url: string = RESET_PASSWORD_URL
): Promise<Record<string, any>> => {
  return axios
    .post(url, {
      ...params
    })
    .then(result => result.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}

export const callValidateResetToken = (
  params: ValidateResetTokenParams,
  url: string = VALIDATE_RESET_URL
): Promise<Record<string, any>> => {
  return axios
    .post(url, {
      ...params
    })
    .then(result => result.data)
    .catch(e => {
      logAxiosError(e)
      throw e
    })
}
