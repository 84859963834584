import cookies from 'js-cookie'

export type CookieConfig = {
  domain?: string
  expires?: number
  secure?: boolean
  sameSite?: string
}

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN ?? ''

export const get = (name: string): any => {
  return (
    cookies.get(name, { domain: COOKIE_DOMAIN }) &&
    JSON.parse(cookies.get(name, { domain: COOKIE_DOMAIN }))
  )
}

export const remove = (name: string): void => {
  cookies.remove(name, { domain: COOKIE_DOMAIN })
}

export const save = (name: string, contents: any, config: CookieConfig): void => {
  cookies.set(name, JSON.stringify(contents), config)
}

export const exists = (name: string): boolean => {
  return !!get(name)
}
