import { UsagePlanItem } from './types'
import { formatPrice } from './utils'

export default function Summary({ usagePlan }: { usagePlan: UsagePlanItem }) {
  return (
    <div className="summary">
      <div className="summary__header">
        <h4 className="summary__title">Selected plan</h4>
      </div>

      <div className="summary__body">
        <ul className="summary__list">
          <li className="summary__item">
            <div className="summary__item__description">{usagePlan.name}</div>
            <div className="summary__item__value">{formatPrice(usagePlan.price)}/month</div>
          </li>
          <li className="summary__item">
            <div className="summary__item__description">Sales tax</div>
            <div className="summary__item__value">{formatPrice(0)}</div>
          </li>
        </ul>
      </div>

      <div className="summary__total">
        <div className="summary__total__label">Total</div>
        <div className="summary__total__value">{formatPrice(usagePlan.price)}</div>
      </div>
    </div>
  )
}
