import { useCallback, useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { ContextRouter } from 'react-router-dom'
import queryString from 'query-string'
import OAuthError from './OAuthError'

const AuthError = ({ location }: ContextRouter) => {
  const [isOpen, setIsOpen] = useState(false)
  const { error_code: errorCode, method } = useMemo(() => queryString.parse(location.search), [
    location.search
  ])

  useEffect(() => {
    errorCode ? setIsOpen(true) : setIsOpen(false)
  }, [errorCode])

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  if (errorCode === 'user_is_using_another_strategy_or_provider') {
    return <OAuthError toggle={toggle} isOpen={isOpen} method={method} />
  }

  return <></>
}

export default withRouter(AuthError)
