export default {
  attributes: {
    name: 'Name',
    terms: 'Terms',
    values: 'Values',
    unique: 'Unique',
    serialized: 'Serialized',
    refId: 'Ref Id',
    preview: 'Preview',
    parentDatabase: 'Parent Database',
    role: 'Role',
    email: 'E-mail',
    businessEmail: 'Work E-mail',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    faunaDbUrl: 'Fauna URL',
    secret: 'Secret',
    key: 'Key',
    historyDays: 'History Days',
    ttl: 'TTL',
    faunaData: 'Fauna Data'
  },
  actions: {
    cancel: 'Cancel',
    create: 'Create',
    save: 'Save',
    update: 'Update',
    remove: 'Delete',
    revoke: 'Revoke',
    close: 'Close',
    edit: 'Edit',
    clear: 'Clear',
    discardChanges: 'Discard Changes',
    delete: 'Delete',
    settings: 'Settings',
    copy: 'Copy'
  },
  auth: {
    actions: {
      start: 'Sign up with e-mail',
      logout: 'Sign Out',
      logIn: 'Log in',
      signUp: 'Sign up',
      enterNow: 'Sign in with e-mail',
      enterWithSecret: 'Sign in with key',
      forgotPassword: 'Forgot your password?'
    },
    errors: {
      default: 'Something is wrong. Please, try again.',
      invalidUserOrPassword: 'User or password are invalid.',
      emailRegistered: 'This e-mail is already registered.',
      invalidConfirm: "Confirm password doesn't match.",
      invalidSecret: 'The secret is invalid.',
      noConnection: 'We are not able to connect to the provided Fauna URL.',
      expiredSession: 'Your session has expired',
      unauthorized:
        'Your access key may not have sufficient permissions to view or modify all the resources on this page.',
      invalidSession: 'Invalid session.',
      invalidSessionMessage: 'Your session has expired, please log in again.'
    },
    messages: {
      startNow: 'Start Now on Fauna',
      signIn: 'Welcome to Fauna',
      getStarted: 'Get started for free',
      pricingDetails: 'pricing details',
      alreadyHaveAccount: 'Already have an account?',
      noAccount: 'New to Fauna?',
      orSignInWith: 'or log in with',
      orSignUpWith: 'or sign up with',
      needHelp: 'Needing help?',
      contactUs: 'Contact us',
      checking: 'Checking credentials',
      actionRequired: 'Action required.',
      addEndpointManually: 'Run the command below to manually add cloud endpoint.',
      shellLoginComplete: 'Shell Login Complete',
      closeWindow: 'You can now close this browser window.',
      waitingRedirect: 'You will be redirected in a few moments'
    },
    terms: {
      first: 'By signing up for Fauna, you are accepting the',
      second: 'and the'
    },
    signup: {
      infoBullets: {
        one: 'Sign up today and access Fauna’s Pro plan free for 30 days.',
        two:
          'Create your first database in seconds using drivers in JavaScript, Python, Java, C#, Go, and Scala.',
        three: 'Integrate your database into your application and perform CRUD operations.',
        four: 'Quickly get started with Fauna’s developer-friendly database language, FQL.',
        five: 'Invite multiple members of your team to share an organizational-level Fauna account.'
      }
    }
  },
  footer: {
    copyright: 'Copyright {0} Fauna Inc.',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy'
  },
  loading: {
    noData: 'No resources yet.',
    loading: 'Loading resources...',
    simple: 'Loading...'
  },
  database: {
    home: 'Home',
    settings: 'Database Settings',
    overview: 'Overview',
    children: {
      name: 'Child Databases'
    },
    child: {
      name: 'Child Database'
    },
    name: 'Database',
    plural: 'Databases',
    empty: {
      create: 'Create your first database.',
      childCreate: 'Create your first child database.'
    },
    actions: {
      new: 'Create Database',
      edit: 'Database Settings',
      select: 'Select a database',
      delete: 'Yes, delete database'
    },
    form: {
      name: 'Name',
      create: 'Create',
      save: 'Save',
      submitRequest: 'Submit Request'
    },
    messages: {
      deleteConfirm: 'Are you sure you want to delete database {0}?',
      actionWarning: 'This action cannot be undone.'
    },
    fields: {
      name: 'Database Name'
    },
    demo: {
      label: 'Use demo data',
      info:
        'The demo data is used to create a simple warehouse data structure for you, including GraphQL schema. This lets you learn more about how Fauna works with practical examples.'
    }
  },
  databaseTable: {
    columns: {
      actions: 'Actions'
    }
  },
  index: {
    name: 'Index',
    plural: 'Indexes',
    actions: {
      new: 'New Index',
      edit: 'Edit Index',
      confirmDelete: 'Are you sure you want to delete index {0}?'
    },
    empty: {
      title: 'Create your first index.',
      class_: 'Collections before indexes',
      secondaryClassTitle: 'Collections before indexes',
      terms: 'No terms',
      values: 'No values'
    },
    messages: {
      derivedView: 'This view was derived from ',
      source: 'Source',
      terms: 'Terms',
      values: 'Values',
      status: 'Status',
      active: 'Active',
      buildingIndex: 'Building Index...',
      classEmpty:
        'Indexes help you browse the data in your collections, but you need a collection before you create an index.',
      empty:
        'In Fauna, indexes are used to easily browse the data in your collections. To get started, create your first index.',
      noDocuments:
        'This index contains no documents. Fauna stores data in "documents". If you’re coming from another database, the documents in a collection are like the rows in a table.'
    }
  },
  class_: {
    name: 'Collection',
    plural: 'Collections',
    actions: {
      new: 'New Collection',
      edit: 'Collection Settings',
      select: 'Select a Collection'
    },
    empty: {
      title: 'Create first collection'
    },
    messages: {
      deleteConfirm: 'Are you sure you want to delete collection {0}?',
      empty: `
      Collections are Fauna's version of tables. To get started, create your first collection.
      `
    }
  },
  document_: {
    name: 'Document',
    plural: 'Documents',
    actions: {
      new: 'New Document',
      delete: 'Delete Document',
      confirmDelete: 'Do you want to delete this document?',
      search: 'Search Documents',
      edit: 'Edit {0}'
    },
    messages: {
      noResults: 'No results found for',
      search: 'Search the documents',
      searchSubTitle: 'using the form.',
      noDocuments:
        'This collection contains no documents. Fauna stores data in "documents". If you’re coming from another database, the documents in a collection are like the rows in a table.',
      noDocumentsYet: 'no documents yet',
      saveReturnToCollections: 'Save and go back'
    }
  },
  usage: {
    freeLimits: 'Free limits',
    empty: 'No usage data for this period',
    ops: {
      transfer: 'Data Transfer',
      read: 'Read Ops (K)',
      write: 'Write Ops (K)',
      compute: 'Compute Ops (K)',
      storage: 'Storage (GB)',
      database_storage: 'Database Storage (GB)',
      backup_storage: 'Backup Storage (GB)',
      // compound metric types
      read_write: 'Read/Write Ops (K)',
      backup_compute: 'Backup Compute Ops (K)'
    },
    free: 'Free',
    used: 'Used',
    observations: {
      storage:
        'Storage usage is a weighted average over the last 30 days, and can be delayed by up to 48 hours.'
    }
  },
  billing: {
    byte_read_ops: 'Read Ops',
    read_ops: 'Read Ops',
    byte_write_ops: 'Write Ops',
    write_ops: 'Write Ops',
    storage: 'Storage',
    acc_snapshot_size: 'Backup Storage',
    snapshot_tcos: 'Backup Snapshots',
    restore_tcos: 'Backup Restores',
    data_transferred: 'Data Transfer',
    minimum_charge:
      "Please ignore invoices below $0.50 as they will roll over into next month's bill, and we will not throttle usage.",
    billing_notice: 'Billing Notice',
    compute_ops: 'Compute Ops',
    account: 'Account usage from Dashboard, Shell, and GraphQL Playground.'
  },
  period: {
    last7Days: 'Last 7 days',
    last30Days: 'Last 30 days'
  },
  helpful: {
    helpfulResources: 'Helpful Resources',
    fqlCheatSheet: {
      title: 'FQL cheat sheet',
      subtitle: 'The Fauna Developer’s favorite page in the documentation'
    },
    fqlForSqlUsers: {
      title: 'Fauna | Fauna Query Language for SQL users',
      subtitle: 'Our SQL to FQL translation will help you get started with Fauna.'
    },
    learnFQLBasics: {
      title: 'Fauna | Fundamental concepts',
      subtitle: 'The best tutorial series for becoming a Fauna power user'
    }
  },
  gettingStarted: {
    gettingStartedTitle: 'Getting Started',
    faunaQuickStart: {
      title: 'Fauna quick start',
      subtitle: 'Query your first Fauna database in minutes'
    },
    faunaSampleApps: {
      title: 'Fauna | Sample apps',
      subtitle: 'Learn from our Sample apps.'
    },
    driverInstallation: {
      title: 'Driver installation',
      subtitle: 'JavaScript, Python, Java, C#, Go, or Scala'
    }
  },
  javascript: {
    name: 'Javascript'
  },
  json: {
    name: 'JSON'
  },
  keys: {
    name: 'Key',
    plural: 'Keys',
    title: 'Database Keys',
    actions: {
      new: 'New key',
      revoke: 'Revoke key',
      confirmRevoke: 'Are you sure you want to revoke this key?'
    },
    messages: {
      new: {
        first: "Your key's secret is:",
        second: "This secret won't be displayed again, so please copy and save it somewhere safe."
      }
    }
  },
  accountKeys: {
    name: 'Key',
    plural: 'Keys',
    actions: {
      new: 'New Key',
      delete: 'Delete Key',
      deleteConfirm: 'Yes, delete key'
    },
    messages: {
      new: {
        first: "Your key's secret is:",
        second: "This secret won't be displayed again, so please copy and save it somewhere safe."
      },
      delete: {
        first: 'Are you sure you want to delete key {0}?',
        second: 'This action cannot be undone.'
      }
    }
  },
  status: {
    name: 'Status',
    active: 'Active',
    unstable: 'Unstable',
    errors: {
      unavailable:
        'Looks like the server is taking too long to respond. This can be caused by internet connection issues or by an error with our servers.'
    }
  },
  info: {
    resources: 'Resources',
    settings: 'Settings',
    doc: 'Documentation',
    community: 'Community',
    download: 'Download'
  },
  tooltips: {
    databaseName:
      'Cannot be events, sets, self, documents, or _. This required field can be changed later.',
    editorJson: 'Data entered into editor must be full JSON. This can be edited later.',
    trackDays:
      'Document history can impact storage utilization and is not retained by default. Setting a value will store documents for the defined number of days. Leaving the value blank will store document history indefinitely.',
    ttl:
      'Documents are deleted this many days after their last write. Documents of the collection will be removed if they have not been updated within the configured TTL. This optional field can be changed later.',
    className:
      'Cannot be events, sets, self, documents, or _. Please wait a few moments before using a recently deleted collection name. This required field can be changed later.',
    indexName:
      'Cannot be events, sets, self, documents, or _. Renaming an index changes its ref, but preserves inbound references to the index. Index data is not rebuilt. This required field can be changed later.',
    indexTerms:
      'The terms field specifies which document fields can be searched. Leaving it blank means that specific documents cannot be searched for, but the index can be used to paginate over all documents in the source collection(s). This optional field may not be changed later. To adjust it, delete the current index and create a new one with the desired definition.',
    indexValues:
      "The values field specifies which document fields to return for matching entries. Leaving it blank returns the indexed document's reference. This optional field may not be changed later. To adjust it, delete the current index and create a new one with the desired definition.",
    indexUnique:
      'If checked, maintains a uniqueness constraint on combined terms and values. Indexes with the unique constraint must also be serialized. Note: If you update the unique field, it will not remove existing duplicated items from the index.',
    indexSerialized:
      'If checked, writes to this index are serialized with concurrent reads and writes.',
    indexSourceClasses:
      'The ability to add multiple source collections and field bindings is coming to the Dashboard soon. Meanwhile, you can do this via the Fauna Shell.',
    indexSearch:
      'Search data type must match term data type (that is, surround string values with "").',
    usageInterruption: 'You are at or near usage limits. To avoid service interruption, ',
    usageInterruptionLink: 'enable billing',
    usageFreeQuota:
      'Free quotas for read ops, write ops, and data transfer reset daily at 00:00 GMT.',
    usageNextReset: 'Next reset: ',
    keyTypes: {
      admin:
        'Used to create, destroy, or manage any database or key. Protect admin keys very carefully.',
      server:
        'Can be used to create, destroy, or manage the database to which they are assigned. Protect server keys carefully.',
      'server-readonly':
        'Provides full read access to the database that they are assigned to, or any child database.',
      client:
        'Provides restricted access to resources that are marked with the `public` permission. Due to their restrictions, they are suitable for use in untrusted environments, such as within mobile clients.'
    },
    readOpsTooltip:
      'Number of read requests (in 1000s) that are by default strongly consistent and transactional.',
    readOpsTooltipFree: 'Free plan includes 100K read requests.',
    writeOpsTooltip:
      'Number of write requests (in 1000s) that are by default transactional and replicated.',
    writeOpsTooltipFree: 'Free plan includes 50K write requests.',
    storageTooltip:
      'Persistent storage on disk (in GBs) that includes space used for replication and temporality.',
    storageTooltipFree: 'Free plan includes 5GB in storage.',
    computeOpsTooltip: 'CPU usage that is billed in the form of Compute ops (in 1000s).',
    computeOpsTooltipFree: 'Free plan includes 500K compute ops.',
    additionalDetails: 'For additional details visit',
    pricingAndPlansLink: 'Pricing and Plans | Fauna',
    exportLogs: 'Export Logs',
    logSource:
      'RG = Region Group, logs were requested for all DBs in a region group. DB = Database, logs were requested for a single database.'
  },
  deprecations: {
    root:
      'Deprecation Warning: Creation of any objects, collections or indexes, is not recommended in root.'
  },
  query: {
    plural: 'Shell',
    actions: {
      run: 'run query',
      runAs: 'run as',
      runQueryAs: 'run query as',
      specifyDocument: 'Specify a document'
    },
    messages: {
      success: 'Query executed successfully.'
    },
    labels: {
      results: 'Query Results'
    },
    inputPlaceholders: {
      collection: 'Collection',
      id: 'ID'
    }
  },
  tabs: {
    simple: 'Simple',
    fql: 'FQL'
  },
  webshell: {
    pageTitle: 'Shell',
    download: 'Download',
    openfile: 'Open file',
    clear: 'Clear',
    bulkResults: '/* Bulk results mode: */\n',
    initQuery: 'Paginate(Collections());\nPaginate(Indexes());\nPaginate(Databases());'
  },
  graphql: {
    pageTitle: 'GraphQL Playground',
    mergeSchema: 'Merge schema',
    replaceSchema: 'Replace schema',
    merge: 'Merge',
    replace: 'Replace',
    crumb: 'GraphQL',
    importTitle: 'Import your GraphQL schema',
    importSchema: 'Import schema',
    importDescription: 'To get started, import your GraphQL schema using a .gql or .graphql file.',
    importError: 'Error on import GraphQL schema',
    updateSuccess: 'Schema update successful!',
    updateError: 'Error updating GraphQL schema.',
    errorBoundaryTitle: 'Issues processing last GraphQL query',
    errorBoundary:
      "If you got this error, it's probably because your last query contained an invalid character type. To fix it, just clear your local storage.",
    clearLocalStorage: 'Clear local storage'
  },
  backup: {
    title: 'Backup',
    titlePlural: 'Backups',
    enable: 'Enable Backup',
    pagination: 'Page {0} of {1}',
    search: 'Search by name',
    showAll: 'Show All',
    enabled: 'Enabled',
    disabled: 'Disabled',
    noData: 'No Data',
    columnHeaders: {
      database: 'Database',
      status: 'Status',
      latest: 'Latest',
      retention: 'Retention',
      snapshots: 'Snapshots',
      actions: 'Actions'
    },
    schedule: {
      message: {
        prefix: 'At 12:00 AM',
        new_prefix: 'daily'
      },
      daily: {
        default_cron_expression: '0 0 * * *',
        label: 'Daily'
      },
      weekly: {
        default_cron_expression: '0 0 * * 1',
        label: 'Weekly',
        invalid_cron_expression: 'Day (week) should be within [1-7]'
      },
      monthly: {
        default_cron_expression: '0 0 1 * *',
        label: 'Monthly',
        invalid_cron_expression: 'Day (month) should be within [1-28]'
      },
      custom: {
        default_cron_expression: '0 0 L * *',
        label: 'Last day of month',
        invalid_cron_expression: 'Entered cron expression is invalid'
      }
    },
    cron_expression: {
      hour: 'hour',
      minute: 'minute',
      day_of_month: 'day (month)',
      month: 'month',
      day_of_week: 'day (week)'
    }
  },
  logs: {
    title: 'Logs',
    exportTitle: 'Export Logs',
    querylogs: {
      name: 'Query Log',
      plural: 'Query Logs',
      region: 'Export logs for Region Group'
    },
    actions: {
      new: 'New Export',
      export: 'Export Logs',
      download: 'Download'
    },
    empty: 'Request your first Query Log',
    description:
      'Query Logs allow you to view query statistics across a region or database for a given time interval.'
  },
  security: {
    title: 'Security'
  },
  role: {
    title: 'Database Roles',
    editTitle: 'Edit Role',
    firstRole: 'Create your first custom role',
    newCustomRole: 'New custom role',
    newRole: 'New role',
    customRoleTitle: 'Custom roles',
    builtInRoles: 'Built-in roles',
    addMemberCollection: 'Add member collection',
    discardChangesConfirm:
      '"Discard changes" will discard any changes you have made to this role. "Cancel" will take you back to editing the role. Are you sure you want to discard changes?',
    privileges: 'Privileges',
    membership: 'Membership',
    confirmDelete: 'Do you really want to remove role :roleId?',
    removeRole: 'Remove role',
    actions: {
      read: 'Read',
      write: 'Write',
      create: 'Create',
      delete: 'Delete',
      call: 'Call',
      historyRead: 'History Read',
      historyWrite: 'History Write',
      unrestricted: 'Unrestricted'
    },
    addResource: 'Add {0}',
    noResourcesAvailable: 'No resources available'
  },
  function_: {
    title: 'Functions',
    name: 'Function'
  },
  schema: {
    title: 'Schemas',
    name: 'Schema'
  },
  addons: {
    netlify: {
      notFoundError:
        "It seems you've already imported your site db into your Fauna account. Good job!",
      genericError: 'It seems something wrong happened. Please try again.',
      importDatabaseMessage1: 'It looks like you want to import the database for',
      importDatabaseMessage2: 'to your Fauna account.',
      importingDatabase: 'Importing database...',
      importDatabase: 'Import Database',
      importNetlifyDatabase: 'Import Netlify Database'
    }
  },
  provider: {
    title: 'Database Providers',
    newProvider: 'New access provider',
    editProvider: 'Edit provider',
    noProviders:
      'Third-party services can be configured as access providers vending tokens that map to a database role defining actions a request can perform and the resources that can be acted on. Note that the third-party service must be configured with the Fauna-provided audience to set up the integration (see the docs for more information).',
    actions: {
      createSuccess: 'Access provider created successfully',
      createError: 'Error creating new access provider',
      updateSuccess: 'Access provider successfully updated',
      updateError: 'Error updating access provider',
      deleteSuccess: 'Access provider successfully deleted',
      deleteError: 'Error deleting the access provider'
    },
    form: {
      nameLabel: 'Name',
      issuerLabel: 'Issuer',
      jwksUriLabel: 'JWKS endpoint',
      allowedCollectionsLabel: 'Allowed collections',
      allowedCollectionsTooltip:
        'A list of user-defined Collection references, which enumerates the collections that the authentication provider will be able to grant access to.',
      allowedRolesTooltip:
        'A list of Role references, which enumerates the roles the access provider will be able to grant access to.',
      issuerJwksInstructionsText:
        'Get the Issuer and JSON Web Key Secret endpoint from your auth provider settings.',
      roleInstructionText:
        'At least one role is required for access, but you can add or remove roles at any time.',
      error: 'Error fetching access providers from the server...'
    }
  },
  teams: {
    upgradeMessage:
      'The Pro plan includes: up to 100 users, the option to purchase support, increased storage, operations, and network transfer: 200GB storage, 45M read operations, 22.5M write operations, 15GB network transfer.'
  },
  modals: {
    teamsPaywall: 'Upgrade to the Team Plan to add team members'
  }
}
