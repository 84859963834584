import _ from 'lodash'
import React from 'react'
import moment from 'moment-timezone'

type SnapshotDateProps = {
  state: string
  timestamp: string
  displayableStates?: Array<string>
}

export const formatSnapshotDate = (timestamp: string) => {
  return `${moment(timestamp).format('MM-DD-YYYY HH:mm:ss')} (UTC)`
}

export const SnapshotDate: React.FC<SnapshotDateProps> = ({
  state,
  timestamp,
  displayableStates = ['Pending', 'InProgress', 'Failed']
}) => {
  const shouldDisplayState = displayableStates.includes(state)
  return (
    <div className={`snapshot-date ${shouldDisplayState ? 'secondary-neutral' : ''}`}>
      <div>{formatSnapshotDate(timestamp)}</div>
      {shouldDisplayState && <div className="padding-x-1">-</div>}
      {shouldDisplayState && <div className="lowercase italic">{_.startCase(state)}</div>}
    </div>
  )
}

export default SnapshotDate
