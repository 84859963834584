import * as React from 'react'
import Button from './shared/Button'
import { logout } from '../modules/auth'
import routes from '../config/routes'
import { logStatusCode, ResponseErrorTypes } from '../utils/log-helper'
import { datadogRum } from '@datadog/browser-rum'

type Props = {
  children: React.ReactNode
}

export default class ErrorBoundary extends React.Component<Props> {
  state = {
    error: null,
    isRefreshing: false
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error) {
    logStatusCode(
      error.code ? error.code : error.response?.status,
      error,
      ResponseErrorTypes.HANDLED
    )
  }

  clearLocalStorage() {
    this.setState(state => ({ ...state, isRefreshing: true }))
    window.localStorage.clear()
    window.sessionStorage.clear()
    logout()
    window.location.assign(routes.auth.signIn.path)
  }

  render() {
    if (this.state.error) {
      datadogRum.addError(this.state.error, {
        error_type: 'whoops',
        location: window.location.pathname
      })
      return (
        <div className="center min-height-100 primary">
          <div>
            <div className="center">
              <div className="container container--small grid">
                <div className="row padding-y-8 padding-x-4 padding-x-small-0">
                  <div className="col col--auto margin-right-4">
                    <ColoredLogo />
                  </div>

                  <div className="col">
                    <header className="line-height-tight margin-bottom-2">
                      <h1 className="text-2xl no-margin">Ooops...</h1>
                      <h2 className="text-xl no-margin">Something went wrong</h2>
                    </header>

                    <main>
                      <p className="text-lg secondary">Please login again.</p>

                      <Button
                        loading={this.state.isRefreshing}
                        onClick={this.clearLocalStorage.bind(this)}
                        color="success"
                        className="margin-bottom-3"
                      >
                        Go back to login.
                      </Button>
                      <a href="/">Go back to Home</a>
                    </main>
                  </div>
                </div>
              </div>
            </div>

            <div className="container container--medium">
              <hr />

              <footer className="secondary text-center padding-4">
                If the issue recurs after login, please contact{' '}
                <a href="mailto:support@fauna.com">support@fauna.com</a> and describe what actions
                you were taking right before you encountered this error. Alternatively, for more
                immediate help, please reach out to us in{' '}
                <a href="https://community.fauna.com/">Community</a>.
              </footer>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

function ColoredLogo() {
  return (
    <svg
      width="148"
      height="179"
      viewBox="0 0 148 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8171 65.8947L0 0L91.2864 71.233L106.785 51.548L126.541 47.7111L148 43.5405L126.541 75.904V119.444L16.6904 179L46.8355 131.789L75.107 114.773L45.6433 121.613L20.267 79.5741L72.893 93.754"
        fill="#323FCB"
      />
    </svg>
  )
}
