export enum UsagePlan {
  free = 'free',
  individual = 'individual',
  team = 'team',
  business = 'business',
  'Free-V2' = 'Free-V2',
  'Pay-As-You-Go' = 'Pay-As-You-Go',
  Startup = 'Startup',
  Trial = 'Trial',
  Pro = 'Pro',
  'Enterprise-Low' = 'Enterprise-Low',
  'Enterprise-Medium' = 'Enterprise-Medium',
  'Enterprise-High' = 'Enterprise-High'
}

// All usage plans.
export const ALL_USAGE_PLANS = [
  UsagePlan.free,
  UsagePlan.individual,
  UsagePlan.team,
  UsagePlan.business,
  UsagePlan['Free-V2'],
  UsagePlan['Pay-As-You-Go'],
  UsagePlan.Startup,
  UsagePlan.Trial,
  UsagePlan.Pro,
  UsagePlan['Enterprise-Low'],
  UsagePlan['Enterprise-Medium'],
  UsagePlan['Enterprise-High']
]

// Which usage plans have access to the 'Teams' feature.
export const TEAM_USAGE_PLANS = [UsagePlan.team, UsagePlan.business]

// Which usage plans are 'free'.
export const FREE_USAGE_PLANS = [UsagePlan.free]

// Which usage plans can be 'upgraded'. (Determines whether we show an 'upgrade' button.)
export const UPGRADEABLE_USAGE_PLANS = ALL_USAGE_PLANS.filter(
  plan =>
    plan !== UsagePlan.business &&
    plan !== UsagePlan['Enterprise-Low'] &&
    plan !== UsagePlan['Enterprise-Medium'] &&
    plan !== UsagePlan['Enterprise-High']
)
