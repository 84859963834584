import React, { FormEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import routes from '../../../config/routes'
import { showAlert } from '../../../modules/alert/actions'
import { RequestStatus } from '../../../modules/api'
import { BillingInfo } from '../../../modules/api/auth-service/billingInfo'
import useContactInfo, { ContactInfo } from '../../../modules/api/auth-service/contactInfo'
import useProfile, { Profile } from '../../../modules/api/auth-service/profile'
import useBillingInfo from '../../../modules/api/auth-service/billingInfo'
import { useForm } from '../../../modules/form'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import Spinner from '../../shared/Spinner'

const makeInitial = (
  contactInfo?: ContactInfo,
  profileData?: Profile,
  billingData?: BillingInfo
) => ({
  contact_name: contactInfo?.contact_name ?? profileData?.name ?? '',
  contact_email: contactInfo?.contact_email ?? profileData?.email ?? '',
  company_name: contactInfo?.company_name ?? billingData?.company_name ?? '',
  website: contactInfo?.website ?? ''
})

const AccountSettings: React.FC = () => {
  const dispatch = useDispatch()
  const { contactInfo, update } = useContactInfo()
  const { billingInfo } = useBillingInfo()
  const { profile } = useProfile()
  const isLoading = [profile, billingInfo, contactInfo].some(({ data, error }) => !data && !error)
  const { handleChange, formValues, setFormValues } = useForm<ContactInfo>(makeInitial())

  useEffect(() => {
    if (contactInfo.data && profile.data && billingInfo.data) {
      setFormValues(makeInitial(contactInfo.data, profile.data, billingInfo.data))
    }
  }, [profile.data, contactInfo.data, billingInfo.data, setFormValues])

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault()
      await update.call(formValues)
      dispatch(showAlert('Account updated successfully!', 'success'))
    } catch (error) {
      dispatch(showAlert(getErrorMessage(error), 'error'))
    }
  }

  const handleCancel = () => {
    setFormValues(makeInitial(contactInfo.data, profile.data))
  }

  if (isLoading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <ContentHeader divider>Account Settings</ContentHeader>

      <div className="container container--xsmall padding-y-3">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="contact_name" className="required">
              Account contact name
            </label>
            <input
              value={formValues.contact_name}
              onChange={handleChange}
              type="text"
              name="contact_name"
              id="contact_name"
              autoFocus
            />
          </div>

          <div className="form-group">
            <label htmlFor="contact_email" className="required">
              Account contact email
            </label>
            <input
              value={formValues.contact_email}
              onChange={handleChange}
              type="email"
              name="contact_email"
              id="contact_email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="company_name" className="optional">
              Company name
            </label>
            <input
              value={formValues.company_name}
              onChange={handleChange}
              type="text"
              name="company_name"
              id="company_name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="website" className="optional">
              Website
            </label>
            <input
              value={formValues.website}
              onChange={handleChange}
              type="url"
              name="website"
              id="website"
              placeholder="Example: https://fauna.com"
              onInvalid={() =>
                dispatch(
                  showAlert(
                    getErrorMessage({
                      message: "Website must be a valid URL starting with 'http://' or 'https://'."
                    }),
                    'error'
                  )
                )
              }
            />
          </div>

          <div className="form-actions">
            <Button onClick={handleCancel} color="secondary">
              {tx('actions.cancel')}
            </Button>

            <Button type="submit" color="success" loading={update.status === RequestStatus.loading}>
              {tx('actions.save')}
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Account',
    path: routes.settings.account.path
  }
])(AccountSettings)
