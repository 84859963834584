import { useState, useEffect } from 'react'
import { CacheOptions } from './cache'
import { useCache } from './cache'

export function useFetch<FetchData>(
  promiseFn: (...args: any[]) => Promise<FetchData>,
  args?: any[],
  cacheOptions?: CacheOptions
): {
  data: FetchData | null | undefined
  loading: boolean
  error: Error | null | undefined
} {
  const { cacheData, setCache } = useCache(cacheOptions)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<FetchData | null | undefined>(cacheData)
  const [error, setError] = useState<Error | null | undefined>()

  useEffect(
    () => {
      setLoading(true)

      const promise = args ? promiseFn(...args) : promiseFn()

      promise
        .then(responseData => {
          if (cacheOptions) setCache(responseData)
          setData(responseData)
        })
        .catch(setError)
        .finally(() => setLoading(false))
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(cacheOptions)]
  )

  return {
    loading,
    data: cacheOptions ? cacheData : data,
    error
  }
}

export function useCallableFetch<FetchData>(
  promiseFn: (...args: any[]) => Promise<FetchData>,
  cacheOptions?: CacheOptions
): {
  loading: boolean
  runFetch: (...args: any[]) => Promise<FetchData | null | undefined>
} {
  const { setCache } = useCache(cacheOptions)
  const [loading, setLoading] = useState(false)

  function runFetch(...args: any[]): Promise<FetchData | null | undefined> {
    setLoading(true)

    return promiseFn(...args)
      .then(data => {
        if (cacheOptions) setCache(data)
        return data
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    runFetch
  }
}
