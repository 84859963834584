import ContentHeader from '../../layouts/ContentHeader'
import Well, { WellTextIcon } from '../../shared/Well'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import DownloadSteps from './DownloadSteps'

const Step = ({ title, description, index }) => (
  <div className="card margin-top-4">
    <Well
      title={title}
      icon={<WellTextIcon>{index + 1}</WellTextIcon>}
      description={description}
      growDescription
    />
  </div>
)

const DownloadsIndex = () => {
  return (
    <section>
      <ContentHeader className="margin-bottom-4 margin-bottom-small-none" divider={false}>
        Download Fauna Dev
      </ContentHeader>

      <div className="card-list">
        {DownloadSteps.map((step, index) => (
          <Step key={index} {...step} index={index} />
        ))}
      </div>
    </section>
  )
}

export default withBreadcrumbs((_, routes) => {
  return [
    {
      label: 'Downloads',
      path: routes.downloads.index
    },
    {
      label: 'Fauna Dev',
      path: routes.downloads.index
    }
  ]
})(DownloadsIndex)
